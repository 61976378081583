import * as React from "react";
import {ExtendedPipeline, PipelineMenuProvider} from "../../utils/pipelines";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {PipelineStatusStyles} from "../../styles/pipelines";
import {Masonry} from "@mui/lab";
import {PipelineStageIcons} from "./PipelineStageIcons";
import {monopolisTheme} from "../../../common/styles/theme";
import {CalendarIcon} from "@primer/octicons-react";
import {DateFromNow} from "../DateFromNow";
import {Link} from "../../../common/components/navigation/Link";
import {PipelineStatusIcon} from "./PipelineStatusIcon";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type PipelinesRadiatorPanelProps = {
  pipelines: ExtendedPipeline[];
  menu: PipelineMenuProvider;
}

export function PipelinesRadiatorPanel({pipelines, menu}: PipelinesRadiatorPanelProps) {
  return <Grid item xs={12}>
      <Masonry columns={{xs: 1, sm: 3, md: 4, lg: 6}} spacing={1}>
        {pipelines.map(pipeline => <PipelineRadiatorCard pipeline={pipeline} menu={menu}/>)}
      </Masonry>
  </Grid>
}

export type PipelineRadiatorCardProps = {
  pipeline: ExtendedPipeline;
  menu: PipelineMenuProvider;
}

export function PipelineRadiatorCard({pipeline, menu}: PipelineRadiatorCardProps) {
  const {urls} = useUserAppInfrastructure()
  const style = PipelineStatusStyles[pipeline.status]
  const run = pipeline.runs[0];
  const mainColor = style.backgroundColor;
  const textColor = monopolisTheme.palette.primary.main;
  const path = pipeline.path;
  const branch = pipeline.branch;
  const monode = pipeline.name;
  return <Paper sx={{
    backgroundColor: mainColor,
    borderColor: mainColor,
    borderWidth: "3px"
  }}>
    <Box sx={{py: 1, pr: 1, display: "flex", flexDirection: "row", alignItems: "center", position: "relative"}}>
      <Box sx={{mr: 0.5}}>
        <PipelineStatusIcon variant="job" status={pipeline.status} styles={PipelineStatusStyles} isFilled={false}
                            hasLink={false}/>
      </Box>
      <Box sx={{width: "100%"}}>
        <Typography maxWidth={"calc(100% - 45px)"} variant="body2" noWrap sx={{textOverflow: "ellipsis"}}
                    color={style.color}
                    fontSize={"medium"}
                    fontWeight="700">
          <Link href={urls.monode({path, branch, monode})} color={style.color}>
            {monode}
          </Link>
        </Typography>
      </Box>
      <Box sx={{position: "absolute", top: 0, right: 0}}>
        <Typography color={style.color}>
          {menu({pipeline, run})}
        </Typography>
      </Box>
    </Box>
    <Box sx={{
      backgroundColor: style.backgroundShade,
      borderRadius: "0.5em",
    }}>
      <Box sx={{p: 1}}>
        <PipelineStageIcons stages={run.stages} rollouts={run.rollouts}/>
      </Box>

      <Box sx={{p: 1, pt:0}} width={"100%"}>
        <Typography noWrap maxWidth={"100%"} variant="caption" display="block" color={textColor}>
          <Link href={urls.repository(path, branch)} color={textColor}>{path.org + "/" + path.repo}</Link>
        </Typography>

        <Box sx={{mt: 0.5}}/>

        <Typography variant="caption" color={textColor}>
          <CalendarIcon/> <DateFromNow variant="caption" display="inline"
                                       color={textColor}
                                       date={run.startedAt}/>
        </Typography>

      </Box>
    </Box>
  </Paper>
}
