import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Title} from './Title';
import {Link} from "../../../common/components/navigation/Link";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export function Deposits() {
  return (
      <React.Fragment>
        <Title>Recent Deposits</Title>
        <Typography component="p" variant="h4">
          $3,024.00
        </Typography>
        <Typography color="text.secondary" sx={{flex: 1}}>
          on 15 March, 2019
        </Typography>
        <div>
          <Link color="primary" href="#" onClick={preventDefault}>
            View balance
          </Link>
        </div>
      </React.Fragment>
  );
}
