import * as React from "react";
import {FormElement} from "../../../common/components/form";
import {ConfigurationSection, ConfigurationSectionDivider} from "../../../common/components/configuration";
import {OAuthConfigurationPanel} from "./OAuthConfigurationPanel";
import {AccessTokenConfigurationPanel} from "./AccessTokenConfigurationPanel";
import {useAccountConfigurationQuery} from "../../api/account";
import {Skeleton} from "@mui/material";
import {OnError} from "../../../common/api/TransportLayer";

export type SecurityConfigurationPanelProps = {
} & OnError

export function SecurityConfigurationPanel({onError}: SecurityConfigurationPanelProps) {
  const {data: config}  = useAccountConfigurationQuery({onQueryError: onError})
  if (!config) return <Skeleton height={50}/>
  if (config.kind === "error") return <></>

  return <ConfigurationSection data-test="security-config" label="Security">
    <OAuthConfigurationPanel config={config.data}/>
    <FormElement>
      <ConfigurationSectionDivider spacing={3}/>
    </FormElement>
    <AccessTokenConfigurationPanel/>
  </ConfigurationSection>
}


