import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography
} from "@mui/material";
import {CancelButton} from "../../../common/components/navigation/CancelButton";
import {useCreateDashboardMutation} from "../../api/dashboard";
import {Dashboard} from "../../api/dashboard/model";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {VcsOwner} from "../../../common/models";

export type CreateDashboardDialogProps = {
  owner: VcsOwner
  onSuccessful: (dashboard: Dashboard) => void
} & OnError

export function CreateDashboardDialog(
    {
      owner,
      onSuccessful,
      onError
    }: CreateDashboardDialogProps) {
  const {analytics} = useUserAppInfrastructure()
  const [open, setOpen] = useState(false);

  useEffect(() => {
    analytics.dialogview("Create Dashboard")
  }, [analytics]);

  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  const createDashboard = useCreateDashboardMutation({owner})

  const handleCreateDashboard = useCallback(async () => {
        setOpen(true)
      }, [setOpen]
  )

  const handleOnCreate = useCallback(async () => {
    setOpen(false)
    const response = await createDashboard.mutateAsync({name, widgets: []});
    const isSuccess = response.kind === "ok";

    if (isSuccess) {
      onSuccessful(response.data)
      analytics.event("User", "Create Dashboard", `Create dashboard successful`)
      setName("")
    } else {
      analytics.event("User", "Create Dashboard", `Create dashboard failure`)
      onError && onError(response.error)
      setName("")
    }
  }, [analytics, name, onError, onSuccessful, createDashboard])

  const handleOnClose = useCallback(async () => {
        setOpen(false)
      }, [setOpen]
  )

  const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValidName(value.length > 0)
    setName(value)
  }, [setName]);

  return (
      <Box>
        <Button data-test="create-dashboard-btn" aria-label="Register email button" variant="outlined" color="primary"
                onClick={handleCreateDashboard}>
          Create Dashboard
        </Button>
        <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={handleOnClose}>
          <DialogTitle>
            Create Dashboard
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <DialogContentText component={'span'} color="primary">
              <Typography variant="body1">
                Enter details of a dashboard you would like to create
              </Typography>
            </DialogContentText>

            <DialogContentText color="primary" sx={{mt: 3, mb: 3}}>
              <TextField id="name" type="text" value={name} fullWidth={true} autoFocus={true} label="Name"
                         variant="outlined"
                         inputProps={{
                           "data-test": "create-dashboard-dialog-name"
                         }}
                         onChange={handleChangeName}/>
            </DialogContentText>
          </DialogContent>

          <Divider/>

          <DialogActions>
            <CancelButton onClick={handleOnClose}/>
            <Button data-test="create-dashboard-dialog-btn" aria-label="Create"
                    variant="contained"
                    disabled={!validName}
                    onClick={handleOnCreate}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Box>);
}
