import React, {useState} from 'react';
import {closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors,} from '@dnd-kit/core';
import {arrayMove, rectSortingStrategy, SortableContext,} from '@dnd-kit/sortable';

import {DragGrid} from './DragGrid';
import {SortablePhoto} from './SortablePhoto';
import {Photo} from './Photo';
import {DragEndEvent, DragStartEvent} from "@dnd-kit/core/dist/types";

const photos = [
  "https://source.unsplash.com/WLUHO9A_xik/900x900",
  "https://source.unsplash.com/R4K8S77qtwI/900x900",
  "https://source.unsplash.com/jJGc21mEh8Q/900x900",
  "https://source.unsplash.com/omNxg6JP6Fo/900x900",
  "https://source.unsplash.com/-M1gkucIqkQ/900x900",
  "https://source.unsplash.com/czOuPVsfHDw/900x900",
  "https://source.unsplash.com/-vr0gMUM6Fk/900x900",
  "https://source.unsplash.com/TsMuMM_qVec/900x900"
]


const UploadGallery = () => {
  const [items, setItems] = useState(photos);
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
      <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <DragGrid columns={3}>
            {items.map((url, index) => (
                <SortablePhoto key={url} url={url} index={index}/>
            ))}
          </DragGrid>
        </SortableContext>

        <DragOverlay adjustScale={true}>
          {activeId ? (
              <Photo url={activeId} index={items.indexOf(activeId.toString())}/>
          ) : null}
        </DragOverlay>
      </DndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id.toString());
  }

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (over && active && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id.toString());
        const newIndex = items.indexOf(over.id.toString());

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(undefined);
  }

  function handleDragCancel() {
    setActiveId(undefined);
  }
};

export default UploadGallery;
