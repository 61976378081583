import * as React from 'react';
import {SettingsOutlined} from "@mui/icons-material";
import {Menu, MenuActionDefinition} from "../../../common/components/navigation/Menu";
import {PermissionTemplate, UserPermissions} from "../../auth/Permissions";
import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {VcsOwner} from "../../../common/models";
import {useNavigation} from "../../../common/port/Navigation";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type OrgBreadcrumbsMenuAction = "orgConfiguration"

export type OrgBreadcrumbsMenuProps = {
  owner: VcsOwner,
  userProfile: AuthenticatedUserProfile,
  actions: OrgBreadcrumbsMenuAction[]
  onSuccessfulAction?: (action: OrgBreadcrumbsMenuAction) => void
}

export function OrgBreadcrumbsMenu({owner, actions, userProfile}: OrgBreadcrumbsMenuProps) {
  const {navigateTo} = useNavigation()
  const {urls} = useUserAppInfrastructure()
  const actionDefinitionIfAllowed = (action: OrgBreadcrumbsMenuAction, permission: PermissionTemplate): (MenuActionDefinition | undefined) => {
    if (actions.includes(action) && UserPermissions.checkAllowed(userProfile, permission, {owner})) {
      switch (action) {
        case "orgConfiguration":
          return {
            id: action,
            text: "Settings",
            icon: <SettingsOutlined fontSize="small"/>,
            onAction: () => navigateTo(urls.orgConfiguration(owner))
          }
      }
    }
  }

  return <Menu actions={[
    actionDefinitionIfAllowed("orgConfiguration", UserPermissions.EditOrganisation)
  ]}/>
}
