import * as React from 'react';
import {useState} from 'react';

import {AccountConfigurationPageTab, AccountConfigurationPageTabProps} from "./AccountConfigurationPageTab";
import {AccountConfigurationPageBillingTab} from "./AccountConfigurationPageBillingTab";
import {SystemError} from "../../../common/api/TransportLayer";
import {useTabControls} from "../../../common/tabs";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {useAccountAuthentication} from "../../auth/useAccountAuthentication";
import {AccountTrialEndingBanner} from "../../components/AccountTrialEndingBanner";
import {AccountConfigurationPageInstallationTab} from "./AccountConfigurationPageInstallationTab";
import {AccountConfigurationPageOrganisationsTab} from "./AccountConfigurationPageOrganisationsTab";
import {AccountAppPage} from "../../layout/AccountAppPage";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {AccountPageId} from "../AccountPageId";
import {useAccountAppInfrastructure} from "../../AccountAppInfrastructure";

export function AccountConfigurationPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {accountProfile} = useAccountAuthentication()
  const {urls} = useAccountAppInfrastructure()

  const tabs = useTabControls({
    defaultTab: AccountConfigurationPageTab.installation,
    tabs: [
      {title: "Installation", id: AccountConfigurationPageTab.installation},
      {title: "Organisations", id: AccountConfigurationPageTab.organisations},
      {title: "Billing", id: AccountConfigurationPageTab.billing},
    ]
  })
  const banner = <AccountTrialEndingBanner onError={setError}/>

  if (accountProfile.type === AuthenticationType.Unauthenticated) return <UnauthorizedPage/>

  const header = <PageHeader
      title="Settings"
      location={urls.settings()}
      tabs={tabs}
  />

  const tabProps: AccountConfigurationPageTabProps = {
    profile: accountProfile,
    onError: setError,
  }

  return <AccountAppPage pageId={AccountPageId.AccountConfiguration} title="Settings" contentHeader={header}
                         banner={banner} error={error} profile={accountProfile}>
    <PageTab selectedId={tabs.selected} id={AccountConfigurationPageTab.installation}>
      <AccountConfigurationPageInstallationTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={AccountConfigurationPageTab.organisations}>
      <AccountConfigurationPageOrganisationsTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={AccountConfigurationPageTab.billing}>
      <AccountConfigurationPageBillingTab {...tabProps}/>
    </PageTab>

  </AccountAppPage>
}
