import * as React from "react";
import {RepositorySettingsTabProps} from "./RepositoryConfigurationPageTab";
import {
  useRepoNotificationSubscribeMutation,
  useRepoNotificationSubscriptionsQuery,
  useRepoNotificationUnsubscribeMutation
} from "../../api/notifications";
import {NotificationsPanel} from "../../components/notifications/NotificationsPanel";

export function RepositoryConfigurationPageNotificationsTab({path, owner, onError}: RepositorySettingsTabProps) {
  const subscriptionsQuery = useRepoNotificationSubscriptionsQuery({path, refetchInterval: 5000})
  const notificationSubscribeMutation = useRepoNotificationSubscribeMutation({path})
  const notificationUnsubscribeMutation = useRepoNotificationUnsubscribeMutation({path})
  return <NotificationsPanel owner={owner}
                             subscriptionsQuery={subscriptionsQuery}
                             subscribeMutation={notificationSubscribeMutation}
                             unsubscribeMutation={notificationUnsubscribeMutation}
                             onError={onError}/>
}
