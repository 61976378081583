import * as React from "react";
import {ReactNode, useCallback, useMemo} from "react";
import {useSnackbar} from "notistack";
import {Box, Divider, IconButton, List, ListItem, Paper, Skeleton, Typography} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {VcsOwner} from "../../../common/models";
import {AccountConfigurationPageTabProps} from "./AccountConfigurationPageTab";
import {
  OrgMutationRequest,
  useAccountAvailableOrgsQuery,
  useAccountConfigurationQuery,
  useAccountConfiguredOrgsQuery,
  useAccountDisableOrgMutation,
  useAccountEnableOrgMutation
} from "../../api/account";
import {snackOptions} from "../../../common/snackbar";
import {OrgSearchItem, OrgSearchPanel} from "../../../common/components/OrgSearchPanel";
import {DeleteMutation, PutMutation} from "../../../common/api/query";
import {CustomerOnboardingMode} from "../../api/account/model";

type ActionMutation = PutMutation<OrgMutationRequest> | DeleteMutation<OrgMutationRequest>

export function AccountConfigurationPageOrganisationsTab({onError}: AccountConfigurationPageTabProps) {
  const {data: configuration} = useAccountConfigurationQuery({onQueryError: onError})
  const configurable = configuration && configuration.kind === "ok" && configuration?.data?.onboardingMode === CustomerOnboardingMode.Manual && configuration?.data.accessTokenSet
  const {data: configuredOrgs} = useAccountConfiguredOrgsQuery({onQueryError: onError})
  const {data: availableOrgs} = useAccountAvailableOrgsQuery({enabled: configurable, onQueryError: onError})
  const enableOrg = useAccountEnableOrgMutation()
  const disableOrg = useAccountDisableOrgMutation()
  const {enqueueSnackbar} = useSnackbar();

  const availableOrgItems = useMemo(() => availableOrgs && availableOrgs.kind === "ok"
      ? availableOrgs.data.map(x => ({owner: x}))
      : [], [availableOrgs]
  )

  const configuredOrgItems = useMemo(() =>
      configuredOrgs && configuredOrgs.kind === "ok"
          ? availableOrgItems.filter(org => configuredOrgs.data.indexOf(org.owner) >= 0)
          : [], [configuredOrgs, availableOrgItems]
  )

  const configuredOrgsLookup = useMemo(() =>
      Object.fromEntries(
          (configuredOrgs && configuredOrgs.kind === "ok" ? configuredOrgs.data : []).map(e => [e, e])
      ), [configuredOrgs]
  )

  const handleAction = useCallback(async (mutation: ActionMutation, item: OrgSearchItem, success: string, failure: string) => {
    const response = await mutation.mutateAsync(item);
    const isSuccess = response.kind === "ok";
    enqueueSnackbar(isSuccess ? success : failure, snackOptions(isSuccess))
  }, [enqueueSnackbar])

  const onSubscribe = async (org: OrgSearchItem) => await handleAction(enableOrg, org,
      `Enabled org ${org.owner}`,
      `Failed to enable org ${org.owner}`
  )

  const onUnsubscribe = async (org: OrgSearchItem) => await handleAction(disableOrg, org,
      `Disabled org ${org.owner}`,
      `Failed to disable org ${org.owner}`
  )

  if (!configuration) return <Skeleton height={50}/>
  if (configuration && configuration.kind === "error") return <></>

  const all: OrgSearchItem[] = availableOrgItems
  const selected = configuredOrgItems

  return <Box sx={{mb: 5}}>
    <Paper>
      <Box sx={{pt: 2, px: 2}}>
        <OrgSearchPanel
            all={all}
            selected={selected}
            disabled={!configurable}
            onError={onError}
            onAdd={onSubscribe}
            onRemove={onUnsubscribe}
        />
      </Box>
      <List>
        {selected.map((item, index) =>
            <Box key={item.owner}>
              <OrgRow
                  org={configuredOrgsLookup[item.owner]}
                  menu={() =>
                      <IconButton aria-label="disable" onClick={() => onUnsubscribe(item)}>
                        <CloseRoundedIcon/>
                      </IconButton>
                  }/>
              {index !== selected.length - 1 && <Divider sx={{mt: 1, mb: 1}}/>}
            </Box>
        )}
      </List>
    </Paper>
  </Box>
}


type OrgRowMenuProvider = (params: { owner: VcsOwner }) => ReactNode

type OrgRowProps = {
  org: VcsOwner
  menu: OrgRowMenuProvider
}

function OrgRow({org, menu}: OrgRowProps) {
  const spacingWidth = 30
  const menuSpacingWidth = 10

  return <ListItem>
    <Box sx={{display: "flex", flexDirection: "row", height: 35, width: "100%"}} alignItems="center">
      <Box sx={{flexGrow: 1, width: "100%", marginRight: `${spacingWidth}px`}}>
        <Box alignItems="center" display="flex" flexDirection="row">
          <Typography>{org}</Typography>
        </Box>
      </Box>
      <Box sx={{flex: 1, marginLeft: `${menuSpacingWidth}px`}}>
        {menu({owner: org})}
      </Box>
    </Box>
  </ListItem>
}
