import * as React from 'react';
import {ReactNode, useEffect} from 'react';
import Box from '@mui/material/Box';
import Container, {ContainerProps} from '@mui/material/Container';
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {Divider, PaperProps} from "@mui/material";
import {SystemError} from "../api/TransportLayer";
import {SimpleFooter} from "./SimpleFooter";
import {ErrorPanel} from "../components/ErrorPanel";
import {monopolisTheme} from "../styles/theme";
import {monopolisConstraints, ResponsiveDisplay} from "../styles/constraints";
import {Analytics} from "../port/Analytics";
import {useCommonAppInfrastructure} from "../CommonAppInfrastructure";

export type PageProps = {
  title: string;
  pageId?: string;
  error: SystemError | null;
  analytics: Analytics;
  fullWidth?: boolean;
  banner?: ReactNode
  navigationHeader?: ReactNode
  contentHeader?: ReactNode
  sidebar?: ReactNode;
  headerProps?: PaperProps;
} & ContainerProps;


export function Page({
                       analytics,
                       children,
                       title,
                       pageId,
                       navigationHeader,
                       contentHeader,
                       banner,
                       sidebar,
                       headerProps,
                       error
                     }: PageProps) {
  const {config} = useCommonAppInfrastructure()
  useEffect(() => {
    const actualTitle = config.appName + (title ? ": " + title : "");
    document.title = actualTitle;
    analytics.pageview(actualTitle)
  }, [analytics, title, config]);

  const testId = pageId ? {"data-test": "page-id-" + pageId} : {}

  return (<Box sx={{overflowY: "hidden", height: "100vh", width: "100vw", display: "flex", flexDirection: "column"}}
               {...testId}
      >
        <CssBaseline/>
        <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
        {banner}
        {navigationHeader}

        <Box
            component="main"
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              overflowY: "auto",
              overflowX: "auto",
              width: "100vw",
            }}
        >
          {contentHeader ?
              <Box
                  sx={{
                    p: 0,
                    top: 0,
                    position: "sticky",
                    borderLeft: 0,
                    borderBottom: 1,
                    borderRight: 0,
                    borderTop: 0,
                    borderRadius: 0,
                    borderColor: monopolisTheme.palette.secondary.main,
                    backgroundColor: monopolisTheme.palette.background.paper,
                    zIndex: 1000
                  }}
                  {...headerProps}
              >
                <Container maxWidth={false}>
                  {contentHeader}
                </Container>
              </Box>
              : <></>}

          <Box
              component="main"
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                overflowY: "auto",
                overflowX: "auto",
                width: "100vw",
              }}
          >

            <Container maxWidth={false} sx={{flexGrow: 1, p: 3, pb: 6}}>
              {error ? <ErrorPanel error={error} sx={{my: 2}}/> : <></>}

              <ResponsiveDisplay
                  breakpoint={monopolisConstraints.sidebarBreakPoint}
                  standard={() =>
                      <Container maxWidth={"xl"} sx={{flexGrow: 1}}>
                        <Box sx={{
                          display: "flex",
                          flexDirection: "row"
                        }}>
                          <Box sx={{flexGrow: 1}}>{children}</Box>
                          {sidebar &&
                              <Box sx={{
                                ml: 6,
                                mt: 2,
                                maxWidth: monopolisConstraints.sidebarWidth + "px",
                                minWidth: monopolisConstraints.sidebarWidth + "px",
                              }}>{sidebar}</Box>}
                        </Box>
                      </Container>
                  }
                  compact={() =>
                      <Box sx={{
                        display: "flex",
                        flexDirection: "column"
                      }}>
                        {sidebar}
                        {sidebar && <Divider sx={{mt: 2, mb: 2}}/>}
                        {children}
                      </Box>}/>
            </Container>
            <SimpleFooter/>
          </Box>
        </Box>
      </Box>
  );
}
