import {
    AuditEvent,
    AuditEventAggregate,
    AuditEventSpan,
    AuditEventSpanSeverity,
    AuditEventSpanStatus,
    AuditEventType,
    AuditEventTypes,
} from "../api/events/model";
import {MaxTimelineWeight, TimelineDate, TimelineWeight} from "../components/events/EventsDatesView";
import {distinct, groupBy, last} from "../../common/arrays";
import {RepoMonode} from "../models";
import {epochMillisAtUTC, formatUTCToLocalDate} from "../../common/dateTime";
import {monopolisTheme} from "../../common/styles/theme";

export type AuditEventStatistics = Record<AuditEventType, number> & {
    aggregate: string,
}

export function auditEventsAsTimelinePoints(auditEvents: AuditEventAggregate, eventTypes: AuditEventType[] = []): TimelineDate[] {
    const eventCounts = auditEvents.dates.map(evt => evt.events.length);
    eventCounts.push(1)
    const bracketSize = (Math.max.apply(Math, eventCounts)) / MaxTimelineWeight;
    return auditEvents.dates.map(eventAgg => {
        const eventsForDate = filterAuditEventsByType(eventAgg.events, eventTypes);
        const weight = Math.floor(Math.min(eventsForDate.length / bracketSize, MaxTimelineWeight));
        return ({
            date: eventAgg.date,
            weight: weight as TimelineWeight,
            events: eventsForDate.map(auditEvent => {
                const span = last(auditEvent.spans)!!;
                return {
                    type: auditEvent.eventType,
                    status: span.status,
                    severity: span.severity,
                    dateTime: span.updatedAt,
                    title: eventMessageFor(auditEvent, span)
                };
            })
        });
    })
}

export function eventEntityFor(event: AuditEvent): string {
    switch (event.eventType) {
        case AuditEventType.Push:
            return `${event.repo}@${event.branch}`

        case AuditEventType.Rollout:
            return `${event.repo}/${event.monode}`

        case AuditEventType.Incident:
            return `${event.repo}/${event.monode}`

        case AuditEventType.MonodeAlert:
            return `${event.repo}/${event.monode}`
    }
}

export function totalEvents(aggregate: AuditEventAggregate): number {
    return aggregate.dates.map(date => date.events.length).reduce((sum, current) => sum + current)
}

export function allAuditEventsFrom(aggregate: AuditEventAggregate): AuditEvent[] {
    return aggregate.dates.flatMap(date => date.events)
}

export function monodesFromAuditEvents(events: AuditEvent[]): RepoMonode[] {
    return Object.keys(groupBy(events, event => eventEntityFor(event))).sort()
}

export function datesFromAuditEvents(events: AuditEvent[]): string[] {
    return distinct(events.map(event => formatUTCToLocalDate(last(event.spans)!!.updatedAt))).sort()
}

export function aggregateAuditEventsByDate(events: AuditEvent[]): AuditEventStatistics[] {
    const sortedEvents = events
        .sort((a, b) => epochMillisAtUTC(last(b.spans)!!.updatedAt) - epochMillisAtUTC(last(a.spans)!!.updatedAt))
    const groupedByDate = groupBy(sortedEvents, event => formatUTCToLocalDate(last(event.spans)!!.updatedAt))
    return datesFromAuditEvents(events).map(date => countAuditEventsByType(date, groupedByDate[date] || []));
}

export function countAuditEventsByType(aggregate: string, events: AuditEvent[]): AuditEventStatistics {
    const grouped = groupBy(events, event => event.eventType);
    const stats: Partial<AuditEventStatistics> = {}
    for (const auditEventType of AuditEventTypes) {
        stats[auditEventType] = grouped[auditEventType]?.length || 0
    }

    // @ts-ignore
    return {...stats, aggregate}
}

export function filterAuditEventsByType(events: AuditEvent[], types: AuditEventType[]): AuditEvent[] {
    return types.length === 0 ? events : events.filter(event => types.indexOf(event.eventType) >= 0)
}

export function eventMessageFor(event: AuditEvent, span: AuditEventSpan): string {
    switch (event.eventType) {
        case AuditEventType.Push:
            return `Push to ${event.repo} on branch '${event.branch}' ${eventSpanSeverityFor(span).toLowerCase()}`

        case AuditEventType.Rollout:
            return `Rollout of ${event.repo}/${event.monode} in ${event.target} ${eventSpanSeverityFor(span).toLowerCase()}`

        case AuditEventType.Incident:
            return `Incident ${event.repo}/${event.monode} in ${event.target} ${eventSpanSeverityFor(span).toLowerCase()}`

        case AuditEventType.MonodeAlert:
            return `Monode status for ${event.repo}/${event.monode} in ${event.target} ${eventSpanSeverityFor(span).toLowerCase()}`
    }
}


export function eventSpanColorFor(severity: AuditEventSpanSeverity): string {
    switch (severity) {
        case AuditEventSpanSeverity.Success:
            return monopolisTheme.palette.success.main

        case AuditEventSpanSeverity.Failure:
            return monopolisTheme.palette.error.main

        case AuditEventSpanSeverity.Warning:
            return monopolisTheme.palette.warning.main

        case AuditEventSpanSeverity.Info:
            return monopolisTheme.palette.info.main
    }
}

function eventSpanSeverityFor(span: AuditEventSpan): string {
    switch (span.severity) {
        case AuditEventSpanSeverity.Success:
            return span.status === AuditEventSpanStatus.Open ? "Succeeding" : "Successful"

        case AuditEventSpanSeverity.Warning:
            return span.status === AuditEventSpanStatus.Open ? "In Progress" : "Completed"

        case AuditEventSpanSeverity.Info:
            return span.status === AuditEventSpanStatus.Open ? "In Progress" : "Completed"

        case AuditEventSpanSeverity.Failure:
            return span.status === AuditEventSpanStatus.Open ? "Failing" : "Failed"
    }
}
