import {RolloutGraphPanel} from "./RolloutGraphPanel";
import {OnError, SystemResponse} from "../../../common/api/TransportLayer";
import {RolloutGraph} from "../../api/graphs/model";
import {AuditEventAggregate} from "../../api/events/model";
import {Grid} from "@mui/material";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {EventsDatesView} from "../events/EventsDatesView";
import * as React from "react";
import {epochMillisAtEndOfDayUTC, epochMillisAtUTC} from "../../../common/dateTime";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {QueryResult} from "../../../common/api/query";
import {DateTime} from "../../../common/models";
import {VcsProvider} from "../../../common/models";

export function RolloutTimelinePanel(
    {
      vcs,
      graphResult,
      eventsResult,
      onError,
      onTimestampSelected
    }: RolloutTimelinePanelProps) {

  return (<Grid container spacing={monopolisConstraints.gridSpacing}>
    <Grid item xs={12} md={12} lg={12} sx={{height: 500}}>
      <RolloutGraphPanel vcs={vcs} graphResult={graphResult} onError={onError}/>
    </Grid>

    <Grid item xs={12} md={12} lg={12}>


      <QueryLoader result={eventsResult} onError={onError} onLoaded={events =>
          <>
            <EventsDatesView data={events}
                             onSelectDate={date => onTimestampSelected(epochMillisAtEndOfDayUTC(date.date).toString())}
                             onSelectEvent={event => onTimestampSelected(epochMillisAtUTC(event.dateTime).toString())}
            />
          </>
      }/>
    </Grid>
  </Grid>)
}

export type RolloutTimelinePanelProps = {
  vcs: VcsProvider;
  graphResult: QueryResult<SystemResponse<RolloutGraph>>
  eventsResult: QueryResult<SystemResponse<AuditEventAggregate>>
  onTimestampSelected: (error: DateTime) => void
} & OnError
