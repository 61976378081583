import {Grid} from "@mui/material";
import * as React from "react";
import {StatisticsAggregate} from "../../api/statistics/model";
import {PipelinesStatisticsData} from "../pipelines/PipelinesStatisticsData";
import {humaniseDuration} from "../../../common/dateTime";
import {Monode, RolloutTarget} from "../../models";
import {OnError, SystemResponse} from "../../../common/api/TransportLayer";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {BadgeDialog} from "./BadgeDialog";
import {BadgesUrls} from "../../api/badges/urls";
import {formatBreadcrumbs} from "../../../common/breadcrumbs";
import {RolloutGraph} from "../../api/graphs/model";
import {AuditEventAggregate} from "../../api/events/model";
import {RolloutTimelinePanel} from "../graphs/RolloutTimelinePanel";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {QueryResult} from "../../../common/api/query";
import {CustomerId, DateTime, OrgRepoPath, VcsOwner, VcsProvider} from "../../../common/models";

export type RolloutPanelProps = {
  statisticsResult: QueryResult<SystemResponse<StatisticsAggregate>>
  graphResult?: QueryResult<SystemResponse<RolloutGraph>>
  eventsResult?: QueryResult<SystemResponse<AuditEventAggregate>>
  target: RolloutTarget
  onTimestampSelected?: (error: DateTime) => void
  customerId: CustomerId
  vcs: VcsProvider
  urls: BadgesUrls
  org: VcsOwner
  path?: OrgRepoPath
  monode?: Monode
  showDoraStats: boolean
} & OnError

export function RolloutPanel({
                               path,
                               customerId,
                               vcs,
                               org,
                               target,
                               monode,
                               statisticsResult,
                               graphResult,
                               eventsResult,
                               onError,
                               onTimestampSelected,
                               showDoraStats,
                               urls
                             }: RolloutPanelProps) {

  const badgeLinkParams = {customerId, org, path, target, monode}
  const showStats = (graphResult || showDoraStats);

  if (!showStats) return <></>

  return <Grid container item spacing={monopolisConstraints.gridSpacing}>
    {
        showDoraStats &&
        <QueryLoader enabled={true} height={150} result={statisticsResult} onError={onError} onLoaded={statistics => {
          const rolloutName = !path ? `${org} monodes` : formatBreadcrumbs(path.org, path.repo) + (monode ? " / " + monode : "");
          const statisticsDays = statistics.dates.length;

          return <Grid item container spacing={monopolisConstraints.gridSpacing}>
            <Grid item xs={12} md={3} lg={3}>
              <PipelinesStatisticsData title="Rollout Frequency"
                                       link={<BadgeDialog title="Rollout Frequency"
                                                          description={`Average number of rollouts of ${rolloutName} to ${target} per day over the period of last ${statisticsDays} days.`}
                                                          uri={urls.rolloutFrequency(badgeLinkParams)}/>}
                                       graph={statistics.dates.map(date => ({value: date.successful + date.failed}))}
                                       value={((statistics.successful + statistics.failed) / statistics.dates.length).toFixed(2).toString()}/>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <PipelinesStatisticsData title="Change Lead Time"
                                       link={<BadgeDialog title="Change Lead Time"
                                                          description={`Average duration from commit to completed rollout of ${rolloutName} to ${target} over the period of last ${statisticsDays} days.`}
                                                          uri={urls.changeLeadTime(badgeLinkParams)}/>}
                                       graph={statistics.dates.map(date => ({value: date.duration.avg}))}
                                       value={humaniseDuration(statistics.durationFromCommit.avg)}/>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <PipelinesStatisticsData title="Max Change Lead Time"
                                       link={<BadgeDialog title="Max Change Lead Time"
                                                          description={`Maximum duration from commit to completed rollout of ${rolloutName} to ${target} over the period of last ${statisticsDays} days.`}
                                                          uri={urls.maxChangeLeadTime(badgeLinkParams)}/>}
                                       graph={statistics.dates.map(date => ({value: date.duration.max}))}
                                       value={humaniseDuration(statistics.durationFromCommit.max)}/>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <PipelinesStatisticsData title="Change Failure Rate"
                                       link={<BadgeDialog title="Change Failure Rate"
                                                          description={`Rate of rollout failures of ${rolloutName} to ${target} over the period of last ${statisticsDays} days.`}
                                                          uri={urls.changeFailureRate(badgeLinkParams)}/>}
                                       graph={statistics.dates.map(date => ({value: date.failed}))}
                                       value={(statistics.failureRate / 100) + "%"}/>
            </Grid>
          </Grid>
        }}/>
    }

    {
        graphResult && eventsResult && onTimestampSelected &&
        <Grid item xs={12} md={12} lg={12}>
          <RolloutTimelinePanel vcs={vcs}
                                eventsResult={eventsResult}
                                graphResult={graphResult}
                                onTimestampSelected={onTimestampSelected}
                                onError={onError}/>
        </Grid>
    }
  </Grid>
}
