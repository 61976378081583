// noinspection JSMismatchedCollectionQueryUpdate

import React, {ComponentType} from 'react';
import {TextWidgetPanel} from "./TextWidgetPanel";
import {UserWatchlistWidgetPanel} from "./UserWatchlistWidgetPanel";
import {SystemError} from "../../../common/api/TransportLayer";
import {UserOrgsWidgetPanel} from "./UserOrgsWidgetPanel";
import {DashboardWidget, DashboardWidgetType} from "../../api/dashboard/model";
import {BaseDashboardWidget} from "../../api/dashboard/widgets/BaseDashboardWidget";
import {OrgEventsWidgetPanel} from "./OrgEventsWidgetPanel";
import {OrgPipelinesWidgetPanel} from "./OrgPipelinesWidgetPanel";
import {OrgMonodesWidgetPanel} from "./OrgMonodesWidgetPanel";
import {EmbedWidgetPanel} from "./EmbedWidgetPanel";
import {OrgMonodesRadiatorWidgetPanel} from "./OrgMonodesRadiatorWidgetPanel";

export type BaseDashboardWidgetProps = {
  id: string
  height?: number | string
  width?: number
}

export type DashboardWidgetProps<T> = BaseDashboardWidgetProps & {
  type: DashboardWidgetType
  isResizeable: boolean
  isEditable: boolean
  isDeletable: boolean
  isChangingLayout?: boolean
  isEditing?: boolean
  onDelete?: (id: string) => void
  onResize?: (id: string, width: number) => void
  onEdit?: (id: string) => void
  onSave?: (id: string, config: T) => void
  onError: (error: SystemError) => void
} & BaseDashboardWidget<T>

export function asDashboardWidgetProps(widget: DashboardWidget): DashboardWidgetProps<any> {
  const editable: DashboardWidgetType[] = [
    DashboardWidgetType.Embed,
    DashboardWidgetType.Text,
    DashboardWidgetType.OrgEvents,
    DashboardWidgetType.OrgPipelines,
    DashboardWidgetType.OrgMonodes,
    DashboardWidgetType.OrgMonodesRadiator,
  ]

  const resizable: DashboardWidgetType[] = []

  const deletable: DashboardWidgetType[] = [
    DashboardWidgetType.Embed,
    DashboardWidgetType.OrgEvents,
    DashboardWidgetType.OrgPipelines,
    DashboardWidgetType.OrgMonodes,
    DashboardWidgetType.Text,
    DashboardWidgetType.OrgMonodesRadiator,
  ]

  return {
    ...widget,
    isResizable: resizable.indexOf(widget.type) >= 0,
    isEditable: editable.indexOf(widget.type) >= 0,
    isDeletable: deletable.indexOf(widget.type) >= 0,
    onError: () => {
    }
  }
}

const componentForType: Record<DashboardWidgetType, ComponentType<DashboardWidgetProps<any>>> = {
  Embed: EmbedWidgetPanel,
  OrgEvents: OrgEventsWidgetPanel,
  OrgMonodes: OrgMonodesWidgetPanel,
  OrgMonodesRadiator: OrgMonodesRadiatorWidgetPanel,
  OrgPipelines: OrgPipelinesWidgetPanel,
  Text: TextWidgetPanel,
  UserOrgs: UserOrgsWidgetPanel,
  UserWatchlist: UserWatchlistWidgetPanel
}

export function DashboardWidgetView(props: DashboardWidgetProps<any>) {
  // @ts-ignore
  const WidgetComponent = componentForType[props.type]
  return (
      <WidgetComponent {...props}/>
  )
}
