export enum UserRequestId {
    RepositoryPipelines = 'RepositoryPipelines',
    RepositoryMonodes = 'RepositoryMonodes',
    ConfigureRepository = 'ConfigureRepository',
    CommitPipelines = 'CommitPipelines',
    RolloutGraph = 'RolloutGraph',
    PipelineHistory = 'PipelineHistory',
    RepositoryBranches = 'RepositoryBranches',
    RepositoryDefaultBranch = 'RepositoryDefaultBranch',
    RepositoryDependencyGraph = 'RepositoryDependencyGraph',
    OrgDependencyGraph = 'OrgDependencyGraph',
    OrgPipelines = 'OrgPipelines',
    OrgMonodes = 'OrgMonodes',
    MonodeDependencyGraph = 'MonodeDependencyGraph',
    DeletePipeline = 'DeletePipeline',
    RerunPipeline = 'RerunPipeline',
    CancelPipeline = 'CancelPipeline',
    RolloutPipeline = 'RolloutPipeline',
    RepositoryBuildStatistics = 'RepositoryBuildStatistics',
    PipelineBuildStatistics = 'PipelineBuildStatistics',
    RepositoryRolloutStatistics = 'RepositoryRolloutStatistics',
    RepositoryGroupedBuildStatistics = 'RepositoryGroupedBuildStatistics',
    OrgGroupedBuildStatistics = 'OrgGroupedBuildStatistics',
    OrgRolloutStatistics = 'OrgRolloutStatistics',
    OrgSettingsAddIntegration = 'OrgSettingsAddIntegration',
    MonodeRolloutStatistics = 'MonodeRolloutStatistics',
    MonodeDocumentationContent = 'MonodeDocumentationContent',
    MonodeDocumentationList = 'MonodeDocumentationList',
    EnableRepository = 'EnableRepository',
    GenerateOrgApiKey = 'GenerateOrgApiKey',
    DeleteOrgApiKey = 'DeleteOrgApiKey',
    OrgApiKeys = 'OrgApiKeys',
    DisableRepository = 'DisableRepository',
    OrgIntegrationSettings = 'OrgIntegrationSettings',
    OrgIntegrationSettingsWithSecrets = 'OrgIntegrationSettingsWithSecrets',
    OrgAllIntegrationsSettings = 'OrgAllIntegrationsSettings',
    OrgIntegrationRemove = 'OrgIntegrationRemove',
    EnableMember = 'EnableMember',
    DisableMember = 'DisableMember',
    OrgPlanHistory = 'OrgPlanHistory',
    OrgMembers = 'OrgMembers',
    OrgConfiguration = 'OrgConfiguration',
    AllOrgRepositories = 'AllOrgRepositories',
    ConfiguredOrgRepositories = 'ConfiguredOrgRepositories',
    OrgTargets = 'OrgTargets',
    MonodeRollouts = 'MonodeRollouts',
    RepositoryRollouts = 'RepositoryRollouts',
    OrgEvents = 'OrgEvents',
    PostInstallationDetails = 'PostInstallationDetails',
    RepositoryEvents = 'RepositoryEvents',
    MonodeEvents = 'MonodeEvents',
    MonodePluginsConfig = 'MonodePluginsConfig',
    RepoMonodesPluginsConfigs = 'RepoMonodesPluginsConfigs',
    OrgMonodesPluginsConfigs = 'OrgMonodesPluginsConfigs',
    MonodeLinks = 'MonodeLinks',
    MonodeTargetLinks = 'MonodeTargetLinks',
    RepoLinks = 'RepoLinks',
    Dashboard = 'Dashboard',
    CreateDashboard = 'CreateDashboard',
    UpdateDashboard = 'UpdateDashboard',
    DeleteDashboard = 'DeleteDashboard',
    Dashboards = 'Dashboards',
    EmailRegistrationInitiate = 'EmailRegistrationInitiate',
    EmailRegistrationComplete = 'EmailRegistrationComplete',
    UserEmailRemove = 'UserEmailRemove',
    UpdateUserDashboard = 'UpdateUserDashboard',
    UserDashboard = 'UserDashboard',
    UserOrgs = 'UserOrgs',
    UserEmails = 'UserEmails',
    UpdateUserWatchlist = 'UpdateUserWatchlist',
    AddToUserWatchlist = 'AddToUserWatchlist',
    RemoveFromUserWatchlist = 'RemoveFromUserWatchlist',
    UserWatchlist = 'UserWatchlist',
    UserProfile = 'UserProfile',
    UserOptionEnable = 'UserOptionEnable',
    UserOptionDisable = 'UserOptionDisable',
    RegisterTwoFactor = 'RegisterTwoFactor',
    OrgNotificationChannels = 'OrgNotificationChannels',
    OrgNotificationSubscriptions = 'OrgNotificationSubscriptions',
    OrgNotificationSubscribe = 'OrgNotificationSubscribe',
    OrgNotificationUnsubscribe = 'OrgNotificationUnsubscribe',
    RepoNotificationSubscriptions = 'RepoNotificationSubscriptions',
    RepoNotificationSubscribe = 'RepoNotificationSubscribe',
    RepoNotificationUnsubscribe = 'RepoNotificationUnsubscribe',
}
