import {Box, Divider, List, ListItem, Typography} from "@mui/material";
import * as React from "react";
import {Panel} from "../../../common/components/Panel";
import {Link} from "../../../common/components/navigation/Link";
import {formatBreadcrumbs} from "../../../common/breadcrumbs";
import {VcsIcon} from "../VcsIcon";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {VcsOwner, VcsProvider} from "../../../common/models";

export type OrgsPanelProps = {
  vcs: VcsProvider;
  orgs: VcsOwner[];
}

export function OrgsPanel({vcs, orgs}: OrgsPanelProps) {
  const {vcsUrls, urls} = useUserAppInfrastructure()
  const spacingWidth = 30
  const menuSpacingWidth = 10
  const menuWidth = 30

  return <Panel header="Discover" noPadding>
    <List>
      {orgs.map((org, index) => <Box key={org}>
            <ListItem>
              <Box sx={{display: "flex", flexDirection: "row"}} alignItems="center">
                <Box sx={{flex: 1, width: "100%", marginRight: `${spacingWidth}px`}}>
                  <Typography variant="body1" noWrap sx={{
                    textOverflow: "ellipsis"
                  }}>
                    <Link href={urls.org(org)} data-test="org-list">
                      {formatBreadcrumbs(org)}
                    </Link>
                    <span style={{marginRight: 0, marginLeft: 8}}>
                      <Link href={vcsUrls.org(org)}>
                        <VcsIcon type={vcs}/>
                      </Link>
                    </span>
                  </Typography>
                </Box>

                <Box sx={{flex: 1, minWidth: menuWidth, maxWidth: menuWidth, marginLeft: `${menuSpacingWidth}px`}}>
                </Box>
              </Box>
            </ListItem>
            {index !== orgs.length - 1 && <Divider sx={{mt: 1, mb: 1}}/>}
          </Box>
      )}
    </List>
  </Panel>
}
