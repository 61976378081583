import {Target} from "../../api/rollouts/model";
import {useOrgTargetsQuery} from "../../api/rollouts";
import * as React from "react";
import {useState} from "react";
import {MenuItem, Select} from "@mui/material";
import {RolloutTarget} from "../../models";

import {OrgRepoPath} from "../../../common/models";

export function RolloutTargetSelect(params: {
  path: OrgRepoPath,
  targets: RolloutTarget[],
  onTarget: (target?: Target) => void
}) {
  const {data} = useOrgTargetsQuery({path: params.path, org: params.path.org})

  const targetDefinitions = data && data.kind === "ok" ? data.data : [];

  const [selectedTarget, setSelectedTarget] = useState("-")

  return <Select value={selectedTarget} onChange={(event) => {
    const target = event.target.value;
    setSelectedTarget(target);
    const newTarget = targetDefinitions.find(e => e.target === event.target.value);
    params.onTarget(newTarget)
  }
  }>
    <MenuItem value={"-"}>-</MenuItem>
    {params.targets.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
  </Select>;
}
