import {currentHostname} from "../navigation";

export type TeamId = string

export const sandboxTeamId = "sandbox"

export function isValidTeamId(teamId: TeamId): boolean {
    return /^[a-zA-Z0-9-]+$/.test(teamId)
}

export function isSandboxTeamId(teamId: TeamId): boolean {
    return teamId.indexOf(sandboxTeamId) === 0
}

export function teamIdFromCurrentLocation(): TeamId {
    return currentHostname().split(".")[0]
}
