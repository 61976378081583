import * as React from 'react';
import {Avatar, Box, Button, ClickAwayListener, Divider, Fade, IconButton, List, Paper, Popper} from "@mui/material";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import {LoggedInAs} from "../components/LoggedInAs";
import {Paths} from "../models/Paths";
import {MenuDirection, menuItemsStyles} from "../../common/layout/MenuItems";
import {MenuItem} from "../../common/components/navigation/MenuItem";
import {AccountAuthenticationProfile} from "../auth/useAccountAuthentication";
import {useAccountAppInfrastructure} from "../AccountAppInfrastructure";

export function AccountBarMenu({profile}: AccountMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return profile.type === AuthenticationType.Account ?
      <>
        <IconButton aria-label="Account Profile" onClick={handleClick}>
          <Avatar alt={`${profile.customerId} Avatar`} sx={{width: 30, height: 30}}/>
        </IconButton>

        <Popper transition={true} placement="bottom-start" open={open} anchorEl={anchorEl}
                style={{paddingTop: 5, minWidth: 150, maxWidth: 250, zIndex: 1001}}>
          {({TransitionProps}) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper variant="outlined">
                    <Box sx={{p: 2}}>
                      <LoggedInAs profile={profile}/>
                    </Box>
                    <Divider/>
                    <AccountMenuItems profile={profile} direction="vertical"/>
                    <Divider/>
                    <Box sx={{p: 2}}>
                      <Button href={Paths.Logout} fullWidth={true} variant="outlined">Logout</Button>
                    </Box>
                  </Paper>
                </Fade>
              </ClickAwayListener>)}
        </Popper>
      </> : <></>
}


export function AccountDrawerMenu({profile}: AccountMenuProps) {
  return <>
    <Box sx={{px: 1, pt: 1}}><LoggedInAs profile={profile}/></Box>
    {
      profile.type !== AuthenticationType.Unauthenticated
          ? <Button href={Paths.Logout} variant="outlined" sx={{m: 1}}>
            Logout
          </Button>
          : <Button href={Paths.Home} variant="outlined" sx={{m: 1}}>
            Login
          </Button>
    }

    {
      profile.type !== AuthenticationType.Unauthenticated
          ? <Divider/>
          : <></>
    }
    <AccountMenuItems profile={profile} direction="vertical"/>
  </>
}


export function AccountMenuItems({direction, profile}: AccountMenuItemsProps) {
  const styles = menuItemsStyles()
  const {urls} = useAccountAppInfrastructure()
  return (
      profile.type !== AuthenticationType.Unauthenticated ?
          <List style={styles[direction]}>
            <MenuItem text="Settings" href={urls.settings()}/>
          </List>
          : <></>
  )
}


export type AccountMenuProps = {
  profile: AccountAuthenticationProfile
}

export type AccountMenuItemsProps = {
  direction: MenuDirection
  profile: AccountAuthenticationProfile
}
