import validator from "validator";
import {EmailAddress} from "./models";

export type ValidationSuccess<T> = {
    result: "success"
    value: T
}

export type ValidationFailure<T> = {
    result: "failure"
    value: T
    error: string
}

export type ValidationUnvalidated<T> = {
    result: "unvalidated"
    value: T
}

export type Validation<T> = ValidationSuccess<T> | ValidationFailure<T> | ValidationUnvalidated<T>

export function unvalidated<T>(value: T): Validation<T> {
    return {
        result: "unvalidated",
        value
    }
}

export function isUnvalidated<T>(validation: Validation<T>): boolean {
    return validation.result === "unvalidated"
}

export function isValidationFailure<T>(validation: Validation<T>): boolean {
    return asValidationFailure(validation) !== undefined
}

export function isValidationSuccess<T>(validation: Validation<T>): boolean {
    return validation.result === "success"
}

export function asValidationFailure<T>(validation: Validation<T>): undefined | ValidationFailure<T> {
    return validation.result === "failure" ? validation : undefined
}

export function validateEmail(email: EmailAddress): Validation<EmailAddress> {
    return validator.isEmail(email)
        ? {result: "success", value: email}
        : {result: "failure", value: email, error: "Invalid email"}
}
