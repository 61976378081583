import * as React from "react";
import {useCallback, useState} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import {useGenerateTwoFactorQRCode, useRegisterTwoFactorDeviceMutation} from "../../api/user";
import {TwoFactorCodeInput} from "./TwoFactorCodeInput";
import {CancelButton} from "../../../common/components/navigation/CancelButton";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type RegisterTwoFactorDialogProps = {
  onSubmit: () => void
  onCancelled: () => void
  onSuccessful: () => void
} & OnError

export function RegisterTwoFactorDialog(
    {
      onSubmit,
      onCancelled,
      onSuccessful,
      onError
    }: RegisterTwoFactorDialogProps) {
  const {analytics} = useUserAppInfrastructure()

  const [disabled, setDisabled] = useState(true);
  const [twoFactorCode, setTwoFactorCode] = useState("");

  function onChangeTwoFactor(value: string) {
    if (value.length <= 6) {
      setTwoFactorCode(value);
    }
    setDisabled(value.length < 6)
  }

  const handleSetTwoFactorCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeTwoFactor(event.target.value.replace(/[^0-9]/g, ''));
  };

  const registerTwoFactor = useRegisterTwoFactorDeviceMutation({code: twoFactorCode,})

  const handleAction = useCallback(async () => {

    const response = await registerTwoFactor.mutateAsync({});
    const isSuccess = response.kind === "ok";

    if (isSuccess) {
      analytics.event("User", "Register TwoFactor Device", `Register TwoFactor Device`)
      onSuccessful && onSuccessful()
      onSubmit && onSubmit()
    } else {
      analytics.event("User", "Register TwoFactor Device", `TwoFactor Register Failure`)
      onError && onError(response.error)
      onChangeTwoFactor("")
    }
  }, [analytics, onError, onSuccessful, onSubmit, registerTwoFactor])
  return (
      <Dialog
          fullScreen={false}
          open={true}
          onClose={onCancelled}
      >
        <DialogTitle>Register TwoFactor Device</DialogTitle>

        <DialogContent style={{alignContent: "center"}}>
          <img style={{width: "100%"}} src={useGenerateTwoFactorQRCode({size: 200})} alt="QR code"/>
          <TwoFactorCodeInput value={twoFactorCode} onChange={handleSetTwoFactorCode}/>
        </DialogContent>

        <Divider/>

        <DialogActions>
          <CancelButton onClick={onCancelled}/>
          <Button aria-label={`Setup new TwoFactor Device`} variant="contained"
                  disabled={disabled}
                  onClick={handleAction}>
            Register TwoFactor Device
          </Button>
        </DialogActions>
      </Dialog>
  );
}
