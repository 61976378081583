import {OrgRepoPath, VcsOwner} from "../../../common/models";

export class NotificationUrls {
    static orgNotificationSubscribe(params: { owner: VcsOwner }) {
        return `/notifications/subscribe/org/${params.owner}`;
    }

    static orgNotificationUnsubscribe(params: { owner: VcsOwner }) {
        return `/notifications/unsubscribe/org/${params.owner}`;
    }

    static orgNotificationSubscriptions(params: { owner: VcsOwner }) {
        return `/notifications/subscriptions/org/${params.owner}`;
    }

    static repoNotificationSubscribe(params: { path: OrgRepoPath }) {
        return `/notifications/subscribe/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`;
    }

    static repoNotificationUnsubscribe(params: { path: OrgRepoPath }) {
        return `/notifications/unsubscribe/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`;
    }

    static repoNotificationSubscriptions(params: { path: OrgRepoPath }) {
        return `/notifications/subscriptions/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`;
    }

    static orgNotificationChannels(params: { owner: VcsOwner }) {
        return `/notifications/channels/org/${params.owner}`;
    }
}
