import React, {CSSProperties} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {Photo, PhotoProps} from './Photo';

export const SortablePhoto = (props: PhotoProps) => {
    const sortable = useSortable({id: props.url});
    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = sortable;

    const style : CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Photo
            ref={setNodeRef}
            {...{style}}
            {...props}
            {...attributes}
            {...listeners}
            faded={isDragging}
        />
    );
};
