import * as React from "react";
import {Link} from "../../common/components/navigation/Link";
import {Avatar} from "@mui/material";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";

export function VcsAvatar({name, avatar, size}: VcsAvatarProps) {
  const {vcsUrls} = useUserAppInfrastructure()
  return (
      <Link tooltip={name}
            href={vcsUrls.user(name)}>
        <Avatar alt={`${name} Avatar`} sx={{width: size, height: size}} src={avatar}/>
      </Link>
  )
}

export type VcsAvatarProps = {
  name: string;
  avatar: string;
  size: number;
}
