import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {RepositoryPageTabProps} from "./RepositoryPageTab";
import {ApiCataloguePanel} from "../../components/configurations/ApiCataloguePanel";
import {apiConfigViews} from "../../api/configurations/model";
import {useRepositoryMonodesPluginsConfigsQuery} from "../../api/configurations";

export function RepositoryPageAPICatalogueTab({path, onError}: RepositoryPageTabProps) {
  const apiCatalogueQuery = useRepositoryMonodesPluginsConfigsQuery({path})
  return <QueryLoader height={450} result={apiCatalogueQuery} onError={onError} onLoaded={data =>
      <ApiCataloguePanel selectionDetails={false} configs={apiConfigViews(data)}/>
  }/>
}
