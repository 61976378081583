import {Alert, AlertTitle, Box, Button, Divider, IconButton, List, ListItem, Skeleton, Typography} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {useSnackbar} from "notistack";
import {snackOptions} from "../../../common/snackbar";
import {ErrorPanel} from "../../../common/components/ErrorPanel";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {VcsOwner} from "../../../common/models";
import {RegisterApiKeyDialog, RegisterApiKeyDialogProps, RegisteredApiKey} from "./RegisterApiKeyDialog";
import CheckIcon from '@mui/icons-material/Check';
import {ApiKeyName} from "../../api/org/model";
import {useDeleteOrgApiKeyMutation, useOrgApiKeysQuery} from "../../api/org";
import {CopyToClipboardText} from "../../../common/components/CopyToClipboardText";

export type ApiKeysPanelProps = {
  org: VcsOwner
} & OnError

export function ApiKeysPanel({org}: ApiKeysPanelProps) {
  const {data: apiKeys} = useOrgApiKeysQuery({org})

  const [latestRegisteredKey, setLatestRegisteredKey] = React.useState<RegisteredApiKey | undefined>(undefined);
  const [latestOpen, setLatestOpen] = React.useState<boolean>(false);
  const [registerApiKeyDialogProps, setRegisterApiKeyDialogProps] = React.useState<RegisterApiKeyDialogProps | undefined>(undefined);
  const {enqueueSnackbar} = useSnackbar();
  const menuSpacingWidth = 10
  const deleteApiKey = useDeleteOrgApiKeyMutation({org})

  const handleRemoveApiKey = useCallback(async (name: ApiKeyName) => {
    const response = await deleteApiKey.mutateAsync(name)
    const isSuccess = response.kind === "ok";
    if (isSuccess) {
      enqueueSnackbar(`API key "${name}" removed successfully`, snackOptions(true))
    } else {
      enqueueSnackbar(`Failed to remove API key "${name}"`, snackOptions(false))
    }
  }, [deleteApiKey, enqueueSnackbar])

  const handleOpenRegisterApiKeyDialog = useCallback(async () => {
    setRegisterApiKeyDialogProps({
      org,
      onSuccessful: (registered) => {
        setLatestRegisteredKey(registered)
        setLatestOpen(true)
        enqueueSnackbar(`API key "${registered.name}" registered`, snackOptions(true))
      },
      onError: () => enqueueSnackbar(`Failed to register API key`, snackOptions(false)),
      onSubmit: () => setRegisterApiKeyDialogProps(undefined),
      onCancelled: () => setRegisterApiKeyDialogProps(undefined)
    })
  }, [setRegisterApiKeyDialogProps, enqueueSnackbar, org])


  if (!apiKeys) {
    return <Box>
      <Skeleton variant="rectangular" width="100%" height={100}/>
    </Box>
  }

  if (apiKeys.kind === "error") {
    return <ErrorPanel error={apiKeys.error}/>
  }

  return <Box sx={{mb: 5}} data-test="tokens">
    {registerApiKeyDialogProps && <RegisterApiKeyDialog {...registerApiKeyDialogProps}/>}
    {latestRegisteredKey && latestOpen &&
        <Alert
            icon={<CheckIcon fontSize="inherit"/>}
            severity="success"
            action={
              <Button color="inherit" size="small"
                      onClick={() => {
                        setLatestRegisteredKey(undefined)
                        setLatestOpen(false);
                      }}
              >
                Close
              </Button>
            }
            sx={{mb: 2, width: "100%"}}
        >
          <AlertTitle>API Key Registered ({latestRegisteredKey.name})</AlertTitle>
          <Typography sx={{mt: 3}}>
            Please copy the following API key to your build pipeline variables.
          </Typography>
          <CopyToClipboardText text={latestRegisteredKey.key}/>
          <Typography>You will only see this message once!</Typography>
        </Alert>
    }
    <List>
      <>
        {
          apiKeys.data.map((apiKey) => <Box key={apiKey.name}>
            <ListItem>
              <Box sx={{display: "flex", flexDirection: "row", height: 35, width: "100%"}} alignItems="center">
                <Box sx={{flexGrow: 1, width: "100%"}}>
                  <Typography noWrap>{apiKey.name + ", level: " + apiKey.accessLevel + ", expires: " + apiKey.expiry}</Typography>
                </Box>
                <Box sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: `${menuSpacingWidth}px`,
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <IconButton aria-label="disable" onClick={() => handleRemoveApiKey(apiKey.name)}>
                    <CloseRoundedIcon/>
                  </IconButton>
                </Box>
              </Box>
            </ListItem>
            <Divider sx={{mt: 1, mb: 1}}/>
          </Box>)

        }
      </>
      <Button aria-label="Register email button" variant="outlined" color="primary"
              onClick={handleOpenRegisterApiKeyDialog}>
        Register API Key
      </Button>
    </List>
  </Box>
}
