import {Autocomplete, AutocompleteRenderInputParams, Avatar, Box, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {User} from "../../api/model";

export type MemberSearchPanelProps = {
  all: User[]
  selected: User[]
  disabled: boolean
  onAdd: (member: User) => void;
  onRemove: (member: User) => void;
} & OnError

export function MemberSearchPanel(
    {
      disabled,
      all,
      selected,
      onAdd,
      onRemove,
    }: MemberSearchPanelProps) {

  const handleMemberChange = useCallback(async (event: any, newValue: User[]) => {
        const delta = {
          added: newValue.filter(item => selected.map(m => m.login).indexOf(item.login) === -1),
          removed: selected.filter(item => newValue.map(m => m.login).indexOf(item.login) === -1)
        };
        for (const member of delta.added) await onAdd(member)
        for (const member of delta.removed) await onRemove(member)
      }, [selected, onAdd, onRemove]
  );

  return <Box data-test="member-search">
    <Autocomplete
        multiple
        disabled={disabled}
        disableClearable
        value={selected}
        options={all}
        filterSelectedOptions={true}
        onChange={handleMemberChange}
        getOptionLabel={(option) => option.login}
        renderTags={() => <></>}
        renderOption={(props, option) => <MemberItem {...props} {...option} />}
        renderInput={(params) => <MemberSearchInput {...params}/>}
    />
  </Box>
}

type ExtendedMemberSearchDetails = {} & User

type MemberItemProps = {} & React.HTMLAttributes<HTMLLIElement> & ExtendedMemberSearchDetails

function MemberItem({login, avatar, ...props}: MemberItemProps) {
  return <Box component="li" {...props} >
    <Box alignItems="center" display="flex" flexDirection="row">
      <Avatar src={avatar} sx={{display: "inline-block", width: 30, height: 30, m: 1}}/>
      <Typography variant="body1">{login}</Typography>
    </Box>
  </Box>
}

type MemberSearchInputProps = {} & AutocompleteRenderInputParams

function MemberSearchInput({...props}: MemberSearchInputProps) {
  return <TextField {...props} placeholder="Add member..."/>
}
