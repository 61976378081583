import * as React from 'react';
import {ReactNode, useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {OwnerPathParameter, RepositoryPathParameter, usePathParams} from "../../models/Paths";
import {pathFrom} from "../../models";
import {SystemError} from "../../../common/api/TransportLayer";
import {VcsBreadcrumbsMenu, VcsBreadcrumbsMenuProvider} from "../../components/VcsBreadcrumbsMenu";
import {useCachedOrgConfigurationQuery} from "../../api/org";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {Box} from "@mui/material";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {useTabControls} from "../../../common/tabs";
import {RepositoryPageTab, RepositoryPageTabProps} from "./RepositoryPageTab";
import {RepositoryPageBuildsTab} from "./RepositoryPageBuildsTab";
import {RepositoryPageDependenciesTab} from "./RepositoryPageDependenciesTab";
import {RepositoryPageAPICatalogueTab} from "./RepositoryPageAPICatalogueTab";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {Sidebar} from "../../../common/layout/Sidebar";
import {useRepositoryLinksQuery} from "../../api/configurations";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {LinksPanel} from "../../components/configurations/LinksPanel";
import {TargetsPanel} from "../../components/targets/TargetsPanel";
import {useOrgTargetsQuery} from "../../api/rollouts";


import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {RepoBreadcrumb} from "../../components/breadcrumbs/RepoBreadcrumb";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {RepositoryPageOverviewTab} from "./RepositoryPageOverviewTab";

export function RepositoryPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const [tabSidebar, setTabSidebar] = useState<ReactNode>(null)
  const {urls} = useUserAppInfrastructure();
  const {userProfile} = useUserAuthentication()
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter>();
  const path = pathFrom(pathParams);
  const tabs = useTabControls({
    onChanged: () => setTabSidebar(null),
    defaultTab: RepositoryPageTab.overview,
    tabs: [
      {title: "Overview", id: RepositoryPageTab.overview},
      {title: "Build", id: RepositoryPageTab.builds},
      {title: "Dependencies", id: RepositoryPageTab.dependencies},
      {title: "API Catalog", id: RepositoryPageTab.apiCatalogue}
    ]
  })
  const orgConfiguration = useCachedOrgConfigurationQuery({owner: pathParams.owner, onError: setError})
  const targetsQuery = useOrgTargetsQuery({org: pathParams.owner})
  const linksResult = useRepositoryLinksQuery({path})

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const breadcrumbsMenu: VcsBreadcrumbsMenuProvider = () =>
      <VcsBreadcrumbsMenu actions={["repoSettings"]}
                          path={path}/>;

  const header = <PageHeader
      title="Repository"
      location={urls.repository(path)}
      breadcrumbs={[
        <OwnerBreadcrumb owner={path.org}/>,
        <RepoBreadcrumb vcs={userProfile.vcs} path={path}/>
      ]}
      menu={breadcrumbsMenu}
      tabs={tabs}
  />

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={setError}/>

  const title = pathParams.owner + "/" + pathParams.repository;

  const tabProps: RepositoryPageTabProps = {
    path,
    org: pathParams.owner,
    repository: pathParams.repository,
    userProfile,
    orgConfiguration: orgConfiguration,
    onError: setError,
    onSidebar: setTabSidebar
  }

  const sidebar = <Sidebar>
    {tabSidebar}
    <QueryLoader height={150} result={targetsQuery} onError={setError} onLoaded={data =>
        <TargetsPanel vcs={userProfile.vcs}
                      org={pathParams.owner}
                      repo={pathParams.repository}
                      targets={data}
        />
    }/>

    <QueryLoader height={150} result={linksResult} onError={setError} onLoaded={data =>
        <LinksPanel links={data}/>
    }/>


  </Sidebar>

  return <UserAppPage pageId={UserPageId.Repo}
                      title={title} contentHeader={header} banner={banner} sidebar={sidebar} error={error}
                      profile={userProfile}>
    <Box>
      <PageTab selectedId={tabs.selected} id={RepositoryPageTab.overview}>
        <RepositoryPageOverviewTab {...tabProps}/>
      </PageTab>

      <PageTab selectedId={tabs.selected} id={RepositoryPageTab.builds}>
        <RepositoryPageBuildsTab {...tabProps}/>
      </PageTab>

      <PageTab selectedId={tabs.selected} id={RepositoryPageTab.dependencies}>
        <RepositoryPageDependenciesTab {...tabProps}/>
      </PageTab>

      <PageTab selectedId={tabs.selected} id={RepositoryPageTab.apiCatalogue}>
        <RepositoryPageAPICatalogueTab {...tabProps}/>
      </PageTab>
    </Box>
  </UserAppPage>
}
