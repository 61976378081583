import {UserRequestId} from "./UserRequestId";
import {TransportLayer, TransportLayerParams} from "../../common/api/TransportLayer";
import {
    CacheOptions,
    EmptyRequest,
    EmptyResponse,
    GetQuery,
    GetQueryOptions, Payload,
    PostMutation,
    refetchQueryOptions, Resource, useDelete,
    useGet,
    usePost
} from "../../common/api/query";
import {EnvironmentConfig} from "../../Environment";
import {UserAuthenticationStore} from "../auth/UserAuthenticationStore";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";
import {Analytics} from "../../common/port/Analytics";

import {TeamId} from "../../common/models/TeamId";

export class UserAppTransportLayer extends TransportLayer {
    private readonly teamId: TeamId
    private authStore: UserAuthenticationStore
    private config: EnvironmentConfig

    constructor(teamId: TeamId, analytics: Analytics, provider: UserAuthenticationStore, config: EnvironmentConfig, headers?: Record<string, string>) {
        super(analytics, headers)
        this.teamId = teamId
        this.authStore = provider
        this.config = config
    }

    token(): Promise<string | undefined> {
        return new Promise((resolve) => resolve(this.authStore.getToken()))
    }

    logout(): void {
        this.authStore.logout()
    }

    baseUrl() {
        return this.config.apiUri(this.teamId)
    }
}

export function useApiUrl(resource: string) {
    const {transport} = useUserAppInfrastructure()
    return transport.resourceUrl(resource)
}

export function useUserApiPost<REQUEST = EmptyRequest, RESPONSE = EmptyResponse>(
    requestId: UserRequestId,
    resource: Resource<REQUEST>,
    invalidates: UserRequestId[] = [],
    transportOptions: TransportLayerParams = {},
    payload: Payload<REQUEST> = (req) => req,
): PostMutation<REQUEST, RESPONSE> {
    const {transport} = useUserAppInfrastructure()
    return usePost(transport, requestId, resource, invalidates, transportOptions, payload)
}

export function useUserApiDelete<REQUEST = void, RESPONSE = void>(
    requestId: UserRequestId,
    resource: Resource<REQUEST>,
    invalidates: UserRequestId[] = [],
    transportOptions: TransportLayerParams = {},
): PostMutation<REQUEST, RESPONSE> {
    const {transport} = useUserAppInfrastructure()
    return useDelete(transport, requestId, resource, invalidates, transportOptions)
}

export function useUserApiGet<RESPONSE>(
    requestId: UserRequestId,
    resource: Resource,
    transportOptions: TransportLayerParams = {},
    queryOptions: GetQueryOptions = refetchQueryOptions,
    cacheOptions: CacheOptions = {}
): GetQuery<RESPONSE> {
    const {transport, storage} = useUserAppInfrastructure()
    return useGet(transport, storage.session, requestId, resource, transportOptions, queryOptions, cacheOptions)
}
