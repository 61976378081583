import {Monode, VcsBranch, VcsRepoFilePath, VcsRepoFilePathType} from "../../models";
import {humanise} from "../../../common/strings";

import {OrgRepoPath} from "../../../common/models";

export type LinkConfig = {
    name: string
    type: string
    uri: string
}

export enum PluginType {
    api = "api",
    documentation = "documentation"
}

type ApiUi = "swagger"

export type ApiPluginConfig = {
    ui: ApiUi,
    url?: string
    path?: string
}

export type DocumentationPluginConfig = {
    name: string
    path: string
}

export type PluginsConfig = {
    api?: ApiPluginConfig
    documentation?: DocumentationPluginConfig[]
}

export type PluginConfigBaseView = {
    path: OrgRepoPath
    monode: Monode
    branch: VcsBranch
}

export type PluginsConfigView = PluginConfigBaseView & {
    plugins: PluginsConfig
}

export type ApiPluginConfigView = PluginConfigBaseView & { config: ApiPluginConfig }

export function apiConfigViews(views: PluginsConfigView[]): ApiPluginConfigView[] {
    return views.map(getApiConfigView).filter((view): view is ApiPluginConfigView => !!view)
}

export function getApiConfigView({plugins, ...rest}: PluginsConfigView): ApiPluginConfigView | undefined {
    return plugins.api && {config: plugins.api, ...rest} as ApiPluginConfigView
}

export type DocumentationContent = string

export type DocumentationContentDescription = {
    path: VcsRepoFilePath
    type: VcsRepoFilePathType
}

export type DocumentationListView = {
    index?: VcsRepoFilePath
    items: DocumentationContentDescription[]
}

export type BreadcrumbView = {
    name: string
} & DocumentationContentDescription

export function breadcrumbsFrom(root: string, content: DocumentationContentDescription): BreadcrumbView[] {
    const breadcrumbs: BreadcrumbView[] = []
    const pathParts = content.path.split("/")
    let current = ""

    for (let i = 0; i < pathParts.length; i++) {
        const part = pathParts[i]
        breadcrumbs.push({
            path: mergePaths(root, current + part),
            name: part,
            type: i === pathParts.length - 1 ? content.type : VcsRepoFilePathType.dir
        })

        current = current + part + "/"
    }

    return breadcrumbs
}

export function isMarkdown(file: VcsRepoFilePath) {
    return extensionIsOneOf(file, ["md", "markdown"])
}

export function isImage(file: VcsRepoFilePath) {
    return extensionIsOneOf(file, ["jpeg", "jpg", "png", "gif", "svg"])
}

export function extensionIsOneOf(file: VcsRepoFilePath, extensions: string[]): boolean {
    const fileName = file.toLowerCase();
    return extensions.find(ext => fileName.endsWith(`.${ext}`)) !== undefined
}

export function stripExtension(path: VcsRepoFilePath): string {
    const parts = path.split(".");
    return path.substring(0, path.length - parts[parts.length - 1].length - 1)
}

export function humaniseIfMarkdown(path: VcsRepoFilePath): string {
    return isMarkdown(path) ? humanise(stripExtension(path)) : path
}

export function contentDescriptionFrom(root: DocumentationContentDescription, file: VcsRepoFilePath): DocumentationContentDescription {
    return {
        path: mergePaths(root.path, file),
        type: file.trim() !== "" ? VcsRepoFilePathType.file : VcsRepoFilePathType.dir
    }
}

export function nearestDirectoryFrom(content: DocumentationContentDescription): DocumentationContentDescription {
    const breadcrumbs = breadcrumbsFrom("", content);
    return content.type === VcsRepoFilePathType.dir
        ? content
        : breadcrumbs[breadcrumbs.length - 2] || {path: "", type: VcsRepoFilePathType.dir}
}

export function fileNameFrom(content: DocumentationContentDescription): string {
    const breadcrumbs = breadcrumbsFrom("", content);
    return breadcrumbs[breadcrumbs.length - 1].name
}

export function mergePaths(path1: VcsRepoFilePath, path2: VcsRepoFilePath, ...paths: VcsRepoFilePath[]): VcsRepoFilePath {
    return cleanupPath([cleanupPath(path1), cleanupPath(path2), ...paths.map(cleanupPath)].join("/"))
}

export function makeRelativeTo(parent: VcsRepoFilePath, child: VcsRepoFilePath): VcsRepoFilePath {
    return cleanupPath(cleanupPath(child).substring(cleanupPath(parent).length + 1))
}

export function cleanupPath(path: VcsRepoFilePath): VcsRepoFilePath {
    const removedPrefix = path.startsWith("/") ? path.substring(1) : path;
    const removedSuffix = removedPrefix.endsWith("/") ? removedPrefix.substring(0, removedPrefix.length - 1) : removedPrefix;
    return removedSuffix.replace("..", "");
}
