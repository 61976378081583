import * as React from 'react';
import {ReactNode, useCallback} from 'react';
import {ClickAwayListener, Fade, IconButton, Paper, Popper} from "@mui/material";
import {descriptionOf, Pipeline, PipelineRun, RolloutStage} from "../../api/pipelines/model";
import {MoreVert} from "@mui/icons-material";
import {MenuItem} from "../../../common/components/navigation/MenuItem";
import {
  useAddToUserWatchlistMutation,
  useCancelPipelineMutation,
  useRemoveFromUserWatchlistMutation,
  useRerunPipelineMutation
} from "../../api/pipelines";
import {EmptyRequest, EmptyResponse, MutationResult} from "../../../common/api/query";
import {SystemResponse} from "../../../common/api/TransportLayer";
import {useSnackbar} from 'notistack';
import {snackOptions} from "../../../common/snackbar";
import {RolloutDialog, RolloutDialogProps} from "../rollouts/RolloutDialog";
import {RemoveRunDialog, RemoveRunDialogProps} from "./RemoveRunDialog";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type PipelineMenuAction =
    "rollout"
    | "rerun"
    | "cancel"
    | "addToWatchlist"
    | "removeFromWatchlist"
    | "remove"

export type PipelineMenuProps = {
  menuId?: string
  pipeline: Pipeline
  run: PipelineRun,
  actions: PipelineMenuAction[]
  onSuccessfulAction?: (action: PipelineMenuAction) => void
}

export function PipelineMenu({menuId, pipeline, run, actions, onSuccessfulAction}: PipelineMenuProps) {
  const {analytics} = useUserAppInfrastructure()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rolloutDialogProps, setRolloutDialogProps] = React.useState<RolloutDialogProps | undefined>(undefined);
  const [removeRunDialogProps, setRemoveRunDialogProps] = React.useState<RemoveRunDialogProps | undefined>(undefined);

  const open = Boolean(anchorEl);
  const pipelineMutationParams = {
    path: pipeline.path,
    runId: run.runId,
    monode: pipeline.name,
    branch: pipeline.branch
  };
  const pipelineDescription = descriptionOf(pipeline.path, pipeline.name)

  const {enqueueSnackbar} = useSnackbar();

  const rerunPipeline = useRerunPipelineMutation(pipelineMutationParams)
  const cancelPipeline = useCancelPipelineMutation(pipelineMutationParams)
  const addToWatchlist = useAddToUserWatchlistMutation(pipelineMutationParams)
  const removeFromWatchlist = useRemoveFromUserWatchlistMutation(pipelineMutationParams)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenRolloutDialog = useCallback(async (rollouts: RolloutStage[]) => {
    handleCloseMenu()
    setRolloutDialogProps({
      pipeline: pipeline,
      commit: run.commit,
      targets: rollouts.map(it => it.name),
      onSuccessful: (env) => enqueueSnackbar(`Rollout ${pipelineDescription} to ${env}`, snackOptions(true)),
      onError: (env) => enqueueSnackbar(`Failed to rollout ${pipelineDescription} to ${env}`, snackOptions(false)),
      onApproved: () => setRolloutDialogProps(undefined),
      onCancelled: () => setRolloutDialogProps(undefined)
    })
  }, [pipeline, run, setRolloutDialogProps, enqueueSnackbar, pipelineDescription])

  const handleOpenRemoveRunDialog = useCallback(async () => {
    handleCloseMenu()
    setRemoveRunDialogProps({
      pipeline: pipeline,
      run: run,
      onSuccessful: () => enqueueSnackbar(`Remove ${pipelineDescription}`, snackOptions(true)),
      onError: () => enqueueSnackbar(`Failed to remove ${pipelineDescription}`, snackOptions(false)),
      onApproved: () => setRemoveRunDialogProps(undefined),
      onCancelled: () => setRemoveRunDialogProps(undefined)
    })
  }, [pipeline, run, setRemoveRunDialogProps, enqueueSnackbar, pipelineDescription])

  const handleMutationAction = useCallback(async (mutation: MutationResult<SystemResponse<EmptyResponse>, unknown, EmptyRequest>, action: PipelineMenuAction, success: string, failure: string) => {
    handleCloseMenu()
    analytics.event("User", "Pipeline action (" + action + ")", success)
    const response = await mutation.mutateAsync({});
    const isSuccess = response.kind === "ok";

    enqueueSnackbar(isSuccess ? success : failure, snackOptions(isSuccess))
    if (onSuccessfulAction && isSuccess) onSuccessfulAction(action)
  }, [analytics, onSuccessfulAction, enqueueSnackbar])

  const actionIfAllowed = (action: PipelineMenuAction): ReactNode => {
    if (!actions.includes(action))
      return <></>

    const testId = menuId ? {"data-test": `menu-action-${menuId}-${action}`} : {}

    switch (action) {
      case "rerun":
        return [
          <MenuItem {...testId} key="rerun" text="Re-run"
                    onClick={() => handleMutationAction(rerunPipeline, action, `Rerunning ${pipelineDescription}`, `Failed to re-run ${pipelineDescription}`)}/>
        ];

      case "cancel":
        return [
          <MenuItem {...testId} key="cancel" text="Cancel"
                    onClick={() => handleMutationAction(cancelPipeline, action, `Cancelling ${pipelineDescription}`, `Failed to cancel ${pipelineDescription}`)}/>
        ];

      case "rollout":
        return <MenuItem {...testId} key="rollout" text={"Rollout"}
                         onClick={() => handleOpenRolloutDialog(run.rollouts)}/>;

      case "addToWatchlist":
        return [
          <MenuItem {...testId} key="addToWatchlist" text="Add to watchlist"
                    onClick={() => handleMutationAction(addToWatchlist, action, `Added ${pipelineDescription} to watchlist`, `Failed to add ${pipelineDescription} to watchlist`)}/>
        ];

      case "removeFromWatchlist":
        return [
          <MenuItem {...testId} key="removeFromWatchlist" text="Remove from watchlist"
                    onClick={() => handleMutationAction(removeFromWatchlist, action, `Removed ${pipelineDescription} from watchlist`, `Failed to remove ${pipelineDescription} from watchlist`)}/>
        ];

      case "remove":
        return [
          <MenuItem {...testId} key="remove" text="Remove pipeline run"
                    onClick={() => handleOpenRemoveRunDialog()}/>
        ];
    }
  }

  return (<>
        {rolloutDialogProps && <RolloutDialog {...rolloutDialogProps}/>}
        {removeRunDialogProps && <RemoveRunDialog {...removeRunDialogProps}/>}

        <IconButton aria-label={`${pipelineDescription} Menu`} color="inherit" onClick={handleOpenMenu} data-test={`menu-${menuId}`}>
          <MoreVert/>
        </IconButton>

        <Popper transition={true} placement="bottom-end" open={open} anchorEl={anchorEl}
                style={{minWidth: 150, maxWidth: 250, zIndex: 100}}>
          {({TransitionProps}) => (
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper variant="outlined">
                    {run.status === "Running" ? actionIfAllowed("cancel") : actionIfAllowed("rerun")}
                    {run.rollouts.length > 0 ? actionIfAllowed("rollout") : <></>}
                    {pipeline.isWatched ? actionIfAllowed("removeFromWatchlist") : actionIfAllowed("addToWatchlist")}
                    {actionIfAllowed("remove")}
                  </Paper>
                </Fade>
              </ClickAwayListener>)}
        </Popper>
      </>
  )
}
