import * as React from 'react';
import {ReactNode, useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {pathFrom} from "../../models";
import {MonodePathParameter, OwnerPathParameter, RepositoryPathParameter, usePathParams} from "../../models/Paths";
import {SystemError} from "../../../common/api/TransportLayer";
import {useCachedOrgConfigurationQuery} from "../../api/org";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {Box} from "@mui/material";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {PluginTabContent, pluginTabsFrom} from "../../components/configurations/PluginTab";
import {useMonodeLinksQuery, useMonodePluginsConfigQuery} from "../../api/configurations";
import {useTabControls} from "../../../common/tabs";
import {MonodePageTab, MonodePageTabProps} from "./MonodePageTab";
import {MonodePageBuildsTab} from "./MonodePageBuildsTab";
import {MonodePageDependenciesTab} from "./MonodePageDependenciesTab";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {Sidebar} from "../../../common/layout/Sidebar";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {LinksPanel} from "../../components/configurations/LinksPanel";
import {TargetsPanel} from "../../components/targets/TargetsPanel";
import {useOrgTargetsQuery} from "../../api/rollouts";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {RepoBreadcrumb} from "../../components/breadcrumbs/RepoBreadcrumb";
import {MonodeBreadcrumb} from "../../components/breadcrumbs/MonodeBreadcrumb";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {MonodePageOverviewTab} from "./MonodePageOverviewTab";

export function MonodePage() {
  const [error, setError] = useState<SystemError | null>(null)
  const [tabSidebar, setTabSidebar] = useState<ReactNode>(null);
  const {userProfile} = useUserAuthentication()
  const {urls} = useUserAppInfrastructure()
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter & MonodePathParameter>();
  const path = pathFrom(pathParams);
  const monode = pathParams.monode;
  const {data} = useMonodePluginsConfigQuery({path, monode, onQueryError: setError});
  const pluginTabs = data && data.kind === "ok" && data.data ? pluginTabsFrom(data.data) : []
  const orgConfiguration = useCachedOrgConfigurationQuery({owner: pathParams.owner, onError: setError})
  const targetsQuery = useOrgTargetsQuery({org: pathParams.owner})
  const linksResult = useMonodeLinksQuery({path, monode: pathParams.monode})

  const tabs = useTabControls({
    onChanged: () => setTabSidebar(null),
    defaultTab: MonodePageTab.overview,
    tabs: [
      {title: "Overview", id: MonodePageTab.overview},
      {title: "Build", id: MonodePageTab.builds},
      {title: "Dependencies", id: MonodePageTab.dependencies},
      ...pluginTabs
    ]
  })

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={setError}/>

  const header = <PageHeader
      title="Monode"
      breadcrumbs={[
        <OwnerBreadcrumb owner={path.org}/>,
        <RepoBreadcrumb vcs={userProfile.vcs} path={path}/>,
        <MonodeBreadcrumb vcs={userProfile.vcs} path={path} monode={monode}/>
      ]}
      location={urls.monode({path, monode})}
      tabs={tabs}
  />

  const sidebar = <Sidebar>
    {tabSidebar}
    <QueryLoader height={150} result={targetsQuery} onError={setError} onLoaded={data =>
        <TargetsPanel vcs={userProfile.vcs}
                      org={pathParams.owner}
                      repo={pathParams.repository}
                      monode={pathParams.monode}
                      targets={data}
        />
    }/>

    <QueryLoader height={150} result={linksResult} onError={setError} onLoaded={data =>
        <LinksPanel links={data}/>
    }/>
  </Sidebar>

  const title = path.org + "/" + path.repo + "/ Monode: " + monode;

  const tabProps: MonodePageTabProps = {
    path,
    owner: pathParams.owner,
    monode: pathParams.monode,
    userProfile,
    orgConfiguration: orgConfiguration,
    onSidebar: setTabSidebar,
    onError: setError,
  }

  return <UserAppPage pageId={UserPageId.Monode} title={title} contentHeader={header} sidebar={sidebar} banner={banner} error={error}
                      profile={userProfile}>
    <Box>
      <PageTab selectedId={tabs.selected} id={MonodePageTab.overview}>
        <MonodePageOverviewTab {...tabProps}/>
      </PageTab>

      <PageTab selectedId={tabs.selected} id={MonodePageTab.builds}>
        <MonodePageBuildsTab {...tabProps}/>
      </PageTab>

      <PageTab selectedId={tabs.selected} id={MonodePageTab.dependencies}>
        <MonodePageDependenciesTab {...tabProps}/>
      </PageTab>

      {pluginTabs.map(pluginTabProps => <PluginTabContent key={path + pathParams.monode + pluginTabProps.id}
                                                          id={pluginTabProps.id}
                                                          type={pluginTabProps.type}
                                                          vcs={userProfile.vcs}
                                                          selectedId={tabs.selected}
                                                          path={path}
                                                          monode={pluginTabProps.config.monode}
                                                          branch={pluginTabProps.config.branch}
                                                          config={pluginTabProps.config.plugins}
                                                          onError={setError}
      />)}
    </Box>
  </UserAppPage>
}
