import * as React from 'react';
import {useCallback, useState} from 'react';
import {monopolisConstraints} from "../../common/styles/constraints";
import {MonopolisFavicon} from "../../common/components/icons";
import {Box, Button, Container, Grid, Link, TextField, Typography} from "@mui/material";
import {useIssueCustomerOneTimeLoginLinkMutation} from "../api/auth";
import {SystemError} from "../../common/api/TransportLayer";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {EmailOutlined} from "@mui/icons-material";
import {
  isUnvalidated,
  isValidationFailure,
  isValidationSuccess,
  validateEmail,
  Validation
} from "../../common/validation";

import {EmailAddress} from "../../common/models";
import {LoginButton} from "../../common/components/navigation/LoginButton";
import {useAccountAppInfrastructure} from "../AccountAppInfrastructure";

export type LoginPanelProps = {
  onLogin: () => void
}

export function LoginPanel({onLogin}: LoginPanelProps) {
  const {config} = useAccountAppInfrastructure()
  const [tokenIssued, setTokenIssued] = useState<boolean | SystemError>(false);

  function LoginLinkError(props: { error: SystemError }) {
    return <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
    >
      <ErrorOutlineOutlinedIcon fontSize="inherit" color="error" sx={{fontSize: 120}}/>

      <Typography variant="body1" sx={{mt: 2, mb: 3}} align={"center"} color="error">
        Could not issue login link.
        <Typography variant="body2" align={"center"} color="error">
          {props.error.message}
        </Typography>
      </Typography>

      <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => setTokenIssued(false)}
          sx={{mt: 3, mb: 2}}
      >
        Try again
      </Button>

    </Box>
  }

  function LoginLinkIssued() {
    return <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
    >
      <EmailOutlined fontSize="inherit" color="primary" sx={{fontSize: 120}}/>

      <Typography variant="body1" sx={{mt: 2, mb: 3}} align={"center"}>
        Please check your email for login link.
      </Typography>
    </Box>
  }


  function LoginLinkForm() {
    const [email, setEmail] = useState<Validation<EmailAddress>>(validateEmail(""))
    const issueTokenMutation = useIssueCustomerOneTimeLoginLinkMutation()
    const handleLogin = useCallback(async () => {
      const _email = isUnvalidated(email) ? validateEmail(email.value) : email
      setEmail(_email)
      if (isValidationSuccess(_email)) {
        setTokenIssued(true)
        const response = await issueTokenMutation.mutateAsync({email: email.value});
        const isSuccess = response.kind === "ok";
        if (isSuccess) {
          onLogin()
        } else {
          setTokenIssued(response.error)
        }
      }
    }, [email, setEmail, issueTokenMutation])

    return <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
    >
      <Box sx={{m: 1}}>
        <MonopolisFavicon height={monopolisConstraints.headerHeight}/>
      </Box>

      <Typography variant="h4" align={"center"}>
        Welcome to {config.appName}!
      </Typography>

      <Typography variant="body1" sx={{mt: 10, mb: 3}} align={"center"}>
        Enter email address to login to your customer account
      </Typography>
      <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          inputProps={{
            "data-test": "email"
          }}
          onKeyDown={async (event) => {
            if (event.key === "Enter") {
              event.preventDefault()
              await handleLogin()
            }
          }}
          autoComplete="email"
          autoFocus
          onChange={event => setEmail(validateEmail(event.target.value))}
      />

      <LoginButton data-test="login" disabled={isValidationFailure(email)} onClick={() => handleLogin()}
                   sx={{mt: 3, mb: 2}}/>
    </Box>
  }

  return <Container component="main" maxWidth="sm"
                    sx={{mt: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
    <Box component="form" noValidate sx={{width: "80%"}}>
      {tokenIssued === true && <LoginLinkIssued/>}
      {(typeof tokenIssued === "object" && "code" in tokenIssued) && <LoginLinkError error={tokenIssued}/>}
      {tokenIssued === false && <LoginLinkForm/>}
      <Grid container>
        <Grid item xs>
          <Link href={config.userAppBaseUrl} variant="body2">
            Looking for app login?
          </Link>
        </Grid>
        <Grid item>
          <Link href={config.websiteBaseUrl} variant="body2">
            {"Don't have an account?"}
          </Link>
        </Grid>
      </Grid>
    </Box>
  </Container>
}


