import React from "react";
import {NavigationHeader} from "../../common/layout/NavigationHeader";
import {AccountBarMenu, AccountDrawerMenu} from "./AccountMenus";
import {AccountAuthenticationProfile} from "../auth/useAccountAuthentication";

export function AccountAppNavigationHeader({profile}: AccountAppNavigationHeaderProps) {
  return (
      <NavigationHeader barMenu={<AccountBarMenu profile={profile}/>}
                        drawerMenu={<AccountDrawerMenu profile={profile}/>}/>
  )
}

export type AccountAppNavigationHeaderProps = {
  profile: AccountAuthenticationProfile
}
