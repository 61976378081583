import {Box, Divider, List} from "@mui/material";
import * as React from "react";
import {RepositoryRow, RepositoryRowMenuProvider} from "./RepositoryRow";
import {Repository} from "../../api/model";
import {Panel} from "../../../common/components/Panel";

import {VcsProvider} from "../../../common/models";

export type OrgRepositoriesPanelProps = {
  vcs: VcsProvider
  repositories: Repository[]
  menu: RepositoryRowMenuProvider
}

export function OrgRepositoriesPanel({vcs, repositories, menu}: OrgRepositoriesPanelProps) {
  return <Panel header="Repositories" noPadding>
    <List sx={{width: "100%"}}>
      {repositories.map((repository, index) => {
            return <Box key={repository.path.org + repository.path.repo}>
              <RepositoryRow vcs={vcs} org={repository.path.org} path={repository.path} menu={menu}
                             isPrivate={repository.isPrivate}/>
              {index !== repositories.length - 1 && <Divider sx={{mt: 1, mb: 1}}/>}
            </Box>
          }
      )}
    </List>
  </Panel>

}
