import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Tooltip, TypographyProps} from "@mui/material";
import {formatUTCToHumanisedDurationFromNow, formatUTCToLocalDateTime} from "../../common/dateTime";
import {DateTime} from "../../common/models";

export function DateFromNow({date, ...rest}: DateFromNowProps) {
  return (
      <Tooltip arrow title={formatUTCToLocalDateTime(date)} placement="top">
        <Typography {...rest} >
          {formatUTCToHumanisedDurationFromNow(date)}
        </Typography>
      </Tooltip>
  )
}

export type DateFromNowProps = {
  date: DateTime;
} & TypographyProps
