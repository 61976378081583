import {NavigationTarget, reloadWindow} from "../navigation";
import {NavigateOptions, useNavigate} from "react-router-dom";
import {useCommonAppInfrastructure} from "../CommonAppInfrastructure";
import {useCallback} from "react";

export abstract class Navigation {
    abstract baseUrl(): string

    navigationTargetFor(uri: string): NavigationTarget {
        const isExternal = !(uri || "").startsWith(this.baseUrl())
        const href = isExternal ? uri : uri.replace(this.baseUrl(), "")
        return {href, isExternal}
    }
}

export type UseNavigation = {
    navigateTo: (uri: string, options?: NavigateOptions) => void
}

export const useNavigation = () : UseNavigation => {
    const navigate = useNavigate()
    const {navigation} = useCommonAppInfrastructure()
    const navigateTo = useCallback(
        (uri: string, options?: NavigateOptions) => {
            const target = navigation.navigationTargetFor(uri);
            target.isExternal ? reloadWindow(target.href) : navigate(target.href, options)
        },
        [navigate, navigation]
    );

    return {
        navigateTo
    }
}
