import * as React from 'react';
import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link} from "../../../common/components/navigation/Link";
import {LaunchAppPageId} from "../LaunchAppPageId";
import {LaunchAppPage} from "../../layout/LaunchAppPage";
import {OAuthStatusPathParameter, usePathParams} from "../../../userApp/models/Paths";
import {useUserAuthentication} from "../../../userApp/auth/useUserAuthentication";
import {useUserAppInfrastructure} from "../../../userApp/UserAppInfrastructure";

export function LoginCLICompletedPage() {
  const {completeLoginCLI} = useUserAuthentication()
  const {config, urls} = useUserAppInfrastructure()
  const pathParams = usePathParams<OAuthStatusPathParameter>();
  const isSuccessful = pathParams.status === "successful"

  useEffect(() => {
    completeLoginCLI()
  }, [completeLoginCLI]);

  return <LaunchAppPage pageId={LaunchAppPageId.CliLoginCompleted} title="Login CLI" error={null} >
    <Container component="main">
      <CssBaseline/>
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
      >
        <Typography variant="h4">
          {isSuccessful
              ? `You're logged in to the ${config.appName} CLI!`
              : `Sorry, your ${config.appName} CLI login failed!`}
        </Typography>

        <Typography variant="h6" sx={{mt: 10, mb: 3}}>
          You may close this window and use {config.appName} CLI or continue to discover service <Link
            underline="always" href={urls.home()}>here</Link>.
        </Typography>
      </Box>
    </Container>
  </LaunchAppPage>
}
