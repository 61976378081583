import {formatDateToMonthAndDay} from "../../../common/dateTime";

export type StatisticsNumberAggregate = {
    avg: number;
    max: number;
}

export type StatisticsDateAggregate = {
    date: string;
    successful: number;
    failed: number;
    duration: StatisticsNumberAggregate;
}

export type StatisticsAggregate = {
    successful: number;
    failed: number;
    successRate: number;
    failureRate: number;
    duration: StatisticsNumberAggregate;
    durationFromCommit: StatisticsNumberAggregate;
    dates: StatisticsDateAggregate[]
}

export type StatisticsGroupAggregate = {
    group: string
    statistics: StatisticsAggregate
}

export function formatStatisticsDates(statistics: StatisticsDateAggregate[]): StatisticsDateAggregate[] {
    return statistics.map(
        data => ({...data, date: " " + formatDateToMonthAndDay(data.date) + " "})
    )
}
