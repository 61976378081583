import * as React from "react";
import {MenuItem, Select, SelectChangeEvent, Skeleton} from "@mui/material";
import {GitBranchIcon} from "@primer/octicons-react";
import {useRepositoryBranchesQuery} from "../../api/pipelines";
import {VcsBranch} from "../../models";
import {setQuery} from "../../../common/navigation";
import {branchParam} from "../../utils/urlParams";
import {OrgRepoPath} from "../../../common/models";
import {useNavigation} from "../../../common/port/Navigation";

export type BranchSelectionProps = {
  path: OrgRepoPath;
  selected: VcsBranch;
  maxWidth: string;
  onBranch?: (branch: VcsBranch) => void;
}

export function BranchSelection({path, selected, maxWidth}: BranchSelectionProps) {
  const {navigateTo} = useNavigation()
  const {data} = useRepositoryBranchesQuery({path})
  const disabled = !data || data.kind === "error";

  const handleChange = (event: SelectChangeEvent) => {
    navigateTo(setQuery(branchParam(event.target.value)), {replace: true})
  };

  if (disabled) return <Skeleton variant="rectangular" sx={{width: maxWidth, height: "100%"}}/>

  return (
      <Select
          data-test="branch-selection"
          sx={{minWidth: maxWidth, maxWidth: maxWidth}}
          placeholder="branch"
          size="small"
          disabled={disabled}
          value={selected}
          onChange={handleChange}
      >{data.data.map(branch => <MenuItem key={branch} value={branch}><GitBranchIcon/> {branch}</MenuItem>)}
      </Select>
  )
}
