import {CommitSha, VcsBranch, VcsRunId, VcsUrl} from "./index";
import {VcsUrls} from "./VcsUrls";
import {OrgRepoPath, VcsOwner, VcsPath} from "../../common/models";

export class GitHubUrls implements VcsUrls {
    private readonly baseUrl: VcsUrl

    constructor(baseUrl: VcsUrl = "https://github.com") {
        this.baseUrl = baseUrl
    }

    branch(path: OrgRepoPath, branch: VcsBranch): VcsUrl {
        return `${this.baseUrl}/${path.org}/${path.repo}/tree/${branch}`
    }

    commit(path: OrgRepoPath, sha: CommitSha): VcsUrl {
        return `${this.baseUrl}/${path.org}/${path.repo}/commit/${sha}`
    }

    org(org: VcsOwner): VcsUrl {
        return `${this.baseUrl}/${org}`
    }

    pipeline(path: VcsPath): VcsUrl {
        return `${this.baseUrl}/${path}/actions`
    }

    pipelineRun(path: OrgRepoPath, vcsRunId: VcsRunId): VcsUrl {
        return `${this.baseUrl}/${path.org}/${path.repo}/actions/runs/${vcsRunId}`;
    }

    repo(path: OrgRepoPath): VcsUrl {
        return `${this.baseUrl}/${path.org}/${path.repo}`
    }

    user(owner: VcsOwner): VcsUrl {
        return `${this.baseUrl}/${owner}`
    }
}
