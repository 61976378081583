import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import {ReactNode} from "react";
import {Box, CssBaseline, Drawer, IconButton} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Divider from "@mui/material/Divider";
import {Link} from "../components/navigation/Link";
import {monopolisConstraints} from "../styles/constraints";
import {MonopolisLogo} from "../components/icons";
import {monopolisTheme} from "../styles/theme";

export type NavigationHeaderProps = {
  barMenu: ReactNode
  drawerMenu: ReactNode
}

export function NavigationHeader({barMenu, drawerMenu}: NavigationHeaderProps) {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return <Box sx={{display: 'flex'}}>
    <CssBaseline/>
    <AppBar
        position="relative"
        color="default"
        elevation={0}
        sx={{
          zIndex: 1000,
          backgroundColor: monopolisTheme.palette.primary.dark,
          borderBottom: `1px solid ${monopolisTheme.palette.primary.main}`
        }}
    >
      <Toolbar sx={{height: monopolisConstraints.headerHeight}}>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{display: {md: 'none'}, color: monopolisTheme.palette.primary.light}}
            onClick={toggleDrawer}
            data-test="page-nav-drawer-btn"
        >
          <MenuIcon/>
        </IconButton>

        <Link href="/" underline="none" sx={{
          alignItems: "center",
          display: {xs: 'none', sm: 'none', md: "flex"}
        }}>
          <MonopolisLogo
              textColor={monopolisTheme.palette.primary.light}
              iconColor={monopolisTheme.palette.primary.dark}
              height={(monopolisConstraints.headerHeight - 30)}
          />
        </Link>
        <Box sx={{flexGrow: 1}}/>
        <Box sx={{flexDirection: "row", alignItems: "center", display: {xs: 'none', sm: 'none', md: "flex"}}}>
          {barMenu}
        </Box>
      </Toolbar>
    </AppBar>
    <Drawer
        PaperProps={{sx: {width: "60%", minWidth: 200, maxWidth: 350}}}
        sx={{height: 200, minWidth: 600, maxWidth: 600, width: 600}}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
    >

      <Link href="/" underline="none" sx={{ml: 1, mt: 1}}>
        <MonopolisLogo
            textColor={monopolisTheme.palette.primary.main}
            iconColor={monopolisTheme.palette.primary.light}
            height={(monopolisConstraints.headerHeight - 30)}/>
      </Link>

      <Divider/>
      {drawerMenu}
    </Drawer>
  </Box>;
}

