import React, {Component, ReactNode} from "react";
import {UnknownErrorPage} from "../pages/error/UnknownErrorPage";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return {hasError: true};
  }

  public render() {
    if (this.state.hasError) {
      return <UnknownErrorPage/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
