import {AdditionalAnalyticsData, Analytics, EventCategory} from "../../port/Analytics";

export class ConsoleAnalytics implements Analytics {
    initialise(): void {
    }

    additionalData(data: AdditionalAnalyticsData): void {
        console.log(`Additional data: ${JSON.stringify(data)}`)
    }

    pageview(title: string): void {
        console.log(`Page view: ${title}`)
    }

    dialogview(title: string): void {
        console.log(`Dialog view: ${title}`)
    }

    event(category: EventCategory, action: string, label: string): void {
        console.log(`Event: ${category} - ${action} - ${label}`)
    }

    timing(category: EventCategory, action: string, label: string, value: number): void {
        console.log(`Timing: ${category} - ${action} - ${label} (${value})`)
    }
}
