import * as React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "./UserApp.css";
import "font-awesome-animation/css/font-awesome-animation.css";
import 'reactflow/dist/style.css';
import {StyledSnackbarProvider} from "../common/components/StyledSnackbarProvider";
import {UserAppRouter} from "./UserAppRouter";
import ErrorBoundary from "./layout/ErrorBoundary";
import {UserAppInfrastructure, UserAppInfrastructureProvider} from "./UserAppInfrastructure";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";

export type UserAppProps = {
  infra: UserAppInfrastructure
}

export function UserApp({infra}: UserAppProps): React.ReactElement {
  return (
      <StyledSnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={new QueryClient()}>
            <UserAppInfrastructureProvider infra={infra}>
              <ErrorBoundary>
                <BrowserRouter>
                  <UserAppRouter/>
                </BrowserRouter>
              </ErrorBoundary>
              <ReactQueryDevtools initialIsOpen={false}/>
            </UserAppInfrastructureProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </StyledSnackbarProvider>
  );
}
