import axios, {AxiosResponse, RawAxiosRequestConfig, ResponseType} from 'axios';
import {timeZoneOffset} from "../dateTime";

import {Analytics} from "../port/Analytics";

export type SystemErrorCode =
    "UNKNOWN" |
    "BAD_REQUEST" |
    "NOT_FOUND" |
    "UPGRADE_REQUIRED" |
    "FORBIDDEN" |
    "INTERNAL_SERVER_ERROR" |
    "SERVICE_UNAVAILABLE" |
    "INVALID_TOKEN"

export type SystemError = {
    code: SystemErrorCode;
    message: string;
}

export type SystemErrorResponse = {
    error: SystemError
}

export type SystemResponse<T> = {
    kind: "ok"
    data: T
} | {
    kind: "error"
    error: SystemError
}

export type OnError = {
    onError: (error: SystemError) => void
}

export function systemError(code: SystemErrorCode, message: string): SystemResponse<any> {
    return {
        kind: "error",
        error: {
            code: code,
            message: message
        }
    }
}

export function systemResponse<T>(data: T): SystemResponse<T> {
    return {
        kind: "ok",
        data: data
    }
}

export type TransportLayerParams = {
    responseType?: ResponseType
    payload?: string
    token?: () => string | undefined
    headers?: Record<string, string>
    resource?: <REQUEST> (request?: REQUEST) => string,
}

export abstract class TransportLayer {
    private readonly headers?: Record<string, string>;
    private readonly analytics: Analytics;

    abstract token(): Promise<string | undefined>

    abstract logout(): void

    abstract baseUrl(): string

    protected constructor(analytics: Analytics, headers?: Record<string, string>) {
        this.analytics = analytics
        this.headers = headers;
    }

    get<T>(action: string, resource: string, params: TransportLayerParams = {}): Promise<SystemResponse<T>> {
        const startTime = performance.now()
        return this.checkAuthenticated(params.token && params.token() )
            .then(() => axios.get(this.resourceUrl(resource), this.requestConfig(params)))
            .then(this.asSystemResponse<T>())
            .then(result => {
                this.analytics.timing("Api", action, "GET " + resource, performance.now() - startTime)
                return result
            })
    }

    post<T>(action: string, resource: string, params: TransportLayerParams = {}): Promise<SystemResponse<T>> {
        const startTime = performance.now()
        const url = this.resourceUrl((params.resource) ? params.resource(params.payload) : resource)
        return this.checkAuthenticated(params.token && params.token())
            .then(() => axios.post(url, params.payload, this.requestConfig(params)))
            .then(this.asSystemResponse<T>())
            .then(result => {
                this.analytics.timing("Api", action, "POST " + resource, performance.now() - startTime)
                return result
            })
    }

    put<T>(action: string, resource: string, params: TransportLayerParams = {}): Promise<SystemResponse<T>> {
        const startTime = performance.now()
        const url = this.resourceUrl((params.resource) ? params.resource(params.payload) : resource)
        return this.checkAuthenticated(params.token && params.token())
            .then(() => axios.put(url, params.payload, this.requestConfig(params)))
            .then(this.asSystemResponse<T>())
            .then(result => {
                this.analytics.timing("Api", action, "PUT " + resource, performance.now() - startTime)
                return result
            })
    }

    delete<T>(action: string, resource: string, params: TransportLayerParams = {}): Promise<SystemResponse<T>> {
        const startTime = performance.now()
        const url = this.resourceUrl((params.resource) ? params.resource(params.payload) : resource)
        return this.checkAuthenticated(params.token && params.token())
            .then(() => axios.delete(url, this.requestConfig(params)))
            .then(this.asSystemResponse<T>())
            .then(result => {
                this.analytics.timing("Api", action, "DELETE " + resource, performance.now() - startTime)
                return result
            })
    }

    resourceUrl(resource: string): string {
        return this.baseUrl() + resource;
    }

    checkAuthenticated(token: string | undefined): Promise<string> {
        return (token !== undefined ? Promise.resolve(token) : this.token())
            .then(resolvedToken => {
                if (resolvedToken !== undefined) {
                    return resolvedToken
                } else {
                    this.logout()
                    throw Error("Invalid token")
                }
            })
    }

    private requestConfig = (params: TransportLayerParams = {}): RawAxiosRequestConfig => {
        return {
            validateStatus: () => true,
            withCredentials: true,
            responseType: params.responseType,
            headers: {...this.headers, ...params.headers, "x-monopolis-tz": timeZoneOffset()}
        }
    }

    private asSystemResponse<T>(): (response: AxiosResponse<T | SystemErrorResponse>) => SystemResponse<T> {
        return (response: AxiosResponse<T | SystemErrorResponse>) => {
            if ((response.status >= 200 && response.status <= 299)) {
                return systemResponse(response.data as T)
            } else {
                switch (response.status) {
                    case 400:
                        return systemError("BAD_REQUEST", "Bad request")

                    case 401:
                        this.logout()
                        return systemError("INVALID_TOKEN", "Invalid token")

                    case 403:
                        return systemError("FORBIDDEN", "Forbidden")

                    case 404:
                        return systemError("NOT_FOUND", "Not found")

                    case 426:
                        return systemError("UPGRADE_REQUIRED", "Upgrade required")

                    case 500:
                        return systemError("INTERNAL_SERVER_ERROR", "Internal server error")

                    case 503:
                        return systemError("SERVICE_UNAVAILABLE", "Service unavailable")

                    default:
                        return systemError("UNKNOWN", response.statusText)
                }
            }
        };
    }
}


