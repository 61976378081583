import * as React from 'react';
import {PipelineStatus} from "../../api/pipelines/model";

import {Box, Paper} from "@mui/material";
import {Handle, NodeProps, Position} from "reactflow";
import {Link} from "../../../common/components/navigation/Link";
import {PipelineStatusStyles} from "../../styles/pipelines";
import {PipelineStatusIcon} from "../pipelines/PipelineStatusIcon";
import {VcsPipelineName} from "../VcsPipelineName";
import {VcsCommit} from "../VcsCommit";
import Typography from "@mui/material/Typography";
import {DateFromNow} from "../DateFromNow";
import {MonodeRollout} from "../../api/graphs/model";
import {monopolisTheme} from "../../../common/styles/theme";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

import {VcsProvider} from "../../../common/models";

export function RolledOutMonodeGraphNode({data}: RolledOutMonodeGraphNodeProps) {
  const {vcsUrls} = useUserAppInfrastructure()
  const nodeWidth = 300
  const statusIconWidth = 35
  const spacingWidth = 5
  const menuButtonWidth = 30
  const commitWidth = nodeWidth - spacingWidth * 2
  const nameWidth = nodeWidth - statusIconWidth - menuButtonWidth - spacingWidth * 2

  const handleStyle = {
    minWidth: 11,
    minHeight: 11,
    borderWidth: 3,
    borderColor: monopolisTheme.palette.primary.main,
    backgroundColor: monopolisTheme.palette.background.paper
  }
  return (
      <Paper
          variant="outlined"
          style={{
            borderRadius: 15,
            borderWidth: 3,
            borderColor: monopolisTheme.palette.primary.main,
            backgroundColor: monopolisTheme.palette.background.paper,
            minWidth: nodeWidth,
            maxWidth: nodeWidth,
          }}>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          padding: 1,
          borderBottom: 2,
          borderColor: monopolisTheme.palette.primary.main,
        }}
             alignItems="center">
          <Box sx={{
            flex: 1,
            minWidth: `${statusIconWidth}px`,
            maxWidth: `${statusIconWidth}px`,
            marginRight: `${spacingWidth}px`,
          }}>
            <PipelineStatusIcon variant="job" size={statusIconWidth} status={data.status}
                                hasLink={false} styles={PipelineStatusStyles}/>
          </Box>
          <Box sx={{
            flex: 1,
            minWidth: `${nameWidth}px`,
            maxWidth: `${nameWidth}px`,
          }}>
            <VcsPipelineName path={data.rollout.path}
                             branch={data.rollout.branch}
                             monode={data.rollout.monode}
                             maxWidth={nameWidth}/>
          </Box>
        </Box>

        <Box sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: monopolisTheme.palette.background.paper,
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12
        }} alignItems="center">

          <Box sx={{flex: 1, px: 1, pt: 1, minWidth: `${commitWidth}px`, maxWidth: `${commitWidth}px`,}}>
            <VcsCommit path={data.rollout.path}
                       branch={data.rollout.branch}
                       user={data.rollout.user}
                       event="rollout"
                       message={data.rollout.commit.message}
                       sha={data.rollout.commit.sha}/>
          </Box>

          <Box sx={{px: 2, pb: 1, minWidth: `${commitWidth}px`, maxWidth: `${commitWidth}px`,}}>
            <DateFromNow variant="caption" display="inline" date={data.rollout.rolloutTime}/>
            <Typography variant="caption" display="inline"> by <Link
                href={vcsUrls.user(data.rollout.deployer.login)}>{data.rollout.deployer.login}</Link>
            </Typography>
          </Box>
        </Box>

        <Handle type="source" position={Position.Left} isConnectable={true} style={{...handleStyle, top: 64}}/>
        <Handle type="target" position={Position.Right} style={{...handleStyle, top: 64}}/>
      </Paper>
  )
}

export type RolledOutMonodeGraphNodeData = {
  vcs: VcsProvider;
  rollout: MonodeRollout;
  status: PipelineStatus;
}

export type RolledOutMonodeGraphNodeProps = {} & NodeProps<RolledOutMonodeGraphNodeData>
