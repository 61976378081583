import * as React from 'react';
import {ReactNode} from 'react';
import {Grid, Typography} from "@mui/material";
import {Pipeline, PipelineRun} from "../../api/pipelines/model";
import {VcsAvatar} from "../VcsAvatar";
import {Link} from "../../../common/components/navigation/Link";
import {GitCommitIcon} from "@primer/octicons-react";
import {Panel} from "../../../common/components/Panel";
import {monopolisTheme} from "../../../common/styles/theme";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {PipelinesTablePanel} from "./PipelinesTablePanel";
import {extendPipelines, PipelinesTableRowField} from "../../utils/pipelines";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type CommitPanelPipelineMenuProvider = (params: { pipeline: Pipeline, run: PipelineRun }) => ReactNode

export type CommitPanelProps = {
  pipelines: Pipeline[];
  menu: CommitPanelPipelineMenuProvider;
}

export function CommitPanel({pipelines, menu}: CommitPanelProps) {
  const {vcsUrls} = useUserAppInfrastructure()
  const firstPipeline = pipelines[0];
  const firstPipelineRun = firstPipeline && firstPipeline.runs[0];

  return <Grid container>
    {
        (pipelines.length > 0 && firstPipeline && firstPipelineRun) &&
        <Grid container spacing={monopolisConstraints.gridSpacing}>
          <Grid item xs={12} md={12} lg={12}>
            <Panel header="Commit">
              <Grid container spacing={1} alignItems={"center"} justifyContent={"center"}>
                <Grid item>
                  <VcsAvatar name={firstPipelineRun.trigger.user.login}
                             avatar={firstPipelineRun.trigger.user.avatar} size={40}/>
                </Grid>
                <Grid item xs={11} sm container>

                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography variant="body2">
                        {firstPipelineRun.commit.message}
                      </Typography>
                      <Typography noWrap variant="caption" display="block"
                                  color={monopolisTheme.palette.primary.main}>
                        <Link
                            href={vcsUrls.commit(firstPipeline.path, firstPipelineRun.commit.sha)}
                            color={monopolisTheme.palette.primary.main}>
                          <GitCommitIcon/> {firstPipelineRun.commit.sha}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Panel>
          </Grid>

          <PipelinesTablePanel testId="commit-pipelines" title="Pipelines"
                               fields={{
                                 [PipelinesTableRowField.commit]: false,
                                 [PipelinesTableRowField.buildStatusChart]: false
                               }}
                               pipelines={extendPipelines(pipelines)}
                               menu={menu}/>
        </Grid>
    }
  </Grid>
}
