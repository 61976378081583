import {Box, Chip, ListItem, SxProps, Typography} from "@mui/material";
import {Link} from "../../../common/components/navigation/Link";
import * as React from "react";
import {ReactNode} from "react";
import {formatBreadcrumbs} from "../../../common/breadcrumbs";
import {VcsIcon} from "../VcsIcon";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {OrgRepoPath, VcsOwner, VcsPath, VcsProvider} from "../../../common/models";

export type RepositoryRowMenuProvider = (params: { vcs: VcsProvider, owner: VcsOwner, path: VcsPath }) => ReactNode

export type RepositoryRowProps = {
  vcs: VcsProvider
  org: VcsOwner
  path: OrgRepoPath
  isPrivate: boolean
  menu: RepositoryRowMenuProvider
  sx?: SxProps
}

export function RepositoryRow({vcs, org, path, menu, isPrivate, sx}: RepositoryRowProps) {
  const {vcsUrls, urls} = useUserAppInfrastructure()
  return <ListItem sx={sx}>
    <Box sx={{display: "flex", flexDirection: "row", width: "100%"}} alignItems="center">
      <Box sx={{flexGrow: 1}}>
        <Typography variant="body1" noWrap sx={{
          textOverflow: "ellipsis"
        }}>
          <Link href={urls.repository(path)}>
            {formatBreadcrumbs(path.repo)}
          </Link>
          <span style={{marginRight: 8, marginLeft: 8}}>
            <Link href={vcsUrls.repo(path)}>
                <VcsIcon type={vcs}/>
            </Link>
          </span>
          {isPrivate && <Chip label="private" size="small" variant="outlined" sx={{ml: 2}}/>}
        </Typography>
      </Box>

      {menu({vcs, owner: org, path: path.repo})}
    </Box>
  </ListItem>
}
