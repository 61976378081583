import {VcsRepository} from "../models";
import {PathSegments} from "../models/Paths";

import {AuthenticatedUserProfile} from "./AuthenticatedUserProfile";
import {VcsOwner} from "../../common/models";


export type Permission = string

export type PermissionTemplate = {
    template: string
}

export type PermissionTemplateParams = {
    owner?: VcsOwner,
    repository?: VcsRepository
}

export type ResolvedPermission = {
    permission: string
}

export class PermissionActions {
    static Edit = "edit"
}

export class UserPermissions {
    static EditOrganisation: PermissionTemplate = {template: `${PathSegments.owner}::${PermissionActions.Edit}`}

    static checkAllowed(profile: AuthenticatedUserProfile, template: PermissionTemplate, params: PermissionTemplateParams): boolean {
        const resolved = template.template
            .replaceAll(PathSegments.owner, params.owner || "")
            .replaceAll(PathSegments.repository, encodeURIComponent(params.repository || ""))

        return profile.permissions.indexOf(resolved) >= 0
    }
}
