import React from 'react';
import {EdgeProps, getBezierPath} from 'reactflow';
import {monopolisTheme} from "../../../common/styles/theme";

export default function MonodeGraphEdge(
    {
      id,
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
      style = {},
    }: MonodeGraphEdgeProps) {
  const edgePath = getBezierPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition});

  return (
      <>
        <defs>
          <marker className="react-flow__arrowclosed" id="monode_arrowhead" markerWidth="10" markerHeight="10"
                  viewBox="-10 -10 20 20" orient="auto" refX="0" refY="0">
            <polyline stroke={monopolisTheme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="1" fill={monopolisTheme.palette.primary.main}
                      points="-5,-4 0,0 -5,4 -5,-4"/>
          </marker>
        </defs>
        <path id={id} className="react-flow__edge-path"
              style={{...style, stroke: monopolisTheme.palette.primary.main}}
              d={edgePath[0]}
              strokeDasharray="6"
              markerEnd={"url(#monode_arrowhead)"}/>
      </>
  );
}

export type MonodeGraphEdgeProps = {} & EdgeProps
