import {OnError} from "../../../common/api/TransportLayer";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {VcsOwner} from "../../../common/models";

export enum OrgConfigurationPageTab {
  repositories = "repositories",
  members = "members",
  security = "security",
  integrations = "integrations",
  notifications = "notifications",
}

export type OrgConfigurationPageTabProps = {
  owner: VcsOwner
  userProfile: AuthenticatedUserProfile
} & OnError
