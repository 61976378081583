import * as React from "react";
import {OrgConfigurationPageTabProps} from "./OrgConfigurationPageTab";
import {currentLocation} from "../../../common/navigation";
import {useOrgAllIntegrationsSettingsQuery} from "../../api/org";
import {Box} from "@mui/material";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {IntegrationType} from "../../api/org/model";
import {IntegrationSettingsPanel} from "../../components/integrations/IntegrationSettingsPanel";
import {ConfigurationPanel, ConfigurationSectionDivider} from "../../../common/components/configuration";

export function OrgConfigurationPageIntegrationsTab({owner, userProfile, onError}: OrgConfigurationPageTabProps) {
  const originUri = currentLocation()
  const integrationsQuery = useOrgAllIntegrationsSettingsQuery({owner})

  const allowedIntegrations: IntegrationType[] = [
    IntegrationType.Slack,
    IntegrationType.Discord,
    IntegrationType.PagerDuty,
    IntegrationType.StatusPage,
  ]

  return <QueryLoader result={integrationsQuery} onError={onError} onLoaded={integrations =>
      <Box>
        <ConfigurationPanel data-test="integrations">
          {
            allowedIntegrations
                .map(type => integrations.find(integration => integration.type === type))
                .map((integration, index) =>
                    <Box key={typeof integration}>
                      <IntegrationSettingsPanel owner={owner} userProfile={userProfile}
                                                originUri={originUri} {...integration}/>
                      {index < allowedIntegrations.length - 1 && <ConfigurationSectionDivider/>}
                    </Box>
                )
          }
        </ConfigurationPanel>
      </Box>
  }/>
}
