import * as React from "react";
import {OrgConfigurationPageTabProps} from "./OrgConfigurationPageTab";
import {ConfigurationPanel, ConfigurationSection} from "../../../common/components/configuration";
import {ApiKeysPanel} from "../../components/org/ApiKeysPanel";
import {useGenerateOrgApiKeyMutation} from "../../api/org";

export function OrgConfigurationPageSecurityTab({owner, onError}: OrgConfigurationPageTabProps) {
  const generateOrgApiKeyMutation = useGenerateOrgApiKeyMutation({org: owner})

  console.log(generateOrgApiKeyMutation)

  return <ConfigurationPanel data-test="security">
    <ConfigurationSection data-test="api-keys-panel" label="API Keys">
      <ApiKeysPanel org={owner} onError={onError}/>
    </ConfigurationSection>
  </ConfigurationPanel>
}
