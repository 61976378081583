import {Link} from "../../../common/components/navigation/Link";
import {PipelineStatusPill} from "./PipelineStatusPill";
import {VcsCommit} from "../VcsCommit";
import {PipelineDuration} from "./PipelineDuration";
import {PipelineStageIcons} from "./PipelineStageIcons";
import * as React from "react";
import {pipelineId, PipelineRun} from "../../api/pipelines/model";
import {VcsPipelineName} from "../VcsPipelineName";
import {
  ExtendedPipeline,
  hasRowField,
  PipelineMenuProvider,
  PipelinesTableRowField,
  PipelinesTableRowFieldsFilter
} from "../../utils/pipelines";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {monopolisTheme} from "../../../common/styles/theme";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {SmallPipelinesStatusChart} from "./SmallPipelinesStatusChart";
import {Box, Typography} from "@mui/material";
import {PipelineTargetTooltip} from "./PipelineTargetTooltip";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type PipelinesTableRowProps = {
  pipeline: ExtendedPipeline
  run: PipelineRun
  menu: PipelineMenuProvider
  draggable?: boolean
  fields?: PipelinesTableRowFieldsFilter
}

export function PipelinesTableRow({pipeline, run, menu, draggable, fields}: PipelinesTableRowProps) {
  const {vcsUrls, urls} = useUserAppInfrastructure()
  const {attributes, listeners, setNodeRef, transform, transition} =
      useSortable({id: pipelineId(pipeline)});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,

    ...(draggable === true && {backgroundColor: monopolisTheme.palette.background.paper})
  };

  const cellBorder = {
    borderColor: monopolisTheme.palette.divider,
  }

  return <TableRow ref={setNodeRef} style={style} hover>
    {draggable === true &&
        <TableCell sx={{pr: 0, ...cellBorder}}>
          <DragIndicatorIcon {...attributes} {...listeners}/>
        </TableCell>
    }

    {
        hasRowField(PipelinesTableRowField.status, fields) &&
        <TableCell sx={{width: "10px", pr: 0, ...cellBorder}}>
          <Link href={vcsUrls.pipelineRun(pipeline.path, run.runId)}>
            <PipelineStatusPill status={pipeline.status}/>
          </Link>
        </TableCell>
    }

    {
        hasRowField(PipelinesTableRowField.name, fields) &&
        <TableCell sx={{maxWidth: "250px", minWidth: "150px", ...cellBorder}}>
          <VcsPipelineName path={pipeline.path}
                           branch={pipeline.branch}
                           monode={pipeline.name}
                           maxWidth="100%"/>
        </TableCell>
    }

    {
        hasRowField(PipelinesTableRowField.commit, fields) &&
        <TableCell sx={{minWidth: "100px", maxWidth: "300px", ...cellBorder}}>
          <VcsCommit path={pipeline.path}
                     branch={pipeline.branch}
                     user={run.trigger.user}
                     message={run.commit.message}
                     sha={run.commit.sha}
                     event={run.trigger.event}/>
        </TableCell>
    }

    {
        hasRowField(PipelinesTableRowField.buildStatusChart, fields) &&
        <TableCell sx={{minWidth: "50px", maxWidth: "100px", ...cellBorder}}>
          <SmallPipelinesStatusChart statistics={pipeline.statistics?.dates || []}/>
        </TableCell>
    }

    <TableCell sx={{maxWidth: "100px", minWidth: "140px", ...cellBorder}}>
      <PipelineDuration status={run.status} date={run.startedAt}
                        duration={run.durationSeconds}/>
    </TableCell>

    <TableCell sx={{minWidth: "100px", maxWidth: "150px", ...cellBorder}}>
      <PipelineStageIcons stages={run.stages} rollouts={run.rollouts}/>

      {pipeline.rollouts.length > 0 &&
          <Box sx={{mt: 0.5}}>
            {pipeline.rollouts.map(rollout => {
                  return <Link variant="caption"
                               tooltip={<PipelineTargetTooltip {...rollout} monode={pipeline.name}/>}
                               href={urls.target({
                                 org: pipeline.org,
                                 repo: pipeline.repo,
                                 monode: pipeline.name,
                                 target: rollout.target
                               })}
                               sx={{mr: 1, mt: 1}}>{rollout.target}</Link>
                }
            )}
          </Box>
      }
    </TableCell>


    <TableCell sx={{width: "30px", ...cellBorder}}>
      <Typography color={monopolisTheme.palette.primary.main}>
        {menu({pipeline, run})}
      </Typography>
    </TableCell>
  </TableRow>
}
