import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {RepositoryPageTabProps} from "./RepositoryPageTab";
import {DependencyPanel} from "../../components/graphs/DependencyPanel";
import {useDependencyGraphQuery} from "../../api/graphs";

export function RepositoryPageDependenciesTab({path, userProfile, onError}: RepositoryPageTabProps) {
  const dependencyGraphQuery = useDependencyGraphQuery({path})
  return <QueryLoader height={450} result={dependencyGraphQuery} onError={onError} onLoaded={data =>
      <DependencyPanel vcs={userProfile.vcs}
                       graph={data}/>
  }/>
}
