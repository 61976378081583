import * as React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatStatisticsDates, StatisticsDateAggregate} from "../../api/statistics/model";
import {humaniseDuration} from "../../../common/dateTime";
import {RechartsTooltipProps} from "../../utils/recharts";
import {Panel} from "../../../common/components/Panel";
import {monopolisTheme} from "../../../common/styles/theme";

type DataPayload = {
  date: string,
  maxDuration: number,
  avgDuration: number,
  durationMaxDelta: number
}

function formatDurations(statistics: StatisticsDateAggregate[]): DataPayload[] {
  return statistics.map(
      data => ({
        date: data.date,
        maxDuration: data.duration.max,
        avgDuration: data.duration.avg,
        durationMaxDelta: data.duration.max - data.duration.avg
      })
  )
}

export function PipelinesPerformanceChart({statistics}: PipelinesPerformanceChartProps) {
  const formattedStats = formatDurations(formatStatisticsDates(statistics))

  return <Panel header="Build Performance">
    <ResponsiveContainer width="100%" height={170}>
      {formattedStats.length > 0
          ? <BarChart
              maxBarSize={10}
              data={formattedStats}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3"/>
            <XAxis fontSize="small" dataKey="date"/>
            <YAxis fontSize="small" dataKey="maxDuration" width={70}
                   tickFormatter={timeStr => humaniseDuration(timeStr)}/>
            <Tooltip content={<PipelinesPerformanceChartTooltip/>}
                     cursor={{fill: monopolisTheme.palette.secondary.main}}/>

            <Bar dataKey="avgDuration" stackId="durations" opacity={0.7} fill={monopolisTheme.palette.primary.main}/>
            <Bar dataKey="durationMaxDelta" tooltipType="none" stackId="durations" opacity={0.7}
                 fill={monopolisTheme.palette.grey[300]}/>
          </BarChart>
          : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography variant="body1" color="text.secondary">No data</Typography>
          </Box>
      }
    </ResponsiveContainer>
  </Panel>
}

export type PipelinesPerformanceChartProps = {
  statistics: StatisticsDateAggregate[];
}

function PipelinesPerformanceChartTooltip(params: RechartsTooltipProps<DataPayload>) {
  if (params.active && params.payload && params.payload.length) {
    const payload = params.payload[0].payload;
    return (<Paper style={{borderColor: monopolisTheme.palette.secondary.main}} variant="outlined" sx={{p: 1}}>
      <Typography variant="body2" sx={{mb: 1}}>
        {params.label}
      </Typography>

      <Typography color={monopolisTheme.palette.primary.main} variant="body2" sx={{mb: 1}}>
        Avg. Duration : {humaniseDuration(payload.avgDuration)}
      </Typography>

      <Typography color={monopolisTheme.palette.grey[300]} variant="body2" sx={{mb: 1}}>
        Max. Duration : {humaniseDuration(payload.maxDuration)}
      </Typography>
    </Paper>)
  }

  return null;
}
