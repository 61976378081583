import {Edge, getIncomers, getOutgoers, isNode, Node, Position} from "reactflow";
import dagre from "dagre";

export const getAllIncomers = (node: Node, nodes: Node[], edges: Edge[]): Node[] => {
    return getIncomers(node, nodes, edges).reduce<Node[]>(
        (memo, incomer) => [...memo, incomer, ...getAllIncomers(incomer, nodes, edges)],
        []
    )
}

export const getAllOutgoers = (node: Node, nodes: Node[], edges: Edge[]): Node[] => {
    return getOutgoers(node, nodes, edges).reduce<Node[]>(
        (memo, outgoer) => [...memo, outgoer, ...getAllOutgoers(outgoer, nodes, edges)],
        []
    )
}

export const layoutGraph = <T>(nodeWidth: number, nodeHeight: number, nodes: Node<T>[], edges: Edge[]) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({rankdir: "RL"});
    nodes.forEach((elem) => {
        dagreGraph.setNode(elem.id, {width: nodeWidth, height: nodeHeight});
    })

    edges.forEach((elem) => {
        dagreGraph.setEdge(elem.source, elem.target);
    })

    dagre.layout(dagreGraph);

    return nodes.map((elem) => {
        if (isNode(elem)) {
            const nodeWithPosition = dagreGraph.node(elem.id);
            elem.targetPosition = Position.Left;
            elem.sourcePosition = Position.Right;

            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. Moreover we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            elem.position = {
                x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        }

        return elem;
    });
};
