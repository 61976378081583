import {Paths} from "./Paths";

export class LaunchAppUrls {
    baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    login(): string {
        return this.baseUrl + Paths.UserLogin
    }

    loginCli(): string {
        return this.baseUrl + Paths.CliLogin
    }


    home(): string {
        return this.baseUrl + Paths.Home
    }
}
