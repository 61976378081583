import {Box, Grid, TextField, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {ApiKeyIntegration, StatusPageIntegrationSettings} from "../../api/org/model";
import Button from "@mui/material/Button";
import {snackOptions} from "../../../common/snackbar";
import {useSnackbar} from "notistack";
import {useOrgIntegrationAddMutation, useOrgIntegrationRemoveMutation} from "../../api/org";
import {IntegrationSettingsPanelProps} from "./IntegrationSettingsPanel";
import {ConfigurationSection} from "../../../common/components/configuration";

export type StatusPageIntegrationSettingsPanelProps = IntegrationSettingsPanelProps<StatusPageIntegrationSettings>;

export function StatusPageIntegrationSettingsPanel(
    {
      owner,
      type,
      originUri,
      ...settings
    }: StatusPageIntegrationSettingsPanelProps) {
  const {enqueueSnackbar} = useSnackbar();

  const addMutation = useOrgIntegrationAddMutation<ApiKeyIntegration>({owner, type})
  const removeMutation = useOrgIntegrationRemoveMutation({owner, type})
  const [apiKey, setApiKey] = useState("")

  const add = useCallback(async () => {
    const data = await addMutation.mutateAsync({apiKey: apiKey});
    if (data && data.kind === "error") enqueueSnackbar(`Failed to add StatusPage integration for ${owner}`, snackOptions(false))
    if (data && data.kind === "ok") enqueueSnackbar(`Added StatusPage integration for ${owner}`, snackOptions(true))
  }, [addMutation, enqueueSnackbar, owner, apiKey])

  const remove = useCallback(async () => {
    const data = await removeMutation.mutateAsync({});
    if (data && data.kind === "error") enqueueSnackbar(`Failed to remove StatusPage integration from ${owner}`, snackOptions(false))
    if (data && data.kind === "ok") enqueueSnackbar(`Removed StatusPage integration from ${owner}`, snackOptions(true))
  }, [removeMutation, enqueueSnackbar, owner])

  return <ConfigurationSection data-test="statuspage-integration" label="StatusPage">
    {settings.integrated
        ? <Box>
          <Typography pb={1}>Connected to {type}</Typography>
          <Button onClick={remove} variant="outlined">Disconnect</Button>
        </Box>
        : <Box>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField id="apiKey"
                         type="password"
                         fullWidth={true}
                         autoFocus={true}
                         required={true}
                         value={apiKey} onChange={e => setApiKey(e.target.value)}
                         label="API Key" variant="outlined"/>
            </Grid>

            <Grid item>
              <Button onClick={add} variant="outlined">Connect to StatusPage</Button>
            </Grid>
          </Grid>
        </Box>
    }
  </ConfigurationSection>

}
