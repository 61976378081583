import React, {createContext, ReactNode, useContext} from 'react';
import {Analytics} from "./port/Analytics";

import {DataStorages} from "./port/DataStorages";
import {Navigation} from "./port/Navigation";
import {EnvironmentConfig} from "../Environment";

export interface CommonAppInfrastructure {
  config: EnvironmentConfig
  analytics: Analytics
  storage: DataStorages
  navigation: Navigation
}

export const CommonAppInfrastructureContext = createContext<null | CommonAppInfrastructure>(null);

export type CommonAppInfrastructureProviderProps = {
  infra: CommonAppInfrastructure
  children: ReactNode
}

export function CommonAppInfrastructureProvider({infra, children}: CommonAppInfrastructureProviderProps) {
  return <CommonAppInfrastructureContext.Provider value={infra}>
    {children}
  </CommonAppInfrastructureContext.Provider>
}

export const useCommonAppInfrastructure = (): CommonAppInfrastructure => {
  const context = useContext(CommonAppInfrastructureContext);
  if (context == null) {
    throw new Error('CommonAppInfrastructureContext requires to be wrapped in an CommonAppInfrastructureProvider');
  }

  return context;
}
