import * as React from "react";
import {MenuItem, Select, SelectProps} from "@mui/material";
import {FormField} from "./Form";
import Typography from "@mui/material/Typography";

export type FormSelectFieldProps = {
  label: string
  readOnly: boolean
  fullWidth?: boolean
  value?: string
  options: string[]
} & Omit<SelectProps, "disabled" | "variant" | "fullWidth" | "value">

export function FormSelectField({label, fullWidth, readOnly, value, options, ...fieldProps}: FormSelectFieldProps) {
  return <FormField label={label} fullWidth={fullWidth}>
    {readOnly
        ? <Typography color="secondary">{value || "none"}</Typography>
        : <Select
            data-test="branch-selection"
            fullWidth={true}
            variant="outlined"
            disabled={readOnly}
            value={value}
            {...fieldProps}
        >{options.map(item => <MenuItem key={item} value={item}>
          {item}
        </MenuItem>)}
        </Select>
    }
  </FormField>
}
