import * as React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {RechartsTooltipProps} from "../../../utils/recharts";
import {monopolisTheme} from "../../../../common/styles/theme";
import {AuditEvent, AuditEventType, AuditEventTypes} from "../../../api/events/model";
import {aggregateAuditEventsByDate, AuditEventStatistics} from "../../../utils/events";
import {formatDateToMonthAndDay} from "../../../../common/dateTime";

export type EventsGraphProps = {
  events: AuditEvent[];
}

export function EventsGraph({events}: EventsGraphProps) {
  const aggregatedEvents = aggregateAuditEventsByDate(events)
      .map(item => ({...item, aggregate: formatDateToMonthAndDay(item.aggregate)}))

  return <ResponsiveContainer width="100%" height={170}>
    {aggregatedEvents.length > 0
        ? <BarChart

            data={aggregatedEvents}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3"/>
          <XAxis fontSize="small" dataKey="aggregate"/>
          <YAxis fontSize="small"/>
          <Tooltip content={<EventsGraphTooltip/>} cursor={{fill: monopolisTheme.palette.secondary.main}}/>
          {
            AuditEventTypes.map(eventType =>
                <Bar dataKey={eventType}
                     opacity={0.5}
                     stackId="builds"
                     fill={eventTypeColor(eventType)}/>
            )
          }
        </BarChart>
        : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography variant="body1" color="text.secondary">No data</Typography>
        </Box>
    }
  </ResponsiveContainer>
}

type DataPayload = AuditEventStatistics & {}

function EventsGraphTooltip(params: RechartsTooltipProps<DataPayload>) {
  if (params.active && params.payload && params.payload.length) {
    const payload = params.payload[0].payload;
    return (<Paper style={{borderColor: monopolisTheme.palette.secondary.main}} variant="outlined" sx={{p: 1}}>
      <Typography variant="body2" sx={{mb: 1}}>
        {params.label}
      </Typography>

      {
        AuditEventTypes.map(eventType =>
            <Typography color={eventTypeColor(eventType)} variant="body2" sx={{mb: 1}}>
              {eventType} : {payload[eventType]}
            </Typography>
        )
      }

    </Paper>)
  }

  return null;
}

function eventTypeColor(eventType: AuditEventType): string {
  switch (eventType) {
    case AuditEventType.Push:
      return monopolisTheme.palette.primary.main

    case AuditEventType.Incident:
      return monopolisTheme.palette.warning.main

    case AuditEventType.MonodeAlert:
      return monopolisTheme.palette.error.main

    case AuditEventType.Rollout:
      return monopolisTheme.palette.info.main
  }
}

