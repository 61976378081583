import * as React from 'react';
import {ReactElement} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {materialDark as codeHighlightStyle} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Box,
  Divider,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {monopolisTheme} from "../../../common/styles/theme";
import remarkGfm from "remark-gfm";
import remarkGemoji from "remark-gemoji";
import rehypeRaw from 'rehype-raw'
import {Link} from "../../../common/components/navigation/Link";
import {slugFrom} from "../../utils/markdown";
import {getFragment} from "../../../common/navigation";

export type MarkdownPanelProps = {
  content: string,
  onFragment?: (fragment: string) => void,
  transformContentUrl?: (path: string) => string,
}

export function MarkdownPanel({content, onFragment, transformContentUrl}: MarkdownPanelProps) {
  const handleLink = (url: string | undefined) => (event: React.MouseEvent<HTMLElement>) => {
    if (url) {
      if (url.trim().startsWith("#")) {
        event.preventDefault()
        event.stopPropagation()
        onFragment && onFragment(getFragment(url))
      }
    }
  }

  return <Grid item xs={12}>
    <ReactMarkdown
        transformLinkUri={(href, children, title) => {
          return href
        }}

        transformImageUri={(href, children, title) => {
          const imagePath = href.trim().toLowerCase();
          if (!imagePath.startsWith("http://") && !imagePath.startsWith("https://") && transformContentUrl)
            return transformContentUrl(imagePath)
          else return href
        }}

        components={{
          a: ({node, ...props}) => <Link underline="always"
                                         href={props.href}
                                         onClick={handleLink(props.href)}>{props.children}</Link>,

          img: ({node, ...props}) => <Box my={2}>
            <img {...props} src={props.src} alt={props.alt} style={{maxWidth: "100%"}}/>
          </Box>,

          table: ({node, ...props}) => <TableContainer component={Paper} variant="outlined">
            <Table>{props.children}</Table>
          </TableContainer>,

          tbody: ({node, ...props}) => <TableBody>{props.children}</TableBody>,

          thead: ({node, ...props}) => <TableHead>{props.children}</TableHead>,

          tr: ({node, ...props}) => <TableRow>{props.children}</TableRow>,

          th: ({node, className, ...props}) => <TableCell {...node.properties}
                                                          className={className}>{props.children}</TableCell>,

          td: ({node, className, ...props}) => <TableCell {...node.properties}
                                                          className={className}>{props.children}</TableCell>,

          ol: ({node, ...props}) => <List style={{marginLeft: "1em"}} sx={{
            listStyleType: 'numbers',
            pl: 2,
            '& .MuiListItem-root': {
              pl: 0,
              pb: 0.5,
              pt: 0.5,
              display: 'list-item',
            },
          }}>{props.children}</List>,

          ul: ({node, ...props}) => <List style={{marginLeft: "1em"}} sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              pl: 0,
              pb: 0.5,
              pt: 0.5,
              display: 'list-item',
            },
          }}>{props.children}</List>,

          h1: ({children}) =>
              <Box id={slugFrom(children)}>
                <Typography variant="h1" sx={{fontSize: "2em", mb: 1, mt: 2}}>
                  {children}
                </Typography>
                <Divider sx={{mb: 1}}/>
              </Box>,

          h2: ({children}) =>
              <Box id={slugFrom(children)}>
                <Typography variant="h2" sx={{fontSize: "1.5em", mb: 1, mt: 2}}>
                  {children}
                </Typography>
                <Divider sx={{mb: 1}}/>
              </Box>,

          h3: ({children}) =>
              <Typography id={slugFrom(children)} variant="h3" sx={{fontSize: "1.25em", mb: 1, mt: 2}}>
                {children}
              </Typography>,

          h4: ({children}) =>
              <Typography id={slugFrom(children)} variant="h4" sx={{fontSize: "1em", mb: 1, mt: 2}}>
                {children}
              </Typography>,

          h5: ({children}) =>
              <Typography id={slugFrom(children)} variant="h5" sx={{fontSize: "0.875em", mb: 1, mt: 2}}>
                {children}
              </Typography>,

          h6: ({children}) =>
              <Typography id={slugFrom(children)} variant="h6" sx={{fontSize: "0.85em", mb: 1, mt: 2}}>
                {children}
              </Typography>,

          p: ({node, ...props}) => <Typography variant="body1" sx={{mt: 1, mb: 1}}>{props.children}</Typography>,

          pre: ({node, ...props}) => {
            const isLanguageBlock = props.children.find(child =>
                child ? ((child as ReactElement).props.className + "").indexOf("language-") >= 0 : false
            )

            return <Paper variant="outlined" sx={{
              p: 2,
              my: 1,
              wordWrap: "break-word",
              overflowWrap: "break-word",
              backgroundColor: isLanguageBlock ? "rgb(47, 47, 47)" : monopolisTheme.palette.primary.light
            }}>{props.children}
            </Paper>
          },

          hr: ({node, ...props}) => <Divider sx={{mt: 4, mb: 4, borderBottomWidth: 2, borderTopWidth: 2}}/>,

          code: ({node, inline, className, children, ...props}) => {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                // @ts-ignore
                style={{...codeHighlightStyle, whiteSpace: 'pre-wrap'}}
                language={match[1]}
                {...props}
                showInlineLineNumbers={true}
                showLineNumbers={true}
            /> : inline ? <Paper variant="outlined" sx={{
                  display: "inline",
                  px: "5px",
                  py: "1px",
                  backgroundColor: monopolisTheme.palette.primary.light
                }}><code className={className} {...props}>{children}</code>
                </Paper>
                : <code className={className} {...props}>
                  <pre style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}>{children}</pre>
                </code>

          }
        }}

        children={content}
        remarkPlugins={[remarkGfm, remarkGemoji]}
        rehypePlugins={[rehypeRaw]}/>
  </Grid>
}
