import {Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {EventsPanel} from "../../components/events/EventsPanel";
import {useMonodeEventsQuery} from "../../api/events";
import {MonodePageTabProps} from "./MonodePageTab";

export function MonodePageOverviewTab({path, monode, onError}: MonodePageTabProps) {
  const eventsQuery = useMonodeEventsQuery({path, monode})

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <QueryLoader result={eventsQuery} onError={onError} onLoaded={data =>
        <EventsPanel data={data}/>
    }/>

  </Grid>
}


