import {TextField, TextFieldProps} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";

import {EmailAddress} from "../../../common/models";

export type EmailInputProps = {
  onValidEmail: (email: EmailAddress) => void
  onInvalidEmail: (email: string) => void
} & TextFieldProps

export function EmailInput({onValidEmail, onInvalidEmail, ...props}: EmailInputProps) {
  const handleSetEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    isEmail(value) ? onValidEmail(value) : onInvalidEmail(value)
  }, [onValidEmail, onInvalidEmail]);

  return <TextField id="email" type="email" autoFocus={true} label="Enter email" variant="outlined"
                    {...props} onChange={handleSetEmail}/>
}

function isEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}
