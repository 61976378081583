import {CustomContentProps, SnackbarProvider, SnackbarProviderProps} from "notistack";
import {makeStyles} from "@mui/styles";
import React, {forwardRef} from "react";
import {Alert} from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import {monopolisTheme} from "../styles/theme";

const snackbarClasses = makeStyles({
  snackBarContainer: {
    marginTop: "80px"
  }
});

export function StyledSnackbarProvider({children, ...props}: SnackbarProviderProps) {
  const classes = snackbarClasses()
  return <SnackbarProvider {...props} maxSnack={3}
                           classes={{containerRoot: classes.snackBarContainer}}
                           Components={{
                             default: StyledSnackbarContent,
                             success: StyledSnackbarContent,
                             error: StyledSnackbarContent,
                             warning: StyledSnackbarContent,
                             info: StyledSnackbarContent
                           }}
  >
    {children}
  </SnackbarProvider>
}

const StyledSnackbarContent = forwardRef<HTMLDivElement, CustomContentProps>((props, forwardedRef) => {
  const severity = props.variant === "default" ? "info" : props.variant;
  return (
      <Alert ref={forwardedRef}
             severity={severity}
             variant="standard"
             color={severity}
             iconMapping={{
               success: <CheckRoundedIcon fontSize="inherit" color={severity}/>,
               error: <CloseRoundedIcon fontSize="inherit" color={severity}/>,
               warning: <WarningAmberRoundedIcon fontSize="inherit" color={severity}/>,
               info: <NotificationsNoneRoundedIcon fontSize="inherit" color={severity}/>,
             }}
             sx={{
               boxShadow: 10,
               color: `${monopolisTheme.palette[severity].main} !important`,
               backgroundColor: `${monopolisTheme.palette[severity].light} !important`,
               borderColor: `${monopolisTheme.palette[severity].main} !important`,
             }}
      >
        {props.message}
      </Alert>
  );
});
