import {AccountStoredItemKey} from "../AccountStoredItemKey";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import {reloadWindow} from "../../common/navigation";
import {Paths} from "../models/Paths";
import {AuthenticatedAccountProfile} from "./AuthenticatedAccountProfile";
import {UnauthenticatedProfile, unauthenticatedProfile} from "../../common/auth/UnauthenticatedProfile";
import {Analytics} from "../../common/port/Analytics";
import {DataStorages} from "../../common/port/DataStorages";

export class AccountAuthenticationStore {
    private readonly storage: DataStorages
    private readonly analytics: Analytics
    private readonly apiUri: string
    constructor(storage: DataStorages, analytics: Analytics, apiUri: string) {
        this.storage = storage
        this.analytics = analytics
        this.apiUri = apiUri
    }

    logout() {
        const userProfile = this.profile()
        if (userProfile.type === AuthenticationType.Account) {
            this.analytics.event("Account", "LoggedOut", `Customer logged out ${userProfile.customerId}`)
        }
        this.storage.local.removeItem(AccountStoredItemKey.Profile)
        this.storage.local.removeItem(AccountStoredItemKey.Token)
        this.storage.local.removeItem(AccountStoredItemKey.AuthRedirect)
        reloadWindow(`${this.apiUri}/logout`)
    }

    redirectToLogin(redirectionUri: string) {
        this.storage.session.setItem(AccountStoredItemKey.AuthRedirect, redirectionUri)
        reloadWindow(Paths.Home);
    }

    authenticate(token: string, profile: AuthenticatedAccountProfile) {
        this.analytics.event("Account", "LoggedIn", `Customer logged in ${profile.customerId}`)
        const redirectPath = this.storage.session.getItem(AccountStoredItemKey.AuthRedirect) || Paths.Home;
        this.storage.local.setItem(AccountStoredItemKey.Token, token);
        this.storage.session.removeItem(AccountStoredItemKey.AuthRedirect)
        reloadWindow(redirectPath);
    }

    profile(): AuthenticatedAccountProfile | UnauthenticatedProfile {
        const item = this.storage.local.getObject<AuthenticatedAccountProfile>(AccountStoredItemKey.Profile);
        return item ? {...item, type: AuthenticationType.Account} : unauthenticatedProfile;
    }

    token() {
        return this.storage.local.getItem(AccountStoredItemKey.Token);
    }
}
