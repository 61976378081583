import {Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {EventsPanel} from "../../components/events/EventsPanel";
import {ConfigureOrgHint} from "../../components/hints";
import {OrgRepositoriesPanel} from "../../components/org/OrgRepositoriesPanel";
import {useConfiguredOrgRepositoriesQuery} from "../../api/org";
import {useOrgEventsQuery} from "../../api/events";
import {OrgPageTabProps} from "./OrgPageTab";

export function OrgPageOverviewTab({owner, userProfile, onError}: OrgPageTabProps) {
  const reposResult = useConfiguredOrgRepositoriesQuery({owner})
  const orgEventsQuery = useOrgEventsQuery({owner})

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <QueryLoader result={orgEventsQuery} onError={onError} onLoaded={data =>
        <EventsPanel data={data}/>
    }/>

    <QueryLoader result={reposResult} onError={onError} onLoaded={data =>
        <Grid item xs={12}>
          {data.length === 0
              ? <ConfigureOrgHint/>
              : <OrgRepositoriesPanel vcs={userProfile.vcs}
                                      repositories={data}
                                      menu={() => <></>}/>}
        </Grid>
    }/>
  </Grid>
}
