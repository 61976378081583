import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {CalendarIcon, ClockIcon} from "@primer/octicons-react";
import {durationInSecondsFromNowUTC, formatDuration} from "../../../common/dateTime";
import {PipelineStatus} from "../../api/pipelines/model";
import {Box} from "@mui/material";
import {DateFromNow} from "../DateFromNow";
import {monopolisTheme} from "../../../common/styles/theme";

function calculateDuration(status: PipelineStatus, date: string, duration: number) {
  return status === "Running" ? durationInSecondsFromNowUTC(date) : duration
}

export function PipelineDuration({status, date, duration}: PipelineDurationProps) {
  const [actualDuration, setActualDuration] = useState(calculateDuration(status, date, duration));
  const [isActive] = useState(true);

  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(() => {
        setActualDuration(calculateDuration(status, date, duration));
      }, 1000);
    } else if (!isActive && actualDuration !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, actualDuration, date, duration, status]);

  return (
      <Box>
        <Typography variant="body2">
          <ClockIcon/> {formatDuration(actualDuration)}
        </Typography>
        <Typography variant="caption" color={monopolisTheme.palette.primary.main}>
          <CalendarIcon/> <DateFromNow variant="caption" display="inline" color={monopolisTheme.palette.primary.main}
                                       date={date}/>
        </Typography>
      </Box>
  )
}

export type PipelineDurationProps = {
  status: PipelineStatus
  date: string
  duration: number
}
