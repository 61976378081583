import {AuditEvent, AuditEventSpanSeverity, AuditEventSpanStatus, AuditEventType} from "../../api/events/model";

export const exampleTimelineEvents: AuditEvent[] = [
  {
    id: "ev1",
    eventType: AuditEventType.Rollout,
    monode: "Monode1",
    repo: "org/repo",
    target: "live",
    spans: [
      {
        status: AuditEventSpanStatus.Open,
        severity: AuditEventSpanSeverity.Warning,
        startedAt: '2019-03-05 11:23:48.111',
        updatedAt: '2019-03-05 11:23:48.111',
      },
      {
        status: AuditEventSpanStatus.Closed,
        severity: AuditEventSpanSeverity.Success,
        startedAt: '2019-03-05 11:28:48.111',
        updatedAt: '2019-03-05 11:28:48.111',
      },
      {
        status: AuditEventSpanStatus.Closed,
        severity: AuditEventSpanSeverity.Success,
        startedAt: '2019-03-05 12:23:48.111',
        updatedAt: '2019-03-05 12:23:48.111',
      }
    ]
  },
  {
    id: "ev2",
    eventType: AuditEventType.Rollout,
    monode: "Monode2",
    repo: "org/repo",
    target: "live",
    spans: [
      {
        status: AuditEventSpanStatus.Open,
        severity: AuditEventSpanSeverity.Warning,
        startedAt: '2019-03-05 12:23:48.111',
        updatedAt: '2019-03-05 12:23:48.111',
      },
      {
        status: AuditEventSpanStatus.Closed,
        severity: AuditEventSpanSeverity.Success,
        startedAt: '2019-03-05 12:28:48.111',
        updatedAt: '2019-03-05 12:28:48.111',
      }
    ]
  },

  {
    id: "ev3",
    eventType: AuditEventType.Incident,
    monode: "Monode3",
    repo: "org/repo",
    target: "live",
    spans: [
      {
        status: AuditEventSpanStatus.Open,
        severity: AuditEventSpanSeverity.Warning,
        startedAt: '2019-03-05 15:27:48.111',
        updatedAt: '2019-03-05 15:27:48.111',
      },
      {
        status: AuditEventSpanStatus.Open,
        severity: AuditEventSpanSeverity.Info,
        startedAt: '2019-03-05 15:28:48.111',
        updatedAt: '2019-03-05 15:28:48.111',
      },
      {
        status: AuditEventSpanStatus.Closed,
        severity: AuditEventSpanSeverity.Success,
        startedAt: '2019-03-08 11:28:48.111',
        updatedAt: '2019-03-08 11:28:48.111',
      }
    ]
  },
];
