import {OriginUri} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";
import {ApiKeyName, IntegrationType} from "./model";
import {originUriParam} from "../../utils/urlParams";
import {VcsOwner} from "../../../common/models";

export class OrgUrls {
    static orgConfiguration(params: { owner: VcsOwner }) {
        return `/org/config/${params.owner}`;
    }

    static orgAdminConfiguration(params: { owner: VcsOwner }) {
        return `/org/config/${params.owner}/admin`;
    }

    static orgIntegrationSettings(params: { owner: VcsOwner, type: IntegrationType }) {
        return `/org/config/${params.owner}/${params.type.toLowerCase()}`;
    }

    static orgIntegrationConfigurationssWithSecrets(params: { owner: VcsOwner, type: IntegrationType }) {
        return `/org/config/${params.owner}/${params.type.toLowerCase()}/secrets`;
    }

    static orgAllIntegrationsConfigurations(params: { owner: VcsOwner}) {
        return `/org/config/${params.owner}/integrations`;
    }

    static orgIntegrationAdd(params: { owner: VcsOwner, type: IntegrationType, originUri?: OriginUri }) {
        const query = Array<UrlQueryParam>()
        if(params.originUri) {
            query.push(originUriParam(params.originUri));
        }
        return buildUrl(`/org/config/${params.owner}/${params.type.toLowerCase()}/add`, query);
    }

    static orgIntegrationRemove(params: { owner: VcsOwner, type: IntegrationType }) {
        return `/org/config/${params.owner}/${params.type.toLowerCase()}/remove`;
    }

    static planHistory(params: { owner: VcsOwner }) {
        return `/org/plan/${params.owner}`;
    }

    static configuredOrgRepositories(params: { owner: VcsOwner }) {
        return `/org/${params.owner}/repos`;
    }

    static allOrgRepositories(params: { owner: VcsOwner }) {
        return `/org/${params.owner}/repos/all`;
    }

    static orgMembers(params: { owner: VcsOwner }) {
        return `/org/${params.owner}/members`;
    }

    static enableRepository(params: { org: VcsOwner }) {
        return `/org/config/${params.org}/repo/enable`;
    }

    static disableRepository(params: { org: VcsOwner }) {
        return `/org/config/${params.org}/repo/disable`;
    }

    static orgApiKeys(params: { org: VcsOwner }) {
        return `/org/config/${params.org}/apikey`;
    }

    static orgApiKey(params: { org: VcsOwner, name: ApiKeyName }) {
        return `/org/config/${params.org}/apikey/${encodeURIComponent(params.name)}`;
    }

    static enableMember(params: { org: VcsOwner }) {
        return `/org/config/${params.org}/member/enable`;
    }

    static disableMember(params: { org: VcsOwner }) {
        return `/org/config/${params.org}/member/disable`;
    }
}
