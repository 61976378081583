import * as React from "react";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {OAuthProvider, oauthProviderDetails} from "../../../common/models";
import {CustomIcon} from "../../../common/components/icons";

export type OAuthProviderSelectionProps = {
  options: OAuthProvider[];
  selected: OAuthProvider;
  maxWidth: string;
  disabled: boolean
  onOAuthProvider?: (provider: OAuthProvider) => void;
}

export function OAuthProviderSelection({selected, options, disabled, onOAuthProvider, maxWidth}: OAuthProviderSelectionProps) {
  const handleChange = (event: SelectChangeEvent) => {
    onOAuthProvider && onOAuthProvider(event.target.value as OAuthProvider)
  };

  return (
      <Select
          data-test="branch-selection"
          sx={{minWidth: maxWidth, maxWidth: maxWidth}}
          placeholder="branch"
          size="small"
          disabled={disabled}
          value={selected}
          onChange={handleChange}
      >{options.map(provider => <MenuItem key={provider} value={provider}><CustomIcon
          style={{width: 15, marginRight: 5}} name={provider}/>{oauthProviderDetails(provider).name}
      </MenuItem>)}
      </Select>
  )
}
