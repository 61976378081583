export enum UserPageId {
    Error = "error",
    CliLogin = "cli-login",
    CliLoginCompleted = "cli-login-completed",
    UserLogin = "user-login",
    OrgDashboard = "org-dashboard",
    UserDashboard = "user-dashboard",
    Commit = "commit",
    Org = "org",
    Repo = "repo",
    Monode = "monode",
    OrgTarget = "org-target",
    RepoTarget = "repo-target",
    MonodeTarget = "monode-target",
    UserConfiguration = "user-configuration",
    OrgConfiguration = "org-configuration",
    RepoConfiguration = "repo-configuration",
    OnboardingWelcome = "onboarding-welcome",
}
