import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  ChipProps,
  PaperProps,
  TextField,
  Typography
} from "@mui/material";
import * as React from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {
  NotificationChannelDetails,
  NotificationChannelType,
  NotificationEvent,
  NotificationSubscriptions,
} from "../../api/notifications/model";
import {CustomIcon, CustomIconName} from "../../../common/components/icons";

export type NotificationSubscriptionsPanelProps = {
  notificationEvent: NotificationEvent;
  channels: NotificationChannelDetails[];
  subscriptions: NotificationSubscriptions;
  onSubscribe: (event: NotificationEvent, channel: NotificationChannelDetails) => void;
  onUnsubscribe: (event: NotificationEvent, channel: NotificationChannelDetails) => void;
} & OnError

export function NotificationSubscriptionsPanel({...props}: NotificationSubscriptionsPanelProps) {
  return <NotificationSubscriptionsPanelView {...props} containerProps={{}}/>
}

function NotificationSubscriptionsPanelView(
    {
      notificationEvent,
      subscriptions,
      channels,
      onSubscribe,
      onUnsubscribe,
      containerProps
    }: NotificationSubscriptionsPanelViewProps) {

  const actualSubscriptions = subscriptions
  const actualChannels = channels.map(item => {
        return ({...item, channelType: item.type} as ExtendedNotificationChannelDetails)
      }
  )

  const subscribedChannels = (actualSubscriptions[notificationEvent] || [])
      .map(subscription => actualChannels.find(channelDetails => channelDetails.channel === subscription))
      .filter((channelDetails): channelDetails is ExtendedNotificationChannelDetails => !!channelDetails)

  return <Box {...containerProps} >
    <Box>
      <Typography variant="body1" sx={{mb: 1}}>{notificationEvents[notificationEvent]}</Typography>
      <Autocomplete
          id={`${notificationEvent}NotificationPanel`}
          multiple
          // limitTags={5}
          value={subscribedChannels}
          options={actualChannels}
          // options={actualChannels.sort((x, y) => -y.channelType.localeCompare(x.channelType))}
          // groupBy={(option) => option.channelType}
          onChange={(event: any, newValue: ExtendedNotificationChannelDetails[]) => {
            const delta = {
              added: newValue.filter(item => subscribedChannels.indexOf(item) === -1),
              removed: subscribedChannels.filter(item => newValue.indexOf(item) === -1)
            };

            delta.added.forEach(async channel => {
              await onSubscribe(notificationEvent, channel)
            })

            delta.removed.forEach(async channel => {
              await onUnsubscribe(notificationEvent, channel)
            })
          }}

          getOptionLabel={(option) => option.name}
          renderTags={(tagValue, getTagProps) => tagValue
              .map((option, index) => (
                  <NotificationChip {...getTagProps({index})} {...option}/>
              ))}

          renderOption={(props, option) => (
              <NotificationItem {...props} {...option} />
          )}

          renderInput={(params) => (
              <NotificationInput {...params}/>
          )}
      />
    </Box>
  </Box>
}


type NotificationSubscriptionsPanelViewProps = {
  containerProps: PaperProps;
} & NotificationSubscriptionsPanelProps

type ExtendedNotificationChannelDetails = {
  channelType: NotificationChannelType
} & NotificationChannelDetails

const notificationIcons: Record<NotificationChannelType, CustomIconName> = {
  Slack: "slack",
  Discord: "discord"
}

const notificationEvents: Record<NotificationEvent, string> = {
  RolloutStarted: "Rollout Started",
  RolloutFinished: "Rollout Finished",
  BuildStatusChanged: "Pipeline Fixed Or Broken",
  IncidentUpdate: "Incident Update",
  MonodeAlert: "Monode Alert Status Changed",
  Push: "Repository Push"
}

type NotificationItemProps = {} & React.HTMLAttributes<HTMLLIElement> & ExtendedNotificationChannelDetails

function NotificationItem({channelType, name, ...props}: NotificationItemProps) {
  return <Box component="li" {...props}>
    <CustomIcon name={notificationIcons[channelType]} style={{width: "1em", marginRight: "0.5em"}}/> {name}
  </Box>
}

type NotificationChipProps = {} & ChipProps & ExtendedNotificationChannelDetails

function NotificationChip({channelType, name, ...props}: NotificationChipProps) {
  return <Chip {...props}
               label={name}
               icon={<CustomIcon name={notificationIcons[channelType]} style={{width: "1.4em", marginLeft: "1em"}}/>}/>
}

type NotificationInputProps = {} & AutocompleteRenderInputParams

function NotificationInput({...props}: NotificationInputProps) {
  return <TextField {...props} placeholder="Notify..."/>
}
