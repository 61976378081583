import {OnError} from "../../../common/api/TransportLayer";
import {AuthenticatedAccountProfile} from "../../auth/AuthenticatedAccountProfile";

export enum AccountConfigurationPageTab {
  installation = "installation",
  organisations = "organisations",
  billing = "billing",
}

export type AccountConfigurationPageTabProps = {
  profile: AuthenticatedAccountProfile
} & OnError
