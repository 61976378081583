import * as React from "react";
import {UserOption} from "../../api/user/model";
import {Toggle} from "../../api/model";
import {UserOptionRowMenuProvider} from "./UserOptionRow";
import {OnError} from "../../../common/api/TransportLayer";
import {EmailsPanel} from "./EmailsPanel";
import {AccountDetailsPanel} from "./AccountDetailsPanel";
import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {ConfigurationPanel, ConfigurationStack} from "../../../common/components/configuration";
import {OptionsPanel} from "./OptionsPanel";

export type UserSettingsPanelProps = {
  profile: AuthenticatedUserProfile
} & OptionPanelProps & OnError

export type OptionPanelProps = {
  options: Record<UserOption, Toggle>
  menu: UserOptionRowMenuProvider
}

export function UserSettingsTab({options, profile, menu, onError}: UserSettingsPanelProps) {
  return <ConfigurationStack>
    <ConfigurationPanel>
      <OptionsPanel options={options} menu={menu}/>
    </ConfigurationPanel>
    <ConfigurationPanel>
      <EmailsPanel/>
    </ConfigurationPanel>
    <ConfigurationPanel>
      <AccountDetailsPanel profile={profile}/>
    </ConfigurationPanel>
  </ConfigurationStack>
}

