import {Autocomplete, Box, Chip, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {OrgRepoPath, VcsOwner} from "../../../common/models";
import {VcsProvider} from "../../../common/models";

export type RepositoryOption = {
  vcs: VcsProvider
  org: VcsOwner
  path: OrgRepoPath
  isPrivate: boolean
}

export type RepositorySearchPanelProps = {
  all: RepositoryOption[]
  selected: RepositoryOption[]
  disabled: boolean
  onAdd: (value: RepositoryOption) => void
  onRemove: (value: RepositoryOption) => void
} & OnError

export function RepositorySearchPanel(
    {
      disabled,
      all,
      selected,
      onAdd,
      onRemove,
    }: RepositorySearchPanelProps) {
  const handleChange = useCallback(async (event: any, newValue: RepositoryOption[]) => {
        const delta = {
          added: newValue.filter(item => selected.map(m => m.path).indexOf(item.path) === -1),
          removed: selected.filter(item => newValue.map(m => m.path).indexOf(item.path) === -1)
        };
        for (const item of delta.added) await onAdd(item)
        for (const item of delta.removed) await onRemove(item)
      }, [selected, onAdd, onRemove]
  );

  return <Box data-test="repo-search">
    <Autocomplete
        multiple
        disabled={disabled}
        disableClearable
        value={selected}
        options={all}
        isOptionEqualToValue={(option, value) => option.path === value.path}
        filterSelectedOptions={true}
        onChange={handleChange}
        getOptionLabel={(option) => option.path.org + "/" + option.path.repo}
        renderTags={() => <></>}
        renderOption={(props, option) =>
            <Box component="li" {...props} >
              <Box alignItems="center" display="flex" flexDirection="row">
                <Typography variant="body1">{option.path.repo}</Typography>
                {option.isPrivate && <Chip label="private" size="small" variant="outlined" sx={{ml: 2}}/>}
              </Box>
            </Box>
        }

        renderInput={(params) => <TextField {...params} placeholder="Add repository..."/>}
    />
  </Box>
}
