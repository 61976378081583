import * as React from 'react';
import {LoginPanel} from "../../components/LoginPanel";
import {AccountAppPage} from "../../layout/AccountAppPage";
import {unauthenticatedProfile} from "../../../common/auth/UnauthenticatedProfile";
import {AccountPageId} from "../AccountPageId";

export function LoginPage() {
  return <AccountAppPage pageId={AccountPageId.AccountLogin} title="Login" error={null} profile={unauthenticatedProfile}>
    <LoginPanel onLogin={() => {
    }}/>
  </AccountAppPage>;
}
