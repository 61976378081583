import * as React from "react";
import {ReactNode, SyntheticEvent, useCallback} from "react";
import {Box, Breadcrumbs, Tabs, Typography} from "@mui/material";
import {ResponsiveDisplay} from "../../styles/constraints";
import {TabControls} from "../../tabs";
import {currentLocation} from "../../navigation";
import {StyledTab, StyledTabContent} from "./StyledTab";

export type PageHeaderMenuProvider = () => ReactNode

export type PageHeaderProps = {
  title: ReactNode
  breadcrumbs?: ReactNode[]
  location?: string
  menu?: PageHeaderMenuProvider
  tabs?: TabControls
}

export function PageHeader(props: PageHeaderProps) {
  return <ResponsiveDisplay
      standard={() => <PageHeaderView {...props} direction="row" alignment="center" branchSelectionWidth="250px"/>}
      compact={() => <PageHeaderView {...props} direction="column" alignment="left" branchSelectionWidth="100%"/>}
  />
}

export type PageTabProps = {
  children?: React.ReactNode;
  id: string;
  selectedId: string;
}

export function PageTab(props: PageTabProps) {
  return <StyledTabContent {...props} group="page" sx={{pt: 2, mx: 0}}/>
}

function PageHeaderView(
    {
      title,
      location,
      breadcrumbs,
      menu,
      direction,
      alignment,
      tabs,
    }: PageHeaderViewProps) {
  const baseLocation = location || currentLocation();

  const handleTabChange = useCallback((event: SyntheticEvent, newValue: string) => {
    if (tabs !== undefined) {
      tabs.onChanged && tabs.onChanged(newValue)
      tabs.setSelected(newValue, baseLocation)
    }
  }, [tabs, baseLocation]);

  const menuWrapper = <Box sx={{display: "flex", flexDirection: "row"}}>
    {menu && <Box sx={{ml: 1}}>{menu()}</Box>}
  </Box>

  return <Box sx={{width: "100%", display: "flex", flexDirection: direction, pt: 2, pb: tabs ? 0 : 2}}
              alignItems={alignment}>
    <Box sx={{flexGrow: 1}}>
      <Typography variant="h5" fontWeight={500} sx={{mb: 0.5}} data-test="page-header">{title}</Typography>

      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>

      {tabs && <Box sx={{display: "flex", flexDirection: "row", mt: 1}}>
        <Tabs sx={{flexGrow: 1}}
              value={tabs.selected}
              onChange={handleTabChange}
              aria-label="settings"
              variant={"scrollable"}
              allowScrollButtonsMobile={true}
        >
          {tabs.tabs.map(tab => <StyledTab key={tab.id} value={tab.id} label={tab.title} tabId={tab.id}
                                           onClick={() => tabs.setSelected(tab.id, baseLocation)}
                                           tabGroup="page"/>)}
        </Tabs>

        {menuWrapper}
      </Box>
      }
    </Box>

    {
        !tabs && menuWrapper
    }
  </Box>
}

type PageHeaderViewProps = {
  direction: "column" | "row"
  alignment: "center" | "left"
  branchSelectionWidth: string
} & PageHeaderProps
