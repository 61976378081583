import {unified} from "unified";
import markdown from "remark-parse";
import {ReactNode} from "react";

const extractToc = require("remark-extract-toc");

type RemarkTOCNode = {
    depth: number,
    value: string,
}

export type MarkdownTOCNode = {
    id: string,
    title: string,
    level: number,
}

export function parseMarkdownTOC(content: string) : MarkdownTOCNode[]{
    const processor = unified()
        .use(markdown)
        .use(extractToc, {flatten: true})

    const node = processor.parse(content);

    // @ts-ignore
    const nodes : RemarkTOCNode[] = processor.runSync(node);

    return nodes.map(node => ({
        id:  markdownId(node.value),
        title: node.value,
        level: node.depth
    }))
}

export function slugFrom(node: ReactNode[]): string {
  return node.map(child =>
      // @ts-ignore
      typeof child !== "string" ? slugFrom((child.props.children || []) as ReactNode[])
          : markdownId(child)
  ).join("")
}

export function markdownId(text: string) : string {
  return text.toLowerCase().replace(/[^a-z0-9]+/g, "-")
}
