import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {CommitPathParameter, OwnerPathParameter, RepositoryPathParameter, usePathParams} from "../../models/Paths";
import {pathFrom, VcsBranch} from "../../models";
import {useBranchOrDefault} from "../../utils/branches";
import {useCommitPipelinesQuery} from "../../api/pipelines";
import {PipelineMenu} from "../../components/pipelines/PipelineMenu";
import {OnError, SystemError} from "../../../common/api/TransportLayer";
import {CommitPanel, CommitPanelPipelineMenuProvider} from "../../components/pipelines/CommitPanel";
import {useOrgConfigurationQuery} from "../../api/org";
import {queryRefetchIntervalFrom} from "../../utils/orgConfigurations";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {PageHeader} from "../../../common/components/navigation/PageHeader";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {RepoBreadcrumb} from "../../components/breadcrumbs/RepoBreadcrumb";
import {CommitBreadcrumb} from "../../components/breadcrumbs/CommitBreadcrumb";
import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {OrgConfiguration} from "../../../common/models";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function CommitPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {userProfile} = useUserAuthentication()
  const {urls} = useUserAppInfrastructure()
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter & CommitPathParameter>();
  const sha = pathParams.sha
  const path = pathFrom(pathParams);
  const branch = useBranchOrDefault({path, onError: setError});
  const loadComponents = branch !== undefined;

  const orgConfiguration = useOrgConfigurationQuery({owner: pathParams.owner, readCache: true, writeCache: true})

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const header = <PageHeader
      title="Commit"
      location={urls.commit({path, branch, sha})}
      breadcrumbs={[
        <OwnerBreadcrumb owner={path.org}/>,
        <RepoBreadcrumb vcs={userProfile.vcs} path={path}/>,
        <CommitBreadcrumb vcs={userProfile.vcs} path={path} branch={branch} sha={sha}/>
      ]}
  />

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={setError}/>

  const title = pathParams.owner + "/" + pathParams.repository + "/ Commit: " + pathParams.sha;

  return <UserAppPage pageId={UserPageId.Commit} title={title} contentHeader={header} banner={banner}
                      profile={userProfile} error={error}>
    <QueryLoader result={orgConfiguration} onError={setError} onLoaded={orgConfiguration =>
        loadComponents && <CommitPageContent
            branch={branch}
            userProfile={userProfile}
            orgConfiguration={orgConfiguration}
            onError={setError}/>
    }/>
  </UserAppPage>
}

function CommitPageContent({branch, orgConfiguration, onError}: CommitPageContentProps) {
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter & CommitPathParameter>();
  const path = pathFrom(pathParams);
  const repositoryPipelinesResult = useCommitPipelinesQuery({
    path,
    branch,
    sha: pathParams.sha,
    refetchInterval: queryRefetchIntervalFrom(orgConfiguration)
  })

  const menu: CommitPanelPipelineMenuProvider = (params) =>
      <PipelineMenu {...params} actions={["rerun", "cancel", "rollout", "addToWatchlist"]}/>;

  return <>
    <QueryLoader result={repositoryPipelinesResult} onError={onError} onLoaded={data =>
        <CommitPanel pipelines={data} menu={menu}/>
    }/>
  </>
}

type CommitPageContentProps = {
  branch: VcsBranch
  userProfile: AuthenticatedUserProfile
  orgConfiguration: OrgConfiguration
} & OnError
