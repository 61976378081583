import * as React from 'react';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import {AccountAuthenticationProfile} from "../auth/useAccountAuthentication";

export function LoggedInAs({profile}: LoggedInAsProps) {

  return profile.type === AuthenticationType.Account ?
      <Box>
        <Typography variant="caption">
          Logged in as <strong>{profile.customerId}</strong>
        </Typography>
      </Box>
      : <></>

}

export type LoggedInAsProps = {
  profile: AccountAuthenticationProfile
}
