import {UrlQueryParam} from "./urls";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

import {optionalStringFrom} from "./models";
import {useCommonAppInfrastructure} from "./CommonAppInfrastructure";

export function reloadWindow(url: string) {
  window.location.assign(url)
}

export function currentLocation() {
  return window.location.href
}

export function currentHostname() {
  return window.location.hostname
}

export function addSubDomain(url: string, subdomain: string) {
  const host = new URL(url).host;
  return url.replace(host, subdomain + "." + host)
}

export function hostname(url: string) {
  return new URL(url).host
}

export function currentLocationFragment() {
  return window.location.hash
}

export function setQuery(param: UrlQueryParam, location: string = currentLocation()): string {
  const url = new URL(location);
  param.value !== undefined && url.searchParams.set(param.name, param.value)
  return url.href
}

export function removeQuery(paramName: string, location: string = currentLocation()): string {
  const url = new URL(location);
  url.searchParams.delete(paramName)
  return url.href
}

export function getQuery(name: string, location: string = currentLocation()): string | null {
  return new URL(location).searchParams.get(name)
}

export function setFragment(fragment: string, location: string = currentLocation()): string {
  const url = new URL(location);
  url.hash = fragment
  return url.href
}

export function getFragment(location: string = currentLocation()): string {
  return new URL(location).hash.trim().substring(1)
}

export type NavigationTarget = {
  href: string
  isExternal: boolean
}

export type UseQueryParamOrDefault = {
  value: string,
  set: (value: string, location?: string) => void
  delete: (location?: string) => void
}

export function useQueryParamOrDefault(name: string, defaultValue: (() => Promise<string>) | string): UseQueryParamOrDefault {
  const document = optionalStringFrom(getQuery(name))
  const {navigation} = useCommonAppInfrastructure()
  const navigate = useNavigate()
  useEffect(() => {
    if (document === undefined ) {
      setTimeout(async () => {
          const resolvedDefault = (defaultValue instanceof Function) ? await defaultValue() : defaultValue
          navigate(navigation.navigationTargetFor(setQuery({name, value: resolvedDefault})).href, {replace: true})
      }, 0);
    }
    return () => {
    }
  }, [navigate, navigation, name, defaultValue, document]);

  return {
    value: document || "",
    set: (value, location) => navigate(navigation.navigationTargetFor(setQuery({name, value}, location)).href, {replace: true}),
    delete: (location) => navigate(navigation.navigationTargetFor(removeQuery(name, location)).href, {replace: true}),
  }
}

export const tabParamName = "tab";

export function tabParam(value: string): UrlQueryParam {
  return {name: tabParamName, value: value};
}


