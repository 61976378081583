import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {OwnerPathParameter, RepositoryPathParameter, TargetPathParameter, usePathParams} from "../../models/Paths";
import {pathFrom} from "../../models";
import {SystemError} from "../../../common/api/TransportLayer";
import {useCachedOrgConfigurationQuery} from "../../api/org";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {useTabControls} from "../../../common/tabs";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {RepositoryTargetPageTab, RepositoryTargetPageTabProps} from "./RepositoryTargetPageTab";
import {RepositoryTargetPageRolloutsTab} from "./RepositoryTargetPageRolloutsTab";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {RepoBreadcrumb} from "../../components/breadcrumbs/RepoBreadcrumb";
import {RepoTargetBreadcrumb} from "../../components/breadcrumbs/RepoTargetBreadcrumb";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function RepositoryTargetPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {urls} = useUserAppInfrastructure()
  const {userProfile} = useUserAuthentication()
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter & TargetPathParameter>();
  const path = pathFrom(pathParams);
  const orgConfiguration = useCachedOrgConfigurationQuery({owner: pathParams.owner, onError: setError})


  const tabs = useTabControls({
    defaultTab: RepositoryTargetPageTab.rollouts,
    tabs: [
      {title: "Rollouts", id: RepositoryTargetPageTab.rollouts},
    ]
  })

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const header = <PageHeader
      title="Target"
      breadcrumbs={[
        <OwnerBreadcrumb owner={path.org}/>,
        <RepoBreadcrumb vcs={userProfile.vcs} path={path}/>,
        <RepoTargetBreadcrumb path={path} target={pathParams.target}/>
      ]}
      location={urls.target({
        org: path.org,
        repo: path.repo,
        target: pathParams.target
      })}
      tabs={tabs}
  />

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={setError}/>

  const title = pathParams.owner + "/" + pathParams.repository;

  const tabProps: RepositoryTargetPageTabProps = {
    path,
    owner: pathParams.owner,
    target: pathParams.target,
    userProfile,
    orgConfiguration: orgConfiguration,
    onError: setError,
  }

  return <UserAppPage pageId={UserPageId.RepoTarget} title={title} contentHeader={header} banner={banner} error={error} profile={userProfile}>
    <PageTab selectedId={tabs.selected} id={RepositoryTargetPageTab.rollouts}>
      <RepositoryTargetPageRolloutsTab {...tabProps}/>
    </PageTab>
  </UserAppPage>
}
