import * as React from 'react';
import {LoginPanel} from "../../components/LoginPanel";
import {LaunchAppPage} from "../../layout/LaunchAppPage";
import {LaunchAppPageId} from "../LaunchAppPageId";
import {useUserAuthentication} from "../../../userApp/auth/useUserAuthentication";

export function LoginPage() {
  const {navigateToLogin} = useUserAuthentication()
  return <LaunchAppPage pageId={LaunchAppPageId.UserLogin} title="Login" error={null}>
    <LoginPanel target="App" sandboxDisabled={false} onLogin={navigateToLogin}/>
  </LaunchAppPage>;
}
