import React, {useState} from "react";
import {DashboardWidgetProps} from "./DashboardWidgetView";
import {DashboardWidgetPanel, useDashboardWidgetPanel} from "./DashboardWidgetPanel";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {useOrgEventsQuery} from "../../api/events";
import {EventsPanel} from "../events/EventsPanel";
import {OrgEventsWidgetConfig} from "../../api/dashboard/widgets/OrgEventsWidget";
import {Box, Divider, IconButton, TextField} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export type OrgEventsWidgetPanelProps = DashboardWidgetProps<OrgEventsWidgetConfig>;

export function OrgEventsWidgetPanel({org, metaFilter, ...props}: OrgEventsWidgetPanelProps) {
  const [newMetaFilter, setNewMetaFilter] = useState(metaFilter);
  const panelProps = useDashboardWidgetPanel({...props, title: "Organisation events"});
  const orgEventsQuery = useOrgEventsQuery({owner: org, metaFilter})
  const {id, isEditing, onSave} = props

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setNewMetaFilter(event.target.value)
  }

  function handleSave() {
    onSave && onSave(id, {org, metaFilter: newMetaFilter})
  }

  function handleCancel() {
    setNewMetaFilter(metaFilter)
    onSave && onSave(id, {org, metaFilter})
  }

  return <DashboardWidgetPanel {...panelProps}>

    {
        isEditing && <Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
            <TextField fullWidth={true}
                       defaultValue={newMetaFilter}
                       variant="standard"
                       label="Filter"
                       onChange={handleTextChange}/>

            <IconButton aria-label="save" onClick={handleSave}>
              <CheckRoundedIcon/>
            </IconButton>

            <IconButton aria-label="cancel" onClick={handleCancel}>
              <CloseRoundedIcon/>
            </IconButton>

          </Box>
          <Divider sx={{my: 1}}/>
        </Box>
    }

    <QueryLoader result={orgEventsQuery} onError={props.onError} onLoaded={data =>
        <EventsPanel data={data}/>
    }/>

  </DashboardWidgetPanel>
}
