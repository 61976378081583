import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Paths} from "./models/Paths";
import {LoginPage} from "./pages/login/LoginPage";
import {PageNotFound} from "./pages/error/PageNotFound";
import {LoginCLIPage} from "./pages/login/LoginCLIPage";
import {LoginCLICompletedPage} from "./pages/login/LoginCLICompletedPage";

export const LaunchAppRouter: React.FC = () => {
  return (
      <Routes>
        <Route path={Paths.UserLogin} element={
          <LoginPage/>
        }/>

        <Route path={Paths.CliLogin} element={
          <LoginCLIPage/>
        }/>

        <Route path={Paths.CliLoginCompleted} element={
          <LoginCLICompletedPage/>
        }/>

        <Route path={Paths.Home} element={
          <LoginPage/>
        }/>

        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
  );
};
