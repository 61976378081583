import {
  EmailRegistrationCompleteRequest,
  EmailRegistrationInitiateRequest,
  RemoveUserEmailRequest,
  TwoFactorCode,
  UserEmails,
  UserOption
} from './model';
import {UserUrls} from './urls';
import {
  EmptyRequest,
  EmptyResponse,
  GetQueryOptions,
  queryOptions,
  refetchQueryOptions
} from "../../../common/api/query";
import {UserRequestId} from "../UserRequestId";
import {UserAuthenticationProfile, useUserAuthentication} from "../../auth/useUserAuthentication";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {useApiUrl, useUserApiPost, useUserApiGet} from "../userApi";
import {UserStoredItemKey} from "../../UserStoredItemKey";
import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function useFetchUserProfileQuery(params: {
  token?: () => string | undefined,
} & GetQueryOptions) {
  const {storage, authStore} = useUserAppInfrastructure()

  return useUserApiGet<UserAuthenticationProfile>(UserRequestId.UserProfile, UserUrls.userProfile(), {token: params.token},
      {...refetchQueryOptions, ...params},
      {
        writeCache: true,
        cacheExpiryInSeconds: 20,
        cache: storage.local,
        cacheKey: authStore.itemId(UserStoredItemKey.UserProfile),
      }
  );
}

export function useRefetchUserProfileQuery(): UserAuthenticationProfile {
  const {userProfile} = useUserAuthentication()
  const {storage, authStore} = useUserAppInfrastructure()
  const {data} = useUserApiGet<UserAuthenticationProfile>(UserRequestId.UserProfile, UserUrls.userProfile(), {}, queryOptions({}),
      {
        readCache: true,
        writeCache: true,
        cacheExpiryInSeconds: 20,
        cache: storage.local,
        cacheKey: authStore.itemId(UserStoredItemKey.UserProfile),
      }
  );

  if (!data) return userProfile

  if (data.kind === "error") {
    return userProfile
  }

  if (data.kind === "ok") {
    return {...(data.data as AuthenticatedUserProfile), type: AuthenticationType.User}
  }

  return userProfile
}

const userEmailRequests = [UserRequestId.UserEmails]

export const useUserEmailsQuery = (params: GetQueryOptions = {}) =>
    useUserApiGet<UserEmails>(UserRequestId.UserEmails, UserUrls.userEmails(), {}, queryOptions(params))

export const useRemoveUserEmailMutation = () =>
    useUserApiPost<RemoveUserEmailRequest, EmptyResponse>(UserRequestId.UserEmailRemove, UserUrls.userEmailRemove(), userEmailRequests)

export const useEmailRegistrationInitiateMutation = () =>
    useUserApiPost<EmailRegistrationInitiateRequest, EmptyResponse>(UserRequestId.EmailRegistrationInitiate, UserUrls.emailRegistrationInitiate(), userEmailRequests)

export const useEmailRegistrationCompleteMutation = () =>
    useUserApiPost<EmailRegistrationCompleteRequest, EmptyResponse>(UserRequestId.EmailRegistrationComplete, UserUrls.emailRegistrationComplete(), userEmailRequests)

export const useOptionOnMutation = (params: { option: UserOption }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.UserOptionEnable, UserUrls.optionOn(params))

export const useOptionOffMutation = (params: { option: UserOption }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.UserOptionDisable, UserUrls.optionOff(params))

export const useRegisterTwoFactorDeviceMutation = (params: { code: TwoFactorCode }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.RegisterTwoFactor, UserUrls.registerTwoFactorDevice(params))

export const useGenerateTwoFactorQRCode = (params: { size: number }) =>
    useApiUrl(UserUrls.generateTwoFactorQRCode(params))

