import * as React from "react";
import {RolloutTarget} from "../../models";
import {Link} from "../../../common/components/navigation/Link";
import {VcsOwner} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type OwnerTargetBreadcrumbProps = {
  owner: VcsOwner
  target: RolloutTarget
}

export function OwnerTargetBreadcrumb({target, owner}: OwnerTargetBreadcrumbProps) {
  const {urls} = useUserAppInfrastructure()
  return <Link href={urls.target({org: owner, target: target})}>
    {target}
  </Link>
}
