import {Grid, List, ListItem} from "@mui/material";
import * as React from "react";
import {Link} from "../../../common/components/navigation/Link";
import {Panel} from "../../../common/components/Panel";
import {LinkConfig} from "../../api/configurations/model";

export type LinksPanelProps = {
  links: LinkConfig[],
}

export function LinksPanel({
                             links,
                           }: LinksPanelProps) {
  return links.length > 0 ? <Grid item xs={12}>
    <Panel data-test="links" header="Links" contentProps={{sx:{maxHeight: "200px", overflow: 'auto'}}} >
      <List>
        {
          links.map(link =>
              <ListItem>
                <Link href={link.uri} target="_blank">
                  {link.name}
                </Link>
              </ListItem>
          )
        }
      </List>
    </Panel>
  </Grid> : null
}

