export type MenuDirection = "horizontal" | "vertical"

export const menuItemsStyles = () => ({
      horizontal: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0
      },
      vertical: {}
    } as const
)
