import {Box, ListItemButton, Typography} from "@mui/material";
import * as React from "react";
import {ReactNode} from "react";
import {TimelineEvent} from "../EventsDatesView";
import {formatUTCToLocalTime} from "../../../../common/dateTime";

export type EventsListRowMenuProvider = (params: { event: TimelineEvent }) => ReactNode

export type EventsListRowProps = {
  event: TimelineEvent
  selected: boolean
  onClick: (event: TimelineEvent) => void
  menu: EventsListRowMenuProvider
}

export function EventsListRow({event, menu, selected, onClick}: EventsListRowProps) {
  const spacingWidth = 30
  const menuSpacingWidth = 10

  return <ListItemButton selected={selected} onClick={() => onClick(event)}>
    <Box sx={{display: "flex", flexDirection: "row", width: "100%"}} alignItems="center">
      <Box sx={{flexGrow: 1, width: "100%", marginRight: `${spacingWidth}px`}}>
        <Typography variant="body1">
          {formatUTCToLocalTime(event.dateTime)} {event.title}
        </Typography>
      </Box>

      <Box sx={{flex: 1, marginLeft: `${menuSpacingWidth}px`}}>
        <Typography>{event.type}</Typography>
        {menu({event})}
      </Box>
    </Box>
  </ListItemButton>
}
