import * as React from 'react';
import {useState} from 'react';

export function TestBoundary() {
  const [shouldThrow] = useState(true);

  if (shouldThrow) {
    throw new Error('I crashed!');
  }

  return (<>I'm ok</>)
}
