import {UserOption} from "../../api/user/model";
import {ConfigurationSection} from "../../../common/components/configuration";
import {FormStack} from "../../../common/components/form";
import {Box, Divider, List} from "@mui/material";
import {TwoFactorSetup} from "./TwoFactorSetup";
import {UserOptionRow} from "./UserOptionRow";
import * as React from "react";
import {OptionPanelProps} from "./UserSettingsTab";

export function OptionsPanel({options, menu}: OptionPanelProps) {
  const optionNames = Object.keys(options).map(option => option as UserOption);

  return<ConfigurationSection data-test="options" label="Options">
      <FormStack>
        <List>
          {optionNames.map((option, index) => {
                switch (option) {
                  case "TwoFactor": {
                    return <TwoFactorSetup toggle={options[option]}/>
                  }

                  case "Theme":
                    return <Box key={option}>
                      <UserOptionRow option={option} menu={menu}/>
                      {index !== optionNames.length - 1 && <Divider sx={{mt: 1, mb: 1}}/>}
                    </Box>

                  default:
                    return null
                }
              }
          )}
        </List>
      </FormStack>
    </ConfigurationSection>;
}
