import {OptionsObject} from "notistack";

export function snackOptions(success: boolean): OptionsObject {
    return {
        variant: success ? "success" : "error",
        autoHideDuration: 2500,
        preventDuplicate: true,
        anchorOrigin: {vertical: "top", horizontal: "center"}
    }
}
