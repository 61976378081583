import {Box, CircularProgress, Link, Typography} from "@mui/material";
import * as React from "react";
import {useCallback, useState} from "react";
import {LoginButton} from "../../../common/components/navigation/LoginButton";
import {FormStack} from "../../../common/components/form";

export type LoginButtonsProps = {
  loginDisabled?: boolean
  sandboxDisabled?: boolean
  onLogin: () => void
  onSandbox: () => void
}

export function LoginButtons({loginDisabled, sandboxDisabled, onLogin, onSandbox}: LoginButtonsProps) {
  const [loggingIn, setLoggingIn] = useState(false)

  const handleLogin = useCallback(() => {
    setLoggingIn(true)
    onLogin()
  }, [setLoggingIn, onLogin])

  const handleSandbox = useCallback(() => {
    setLoggingIn(true)
    onSandbox()
  }, [setLoggingIn, onSandbox])

  return <FormStack>
    <LoginButton data-test="login" disabled={loginDisabled === true || loggingIn} onClick={handleLogin}/>

    {
        (sandboxDisabled === undefined || !sandboxDisabled) &&
        <Box justifyContent="center" width="100%" display="flex">
          <Link sx={{my: 2}} href="#" onClick={handleSandbox} data-test="login-sandbox">
            Or login to sandbox to preview all features
          </Link>
        </Box>
    }

    {
        loggingIn
        && <Box justifyContent="center" width="100%" display="flex">
          <Typography> <CircularProgress sx={{color: "#000000", mr: 1}} size={14}/>
            Logging in ...
          </Typography>
        </Box>
    }
  </FormStack>
}


