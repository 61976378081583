import * as React from 'react';
import {Divider, Typography} from "@mui/material";
import {PipelineMonodeRollout} from "../../api/pipelines/model";
import {Monode} from "../../models";
import {formatUTCToLocalDateTime} from "../../../common/dateTime";

export type PipelineTargetTooltipProps = PipelineMonodeRollout & {
  monode: Monode
}

export function PipelineTargetTooltip({
                                        monode,
                                        target,
                                        commit,
                                        deployer,
                                        rolloutTime,
                                        batchSize
                                      }: PipelineTargetTooltipProps) {
  return (<>
        <Typography variant="body2"
                    fontWeight="bold">
          {monode} to {target}
        </Typography>
        <Divider sx={{mb: 1}}/>

        <Typography variant="body2" sx={{mb: 1}}>
          <strong>Version:</strong> {commit.sha} by {deployer.login} on {formatUTCToLocalDateTime(rolloutTime)}
        </Typography>

        <Typography variant="body2" sx={{mb: 1}}>
          <strong>Commit:</strong> <i>{commit.message}</i> {commit.author && <>by {commit.author.login}</>} on {formatUTCToLocalDateTime(commit.timestamp)}
        </Typography>

        <Typography variant="body2">
          <strong>Batch size:</strong> {batchSize}
        </Typography>
      </>
  )
}
