import React, {useCallback} from "react";
import {asDashboardWidgetProps, DashboardWidgetProps} from "./DashboardWidgetView";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {
  DashboardId,
  DashboardName,
  DashboardWidget,
  DashboardWidgetType,
  TextWidgetVariant
} from "../../api/dashboard/model";
import {DashboardGrid} from "./DashboardGrid";
import {useDashboardQuery, useUpdateDashboardMutation} from "../../api/dashboard";
import {OnError} from "../../../common/api/TransportLayer";
import {snackOptions} from "../../../common/snackbar";
import {useSnackbar} from "notistack";
import {VcsOwner} from "../../../common/models";

export type OrgDashboardPanelProps = {
  owner: VcsOwner
  dashboardId: DashboardId
  isEditing?: boolean
  onChangingLayout?: (changingLayout: boolean) => void
} & OnError;

export function OrgDashboardPanel({onError, isEditing, onChangingLayout, ...props}: OrgDashboardPanelProps) {
  const getDashboard = useDashboardQuery(props)
  const updateDashboard = useUpdateDashboardMutation(props)
  const {enqueueSnackbar} = useSnackbar();

  const handleSaveDashboard = useCallback(async (name: DashboardName, widgets: DashboardWidgetProps<any>[]) => {
    const response = await updateDashboard.mutateAsync({name, widgets});
    const success = response.kind === "ok";
    const message = success ? `Updated "${name}" dashboard` : `Failed to update "${name}" dashboard`
    enqueueSnackbar(message, snackOptions(success))
  }, [enqueueSnackbar, updateDashboard])


  const checkWidgetAllowed = (type: DashboardWidgetType, widgets: DashboardWidget[]) => {
    const notAllowed = [DashboardWidgetType.UserWatchlist, DashboardWidgetType.UserOrgs];
    const allowedOnlyOne = [DashboardWidgetType.OrgEvents];

    return notAllowed.indexOf(type) < 0 &&
        (allowedOnlyOne.indexOf(type) < 0 || (allowedOnlyOne.indexOf(type) >= 0 && widgets.findIndex(widget => widget.type === type) < 0))
  }

  return <QueryLoader result={getDashboard} onError={onError} onLoaded={data =>
      <DashboardGrid
          showName={true}
          name={data.name}
          columns={16}
          onError={onError}
          isWidgetAllowed={checkWidgetAllowed}
          isEditing={isEditing}
          createWidget={type => orgDashboardWidget(type, props.owner)}
          onSave={(name, widgets) => handleSaveDashboard(name, widgets)}
          onChangingLayout={onChangingLayout}
          widgets={data.widgets.map(asDashboardWidgetProps)}
      />
  }/>
}

function orgDashboardWidget(type: DashboardWidgetType, org: VcsOwner): DashboardWidget {
  const id = new Date().getTime().toString()
  const widgetMap : Record<DashboardWidgetType, DashboardWidget> = {
    Embed: {type: DashboardWidgetType.Embed, id, url:"https://example.com", title:"", height:500},
    OrgEvents: {type: DashboardWidgetType.OrgEvents, id, org, metaFilter: ""},
    OrgMonodes: {type: DashboardWidgetType.OrgMonodes, id, org, metaFilter: ""},
    OrgMonodesRadiator: {type: DashboardWidgetType.OrgMonodesRadiator, id, org, metaFilter: ""},
    OrgPipelines: {type: DashboardWidgetType.OrgPipelines, id, org, metaFilter: ""},
    Text: {type: DashboardWidgetType.Text, id, text: "Text", variant: TextWidgetVariant.h5},
    UserOrgs: {type: DashboardWidgetType.UserOrgs, id},
    UserWatchlist: {type:DashboardWidgetType.UserWatchlist, id}
  }

  return widgetMap[type]
}

