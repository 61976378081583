import React from "react";
import {Page, PageProps} from "../../common/layout/Page";
import {AccountAppNavigationHeader} from "./AccountAppNavigationHeader";
import {AccountAuthenticationProfile} from "../auth/useAccountAuthentication";
import {useAccountAppInfrastructure} from "../AccountAppInfrastructure";
import {AccountPageId} from "../pages/AccountPageId";

export function AccountAppPage({profile, pageId, onError, ...rest}: AccountAppPageProps) {
  const {analytics} = useAccountAppInfrastructure()
  const navigationHeader = <AccountAppNavigationHeader profile={profile}/>;
  return (
      <Page pageId={pageId} analytics={analytics} navigationHeader={navigationHeader} {...rest}/>
  )
}

export type AccountAppPageProps = {
  profile: AccountAuthenticationProfile
  pageId: AccountPageId
} & Omit<PageProps, "navigationHeader" | "analytics" | "pageId">


