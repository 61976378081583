import {Divider, List} from "@mui/material";
import * as React from "react";
import {useMemo} from "react";
import {VcsRepoFilePath, VcsRepoFilePathType} from "../../models";
import {
  DocumentationContentDescription,
  fileNameFrom,
  humaniseIfMarkdown,
  isMarkdown
} from "../../api/configurations/model";
import {DocumentationMenuItem} from "./DocumentationMenuItem";

import {OrgRepoPath} from "../../../common/models";

export type DocumentationListPanelProps = {
  path: OrgRepoPath
  content: DocumentationContentDescription[]
  selected?: VcsRepoFilePath
  onContent: (content: DocumentationContentDescription) => void
}

export function DocumentationListPanel({content, selected, onContent}: DocumentationListPanelProps) {
  const sorted = useMemo((() => content.sort((a, b) => a.path.localeCompare(b.path))), [content])
  const markdowns = useMemo((() => sorted.filter(item => item.type === VcsRepoFilePathType.file && isMarkdown(item.path))), [sorted])
  const other = useMemo((() => [
    ...sorted.filter(item => item.type === VcsRepoFilePathType.file && !isMarkdown(item.path)),
    ...sorted.filter(item => item.type === VcsRepoFilePathType.dir)
  ]), [sorted])
  const isSelected = (item: DocumentationContentDescription) => selected !== undefined && selected === item.path
  const selectionHandler = (item: DocumentationContentDescription) => () => onContent(item)
  const needsDivider = other.length > 0 && markdowns.length > 0;

  const textFor = (item: DocumentationContentDescription) =>
      humaniseIfMarkdown(fileNameFrom(item)) + (item.type === VcsRepoFilePathType.dir ? " /" : "")


  return <List sx={{p: 0, m: 0}}>
    {markdowns.map(item =>
        <DocumentationMenuItem key={item.path}
                               selected={isSelected(item)}
                               onSelect={selectionHandler(item)}
                               children={textFor(item)}
        />
    )}

    {needsDivider && <Divider sx={{my: 1, borderBottomWidth: 1, borderTopWidth: 1}}/>}

    {other.map(item =>
        <DocumentationMenuItem key={item.path}
                               selected={isSelected(item)}
                               onSelect={selectionHandler(item)}
                               children={textFor(item)}
        />
    )}
  </List>

}
