import {Box, ListItem, ListItemProps, Typography} from "@mui/material";
import * as React from "react";
import {ReactNode} from "react";
import {UserOption} from "../../api/user/model";

export type UserOptionRowMenuProvider = (params: { option: UserOption }) => ReactNode

export type UserOptionRowProps = {
  option: UserOption
  menu: UserOptionRowMenuProvider
}

export function UserOptionRow(props: UserOptionRowProps) {
  return <StandardUserOptionRow {...props} containerProps={{}}/>
}

function StandardUserOptionRow({option, menu, containerProps}: UserOptionRowViewProps) {
  const spacingWidth = 30
  const menuSpacingWidth = 10

  return <ListItem {...containerProps}>
    <Box sx={{display: "flex", flexDirection: "row"}} alignItems="center">
      <Box sx={{flexGrow: 1, width: "100%", marginRight: `${spacingWidth}px`}}>
        <Typography variant="body1" noWrap sx={{
          textOverflow: "ellipsis"
        }}>
          {option}
        </Typography>
      </Box>

      <Box sx={{flex: 1, marginLeft: `${menuSpacingWidth}px`}}>
        {menu({option})}
      </Box>
    </Box>
  </ListItem>
}

export type UserOptionRowViewProps = {
  containerProps: ListItemProps;
} & UserOptionRowProps
