import React, {createContext, ReactNode, useContext} from 'react';
import {CommonAppInfrastructure} from "../common/CommonAppInfrastructure";
import {LaunchAppUrls} from "./models/LaunchAppUrls";
import {UserAppInfrastructure, UserAppInfrastructureProvider} from "../userApp/UserAppInfrastructure";

export interface LaunchAppInfrastructure extends CommonAppInfrastructure, UserAppInfrastructure {
  launchUrls: LaunchAppUrls
}

export type LaunchAppInfrastructureProviderProps = {
  infra: LaunchAppInfrastructure
  children: ReactNode
}

export const LaunchAppInfrastructureContext = createContext<null | LaunchAppInfrastructure>(null);

export const LaunchAppInfrastructureProvider = ({children, infra}: LaunchAppInfrastructureProviderProps) => {
  return <UserAppInfrastructureProvider infra={infra}>
    <LaunchAppInfrastructureContext.Provider value={infra}>
      {children}
    </LaunchAppInfrastructureContext.Provider>
  </UserAppInfrastructureProvider>
}

export const useLaunchAppInfrastructure = (): LaunchAppInfrastructure => {
  const context = useContext(LaunchAppInfrastructureContext);
  if (context == null) {
    throw new Error('LaunchAppInfrastructureContext requires to be wrapped in an LaunchAppInfrastructureProvider');
  }
  return context;
}
