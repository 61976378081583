import {Grid} from "@mui/material";
import * as React from "react";
import {DependencyGraphPanel} from "./DependencyGraphPanel";
import {DependencyGraph} from "../../api/graphs/model";
import {FollowDocumentationToConfigureHint} from "../hints";

import {VcsProvider} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type DependencyPanelProps = {
  vcs: VcsProvider
  graph: DependencyGraph;
}

export function DependencyPanel({graph, vcs}: DependencyPanelProps) {
  const {docsUrls} = useUserAppInfrastructure()
  if (graph.nodes.length === 0)
    return <FollowDocumentationToConfigureHint feature="dependencies" url={docsUrls.configureDependencies()}/>

  return <Grid item xs={12} sx={{height: 500}}>
    <DependencyGraphPanel vcs={vcs}
                          graph={graph}/>
  </Grid>
}
