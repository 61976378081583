import * as React from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {LoginPanel} from "../../components/user/LoginPanel";
import {unauthenticatedProfile} from "../../../common/auth/UnauthenticatedProfile";
import {UserPageId} from "../UserPageId";

export function LoginPage() {
  const {navigateToLogin} = useUserAuthentication()
  return <UserAppPage pageId={UserPageId.UserLogin} title="Login" error={null} profile={unauthenticatedProfile}>
    <LoginPanel target="app" onLogin={navigateToLogin}/>
  </UserAppPage>;
}
