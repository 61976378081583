import * as React from 'react';

import {Box, Paper} from "@mui/material";
import {Handle, NodeProps, Position} from "reactflow";
import {Link} from "../../../common/components/navigation/Link";
import {Monode, MonodeType, VcsBranch} from "../../models";
import {VcsPipelineName} from "../VcsPipelineName";
import {CustomIcon, CustomIconName} from "../../../common/components/icons";
import {monopolisTheme} from "../../../common/styles/theme";

import {OrgRepoPath} from "../../../common/models";
import {VcsProvider} from "../../../common/models";

export function MonodeGraphNode({data}: MonodeGraphNodeProps) {
  const nodeWidth = 300
  const statusIconWidth = 25
  const spacingWidth = 8
  const nameWidth = nodeWidth - statusIconWidth - 40
  const iconMapping: Record<MonodeType, CustomIconName> = {
    generic: "code",
    service: "cube",
    package: "server"
  }

  const handleStyle = {
    minWidth: 11,
    minHeight: 11,
    borderWidth: 3,
    borderColor: monopolisTheme.palette.primary.main,
    backgroundColor: monopolisTheme.palette.background.paper
  }
  return (
      <Paper variant="outlined" style={{
        borderRadius: 15,
        borderWidth: 3,
        backgroundColor: monopolisTheme.palette.background.paper,
        borderColor: monopolisTheme.palette.primary.main,
        minWidth: nodeWidth,
        maxWidth: nodeWidth,
      }}>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          padding: 1,
          borderColor: monopolisTheme.palette.primary.main,
        }}
             alignItems="center">
          <Box sx={{
            flex: 1,
            minWidth: `${statusIconWidth}px`,
            maxWidth: `${statusIconWidth}px`,
            mx: `${spacingWidth}px`,
          }}>
            <Link href={"#"}>
              <CustomIcon name={iconMapping[data.type]} style={{height: `${statusIconWidth}px`}}/>
            </Link>
          </Box>
          <Box sx={{
            flex: 1,
            minWidth: `${nameWidth}px`,
            maxWidth: `${nameWidth}px`,
          }}>
            <VcsPipelineName path={data.path}
                             branch={data.branch}
                             monode={data.monode}
                             maxWidth={nameWidth}/>
          </Box>
        </Box>

        <Handle type="source" position={Position.Left} isConnectable={true} style={{...handleStyle, top: 34}}/>
        <Handle type="target" position={Position.Right} style={{...handleStyle, top: 34}}/>
      </Paper>
  )
}

export type MonodeGraphNodeData = {
  vcs: VcsProvider;
  path: OrgRepoPath;
  monode: Monode;
  type: MonodeType;
  branch?: VcsBranch;
}

export type MonodeGraphNodeProps = {} & NodeProps<MonodeGraphNodeData>
