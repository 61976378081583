import * as React from 'react';
import {SystemError} from "../../../common/api/TransportLayer";
import {LaunchAppPage} from "../../layout/LaunchAppPage";
import {LaunchAppPageId} from "../LaunchAppPageId";

export type ErrorPageProps = {
  error: SystemError;
}

export function ErrorPage({error}: ErrorPageProps) {
  return <LaunchAppPage pageId={LaunchAppPageId.Error} title={`Error: ${error.message}`} error={error}/>;
}
