import {EmailAddress, VcsProvider} from "../../../common/models";
import {TeamId} from "../../../common/models/TeamId";

export type OAuthClientId = string
export type OAuthClientSecret = string
export type VcsAccessToken = string
export type PlanName = string
export type PlanType = "Free" | "Paid"
export type BillingPeriod = "Annual" | "Monthly"
export type BillingDate = string
export type SeatPrice = number
export type PurchasedSeats = number
export type TeamUrl = string

export enum CustomerOnboardingMode {
    Auto = "Auto",
    Manual = "Manual"
}

export type AccountConfiguration = {
    plan: BillingPlan
    vcs: VcsInstance
    onboardingMode: CustomerOnboardingMode,
    accessTokenSet: boolean,
    oauthCredentials?: AccountOAuthCredentials,
    teamUri?: AccountOAuthCredentials,
    teamId: TeamId,
    teamUrl: TeamUrl

}

export type MaybeAccountConfiguration = AccountConfiguration | undefined

export type AccountVcsAccessTokenRequest = {
    token?: VcsAccessToken
}

export type AccountOAuthCredentials = {
    clientId: OAuthClientId
    clientSecret: OAuthClientSecret
}

export type IssueOneTimeLoginLinkRequest = {
    email: EmailAddress
}

export type BillingPlan = {
    name: PlanName
    features: PlanFeature[]
    limits: Record<PlanLimit, number>
    endAt?: string
    trialEndAt?: string
    type: PlanType
}

export type VcsInstance = {
    vcs: VcsProvider
    api: string
    server: string
}

export enum PlanFeature {
    PrivateRepos = 'PrivateRepos',
    Crosscheck = 'Crosscheck',
    AutoRollout = 'AutoRollout',
    DoraStats = 'DoraStats'
}

export enum PlanLimit {
    DataRetention = 'DataRetention',
    Environments = 'Environments',
    Monodes = 'Monodes',
    RefreshSeconds = 'RefreshSeconds',
    Repos = 'Repos',
    StatsRange = 'StatsRange'
}

export type PlanPurchase = {
    startAt: string
    plan: BillingPlan
    billing: PlanBilling
}

export type PlanBilling = {
    seatCost: SeatPrice
    numberOfSeats: PurchasedSeats
    period: BillingPeriod
    billingDate?: BillingDate
    billingEmail?: EmailAddress
}
