import * as React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "./AccountApp.css";
import "font-awesome-animation/css/font-awesome-animation.css";
import 'reactflow/dist/style.css';
import {StyledSnackbarProvider} from "../common/components/StyledSnackbarProvider";
import {AccountAppRouter} from "./AccountAppRouter";
import ErrorBoundary from "./layout/ErrorBoundary";
import {AccountAppInfrastructure, AccountAppInfrastructureProvider} from "./AccountAppInfrastructure";

export type AccountAppProps = {
  infra: AccountAppInfrastructure
}

export function AccountApp({infra}: AccountAppProps): React.ReactElement {
  return (
      <StyledSnackbarProvider>
        <QueryClientProvider client={new QueryClient()}>
          <AccountAppInfrastructureProvider infra={infra}>
            <ErrorBoundary>
              <BrowserRouter>
                <AccountAppRouter/>
              </BrowserRouter>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false}/>
          </AccountAppInfrastructureProvider>
        </QueryClientProvider>
      </StyledSnackbarProvider>
  );
}
