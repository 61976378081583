import * as React from "react";
import {ReactNode} from "react";
import {MoreVert} from "@mui/icons-material";
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import {ClickAwayListener, Fade, IconButton, Paper, Popper, Tooltip} from "@mui/material";
import {MenuItem} from "./MenuItem";

import {monopolisTheme} from "../../styles/theme";

export type MenuActionDefinition = {
  id: string,
  text: string,
  icon?: ReactNode
  onAction: () => void
}

export type MenuProps = {
  menuId?: string
  icon?: ReactNode
  showMenuForSingleItem?: boolean
  actions: (MenuActionDefinition | undefined)[]
}

export function Menu({actions, icon, showMenuForSingleItem}: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (definition: MenuActionDefinition) => () => {
    setAnchorEl(null);
    definition.onAction()
  };

  const actionDefinitions = actions.filter(a => a !== undefined) as MenuActionDefinition[]

  if (actionDefinitions.length === 0) {
    return null
  }

  if (actionDefinitions.length === 1 && !showMenuForSingleItem) {
    const definition = actionDefinitions[0]
    return <Tooltip arrow title={definition.text}
                    placement="top">
      <IconButton
          data-test={"menu-action-" + definition.id}
          aria-label={definition.text}
          sx={{border: 1, borderRadius: 1, borderColor: monopolisTheme.palette.secondary.main, height: 40, width: 40}}
          color="primary" onClick={definition.onAction}>
        {icon || definition.icon || <QuestionMarkRoundedIcon/>}
      </IconButton>
    </Tooltip>
  }

  return (<>
        <IconButton
            aria-label="Menu"
            data-test="menu"
            sx={{border: 1, borderRadius: 1, borderColor: monopolisTheme.palette.secondary.main, height: 40, width: 40}}
            color="primary" onClick={handleOpen}>
          {icon || <MoreVert/>}
        </IconButton>

        <Popper transition={true} placement="bottom-end" open={open} anchorEl={anchorEl}
                style={{minWidth: 150, maxWidth: 250, zIndex: 10000, paddingTop: 5}}>
          {({TransitionProps}) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper variant="outlined">
                    {
                      actionDefinitions.map(definition =>
                          <MenuItem key={definition.text} text={definition.text} onClick={handleAction(definition)}
                                    data-test={"menu-action-" + definition.id}/>)
                    }
                  </Paper>
                </Fade>
              </ClickAwayListener>)}
        </Popper>
      </>
  )
}
