import * as React from 'react';
import {Divider, Typography} from "@mui/material";
import {TargetView} from "./TargetsPanel";

export type TargetTooltipProps = TargetView

export function TargetTooltip({target, rollouts, rollbacks}: TargetTooltipProps) {
  return (<>
        <Typography variant="body2"
                    fontWeight="bold">
          Target: {target}
        </Typography>

        <Divider sx={{mb: 1}}/>

        <Typography variant="body2">
          Rollout: {rollouts.join(", ")}
        </Typography>

        <Typography variant="body2">
          Rollback: {rollbacks.join(", ")}
        </Typography>
      </>
  )
}
