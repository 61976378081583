import {RolloutTarget} from "../../models";
import {OnError} from "../../../common/api/TransportLayer";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {VcsOwner} from "../../../common/models";
import {MaybeOrgConfiguration} from "../../../common/models";

export enum OrgTargetPageTab {
  rollouts = "rollouts"
}

export type OrgTargetPageTabProps = {
  owner: VcsOwner
  target: RolloutTarget
  userProfile: AuthenticatedUserProfile
  orgConfiguration: MaybeOrgConfiguration
} & OnError
