import * as React from 'react';
import {ReactNode, useCallback} from 'react';
import {SettingsOutlined} from "@mui/icons-material";
import {useAddToUserWatchlistMutation, useRemoveFromUserWatchlistMutation} from "../api/pipelines";
import {EmptyRequest, EmptyResponse, MutationResult} from "../../common/api/query";
import {SystemResponse} from "../../common/api/TransportLayer";
import {useSnackbar} from 'notistack';
import {Monode, VcsBranch} from "../models";
import {snackOptions} from "../../common/snackbar";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {Menu, MenuActionDefinition} from "../../common/components/navigation/Menu";
import {OrgRepoPath} from "../../common/models";
import {descriptionOf} from "../api/pipelines/model";
import {useNavigation} from "../../common/port/Navigation";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";

export type VcsBreadcrumbsMenuProvider = () => ReactNode

export type VcsBreadcrumbsMenuAction = "addToWatchlist" | "removeFromWatchlist" | "repoSettings"

export type VcsBreadcrumbsMenuProps = {
  path: OrgRepoPath,
  monode?: Monode,
  branch?: VcsBranch,
  isWatched?: boolean,
  actions: VcsBreadcrumbsMenuAction[]
  onSuccessfulAction?: (action: VcsBreadcrumbsMenuAction) => void
}

export function VcsBreadcrumbsMenu({
                                     path,
                                     monode,
                                     branch,
                                     actions,
                                     isWatched,
                                     onSuccessfulAction
                                   }: VcsBreadcrumbsMenuProps) {
  const {urls} = useUserAppInfrastructure()
  const pipelineMutationParams = {
    path: path,
    monode: monode || "",
    branch: branch || "",
  };

  const {navigateTo} = useNavigation()
  const {enqueueSnackbar} = useSnackbar();
  const addToWatchlist = useAddToUserWatchlistMutation(pipelineMutationParams)
  const removeFromWatchlist = useRemoveFromUserWatchlistMutation(pipelineMutationParams)

  const handleAction = useCallback(async (mutation: MutationResult<SystemResponse<EmptyResponse>, unknown, EmptyRequest>, action: VcsBreadcrumbsMenuAction, success: string, failure: string) => {
    const response = await mutation.mutateAsync({});
    const isSuccess = response.kind === "ok";

    enqueueSnackbar(isSuccess ? success : failure, snackOptions(isSuccess))

    if (onSuccessfulAction && isSuccess) onSuccessfulAction(action)
  }, [enqueueSnackbar, onSuccessfulAction])

  const actionDefinitionIfAllowed = (action: VcsBreadcrumbsMenuAction): (MenuActionDefinition | undefined) => {
    const pipelineDescription = descriptionOf(path, monode)

    if (actions.includes(action)) {
      switch (action) {
        case "addToWatchlist": {
          return {
            id: action,
            text: "Add to watchlist",
            icon: <StarBorderRoundedIcon fontSize="small"/>,
            onAction: () => handleAction(addToWatchlist, action, `Added ${pipelineDescription} to watchlist`, `Failed to add ${pipelineDescription} to watchlist`)
          }
        }

        case "removeFromWatchlist": {
          return {
            id: action,
            text: "Remove from watchlist",
            icon: <StarRoundedIcon fontSize="small"/>,
            onAction: () => handleAction(removeFromWatchlist, action, `Removed ${pipelineDescription} from watchlist`, `Failed to remove ${pipelineDescription} from watchlist`)
          }
        }

        case "repoSettings": {
          return {
            id: action,
            text: "Settings",
            icon: <SettingsOutlined fontSize="small"/>,
            onAction: () => navigateTo(urls.repositorySettings({path}))
          }
        }
      }
    }
  }

  return <Menu actions={[
    isWatched
        ? actionDefinitionIfAllowed("removeFromWatchlist")
        : actionDefinitionIfAllowed("addToWatchlist"),
    actionDefinitionIfAllowed("repoSettings")
  ]}/>
}
