import React, {useCallback} from "react";
import {DashboardWidgetProps} from "./DashboardWidgetView";
import {DashboardWidgetPanel, useDashboardWidgetPanel} from "./DashboardWidgetPanel";
import {useUpdateUserWatchlistMutation, useUserWatchlistQuery} from "../../api/pipelines";
import {ConfigureWatchlistHint} from "../hints";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {PipelineMenu} from "../pipelines/PipelineMenu";
import {useSnackbar} from "notistack";
import {PipelineId} from "../../api/pipelines/model";
import {snackOptions} from "../../../common/snackbar";
import {extendPipelines, PipelineMenuProvider} from "../../utils/pipelines";
import {PipelinesTablePanel} from "../pipelines/PipelinesTablePanel";

export type UserWatchlistWidgetPanelProps = DashboardWidgetProps<{}>;

export function UserWatchlistWidgetPanel({...props}: UserWatchlistWidgetPanelProps) {
  const panelProps = useDashboardWidgetPanel({...props, title: "Watchlist"});
  const watchlist = useUserWatchlistQuery()
  const {enqueueSnackbar} = useSnackbar();
  const updateDashboard = useUpdateUserWatchlistMutation()
  const saveDashboard = useCallback(async (order: PipelineId[]) => {
    const response = await updateDashboard.mutateAsync(order);
    const success = response.kind === "ok";
    const message = success ? "Updated watchlist" : "Failed to update watchlist"
    enqueueSnackbar(message, snackOptions(success))
  }, [enqueueSnackbar, updateDashboard])

  const menu: PipelineMenuProvider = (params) =>
      <PipelineMenu menuId={"watchlist"}
                    {...params}
                    actions={["rerun", "cancel", "rollout", "removeFromWatchlist"]}/>;

  return <DashboardWidgetPanel {...panelProps}>
    <QueryLoader result={watchlist} onError={props.onError} onLoaded={data =>
        data.length === 0
            ? <ConfigureWatchlistHint/>
            : <PipelinesTablePanel
                title="Watching"
                draggable={props.isChangingLayout}
                onReordered={saveDashboard} pipelines={extendPipelines(data)} menu={menu}/>
    }/>

  </DashboardWidgetPanel>
}
