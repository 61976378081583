import {CommitSha, VcsBranch, VcsRunId} from "./index";
import {VcsUrls} from "./VcsUrls";
import {GitHubUrls} from "./GitHubUrls";
import {GitLabUrls} from "./GitLabUrls";
import {SandboxUrls} from "./SandboxUrls";
import {UserAuthenticationStore} from "../auth/UserAuthenticationStore";
import {OrgRepoPath, VcsOwner, VcsPath, VcsProvider} from "../../common/models";
import {AuthenticationType} from "../../common/auth/AuthenticationType";

export class VcsUrlsSelector implements VcsUrls {
    private readonly authStore: UserAuthenticationStore

    constructor(authStore: UserAuthenticationStore) {
        this.authStore = authStore
    }

    private urlsForAuth(): VcsUrls {
        const profile = this.authStore.getProfile();
        if (!profile) throw Error(`Profile not configured`)
        if (profile.type === AuthenticationType.Unauthenticated) throw Error(`User not authenticated`)

        switch (profile.vcs) {
            case VcsProvider.GitHub:
                return profile.vcsServer ? new GitHubUrls(profile.vcsServer) : new GitHubUrls();
            case VcsProvider.GitLab:
                return profile.vcsServer ? new GitLabUrls(profile.vcsServer) : new GitLabUrls();
            case VcsProvider.Sandbox:
                return profile.vcsServer ? new SandboxUrls(profile.vcsServer) : new SandboxUrls();

        }
    }

    pipelineRun(path: OrgRepoPath, vcsRunId: VcsRunId) {
        return this.urlsForAuth().pipelineRun(path, vcsRunId)
    }

    pipeline(path: VcsPath) {
        return this.urlsForAuth().pipeline(path)
    }

    org(org: VcsOwner) {
        return this.urlsForAuth().org(org)
    }

    repo(path: OrgRepoPath) {
        return this.urlsForAuth().repo(path)
    }

    user(owner: VcsOwner) {
        return this.urlsForAuth().user(owner)
    }

    commit(path: OrgRepoPath, sha: CommitSha) {
        return this.urlsForAuth().commit(path, sha)
    }

    branch(path: OrgRepoPath, branch: VcsBranch) {
        return this.urlsForAuth().branch(path, branch)
    }
}
