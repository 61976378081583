import * as React from "react";
import {FormField} from "./Form";
import Typography from "@mui/material/Typography";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {DatePickerProps} from "@mui/x-date-pickers/DatePicker/DatePicker.types";

export type FormDateFieldProps = {
  label: string
  readOnly: boolean
  fullWidth?: boolean
  value?: string
  onChange?: (date:String) => void
} & Omit<DatePickerProps<Dayjs>, "disabled" | "variant" | "fullWidth" | "value" | "onChange">

export function FormDateField({label, fullWidth, readOnly, onChange, value, ...fieldProps}: FormDateFieldProps) {
  return <FormField label={label} fullWidth={fullWidth}>
    {readOnly
        ? <Typography color="secondary">{value || "none"}</Typography>
        : <DatePicker
            sx={{width:"100%"}}
            readOnly={readOnly}
            value={value ? dayjs(value) : undefined}
            onChange={e => (onChange && e) && onChange(e.format("YYYY-MM-DD"))}
            {...fieldProps}
        />
    }
  </FormField>
}
