import React from "react";
import {Page, PageProps} from "../../common/layout/Page";
import {UserAppNavigationHeader} from "./UserAppNavigationHeader";

import {UserAuthenticationProfile} from "../auth/useUserAuthentication";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";
import {UserPageId} from "../pages/UserPageId";

export function UserAppPage({onError, profile, ...rest}: UserAppPageProps) {
  const {analytics} = useUserAppInfrastructure()
  const navigationHeader = <UserAppNavigationHeader profile={profile}/>;
  return (
      <Page analytics={analytics} navigationHeader={navigationHeader} {...rest}/>
  )
}

export type UserAppPageProps = {
  profile: UserAuthenticationProfile
  pageId: UserPageId
} & Omit<PageProps, "navigationHeader" | "analytics" | "pageId">
