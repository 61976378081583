import * as React from 'react';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "../../../common/components/navigation/Link";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {UserAuthenticationProfile} from "../../auth/useUserAuthentication";

import {oauthProviderDetails} from "../../../common/models";

export function UserLoggedInAs({profile}: UserLoggedInAsProps) {
  const {vcsUrls} = useUserAppInfrastructure()
  return profile.type === AuthenticationType.User ?
      <Box>
        <Typography variant="caption">
          Logged in via {oauthProviderDetails(profile.vcs).name} as <Link
            href={vcsUrls.user(profile.login)}>
          <strong>{profile.login}</strong>
        </Link>
        </Typography>
      </Box>
      : <></>

}

export type UserLoggedInAsProps = {
  profile: UserAuthenticationProfile
}
