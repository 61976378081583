import * as React from "react";
import {useCallback, useState} from "react";
import {AccountConfiguration} from "../../api/account/model";
import {FormEditableField, FormElement, FormStack, FormTextField} from "../../../common/components/form";
import {ConfigurationSection} from "../../../common/components/configuration";
import {OAuthProviderSelection} from "./OAuthProviderSelection";
import {ConfigurableOAuthProviders, OAuthProvider, VcsProvider} from "../../../common/models";
import {snackOptions} from "../../../common/snackbar";
import {useAccountUpdateConfigurationMutation} from "../../api/account";
import {useSnackbar} from "notistack";

export type VCSConfigurationPanelProps = {
  disabled: boolean
  config: AccountConfiguration
}

export function VCSConfigurationPanel({disabled, config}: VCSConfigurationPanelProps) {
  const {mutateAsync: configure} = useAccountUpdateConfigurationMutation()
  const {enqueueSnackbar} = useSnackbar();
  const [editing, setEditing] = useState(false)
  const [editedServerUrl, setEditedServerUrl] = useState(config.vcs.server)
  const [editedApiUrl, setEditedApiUrl] = useState(config.vcs.api)
  const [editedProvider, setEditedProvider] = useState<OAuthProvider>(config.vcs.vcs)


  const update = useCallback(async () => {
    const data = await configure({vcs: editedProvider as VcsProvider, api: editedApiUrl, server: editedServerUrl});
    if (data && data.kind === "error") enqueueSnackbar(`Failed to configure VCS`, snackOptions(false))
    if (data && data.kind === "ok") enqueueSnackbar(`Configured VCS`, snackOptions(true))
  }, [configure, enqueueSnackbar, editedApiUrl, editedServerUrl, editedProvider])

  const updateEditing = useCallback(
      (editing: boolean) => {
        setEditing(editing)
      },
      [setEditing]
  );

  return <ConfigurationSection data-test="vcs-config" label="Version Control System">
    <FormStack>
      <FormEditableField
          editable={!disabled}
          editLabel="Configure VCS"
          saveLabel="Save VCS Configuration"
          onSave={update}
          onEditing={updateEditing}>
        <FormElement>
          <OAuthProviderSelection options={ConfigurableOAuthProviders}
                                  selected={editing ? editedProvider : config.vcs.vcs}
                                  disabled={disabled || !editing} maxWidth={"100%"}
                                  onOAuthProvider={p => setEditedProvider(p)}/>
        </FormElement>

        <FormTextField label="Server URL"
                       required={true}
                       readOnly={disabled || !editing}
                       value={editing ? editedServerUrl : config.vcs.server}
                       placeholder="Server url..."
                       onChange={e => setEditedServerUrl(e.target.value)}
        />

        <FormTextField label="Api URL"
                       required={true}
                       readOnly={disabled || !editing}
                       value={editing ? editedApiUrl : config.vcs.api}
                       placeholder="Api url..."
                       onChange={e => setEditedApiUrl(e.target.value)}
        />
      </FormEditableField>
    </FormStack>
  </ConfigurationSection>
}


