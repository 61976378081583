import * as React from "react";
import {MarkdownPanel} from "./MarkdownPanel";
import {Monode, VcsBranch, VcsRepoFilePath} from "../../models";
import {useMonodeDocumentationContentQuery} from "../../api/configurations";
import {OnError} from "../../../common/api/TransportLayer";
import {Box, Skeleton} from "@mui/material";
import {ErrorPanel} from "../../../common/components/ErrorPanel";
import {isImage, isMarkdown} from "../../api/configurations/model";
import {PluginsUrls} from "../../api/configurations/urls";
import {detectProgrammingLanguage} from "../../utils/languages";

import {OrgRepoPath} from "../../../common/models";

export type DocumentationContentPanelProps = {
  path: OrgRepoPath
  monode: Monode
  file: VcsRepoFilePath
  branch: VcsBranch
  onFragment: (fragment: string) => void
  transformContentUrl: (path: string) => string
} & OnError

export function DocumentationContentPanel(props: DocumentationContentPanelProps) {
  if (isMarkdown(props.file)) return <MarkdownContentPanel {...props}/>

  if (isImage(props.file)) return <ImageContentPanel {...props}/>

  const language = detectProgrammingLanguage(props.file);
  if (language) return <CodeContentPanel {...props} language={language}/>

  return <></>
}

function MarkdownContentPanel({onError: onQueryError, ...props}: DocumentationContentPanelProps) {
  const {data: content} = useMonodeDocumentationContentQuery({...props, onQueryError})
  if (!content) return <Skeleton height={50}/>
  if (content && content.kind === "error") return <ErrorPanel error={content.error}/>
  return <MarkdownPanel {...props} content={content.data}/>
}

function ImageContentPanel(props: DocumentationContentPanelProps) {
  return <Box m={2}>
    <img src={PluginsUrls.documentationContentUrl(props)}
         alt={"Image loaded from file " + props.file}
         style={{maxWidth: "100%"}}/>
  </Box>
}

function CodeContentPanel({
                            onError: onQueryError,
                            language,
                            ...props
                          }: DocumentationContentPanelProps & { language: string }) {
  const {data: content} = useMonodeDocumentationContentQuery({...props, onQueryError})
  if (!content) return <Skeleton height={50}/>
  if (content && content.kind === "error") return <ErrorPanel error={content.error}/>
  return <MarkdownPanel {...props} content={"```" + language + "\n" + content.data + "\n```"}/>
}
