import * as React from "react";
import Typography from "@mui/material/Typography";
import {durationInDaysFromNowUTC, durationInSecondsFromNowUTC} from "../dateTime";
import {monopolisTheme} from "../styles/theme";
import {CustomerPlan} from "../models";
import {useCommonAppInfrastructure} from "../CommonAppInfrastructure";

export function TrialEndingBanner({plan}: TrialEndingBannerProps) {
  const {config} = useCommonAppInfrastructure()
  if (!plan) return <></>
  if (!(plan.trialEndAt && durationInSecondsFromNowUTC(plan.trialEndAt) > 0)) return <></>

  return (
      <Typography sx={{
        backgroundColor: monopolisTheme.palette.warning.main,
        textAlign: "center",
        py: 0.2
      }}>
        Your free trial of <b>{config.appName} {plan.name}</b> ends
        in {durationInDaysFromNowUTC(plan.trialEndAt)} days
      </Typography>
  )
}

export type TrialEndingBannerProps = {
  plan: CustomerPlan | undefined
}
