import {Monode} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";
import {monodeParam, pathParam} from "../../utils/urlParams";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export class RolloutUrls {
    static orgTargets(params: { org: VcsOwner, path?: OrgRepoPath, monode?: Monode }) {
        const query = Array<UrlQueryParam>()
        if (params.path) query.push(pathParam(params.path));
        if (params.monode) query.push(monodeParam(params.monode));
        return buildUrl(`/targets/org/${params.org}`, query)
    }

    static repositoryRollouts(params: { path: OrgRepoPath}) {
        return buildUrl(`/target/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`)
    }

    static monodeRollouts(params: { path: OrgRepoPath, monode: Monode }) {
        return buildUrl(`/target/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`)
    }
}
