import {Monode, RolloutTarget} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";
import {atParam, pathParam} from "../../utils/urlParams";
import {DateTime, OrgRepoPath, VcsOwner} from "../../../common/models";

export class GraphUrls {

    static rolloutGraph(params: { owner: VcsOwner, path?: OrgRepoPath, target: RolloutTarget, at?: DateTime }) {
        const query = Array<UrlQueryParam>()
        if (params.path) query.push(pathParam(params.path));
        if (params.at) query.push(atParam(params.at));
        return buildUrl(`/graphs/org/${params.owner}/${params.target}`, query)
    }

    static dependencyGraph(params: { path: OrgRepoPath }) {
        const query = Array<UrlQueryParam>()
        return buildUrl(`/graphs/repo/dependencies/${params.path.org}/${encodeURIComponent(params.path.repo)}`, query)
    }

    static orgDependencyGraph(params: { owner: VcsOwner }) {
        return buildUrl(`/graphs/org/dependencies/${params.owner}`)
    }

    static monodeDependencyGraph(params: { path: OrgRepoPath, monode: Monode }) {
        const query = Array<UrlQueryParam>()
        return buildUrl(`/graphs/monode/dependencies/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, query)
    }
}
