import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {SystemError} from "../../../common/api/TransportLayer";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {UnauthorizedPage} from "../error/UnauthorizedPage";

import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {UserPageId} from "../UserPageId";
import {UserSettingsTab} from "../../components/user/UserSettingsTab";
import {UserOptionToggle} from "../../components/user/UserOptionToggle";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {UserSettingsPageTab} from "./UserSettingsPageTab";

export function UserConfigurationPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {urls} = useUserAppInfrastructure()
  const {userProfile} = useUserAuthentication()

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const header = <PageHeader
      title="Settings"
      location={urls.userSettings()}
  />

  return <UserAppPage pageId={UserPageId.UserConfiguration}
                      title="Settings" contentHeader={header} error={error} profile={userProfile}>
    <PageTab selectedId={UserSettingsPageTab.settings} id={UserSettingsPageTab.settings}>
      <UserSettingsTab options={userProfile.options}
                       profile={userProfile}
                       onError={setError}
                       menu={({option}) =>
                           <UserOptionToggle option={option}
                                             toggle={userProfile.options[option]}/>
                       }/>
    </PageTab>

  </UserAppPage>
}
