import {useSnackbar} from "notistack";
import * as React from "react";
import {useCallback, useState} from "react";
import {snackOptions} from "../../../common/snackbar";
import {FormEditableField, FormStack, FormTextField} from "../../../common/components/form";
import {useAccountUpdateVcsAccessTokenMutation} from "../../api/account";

export function AccessTokenConfigurationPanel() {
  const configureMutation = useAccountUpdateVcsAccessTokenMutation()
  const {enqueueSnackbar} = useSnackbar();

  const {mutateAsync: configure} = configureMutation
  const [editing, setEditing] = useState(false)
  const [token, setToken] = useState("")

  const updateEditing = useCallback(
      (editing: boolean) => {
        setToken("")
        setEditing(editing)
      },
      [setEditing]
  );

  const update = useCallback(async () => {
    const data = await configure({token});
    if (data && data.kind === "error") enqueueSnackbar(`Failed to configure VCS access token`, snackOptions(false))
    if (data && data.kind === "ok") enqueueSnackbar(`Configured VCS access token`, snackOptions(true))
  }, [configure, enqueueSnackbar, token])

  return <FormStack>
    <FormEditableField
        editLabel="Configure VCS Access Token"
        saveLabel="Save VCS Access Token"
        onSave={update}
        onEditing={updateEditing}>
      <FormTextField label="VCS Access Token"
                     type="password"
                     inputProps={{
                       minLength: 12,
                       "data-test": "vcs-access-token"
                     }}
                     required={true}
                     readOnly={!editing}
                     value={editing ? token : "**********"}
                     onChange={e => setToken(e.target.value)}
                     placeholder="VCS personal access token..."/>
    </FormEditableField>
  </FormStack>
}


