import {useDeletePipelineMutation} from "../../api/pipelines";
import * as React from "react";
import {useCallback, useEffect} from "react";
import {Pipeline, PipelineRun} from "../../api/pipelines/model";
import {OnError} from "../../../common/api/TransportLayer";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from "@mui/material";
import {CancelButton} from "../../../common/components/navigation/CancelButton";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type RemoveRunDialogProps = {
  pipeline: Pipeline
  run: PipelineRun
  onApproved: () => void
  onCancelled: () => void
  onSuccessful: () => void
} & OnError

export function RemoveRunDialog(
    {
      pipeline,
      run,
      onCancelled,
      onApproved,
      onSuccessful,
      onError
    }: RemoveRunDialogProps) {

  const action = "Remove run"
  const {analytics} = useUserAppInfrastructure()
  useEffect(() => analytics.dialogview(action), [analytics, action])

  const deletePipeline = useDeletePipelineMutation(
      {path: pipeline.path, runId: run.runId, branch: pipeline.branch, pipeline: pipeline.name})

  const handleAction = useCallback(async () => {
    const response = await deletePipeline.mutateAsync({});
    const isSuccess = response.kind === "ok";

    if (isSuccess) {
      analytics.event("User", action, action)
      onSuccessful && onSuccessful()
      onApproved && onApproved()
    } else {
      analytics.event("User", action, `${action} Failure`)
      onError && onError(response.error)
    }
  }, [analytics, onError, onSuccessful, onApproved, deletePipeline])

  return (
      <Dialog fullScreen={false} open={true} onClose={onCancelled}>
        <DialogTitle>
          {action}: {pipeline.name} {run.runId}
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText color="primary">
            Removing this run will hide the pipeline from this view until it is triggered again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onCancelled}/>
          <Button aria-label={action} variant="contained" autoFocus onClick={handleAction}>
            {action}
          </Button>
        </DialogActions>
      </Dialog>);
}
