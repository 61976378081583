import * as React from 'react';
import {useEffect} from 'react';
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import {LaunchAppPage} from "../../layout/LaunchAppPage";
import {LaunchAppPageId} from "../LaunchAppPageId";
import {LoginPanel} from "../../components/LoginPanel";
import {useUserAuthentication} from "../../../userApp/auth/useUserAuthentication";
import {slugFrom} from "../../../userApp/models";
import {useNavigation} from "../../../common/port/Navigation";
import {useLaunchAppInfrastructure} from "../../LaunchAppInfrastructure";

export function LoginCLIPage() {
  const {navigateTo} = useNavigation()
  const {launchUrls} = useLaunchAppInfrastructure()
  const {navigateToLoginCLI, initiateLoginCLI} = useUserAuthentication()
  const location = useLocation();

  useEffect(() => {
    const slug = slugFrom(queryString.parse(location.search).slug)
    if (slug) {
      initiateLoginCLI(slug)
      navigateTo(launchUrls.loginCli())
    }
  }, [location, initiateLoginCLI, launchUrls, navigateTo]);

  return <LaunchAppPage pageId={LaunchAppPageId.CliLogin} title="Login CLI" error={null} >
    <LoginPanel target="CLI" sandboxDisabled={true} onLogin={teamId => navigateToLoginCLI(teamId)}/>
  </LaunchAppPage>;
}
