import * as React from 'react';
import {useEffect} from 'react';
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {useAccountAuthentication} from "../../auth/useAccountAuthentication";

export function Logout() {
  const {logout} = useAccountAuthentication();

  useEffect(() => {
    logout()
  }, [logout])

  return <UnauthorizedPage/>;
}
