import {GetQueryOptions, queryOptions} from "../../../common/api/query";
import {Monode, RolloutTarget, VcsBranch} from "../../models";
import {UserRequestId} from "../UserRequestId";
import {GraphUrls} from "./urls";
import {DependencyGraph, RolloutGraph} from "./model";
import {useUserApiGet} from "../userApi";
import {DateTime, OrgRepoPath, VcsOwner} from "../../../common/models";

export const useRolloutGraphQuery = (params: { owner: VcsOwner, path?: OrgRepoPath, target: RolloutTarget, at?: DateTime } & GetQueryOptions) =>
    useUserApiGet<RolloutGraph>(UserRequestId.RolloutGraph, GraphUrls.rolloutGraph(params), {}, queryOptions(params))

export const useDependencyGraphQuery = (params: { path: OrgRepoPath } & GetQueryOptions) =>
    useUserApiGet<DependencyGraph>(UserRequestId.RepositoryDependencyGraph, GraphUrls.dependencyGraph(params), {}, queryOptions(params))

export const useOrgDependencyGraphQuery = (params: { owner: VcsOwner, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<DependencyGraph>(UserRequestId.OrgDependencyGraph, GraphUrls.orgDependencyGraph(params), {}, queryOptions(params))

export const useMonodeDependencyGraphQuery = (params: { path: OrgRepoPath, monode: Monode } & GetQueryOptions) =>
    useUserApiGet<DependencyGraph>(UserRequestId.MonodeDependencyGraph, GraphUrls.monodeDependencyGraph(params), {}, queryOptions(params))



