import {Box, Divider, IconButton, Slider, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {DashboardWidgetProps} from "./DashboardWidgetView";
import {DashboardWidgetPanel, useDashboardWidgetPanel} from "./DashboardWidgetPanel";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {EmbedWidgetConfig} from "../../api/dashboard/widgets/EmbedWidget";
import {Panel} from "../../../common/components/Panel";

export type EmbedWidgetPanelProps = DashboardWidgetProps<EmbedWidgetConfig>;

export function EmbedWidgetPanel({url, title, height, ...props}: EmbedWidgetPanelProps) {
  const [newUrl, setNewUrl] = useState(url);
  const [newTitle, setNewTitle] = useState(title);
  const [newHeight, setNewHeight] = React.useState(height);
  const panelProps = useDashboardWidgetPanel({...props, title: "Embed"});
  const {id, isEditing, onSave} = props

  function handleUrlChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setNewUrl(event.target.value)
  }

  function handleTitleChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setNewTitle(event.target.value)
  }

  const handleHeightChange = (event: Event, newValue: number | number[]) => {
    setNewHeight(newValue instanceof Array<Number> ? newValue[0] : newValue);
  };

  function handleSave() {
    onSave && onSave(id, {url: newUrl, title: newTitle, height: newHeight})
  }

  function handleCancel() {
    setNewUrl(url)
    setNewTitle(title)
    setNewHeight(height)
    onSave && onSave(id, {url, title, height})
  }

  return <DashboardWidgetPanel {...panelProps}>
    {
        isEditing && <Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: 1
          }}>
            <TextField fullWidth={true}
                       defaultValue={newTitle}
                       variant="standard"
                       label="Title"
                       onChange={handleTitleChange}/>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: 1
          }}>
            <TextField fullWidth={true}
                       defaultValue={newUrl}
                       label="Url"
                       variant="standard"
                       onChange={handleUrlChange}/>
          </Box>


          <Box sx={{px: 0, pt:4}}>
            <Slider
                value={newHeight}
                valueLabelDisplay="on"
                onChange={handleHeightChange}
                min={100}
                max={1000}
                aria-labelledby="input-slider"
            />

            <Typography id="input-slider" variant="body2" >
              Height
            </Typography>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "right",
          }}>
            <IconButton aria-label="save" onClick={handleSave}>
              <CheckRoundedIcon/>
            </IconButton>

            <IconButton aria-label="cancel" onClick={handleCancel}>
              <CloseRoundedIcon/>
            </IconButton>

          </Box>
          <Divider sx={{my: 1}}/>
        </Box>
    }

    <Panel header={newTitle} noPadding>
      <iframe
          scrolling="no"
          src={newUrl}
          width="100%"
          height={newHeight + 30}
          title={title}
          style={{border: "none"}}
      />
    </Panel>
  </DashboardWidgetPanel>
}
