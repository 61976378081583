import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {
  MonodePathParameter,
  OwnerPathParameter,
  RepositoryPathParameter,
  TargetPathParameter,
  usePathParams
} from "../../models/Paths";
import {pathFrom} from "../../models";
import {SystemError} from "../../../common/api/TransportLayer";
import {useCachedOrgConfigurationQuery} from "../../api/org";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {useTabControls} from "../../../common/tabs";
import {MonodeTargetPageTab, MonodeTargetPageTabProps} from "./MonodeTargetPageTab";
import {MonodeTargetPageRolloutsTab} from "./MonodeTargetPageRolloutsTab";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {Sidebar} from "../../../common/layout/Sidebar";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {LinksPanel} from "../../components/configurations/LinksPanel";
import {useMonodeTargetLinksQuery} from "../../api/configurations";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {RepoBreadcrumb} from "../../components/breadcrumbs/RepoBreadcrumb";
import {MonodeBreadcrumb} from "../../components/breadcrumbs/MonodeBreadcrumb";
import {MonodeTargetBreadcrumb} from "../../components/breadcrumbs/MonodeTargetBreadcrumb";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function MonodeTargetPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {userProfile} = useUserAuthentication()
  const {urls} = useUserAppInfrastructure()
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter & MonodePathParameter & TargetPathParameter>();
  const path = pathFrom(pathParams);
  const orgConfiguration = useCachedOrgConfigurationQuery({owner: pathParams.owner, onError: setError})
  const linksResult = useMonodeTargetLinksQuery({path, monode: pathParams.monode, target: pathParams.target})

  const tabs = useTabControls({
    defaultTab: MonodeTargetPageTab.rollouts,
    tabs: [
      {title: "Rollouts", id: MonodeTargetPageTab.rollouts},
    ]
  })

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={setError}/>
  const header = <PageHeader
      title="Target"
      breadcrumbs={[
        <OwnerBreadcrumb owner={path.org}/>,
        <RepoBreadcrumb vcs={userProfile.vcs} path={path}/>,
        <MonodeBreadcrumb vcs={userProfile.vcs} path={path} monode={pathParams.monode}/>,
        <MonodeTargetBreadcrumb path={path} monode={pathParams.monode} target={pathParams.target}/>
      ]}
      location={urls.target({
        org: pathParams.owner,
        repo: path.repo,
        monode: pathParams.monode,
        target: pathParams.target
      })}
      tabs={tabs}
  />

  const sidebar = <Sidebar>
    <QueryLoader height={150} result={linksResult} onError={setError} onLoaded={data =>
        <LinksPanel links={data}/>
    }/>
  </Sidebar>


  const title = path.org + "/" + path.repo + "/" + pathParams.monode;

  const tabsProps: MonodeTargetPageTabProps = {
    path,
    owner: pathParams.owner,
    monode: pathParams.monode,
    repository: pathParams.repository,
    target: pathParams.target,
    userProfile,
    orgConfiguration: orgConfiguration,
    onError: setError,
  }

  return <UserAppPage pageId={UserPageId.MonodeTarget} title={title} contentHeader={header} sidebar={sidebar} banner={banner}
                      error={error} profile={userProfile}>
    <PageTab selectedId={tabs.selected} id={MonodeTargetPageTab.rollouts}>
      <MonodeTargetPageRolloutsTab {...tabsProps}/>
    </PageTab>
  </UserAppPage>

}
