import * as React from "react";
import {AccountConfigurationPageTabProps} from "./AccountConfigurationPageTab";
import {ConfigurationPanel, ConfigurationStack} from "../../../common/components/configuration";
import {SecurityConfigurationPanel} from "../../components/configuration/SecurityConfigurationPanel";
import {VCSConfigurationPanel} from "../../components/configuration/VCSConfigurationPanel";
import {CustomerOnboardingMode} from "../../api/account/model";
import {Skeleton} from "@mui/material";
import {useAccountConfigurationQuery} from "../../api/account";
import {TeamURLPanel} from "../../components/configuration/TeamURLPanel";

export function AccountConfigurationPageInstallationTab({onError}: AccountConfigurationPageTabProps) {
  const {data: config} = useAccountConfigurationQuery({onQueryError: onError})
  if (!config) return <Skeleton height={50}/>
  if (config && config.kind === "error") return <></>

  const readOnly = config.data.onboardingMode !== CustomerOnboardingMode.Manual

  return <ConfigurationStack>
    <ConfigurationPanel>
      <VCSConfigurationPanel disabled={readOnly} config={config.data}/>
    </ConfigurationPanel>

    {
        !readOnly && <ConfigurationPanel>
          <SecurityConfigurationPanel onError={onError}/>
        </ConfigurationPanel>
    }
    {
        <ConfigurationPanel>
          <TeamURLPanel onError={onError} config={config.data}/>
        </ConfigurationPanel>
    }
  </ConfigurationStack>
}
