import {
    MetaQuery,
    Monode,
    OriginUri, PipelineName,
    RolloutTarget,
    VcsBranch,
    VcsEvent,
    VcsRepoFilePath,
    VcsRunId
} from "../models";
import {UrlQueryParam} from "../../common/urls";
import {TwoFactorCode} from "../api/user/model";
import {DashboardId} from "../api/dashboard/model";

import {DateTime, OrgRepoPath} from "../../common/models";
import {OAuthSlug} from "../../common/models";

export function atParam(value: DateTime): UrlQueryParam {
    return {name: "at", value: value};
}

export const dashboardIdParamName = "dashboardId";
export const branchParamName = "branch";

export function pathParam(path: OrgRepoPath): UrlQueryParam {
    return {name: "path", value: path.org + "/" + path.repo};
}

export function filePathParam(value: VcsRepoFilePath): UrlQueryParam {
    return {name: "path", value: value};
}

export function lastRunId(value: VcsRunId): UrlQueryParam {
    return {name: "lastRunId", value: value};
}

export function limit(value: number): UrlQueryParam {
    return {name: "limit", value: value.toString()};
}

export function eventTypes(value: VcsEvent): UrlQueryParam {
    return {name: "events", value: value};
}

export function monodeParam(value: Monode): UrlQueryParam {
    return {name: "monode", value: value};
}

export function targetParam(value: RolloutTarget): UrlQueryParam {
    return {name: "target", value: value};
}

export function metaFilterParam(value: MetaQuery): UrlQueryParam {
    return {name: "meta", value: value};
}

export function crosscheck(value: Boolean): UrlQueryParam {
    return {name: "crosscheck", value: value.toString()};
}

export function twoFaParam(value: TwoFactorCode): UrlQueryParam {
    return {name: "2fa", value: value};
}

export function slugParam(value: OAuthSlug): UrlQueryParam {
    return {name: "slug", value: value};
}

export function originUriParam(value: OriginUri): UrlQueryParam {
    return {name: "originUri", value: value};
}

export function pipelineParam(value: PipelineName): UrlQueryParam {
    return {name: "pipeline", value: value};
}

export function dashboardIdParam(value: DashboardId): UrlQueryParam {
    return {name: dashboardIdParamName, value: value};
}

export function branchParam(value: VcsBranch): UrlQueryParam {
    return {name: branchParamName, value: value};
}
