import {TwoFactorCode, UserOption} from "./model";
import {buildUrl, UrlQueryParam} from "../../../common/urls";
import {twoFaParam} from "../../utils/urlParams";

export class UserUrls {
    static userProfile() {
        return `/user`;
    }

    static userEmails() {
        return `/user/emails`;
    }

    static userEmailRemove() {
        return `/user/email/remove`;
    }

    static emailRegistrationInitiate() {
        return `/user/email/registration/initiate`;
    }

    static emailRegistrationComplete() {
        return `/user/email/registration/complete`;
    }

    static optionOn(params: { option: UserOption }) {
        return `/user/settings/option/${params.option}/on`;
    }

    static optionOff(params: { option: UserOption }) {
        return `/user/settings/option/${params.option}/off`;
    }

    static registerTwoFactorDevice(params: { code: TwoFactorCode }) {
        const query = Array<UrlQueryParam>()
        query.push(twoFaParam(params.code));
        return buildUrl("/user/2fa/register", query)
    }

    static generateTwoFactorQRCode(params: { size: number }) {
        return `/user/2fa/generate/${params.size}`;
    }
}
