import {LinkProps} from "@mui/material/Link";
import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Box, ListItemButton} from "@mui/material";
import {Link} from "./Link";

export type MenuItemProps = {
  text: string
  indent?: string
  icon?: React.ReactNode
} & LinkProps;

export function MenuItem({text, indent, icon = undefined, href, ...rest}: MenuItemProps) {
  return (
      <Link
          {...rest}
          underline="none"
          color="text.primary"
          href={href}
      >
        <ListItemButton>
          <Box sx={{paddingLeft: indent}}/>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : <></>}
          <ListItemText primary={text}/>
        </ListItemButton>
      </Link>
  );
}
