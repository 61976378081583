import {styled} from "@mui/material/styles";
import {Box, BoxProps, Grid, Tab} from "@mui/material";
import * as React from "react";

export interface StyledTabProps {
  label: string;
  value: string;
  tabId: string;
  tabGroup: string;
  onClick?: () => void;
}

export const StyledTab = styled(({tabId, tabGroup, ...props}: StyledTabProps) => {
  const tabProps = tabId && tabGroup
      ? {id: `${tabGroup}-tab-${tabGroup}`, 'aria-controls': `${tabGroup}-tabpanel-${tabGroup}`}
      : {};

  return (<Tab {...tabProps} disableRipple {...props} />)
})(({theme}) => ({
  minWidth: 0,
  margin: 0,
  marginRight: theme.spacing(2),
  padding: 0,
}));

export type StyledTabContentProps = {
  selectedId: string;
  group: string;
} & BoxProps


export function StyledTabContent({children, selectedId, id, group, ...props}: StyledTabContentProps) {
  return (
      <Grid
          item
          role="tabpanel"
          hidden={selectedId !== id}
          id={`${group}-tabpanel-${id}`}
          aria-labelledby={`${group}-tab-${id}`}
          style={{width: "100%"}}

      >
        {selectedId === id && (
            <Box data-test={`page-tab-${id}`} {...props}>
              {children}
            </Box>
        )}
      </Grid>
  );
}
