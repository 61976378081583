import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography
} from "@mui/material";
import {CancelButton} from "../../../common/components/navigation/CancelButton";
import {EmailInput} from "./EmailInput";
import {useEmailRegistrationInitiateMutation} from "../../api/user";

import {EmailAddress} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";


export type RegisterEmailDialogProps = {
  onSubmit: () => void
  onCancelled: () => void
  onSuccessful: (email: EmailAddress) => void
} & OnError

export function RegisterEmailDialog(
    {
      onCancelled,
      onSubmit,
      onSuccessful,
      onError
    }: RegisterEmailDialogProps) {
  const {config, analytics} = useUserAppInfrastructure()

  useEffect(() => {
    analytics.dialogview("Register Email")
  }, [analytics]);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const handleSetValidEmail = (value: EmailAddress) => {
    setValidEmail(true)
    setEmail(value);
  };

  const handleSetInvalidEmail = (value: string) => {
    setValidEmail(false)
    setEmail(value);
  };

  const initiateEmailRegistration = useEmailRegistrationInitiateMutation()
  const handleAction = useCallback(async () => {
    onSubmit && onSubmit()
    const response = await initiateEmailRegistration.mutateAsync({email});
    const isSuccess = response.kind === "ok";

    if (isSuccess) {
      onSuccessful(email)
      analytics.event("User", "Register Email", `Register email successful`)
      setEmail("")
    } else {
      analytics.event("User", "Register Email", `Register email failure`)
      onError && onError(response.error)
      setEmail("")
    }
  }, [analytics, email, onError, onSubmit, onSuccessful, initiateEmailRegistration])

  return (
      <Dialog maxWidth="xs" fullWidth={true} open={true} onClose={onCancelled}>
        <DialogTitle>
          Register Email
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText component={'span'} color="primary">
            <Typography variant="body1">
              Enter your email address to register with {config.appName}.
              After registering check your email for further instructions.
            </Typography>
          </DialogContentText>

          <DialogContentText color="primary" sx={{mt: 3,mb: 3}}>
            <EmailInput value={email} fullWidth={true} onValidEmail={handleSetValidEmail} onInvalidEmail={handleSetInvalidEmail}/>
          </DialogContentText>
        </DialogContent>

        <Divider/>

        <DialogActions>
          <CancelButton onClick={onCancelled}/>
          {
            <Button aria-label="Register" variant="contained" disabled={!validEmail} autoFocus
                    onClick={handleAction}>
              Register
            </Button>
          }
        </DialogActions>
      </Dialog>);
}
