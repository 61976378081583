import {useRolloutPipelineMutation} from "../../api/pipelines";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Commit, Pipeline} from "../../api/pipelines/model";
import {RolloutTarget} from "../../models";
import {OnError} from "../../../common/api/TransportLayer";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField
} from "@mui/material";
import {TwoFactorCodeInput} from "../user/TwoFactorCodeInput";
import {RolloutTargetSelect} from "./RolloutTargetSelect";
import {Target} from "../../api/rollouts/model";
import {CancelButton} from "../../../common/components/navigation/CancelButton";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type RolloutDialogProps = {
  pipeline: Pipeline
  commit: Commit
  targets: RolloutTarget[]
  onApproved: () => void
  onCancelled: () => void
  onSuccessful: (target: RolloutTarget) => void
} & OnError

export function RolloutDialog(
    {
      pipeline,
      commit,
      targets,
      onCancelled,
      onApproved,
      onSuccessful,
      onError
    }: RolloutDialogProps) {
  const {analytics} = useUserAppInfrastructure()

  useEffect(() => {
    analytics.dialogview("Rollout")
  }, [analytics]);

  const [runCrosscheck, setRunCrosscheck] = useState(true);
  const [version, setVersion] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [target, setTarget] = useState<Target | undefined>();

  function onChangeTwoFactor(value: string) {
    if (value.length <= 6) setTwoFactorCode(value);
    twoFactorCode && setDisabled(value.length < 6)
  }

  const handleSetTwoFactorCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(twoFactorCode.length < 6)
    onChangeTwoFactor(event.target.value.replace(/[^0-9]/g, ''));
  };

  const handleSetVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVersion(event.target.value);
  };

  const handleSetTarget = (target?: Target) => {
    setTarget(target)
    target?.rollout === "2fa" && setDisabled(twoFactorCode.length < 6)
  };

  const rolloutPipeline = useRolloutPipelineMutation(pipeline.path)

  const handleAction = useCallback(async () => {
    const response = await rolloutPipeline.mutateAsync({
          request: {
            path: pipeline.path,
            target: target!!.target,
            monode: pipeline.name,
            sha: commit.sha,
            branch: pipeline.branch,
            crosscheck: runCrosscheck,
            version: version.length > 0 ? version : commit.sha
          },
          twoFactorCode: twoFactorCode.length > 0 ? twoFactorCode : undefined
        }
    );
    const isSuccess = response.kind === "ok";

    if (isSuccess) {
      target && analytics.event("User", "Rollout", `Rollout to ${target.target}`)
      onSuccessful && target && onSuccessful(target.target)
      onApproved && onApproved()
    } else {
      analytics.event("User", "Rollout", `Rollout to ${target?.target} Failure`)
      onError && onError(response.error)
      setTwoFactorCode("")
    }
  }, [
    analytics, commit.sha, version, pipeline.branch, pipeline.name, pipeline.path, runCrosscheck, twoFactorCode,
    target, onError, onSuccessful, onApproved, rolloutPipeline])

  return (
      <Dialog fullScreen={false} open={true} onClose={onCancelled}>
        <DialogTitle>
          Rollout commit ({commit.sha})
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText color="primary">
            Would you like to Rollout {pipeline.name} @ {pipeline.path.org}/{pipeline.path.repo})?
          </DialogContentText>
          <RolloutTargetSelect path={pipeline.path}
                               targets={targets}
                               onTarget={handleSetTarget}/>
          <DialogContentText color="primary">
            Run Crosscheck <Checkbox checked={runCrosscheck}
                                     onChange={event => setRunCrosscheck(event.target.checked)}/></DialogContentText>
          {
              target && target.rollout === "2fa" &&
              <TwoFactorCodeInput value={twoFactorCode} onChange={handleSetTwoFactorCode}/>
          }
          <TextField id="version"
                     value={version} onChange={handleSetVersion}
                     label="Version number"
                     variant="outlined"/>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onCancelled}/>
          {
              target &&
              <Button aria-label={`Rollout to ${target}`} disabled={disabled} variant="contained" autoFocus
                      onClick={handleAction}>
                Rollout {target.target}
              </Button>
          }
        </DialogActions>
      </Dialog>);
}
