import React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {useDashboardsQuery} from "../../api/dashboard";
import {OnError} from "../../../common/api/TransportLayer";
import {Box, Divider, IconButton, List, ListItem, SxProps, Typography} from "@mui/material";
import {Panel} from "../../../common/components/Panel";
import {DashboardId, DashboardName} from "../../api/dashboard/model";
import {useSnackbar} from "notistack";
import {Link} from "../../../common/components/navigation/Link";
import {DeleteDashboardDialog} from "./DeleteDashboardDialog";
import {snackOptions} from "../../../common/snackbar";
import {AddDashboardsHint} from "../hints";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {CreateDashboardDialog} from "./CreateDashboardDialog";
import {VcsOwner} from "../../../common/models";
import {useNavigation} from "../../../common/port/Navigation";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type OrgDashboardListPanelProps = {
  owner: VcsOwner
} & OnError;

export function OrgDashboardListPanel({onError, owner}: OrgDashboardListPanelProps) {
  const dashboardsQuery = useDashboardsQuery({owner})
  const {enqueueSnackbar} = useSnackbar();
  return <QueryLoader result={dashboardsQuery} onError={onError} onLoaded={data =>
      <Panel data-test="org-dashboards" header="Dashboards" noPadding>
        {data.length === 0 && <AddDashboardsHint/>}
        <List sx={{width: "100%"}}>
          {data.map((dashboard, index) => {
                return <Box key={dashboard.id}>
                  <DashboardListRow owner={owner} id={dashboard.id} name={dashboard.name}/>
                  {index !== data.length - 1 && <Divider sx={{mt: 1, mb: 1}}/>}
                </Box>
              }
          )}
          <Divider sx={{mb: 1, mt:1}}/>
          <Box sx={{display: "flex", p: 2, justifyContent: "right", alignItems: "right", width: "100%"}}>
            <CreateDashboardDialog owner={owner}
                                   onSuccessful={dashboard => enqueueSnackbar(`Dashboard created ${dashboard.name}`, snackOptions(true))}
                                   onError={() => enqueueSnackbar(`Failed to create dashboard`, snackOptions(false))}
            />
          </Box>
        </List>

      </Panel>
  }/>
}

export type DashboardListRowProps = {
  owner: VcsOwner
  id: DashboardId
  name: DashboardName
  sx?: SxProps
}

export function DashboardListRow({owner, id, name, sx}: DashboardListRowProps) {
  const {enqueueSnackbar} = useSnackbar()
  const {navigateTo} = useNavigation()
  const {urls} = useUserAppInfrastructure()


  return <ListItem sx={sx}>
    <Box data-test="dashboard-row" sx={{display: "flex", flexDirection: "row", width: "100%"}} alignItems="center">
      <Typography variant="body1" noWrap sx={{
        flexGrow: 1,
        textOverflow: "ellipsis"
      }}>
        <Link data-test="dashboard-link" href={urls.orgDashboard(owner, id)}>
          {name}
        </Link>
      </Typography>

      <IconButton onClick={() => navigateTo(urls.editOrgDashboard(owner, id))}>
        <EditRoundedIcon/>
      </IconButton>

      <DeleteDashboardDialog owner={owner}
                             dashboardId={id}
                             dashboardName={name}
                             onSuccessful={(dashboard) => {
                               enqueueSnackbar(`Dashboard deleted ${dashboard.name}`, snackOptions(true))
                             }}
                             onError={() => enqueueSnackbar(`Failed to delete dashboard`, snackOptions(false))}/>

    </Box>
  </ListItem>
}

