import {OAuthProvider} from "./common/models";
import {isSandboxTeamId, TeamId} from "./common/models/TeamId";

export type OAuthProviderUrlMapping = Record<OAuthProvider, string>

export class EnvironmentConfig {
    appName: any
    badgeBaseUrl: string
    docsBaseUrl: string
    accountApiBaseUrl: string
    accountAppBaseUrl: string
    userAppBaseUrl: string
    launchAppBaseUrl: string
    websiteBaseUrl: string
    userApiBaseUrl: string
    sandboxApiBaseUrl: string
    cliApiBaseUrl: string
    signUpBaseUrls: OAuthProviderUrlMapping
    gaMeasurementId: string

    apiUri(teamId: TeamId): string {
        return isSandboxTeamId(teamId) ? this.sandboxApiBaseUrl : this.userApiBaseUrl
    }

    constructor() {
        this.appName = "Monopolis"
        this.badgeBaseUrl = process.env.REACT_APP_BADGE_URL as string;
        this.docsBaseUrl = process.env.REACT_APP_DOCS_URL as string;
        this.accountApiBaseUrl = process.env.REACT_APP_ACCOUNT_API_URL as string;
        this.accountAppBaseUrl = process.env.REACT_APP_ACCOUNT_APP_URL as string;
        this.userAppBaseUrl = process.env.REACT_APP_USER_APP_URL as string;
        this.launchAppBaseUrl = process.env.REACT_APP_LAUNCH_APP_URL as string;
        this.websiteBaseUrl = process.env.REACT_APP_WEBSITE_URL as string;

        this.userApiBaseUrl = process.env.REACT_APP_USER_APP_API_URL as string;
        this.cliApiBaseUrl = process.env.REACT_APP_CLI_API_URL as string;
        this.sandboxApiBaseUrl = process.env.REACT_APP_SANDBOX_API_URL as string;

        this.signUpBaseUrls = {
            github: "https://github.com/marketplace/monopolis-cloud" as string,
            gitlab: "https://monopolis.cloud" as string,
            sandbox: "https://monopolis.cloud" as string,
        };

        this.gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID as string;
    }
}

