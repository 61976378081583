import * as React from 'react';
import {ReactNode} from 'react';
import {Link as MUILink, Tooltip} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {LinkProps} from "@mui/material/Link";
import {useCommonAppInfrastructure} from "../../CommonAppInfrastructure";

export function Link({tooltip, children, href, tooltipEnterDelay, tooltipNextEnterDelay, ...rest}: WebLinkProps) {
  const {navigation} = useCommonAppInfrastructure()
  const target = navigation.navigationTargetFor(href!!);
  return (
      <Tooltip arrow disableHoverListener={!tooltip} title={tooltip || ""} placement="top"
               enterDelay={tooltipEnterDelay} enterNextDelay={tooltipNextEnterDelay}>
        {target.isExternal
            ? <MUILink underline="hover" {...rest}
                       style={{textDecorationThickness: "1px", alignItems: "center"}}
                       href={target.href}>
              {children}
            </MUILink>
            : <MUILink component={RouterLink} underline="hover" {...rest}
                       style={{textDecorationThickness: "1px", alignItems: "center"}}
                       to={target.href}>
              {children}
            </MUILink>
        }
      </Tooltip>
  )
}

export type WebLinkProps = {
  tooltip?: string | ReactNode;
  tooltipEnterDelay?: number
  tooltipNextEnterDelay?: number
} & LinkProps
