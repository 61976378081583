import React, {useState} from 'react';
import {monopolisConstraints} from "../../../common/styles/constraints";
import {closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, rectSortingStrategy, SortableContext} from "@dnd-kit/sortable";
import {DragEndEvent} from "@dnd-kit/core/dist/types";
import {asDashboardWidgetProps, DashboardWidgetProps, DashboardWidgetView} from "./DashboardWidgetView";
import {Box, Divider, IconButton, TextField, Typography} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import {OnError} from "../../../common/api/TransportLayer";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {DashboardName, DashboardWidget, DashboardWidgetType, DashboardWidgetTypes} from "../../api/dashboard/model";
import {Menu, MenuActionDefinition} from "../../../common/components/navigation/Menu";
import {capitalize, humanise} from "../../../common/strings";
import {EditDashboardsHint} from "../hints";
import {VcsOwner} from "../../../common/models";

export type DashboardGridProps = {
  owner?: VcsOwner
  name: DashboardName,
  widgets: DashboardWidgetProps<any>[]
  columns: number
  showName?: boolean,
  isEditable?: boolean,
  isEditing?: boolean,
  isWidgetAllowed?: (type: DashboardWidgetType, widgets: DashboardWidget[]) => boolean,
  createWidget?: (type: DashboardWidgetType) => DashboardWidget,
  onSave?: (name: DashboardName, widgets: DashboardWidgetProps<any>[]) => void
  onChangingLayout?: (changingLayout: boolean) => void
} & OnError

export function DashboardGrid({
                                name,
                                widgets,
                                columns,
                                showName,
                                isEditable,
                                isEditing,
                                isWidgetAllowed,
                                createWidget,
                                onSave,
                                onChangingLayout,
                                onError
                              }: DashboardGridProps) {
  const editable = isEditable !== undefined ? isEditable : true
  const [items, setItems] = useState(widgets);
  const [newName, setNewName] = useState(name);
  const [changingLayout, setChangingLayout] = useState(isEditing || false);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const actualWidgets = changingLayout ? items : widgets;

  function adjustWidth(width?: number) {
    if (width)
      return width > columns ? columns : width < 3 ? 3 : width
    else
      return columns
  }

  function handleNameChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setNewName(event.target.value)
  }

  function handleToggleChangeLayout() {
    const newChangingLayout = !changingLayout;
    if (newChangingLayout) {
      setItems(widgets)
      setNewName(name)
    } else {
      onSave && onSave(newName, items)
    }
    setChangingLayout(newChangingLayout)
    onChangingLayout && onChangingLayout(newChangingLayout)
  }

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    if (over && active && active.id !== over.id) {
      // Look at performance implications when dragging on large dashboards
      setItems((items) => {
        const oldIndex = items.findIndex(widget => widget.id === active.id.toString());
        const newIndex = items.findIndex(widget => widget.id === over.id.toString());
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function handleResizeItem(id: string, width: number) {
    setItems((items) => items.map(item => item.id !== id ? item : {...item, width: adjustWidth(width)}));
  }

  function handleDeleteItem(id: string) {
    setItems((items) => items.filter(item => item.id !== id));
  }

  function handleEditItem(id: string) {
    setItems((items) => items.map(item => item.id !== id ? item : {
      ...item,
      isEditing: item.isEditing !== undefined ? (item.isEditing ? undefined : true) : true
    }))
  }

  function handleSaveItem(id: string, config: any) {
    setItems((items) => items.map(item => item.id !== id ? item : {
      ...item,
      isEditing: undefined,
      ...config
    }))
  }

  function addWidgetActions(): MenuActionDefinition[] {
    return DashboardWidgetTypes
        .filter(type => isWidgetAllowed ? isWidgetAllowed(type, actualWidgets) : true)
        .sort()
        .map(type => ({
          id: "add-"+type,
          text: capitalize(humanise(type).trim()),
          onAction: () => handleAddWidget(type)
        }))

  }

  function handleAddWidget(type: DashboardWidgetType) {
    createWidget && setItems((items) => [asDashboardWidgetProps(createWidget(type)), ...items])
  }

  return (
      <Box>
        <Box sx={{
          my: 1,
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        }}>

          {
              showName === true && (
                  changingLayout
                      ? <TextField fullWidth={true}
                                   defaultValue={newName}
                                   variant="standard"
                                   label="Name"
                                   onChange={handleNameChange}/>
                      : <Typography noWrap variant="h5" sx={{flexGrow: 1, textOverflow: "ellipsis"}}>
                        {name}
                      </Typography>
              )
          }

          {
              changingLayout && editable && createWidget && <Menu showMenuForSingleItem={true}
                                                                    icon={<AddRoundedIcon/>}
                                                                    actions={addWidgetActions()}/>
          }

          {
              changingLayout && createWidget && <Divider orientation="vertical" sx={{height: 40, mx: 2}}/>
          }

          {
              !changingLayout && editable && <IconButton onClick={handleToggleChangeLayout}>
                <EditRoundedIcon/>
              </IconButton>
          }

          {
              changingLayout && editable && <IconButton onClick={handleToggleChangeLayout}>
                <CheckRoundedIcon/>
              </IconButton>
          }
        </Box>

        {
          actualWidgets.length === 0
              ? (editable && <EditDashboardsHint/>)
              : <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
              >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                  <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${columns}, 1fr)`,
                        gridGap: monopolisConstraints.gridSpacing * 6,
                      }}
                  >
                    {
                      actualWidgets.map(item =>
                          <DashboardWidgetView {...item}
                                               key={item.id}
                                               width={adjustWidth(item.width)}
                                               isChangingLayout={editable && changingLayout}
                                               onDelete={handleDeleteItem}
                                               onEdit={handleEditItem}
                                               onSave={handleSaveItem}
                                               onResize={handleResizeItem}
                                               onError={onError}/>)
                    }
                  </div>

                </SortableContext>
              </DndContext>

        }
      </Box>
  );
}
