import * as React from 'react';
import {Box, ClickAwayListener, Divider, Fade, IconButton, List, Paper, Popper, Typography} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import {monopolisTheme} from "../../../common/styles/theme";
import {MarkdownTOCNode} from "../../utils/markdown";
import ListItemText from "@mui/material/ListItemText";
import {DocumentationMenuItem} from "./DocumentationMenuItem";

export type DocumentationTOCItem = MarkdownTOCNode

export function DocumentationTOCMenu({disabled, selected, items, onItem}: DocumentationTOCMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelected = (item: DocumentationTOCItem) => () => {
    handleClose()
    onItem(item)
  };

  return <>
    <IconButton aria-label="Table of contents" color="primary" disabled={disabled} onClick={handleClick}>
      <ListIcon/>
    </IconButton>

    <Popper transition={true} placement="bottom-end" open={open} anchorEl={anchorEl}
            style={{paddingTop: 5}}>
      {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper variant="outlined" sx={{
                borderWidth: 4,
                backgroundColor: monopolisTheme.palette.secondary.light,
                maxHeight: 300,
                maxWidth: 400,
                overflow: 'auto'
              }}>

                <Typography sx={{py: 2, px: 2}} fontWeight={500} variant="body1">Table of Contents</Typography>
                <Divider/>

                <List>{items.map(item =>
                    <DocumentationMenuItem
                        key={item.level + item.id}
                        selected={selected?.id === item.id}
                        onSelect={handleItemSelected(item)}>
                      <Box sx={{paddingLeft: `${(item.level - 1) * 25}px`}}/>
                      <ListItemText primary={item.title}/>
                    </DocumentationMenuItem>
                )}</List>
              </Paper>
            </Fade>
          </ClickAwayListener>)}
    </Popper>
  </>
}

export type DocumentationTOCMenuProps = {
  selected?: DocumentationTOCItem,
  items: DocumentationTOCItem[]
  onItem: (item: DocumentationTOCItem) => void
  disabled?: boolean
}
