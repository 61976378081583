import {
  ApiPluginConfig,
  ApiPluginConfigView,
  PluginsConfig,
  PluginsConfigView,
  PluginType
} from "../../api/configurations/model";
import * as React from "react";
import {Monode, VcsBranch} from "../../models";
import {DocumentationPanel} from "../documentation/DocumentationPanel";
import {OpenApiSpecPanel} from "./OpenApiSpecPanel";
import {PageTab} from "../../../common/components/navigation/PageHeader";
import {OnError} from "../../../common/api/TransportLayer";
import {TabControl} from "../../../common/tabs";
import {Panel} from "../../../common/components/Panel";

import {OrgRepoPath} from "../../../common/models";
import {VcsProvider} from "../../../common/models";

export type PluginTabProps = {
  title: string,
  id: PluginTabs,
  type: PluginType,
  config: PluginsConfigView,
  onSelected?: (tab: TabControl) => void,
}

export enum PluginTabs {
  documentation = "documentation",
  api = "api",
}

export function pluginTabsFrom(config: PluginsConfigView): PluginTabProps[] {
  const tabs = Array<PluginTabProps>()

  config.plugins.documentation && tabs.push({
    id: PluginTabs.documentation,
    title: "Documentation",
    type: PluginType.documentation,
    config: config,
  })

  config.plugins.api && tabs.push({
    id: PluginTabs.api,
    title: "API",
    type: PluginType.api,
    config: config,
  })

  return tabs
}

export type PluginTabsContentProps = {
  id: string
  selectedId: string
  config: PluginsConfig
  type: PluginType,
  vcs: VcsProvider
  path: OrgRepoPath
  monode: Monode
  branch: VcsBranch
} & OnError

export function PluginTabContent({selectedId, id, type, onError, ...props}: PluginTabsContentProps) {
  if (type === PluginType.api && props.config.api)
    return <PageTab selectedId={selectedId} id={id}>
      <Panel data-test="openapi-spec">
        <OpenApiSpecPanel config={asApiConfigView({...props, config: props.config.api})}/>
      </Panel>
    </PageTab>

  if (type === PluginType.documentation && props.config.documentation)
    return <PageTab selectedId={selectedId} id={id}>
      <DocumentationPanel {...props} documentation={props.config.documentation} branch={props.branch}
                          onError={onError}/>
    </PageTab>


  return <>{JSON.stringify(props)}</>
}

function asApiConfigView(props: { config: ApiPluginConfig, path: OrgRepoPath, monode: Monode, branch: VcsBranch }): ApiPluginConfigView {
  return {config: props.config, path: props.path, monode: props.monode, branch: props.branch} as ApiPluginConfigView
}
