// noinspection JSUnusedLocalSymbols,SpellCheckingInspection

import * as React from 'react';
import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {Chart} from './Chart';
import {Deposits} from './Deposits';
import {Page} from "../../../common/layout/Page";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';
import {Box, Typography} from "@mui/material";
import {DragEndEvent} from "@dnd-kit/core/dist/types/events";
import {restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {Monode} from "../../models";
import Button from "@mui/material/Button";
import {DependencyGraphPanel} from "../graphs/DependencyGraphPanel";
import {MonodeGraphEdge, MonodeGraphNode} from "../../api/graphs/model";
import {TimelineDate, TimelineWeight} from "../events/EventsDatesView";
import {addDaysToDate} from "../../../common/dateTime";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"
import {AuditEventSpanSeverity, AuditEventSpanStatus, AuditEventType} from "../../api/events/model";
import {MarkdownPanel} from "../documentation/MarkdownPanel";
import {EventsTimeline} from "../events/timeline/EventsTimeline";
import {exampleTimelineEvents} from "./ExampleTimelineEvents";
import UploadGallery from "./UploadGallery";
import {DashboardGrid} from "../dashboard/DashboardGrid";
import {textWidget} from "../dashboard/TextWidgetPanel";
import {TextWidgetVariant} from "../../api/dashboard/widgets/TextWidget";
import {Panel} from "../../../common/components/Panel";

import {ConsoleAnalytics} from "../../../common/adapter/analytics/ConsoleAnalytics";

import {VcsProvider} from "../../../common/models";


// import { oneDark as codeHighlightStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
// import { idea as codeHighlightStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const markdown = `
A paragraph with *emphasis* and **strong importance**.

# Header
## Header
### Header
#### Header

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

1. one
2. two
3. three

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
| cell1 | cell2 |

---








JS
~~~js
console.log('It works!')
~~~



Kotlin
~~~kotlin
package org.http4k.connect.amazon.ses.model

import dev.forkhandles.values.Maskers.obfuscated
import dev.forkhandles.values.NonBlankStringValueFactory
import dev.forkhandles.values.StringValue

class EmailAddress private constructor(value: String) : StringValue(value.lowercase(), obfuscated()) {
    companion object : NonBlankStringValueFactory<EmailAddress>(::EmailAddress)
}
~~~



Ruby
~~~ruby
require 'redcarpet'
markdown = Redcarpet.new("Hello World!")
puts markdown.to_html
~~~
`

export type SIP = {
  id: string
}

function node(monode: Monode): MonodeGraphNode {
  return {
    path: {
      org: "monopolis-cloud",
      repo: "/monopolis-test"
    },
    monode: monode,
    spec: {
      type: "generic"
    }
  }
}

function edge(source: Monode, target: Monode): MonodeGraphEdge {
  return {source, target}
}

function SortableItem(props: SIP) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
      <Paper sx={{my: 1, p: 1}} ref={setNodeRef} style={style}>
        <Typography><DragIndicatorIcon {...attributes} {...listeners}/> Item {props.id}</Typography>
      </Paper>
  );
}

const DisableTryItOutPlugin = function () {
  return {
    statePlugins: {
      spec: {
        wrapSelectors: {
          allowTryItOutFor: () => () => false
        }
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function monodeSeries(name: string) {
  // const actualName = "events"
  const actualName = name
  return {
    name: actualName,
    data: [
      {
        x: actualName,
        meta: "dddd",
        strokeColor: "red",
        fillColor: "#E91E63",
        y: [
          new Date('2019-03-05').getTime(),
          new Date('2019-03-08').getTime()
        ]
      },

      {
        x: actualName,
        meta: name,
        y: [
          new Date('2019-03-09').getTime(),
          new Date('2019-03-16').getTime()
        ]
      },
      {
        x: actualName,
        y: [
          new Date('2019-03-01').getTime(),
          new Date('2019-03-03').getTime()
        ],
      }
    ]
  };
}

export function Dashboard() {
  const [items, setItems] = useState(['1', '2', '3']);
  const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
  );

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over!!.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id as string);
        const newIndex = items.indexOf(over!!.id as string);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }


  // noinspection JSMismatchedCollectionQueryUpdate
  let timelineDates: TimelineDate[] = []
  for (let i = 0; i < 60; i++) {
    const number: TimelineWeight = (Math.floor(Math.random() * 6)) as TimelineWeight;
    timelineDates.push({
      date: addDaysToDate("2022-03-01", i),
      weight: (number as TimelineWeight),
      events: [
        {
          dateTime: '2020-11-23T22:00:43Z',
          title: "Deployed xyz " + (Math.floor(Math.random() * 100)),
          type: AuditEventType.Rollout,
          status: AuditEventSpanStatus.Closed,
          severity: AuditEventSpanSeverity.Success,
        }, {
          dateTime: '2020-11-23T22:00:42Z',
          title: "Deployed second " + (Math.floor(Math.random() * 100)),
          type: AuditEventType.Rollout,
          status: AuditEventSpanStatus.Closed,
          severity: AuditEventSpanSeverity.Success,
        }, {
          dateTime: '2020-11-23T22:00:41Z',
          title: "Deployed another " + (Math.floor(Math.random() * 100)),
          type: AuditEventType.Rollout,
          status: AuditEventSpanStatus.Closed,
          severity: AuditEventSpanSeverity.Success,
        },
        {
          dateTime: '2020-11-23T22:00:43Z',
          title: "Deployed xyz " + (Math.floor(Math.random() * 100)),
          type: AuditEventType.Rollout,
          status: AuditEventSpanStatus.Closed,
          severity: AuditEventSpanSeverity.Success,
        }, {
          dateTime: '2020-11-23T22:00:42Z',
          title: "Deployed second " + (Math.floor(Math.random() * 100)),
          type: AuditEventType.Rollout,
          status: AuditEventSpanStatus.Closed,
          severity: AuditEventSpanSeverity.Success,
        }, {
          dateTime: '2020-11-23T22:00:41Z',
          title: "Deployed another " + (Math.floor(Math.random() * 100)),
          type: AuditEventType.Rollout,
          status: AuditEventSpanStatus.Closed,
          severity: AuditEventSpanSeverity.Success,
        }
      ]
    })
  }

  return (<Page analytics={new ConsoleAnalytics()} title="Dashboard" error={null} >
        <Grid container spacing={3}>
          {/* Chart */}

          <Grid item xs={12} md={8} lg={9}>
            <Panel header={"Some chart"}>
              <iframe
                  src="https://chart-embed.service.newrelic.com/herald/79d05747-9fed-445b-84a4-f98e629a2ce0?height=400px&timepicker=true"
                  width="100%"
                  height={450}
                  title='A youtube video on React hooks'
                  style={{border: "none"}}
              />
            </Panel>
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <EventsTimeline events={exampleTimelineEvents}/>
          </Grid>

          <Grid item xs={12} md={8} lg={9} m={2}>
            <DashboardGrid name="Test" columns={16} onError={error => console.log(error)} widgets={[
              textWidget({id: "0", text: "Some text", variant: TextWidgetVariant.h2}),
            ]}/>
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <UploadGallery/>
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <MarkdownPanel content={markdown}/>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <SwaggerUI
                tryItOutEnabled={false}
                showExtensions={true}
                docExpansion={"list"}
                plugins={[
                  DisableTryItOutPlugin
                ]}
                // url="https://petstore3.swagger.io/api/v3/openapi.json"
                url="https://petstore3.swagger.io/api/v3/openapi.yaml"
            />
          </Grid>

          <Grid item xs={12} md={8} lg={9}>
            <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
            >

              <Box sx={{mt: 2, height: 500}}>
                <DependencyGraphPanel graph={{
                  nodes: [node("service1"), node("service2"), node("service3"), node("service4"), node("service5"), node("service6"), node("service7"), node("service8"), node("service9")],
                  edges: [edge("service1", "service2"), edge("service2", "service3"), edge("service2", "service4"), edge("service3", "service5"), edge("service4", "service5"), edge("service4", "service6")]
                }} vcs={VcsProvider.GitHub}/>
              </Box>

            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}
            >
              <Deposits/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 500
                }}
            >
              <Chart/>
              <Button
                  onClick={() => {
                    throw new Error("Foo")
                  }}
              >
                Error
              </Button>

            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
              <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
                  onDragEnd={handleDragEnd}
              >
                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                >
                  {items.map(id => <SortableItem key={id} id={id}/>)}
                </SortableContext>
              </DndContext>
            </Paper>
          </Grid>
        </Grid>
      </Page>
  )
}


