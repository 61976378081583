import React, {createContext, ReactNode, useContext} from 'react';
import {UserAuthenticationStore} from "./auth/UserAuthenticationStore";
import {AuthenticationType} from "../common/auth/AuthenticationType";
import {VcsUrls} from "./models/VcsUrls";
import {UserAppTransportLayer} from "./api/userApi";
import {CommonAppInfrastructure, CommonAppInfrastructureProvider} from "../common/CommonAppInfrastructure";
import {UserAppUrls} from "./models/UserAppUrls";
import {AuthenticationResource} from "./utils/resourcesUrl";
import {DocsUrls} from "./api/docs/urls";
import {BadgesUrls} from "./api/badges/urls";

export interface UserAppInfrastructure extends CommonAppInfrastructure {
  transport: UserAppTransportLayer
  authStore: UserAuthenticationStore
  vcsUrls: VcsUrls
  urls: UserAppUrls
  docsUrls: DocsUrls
  badgesUrls: BadgesUrls
  apiUrls: AuthenticationResource
}

export const UserAppInfrastructureContext = createContext<null | UserAppInfrastructure>(null);

export type UserAppInfrastructureProviderProps = {
  infra: UserAppInfrastructure
  children: ReactNode
}

export function UserAppInfrastructureProvider({infra, children}: UserAppInfrastructureProviderProps) {
  return <CommonAppInfrastructureProvider infra={infra}>
    <UserAppInfrastructureContext.Provider value={infra}>
      {children}
    </UserAppInfrastructureContext.Provider>
  </CommonAppInfrastructureProvider>

}

export const useUserAppInfrastructure = (): UserAppInfrastructure => {
  const context = useContext(UserAppInfrastructureContext);
  if (context == null) {
    throw new Error('UserAppInfrastructureContext requires to be wrapped in an UserAppInfrastructureProvider');
  }

  const userProfile = context.authStore.getProfile();
  if (userProfile.type === AuthenticationType.User) {
    context.analytics.additionalData({authenticated: true, vcs: userProfile.vcs})
  } else {
    context.analytics.additionalData({authenticated: false})
  }

  return context;
}
