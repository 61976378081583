import * as React from 'react';
import {Divider, Typography} from "@mui/material";
import {PipelineStatus} from "../../api/pipelines/model";
import {formatUTCToLocalDateTime} from "../../../common/dateTime";

export function PipelineStageTooltip({variant, stage}: PipelineStageTooltipProps) {
  return (<>
        <Typography variant="body2"
                    fontWeight="bold">
          {variant === "rollout" ? `Rollout to ${stage.name}` : stage.name}
        </Typography>

        <Divider sx={{mb: 1}}/>

        <Typography variant="body2">
          Status: {stage.status}
        </Typography>

        <Typography variant="body2">
          Started at {formatUTCToLocalDateTime(stage.startedAt)}
        </Typography>

        <Typography variant="body2">
          Updated at {formatUTCToLocalDateTime(stage.updatedAt)}
        </Typography>
      </>
  )
}

export type PipelineStageTooltipProps = {
  variant: "pipeline" | "rollout"
  stage: {
    name: string;
    status: PipelineStatus;
    startedAt: string;
    updatedAt: string;
  };
}
