import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {MonopolisFavicon} from "../../../common/components/icons";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {sandboxTeamId, TeamId, teamIdFromCurrentLocation} from "../../../common/models/TeamId";
import {LoginButtons} from "./LoginButtons";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type LoginTarget = "CLI" | "app"

export type LoginPanelProps = {
  target: LoginTarget,
  onLogin: (teamId: TeamId) => void
}

export function LoginPanel({target, onLogin}: LoginPanelProps) {
  const {config} = useUserAppInfrastructure()
  const teamId = teamIdFromCurrentLocation()
  const handleLogin = (teamId: TeamId) => {
    onLogin(teamId)
  }

  return <Container component="main">
    <CssBaseline/>
    <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
    >
      <Box sx={{m: 1}}>
        <MonopolisFavicon height={monopolisConstraints.headerHeight}/>
      </Box>

      <Typography variant="h4" align={"center"}>
        Welcome to {config.appName}!
      </Typography>

      <Typography variant="body1" sx={{mt: 10, mb: 3}}>
        Login to <Typography fontWeight="500" component="span">{teamId}</Typography> team {target}
      </Typography>

      <LoginButtons onLogin={() => handleLogin(teamId)}
                    onSandbox={() => handleLogin(sandboxTeamId)}/>
    </Box>
  </Container>
}
