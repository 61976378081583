import {Box} from "@mui/material";
import {monopolisTheme} from "../../../../common/styles/theme";
import * as React from "react";
import {CSSProperties} from "react";
import {first, last} from "../../../../common/arrays";
import {EventsTimelineGraphEvent} from "./EventsTimeline";

const axisMarkerDistance = 80;

export type EventsTimelineAxisProps = {
  index: number
  height: number
  width: number
  zoom: number
  events: EventsTimelineGraphEvent[]
}

function range(n: number): number[] {
  return Array.from({length: n}, (value, key) => key)
}

function eventsDateTimeRange(events: EventsTimelineGraphEvent[]): { start: string, end: string } | undefined {
  const firstEventSpans = first(events)?.event?.spans || [];
  const lastEventSpans = last(events)?.event?.spans || [];
  const start = first(firstEventSpans)?.startedAt
  const end = last(lastEventSpans)?.updatedAt
  return !start ? undefined : {start, end: end || start}
}

export function EventsTimelineAxis({height, width, index, events}: EventsTimelineAxisProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateRange = eventsDateTimeRange(events)

  const lineStyle: CSSProperties = {
    stroke: monopolisTheme.palette.secondary.main
  }

  const textStyle: CSSProperties = {
    fill: monopolisTheme.palette.secondary.main,
    fontSize: 12,
    fontFamily: monopolisTheme.typography.caption.fontFamily,
    cursor: 'default',
  }

  return <Box sx={{
    position: "absolute",
    display: "flex",
    alignItems: "top",
    top: (height * index - 1) + "px",
    // borderTop: 1,
    // borderTopColor: monopolisTheme.palette.secondary.main,
  }}>
    <svg xmlns="http://www.w3.org/2000/svg"
         width={width} height={height} role="img">
      <g>
        <line style={lineStyle} x1={0} y1={0} x2={width} y2={0}/>

        {
          range(width / axisMarkerDistance)
              .map(n => {
                    const x = (n + 1) * axisMarkerDistance;
                    return <g>
                      <line style={lineStyle} x1={x} y1={0} x2={x} y2={10}/>
                      <text textAnchor="middle" style={textStyle} x={x} y={25}></text>
                    </g>
                  }
              )
        }
      </g>
    </svg>
  </Box>
}
