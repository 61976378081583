import {Button} from "@mui/material";
import * as React from "react";
import {ButtonProps} from "@mui/material/Button";

export type LoginButtonProps = ButtonProps

export function LoginButton({...props}: LoginButtonProps) {
  return <Button aria-label="Login" size="large" variant="contained" color="primary" fullWidth {...props}>
    Login
  </Button>
}


