import {EnvironmentConfig} from '../../Environment';
import {Paths} from "../models/Paths";
import {buildUrl, UrlQueryParam} from "../../common/urls";
import {slugParam} from "./urlParams";

import {OAuthSlug} from "../../common/models";
import {addSubDomain} from "../../common/navigation";
import {UserAppUrls} from "../models/UserAppUrls";
import {TeamId} from "../../common/models/TeamId";

export class AuthenticationResource {
    config: EnvironmentConfig
    appUrls: UserAppUrls

    constructor(config: EnvironmentConfig, appUrls: UserAppUrls) {
        this.config = config
        this.appUrls = appUrls
    }

    login(teamId: TeamId) {
        return `${this.config.apiUri(teamId)}/login/${encodeURIComponent(teamId)}?returnTo=${addSubDomain(this.appUrls.rawBaseUrl(), teamId)}${Paths.LoginCallback}`;
    }

    logout() {
        return `${this.config.userApiBaseUrl}/logout?returnTo=${this.appUrls.baseUrl()}/`;
    }

    loginCLI(teamId: TeamId, slug?: OAuthSlug) {
        const query = Array<UrlQueryParam>()
        if (slug) query.push(slugParam(slug));
        return buildUrl(`${this.config.cliApiBaseUrl}/login/${encodeURIComponent(teamId)}`, query)
    }

}

