import * as React from 'react';
import {useEffect} from 'react';
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {UnauthorizedPage} from "../error/UnauthorizedPage";

export function Logout() {
  const {logout} = useUserAuthentication();

  useEffect(() => {
    logout()
  }, [logout])

  return <UnauthorizedPage/>;
}
