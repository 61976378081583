import Typography from "@mui/material/Typography";
import * as React from "react";
import {Link} from "../components/navigation/Link";
import {TypographyProps} from "@mui/material";

export function CopyrightAndStatus(props: TypographyProps) {
  return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        <Link color="inherit" href="https://monopolis.cloud/">About Monopolis</Link>
        {' - '}
        <Link color="inherit" href="https://monopolis.statuspage.io/">System Status</Link>
        {' - '}
        {'Copyright © '}
        <Link color="inherit" href="https://monopolis.cloud">Monopolis Cloud Ltd</Link>
        {' '}
        {new Date().getFullYear()}
      </Typography>
  );
}
