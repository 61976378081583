import React from "react";
import {DashboardWidgetProps} from "./DashboardWidgetView";
import {DashboardWidgetPanel, useDashboardWidgetPanel} from "./DashboardWidgetPanel";
import {useUserOrgsQuery} from "../../api/pipelines";
import {DiscoverHint} from "../hints";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {Box} from "@mui/material";
import {OrgsPanel} from "../org/OrgsPanel";
import {useUserAuthentication} from "../../auth/useUserAuthentication";


import {AuthenticationType} from "../../../common/auth/AuthenticationType";

export type UserOrgsWidgetPanelProps = DashboardWidgetProps<{}>;

export function UserOrgsWidgetPanel({...props}: UserOrgsWidgetPanelProps) {
  const panelProps = useDashboardWidgetPanel({...props, title: "Organisations"});
  const {userProfile} = useUserAuthentication()
  const orgs = useUserOrgsQuery()

  if (userProfile?.type !== AuthenticationType.User) return <></>

  return <DashboardWidgetPanel {...panelProps}>
    <QueryLoader result={orgs} onError={props.onError} onLoaded={data =>
        <Box sx={{mt: 2}}>
          <OrgsPanel vcs={userProfile.vcs} orgs={data}/>
          <DiscoverHint userProfile={userProfile}/>
        </Box>
    }/>

  </DashboardWidgetPanel>
}
