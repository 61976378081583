import * as React from 'react';
import {IconButton, Typography} from "@mui/material";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

export function Paging({previous, next, onPrevious, onNext}: PagingProps) {
  return (
      <Typography>
        <IconButton aria-label="Previous Page" disabled={!previous} onClick={onPrevious}>
          <NavigateBeforeRoundedIcon/>
        </IconButton>

        <IconButton aria-label="Next Page" disabled={!next} onClick={onNext}>
          <NavigateNextRoundedIcon/>
        </IconButton>
      </Typography>
  )
}

export type PagingProps = {
  previous: boolean;
  next: boolean;
  onPrevious: () => void;
  onNext: () => void;
}
