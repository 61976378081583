import {useSnackbar} from "notistack";
import * as React from "react";
import {useCallback, useState} from "react";
import {snackOptions} from "../../../common/snackbar";
import {FormEditableField, FormStack, FormTextField} from "../../../common/components/form";
import {useAccountUpdateOAuthCredentialsMutation} from "../../api/account";
import {AccountConfiguration} from "../../api/account/model";

export type OAuthConfigurationPanelProps = {
  config: AccountConfiguration
}

export function OAuthConfigurationPanel({config}: OAuthConfigurationPanelProps) {
  const {mutateAsync: configure} = useAccountUpdateOAuthCredentialsMutation()
  const {enqueueSnackbar} = useSnackbar();

  const [editing, setEditing] = useState(false)
  const [editedClientId, setEditedClientId] = useState("")
  const [editedClientSecret, setEditedClientSecret] = useState("")

  const update = useCallback(async () => {
    const data = await configure(
        {
          clientId: editedClientId,
          clientSecret: editedClientSecret
        }
    );
    if (data && data.kind === "error") enqueueSnackbar(`Failed to configure OAuth client`, snackOptions(false))
    if (data && data.kind === "ok") enqueueSnackbar(`Configured OAuth client`, snackOptions(true))

    setEditedClientId("")
    setEditedClientSecret("")
  }, [configure, enqueueSnackbar, editedClientId, editedClientSecret])

  return <FormStack>
    <FormEditableField
        editLabel={"Configure OAuth Credentials"}
        saveLabel={"Save OAuth Credentials"}
        onSave={update}
        onEditing={setEditing}>
      <FormTextField label="OAuth Client Id"
                     required={true}
                     readOnly={!editing}
                     inputProps={{minLength: 12}}
                     value={editing ? editedClientId : config.oauthCredentials?.clientId}
                     placeholder="OAuth client id..."
                     onChange={e => setEditedClientId(e.target.value)}
      />

      <FormTextField label="OAuth Client Secret"
                     type="password"
                     readOnly={!editing}
                     inputProps={{minLength: 12}}
                     required={true}
                     value={editing ? editedClientSecret : config.oauthCredentials?.clientSecret}
                     onChange={e => setEditedClientSecret(e.target.value)}
                     placeholder="OAuth client secret..."/>
    </FormEditableField>
  </FormStack>

}
