import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {SystemError} from "../../../common/api/TransportLayer";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {PageHeader, PageHeaderMenuProvider} from "../../../common/components/navigation/PageHeader";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {DashboardPathParameter, OwnerPathParameter, usePathParams} from "../../models/Paths";
import {useDashboardQuery} from "../../api/dashboard";
import {DashboardGrid} from "../../components/dashboard/DashboardGrid";
import {asDashboardWidgetProps} from "../../components/dashboard/DashboardWidgetView";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {Dashboard} from "../../api/dashboard/model";
import {OrgDashboardBreadcrumbsMenu} from "../../components/dashboard/OrgDashboardBreadcrumbsMenu";
import {useTabControls} from "../../../common/tabs";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {UserPageId} from "../UserPageId";

export function OrgDashboardPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const [dashboard, setDashboard] = useState<Dashboard | undefined>();
  const {userProfile} = useUserAuthentication()
  const pathParams = usePathParams<OwnerPathParameter & DashboardPathParameter>();
  const getDashboard = useDashboardQuery({
    owner: pathParams.owner,
    dashboardId: pathParams.dashboard,
    onSuccess: (data) => {
      data.kind === "ok" && setDashboard(data.data)
    }
  })

  const tabs = useTabControls({
    defaultTab: "0",
    tabs: [
      {title: dashboard?.name || "", id: "0"},
    ]
  })

  if (userProfile?.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const menu: PageHeaderMenuProvider = () => dashboard &&
      <OrgDashboardBreadcrumbsMenu owner={pathParams.owner} dashboardId={dashboard.id} actions={["editDashboard"]}/>

  const header = <PageHeader
      title="Dashboard"
      breadcrumbs={[
        <OwnerBreadcrumb owner={pathParams.owner}/>,
      ]}
      tabs={tabs}
      menu={menu}
  />

  return <UserAppPage pageId={UserPageId.OrgDashboard} title="Dashboard" contentHeader={header} error={error} profile={userProfile}>
    <QueryLoader result={getDashboard} onError={setError} onLoaded={data =>
        <DashboardGrid
            showName={false}
            isEditable={false}
            name={data.name}
            columns={16}
            onError={setError}
            widgets={data.widgets.map(asDashboardWidgetProps)}
        />
    }/>

  </UserAppPage>
}
