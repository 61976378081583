import * as React from 'react';
import {useCallback} from 'react';
import {Switch} from "@mui/material";
import {useSnackbar} from "notistack";
import {SystemResponse} from "../../../common/api/TransportLayer";
import {EmptyRequest, EmptyResponse, MutationResult} from "../../../common/api/query";
import {snackOptions} from "../../../common/snackbar";
import {Toggle} from "../../api/model";
import {UserOption} from "../../api/user/model";
import {useOptionOffMutation, useOptionOnMutation} from "../../api/user";

export type UserOptionToggleProps = {
  option: UserOption,
  toggle: Toggle,
}

export function UserOptionToggle({toggle, option}: UserOptionToggleProps) {
  const optionOn = useOptionOnMutation({option})
  const optionOff = useOptionOffMutation({option})
  const {enqueueSnackbar} = useSnackbar();

  const handleAction = useCallback(async (mutation: MutationResult<SystemResponse<EmptyResponse>, unknown, EmptyRequest>, success: string, failure: string) => {
    const response = await mutation.mutateAsync({});
    const isSuccess = response.kind === "ok";

    enqueueSnackbar(isSuccess ? success : failure, snackOptions(isSuccess))
  }, [enqueueSnackbar])

  return <Switch
      checked={toggle === Toggle.On}
      disabled={toggle === Toggle.Disabled}
      onChange={async (event) => await (event.target.checked
          ? handleAction(optionOn, `${option} option is on`, `Failed to enable ${option} option`)
          : handleAction(optionOff, `${option} option is off`, `Disable to disable ${option} option`))
      }
  />
}

