export type EmailAddress = string
export * from "./CustomerPlan"
export * from "./OrgConfiguration"
export * from "./VcsProvider"
export * from "./OAuthProvider"

export function optionalStringFrom(option: string | Array<string | null> | null) {
    switch (typeof option) {
        case "string":
            return option.toString();
        default:
            return undefined;
    }
}

export type ApiKey = string
export type DateTime = string
export type VcsOwner = string
export type VcsPath = string

export type OrgRepoPath = {
    org: VcsOwner
    repo: VcsPath
}
export type CustomerId = string

