import * as React from "react";
import {ReactNode} from "react";
import {Box, IconButton} from "@mui/material";
import {SettingsOutlined} from "@mui/icons-material";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {monopolisTheme} from "../../../common/styles/theme";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

export type HintButtonVariant = "settings" | "addToWatchlist" | "add" | "edit"

export type HintButtonProps = {
  variant: HintButtonVariant
}

export function HintButton({variant}: HintButtonProps) {
  const button = hintButtons[variant]
  return (<Box sx={{display: "inline", mx: 0.3}}>
        <i>{button.text}</i>
        <IconButton
            aria-label={button.text}
            color="primary"
            disabled={true}
            sx={{
              border: 1,
              borderRadius: 1,
              ml: 1,
              borderColor: monopolisTheme.palette.secondary.main,
              height: 30,
              width: 30
            }}>
          {button.icon}
        </IconButton>
      </Box>
  );
}

type HintButtonDefinition = {
  text: string,
  icon: ReactNode
}

const hintButtons: Record<HintButtonVariant, HintButtonDefinition> = {
  addToWatchlist: {text: "Add to watchlist", icon: <StarBorderRoundedIcon fontSize="small"/>},
  settings: {text: "Settings", icon: <SettingsOutlined fontSize="small"/>},
  add: {text: "Add", icon: <AddRoundedIcon fontSize="small"/>},
  edit: {text: "Edit", icon: <EditRoundedIcon fontSize="small"/>}
}


