import React, {CSSProperties, forwardRef, Ref} from 'react';

export type PhotoProps = {
  url: string
  index: number
  faded?: boolean
  style?: CSSProperties
}


export const Photo = forwardRef(({url, index, faded, style, ...props}: PhotoProps, ref: Ref<any>) => {
  const inlineStyles: CSSProperties = {
    opacity: faded ? '0.2' : '1',
    transformOrigin: '0 0',
    // height: index === 0 ? 410 : 200,
    // gridRowStart: index === 0 ? 'span 2' : undefined,
    // gridColumnStart: index === 0 ? 'span 2' : undefined,
    height: 200,
    backgroundImage: `url("${url}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    ...style,
  };

  return <div ref={ref} style={inlineStyles} {...props} />;
});
