import * as React from "react";
import {OrgConfigurationPageTabProps} from "./OrgConfigurationPageTab";
import {
  useOrgNotificationSubscribeMutation,
  useOrgNotificationSubscriptionsQuery,
  useOrgNotificationUnsubscribeMutation
} from "../../api/notifications";
import {NotificationsPanel} from "../../components/notifications/NotificationsPanel";

export function OrgConfigurationPageNotificationsTab({owner, onError}: OrgConfigurationPageTabProps) {
  const subscriptionsQuery = useOrgNotificationSubscriptionsQuery({owner})
  const notificationSubscribeMutation = useOrgNotificationSubscribeMutation({owner})
  const notificationUnsubscribeMutation = useOrgNotificationUnsubscribeMutation({owner})
  return <NotificationsPanel owner={owner}
                             subscriptionsQuery={subscriptionsQuery}
                             subscribeMutation={notificationSubscribeMutation}
                             unsubscribeMutation={notificationUnsubscribeMutation}
                             onError={onError}/>
}
