import * as React from "react";
import {useMemo, useState} from "react";
import {Box, Divider, Paper, Typography} from "@mui/material";
import {Bar, BarChart, Cell, ResponsiveContainer, XAxis} from "recharts";
import {
  AuditEventAggregate,
  AuditEventSpanSeverity,
  AuditEventSpanStatus,
  AuditEventType
} from "../../api/events/model";
import {formatDateToMonthAndDay} from "../../../common/dateTime";
import {monopolisTheme} from "../../../common/styles/theme";
import {auditEventsAsTimelinePoints, monodesFromAuditEvents} from "../../utils/events";
import {EventsView} from "./EventsView";

export type TimelineEvent = {
  dateTime: string
  title: string
  type: AuditEventType
  status: AuditEventSpanStatus
  severity: AuditEventSpanSeverity,
}

export const MaxTimelineWeight = 5
export type TimelineWeight = 0 | 1 | 2 | 3 | 4 | 5

export type TimelineDate = {
  date: string
  weight: TimelineWeight
  events: TimelineEvent[]
}

export type EventsDatesViewProps = {
  data: AuditEventAggregate
  onSelectDate?: (date: TimelineDate) => void
  onSelectEvent?: (event: TimelineEvent) => void
}

export function EventsDatesView({data, onSelectDate, onSelectEvent}: EventsDatesViewProps) {
  const [eventTypes, setEventTypes] = useState<AuditEventType[]>([]);
  const events = useMemo(() => auditEventsAsTimelinePoints(data, eventTypes), [data, eventTypes])
  const monodes = useMemo(() => monodesFromAuditEvents(data.dates.flatMap(date => date.events)), [data])
  const formattedStats: DataPayload[] = events.map(data => ({
    ...data,
    count: 1
  }))

  const [hover, setHover] = useState<DataPayload | undefined>(undefined)
  const [selectedDate, setSelectedDate] = useState<string>(formattedStats[formattedStats.length - 1].date)

  const primaryColor = monopolisTheme.palette.primary.main;
  const opacityDelta = -0.05
  const opacityBrackets: Record<TimelineWeight, number> = {
    0: 0.15 + opacityDelta,
    1: 0.25 + opacityDelta,
    2: 0.30 + opacityDelta,
    3: 0.35 + opacityDelta,
    4: 0.40 + opacityDelta,
    5: 0.50 + opacityDelta
  }

  const handleEventTypesSelection = (newEventTypes: AuditEventType[]) => {
    setEventTypes(newEventTypes)
  };

  const handleDateSelection = (event: DataPayload) => {
    setSelectedDate(event.date)
    onSelectDate && onSelectDate(event)
  };

  const eventsForSelectedDate = data.dates.find(d => d.date === selectedDate)?.events || [];
  return <>
    <Paper>
      <Box sx={{pt: 1, pl: 1, pr: 1, mb: 1}}>
        <ResponsiveContainer width="100%" height={80}>
          {formattedStats.length > 0
              ? <BarChart
                  barGap={0}
                  barCategoryGap={1}
                  data={formattedStats}
                  onMouseLeave={() => setHover(undefined)}
              >
                <XAxis axisLine={false} fontSize="small" dataKey="date"
                       tickFormatter={eventDate => " " + formatDateToMonthAndDay(eventDate) + " "}/>

                <Bar dataKey="count">
                  {formattedStats.map((entry, index) => {
                    const isCellSelected = selectedDate === entry.date;
                    const isCellHover = hover?.date === entry.date;
                    return (
                        <Cell key={`cell-${index}`}
                              fill={primaryColor}
                              fillOpacity={isCellSelected ? 1 : isCellHover ? 0.7 : opacityBrackets[entry.weight]}
                              radius={3}
                              stroke={isCellSelected ? primaryColor : primaryColor}
                              strokeWidth={isCellSelected ? 2 : isCellHover ? 4 : 0}
                              strokeLinecap={"round"}
                              strokeLinejoin={"round"}
                              onMouseOver={() => {
                                if (entry.date !== hover?.date) setHover(entry)
                              }}
                              onMouseUp={() => {
                                if (entry.date !== selectedDate) handleDateSelection(entry)
                              }}
                        />
                    );
                  })}
                </Bar>

              </BarChart>
              : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Typography variant="body1" color="text.secondary">No data</Typography>
              </Box>
          }
        </ResponsiveContainer>
      </Box>

      <Divider/>

      <Box sx={{flexGrow: 1}}>
        <EventsView monodes={monodes} events={eventsForSelectedDate} onEventTypes={handleEventTypesSelection}/>
      </Box>

    </Paper>
  </>
}

type DataPayload = {
  count: number,
} & TimelineDate

