import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Paths} from "./models/Paths";
import {AccountConfigurationPage} from "./pages/accountConfiguration/AccountConfigurationPage";
import {LoginCallback} from "./pages/login/LoginCallback";
import {AuthenticationOrFallback} from "./auth/AuthenticationOrFallback";
import {LoginPage} from "./pages/login/LoginPage";
import {PageNotFound} from "./pages/error/PageNotFound";
import {RequireAuthentication} from "./auth/RequireAuthentication";
import {Logout} from "./pages/logout/Logout";
import {useAccountAppInfrastructure} from "./AccountAppInfrastructure";

//Note: Order of items is important here as we do not prefix owner/repo paths
export const AccountAppRouter: React.FC = () => {
  const {urls} = useAccountAppInfrastructure()
  return (
      <Routes>
        <Route path={Paths.Logout} element={<Logout/>}/>
        <Route path={Paths.LoginCallback} element={<LoginCallback/>}/>
        <Route path={Paths.Login} element={
          <AuthenticationOrFallback
              authenticated={urls.settings()}
              fallback={<LoginPage/>}
          />
        }/>

        <Route path={Paths.Settings} element={
          <RequireAuthentication>
            <AccountConfigurationPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.Home} element={
          <AuthenticationOrFallback
              authenticated={urls.settings()}
              fallback={urls.login()}
          />
        }/>

        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
  );
};
