import * as React from 'react';
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OAuthProviderPathParameter, usePathParams} from "../../models/Paths";
import {currentLocation} from "../../../common/navigation";
import {usePostInstallationDetails} from "../../api/onboarding";
import {ErrorPage} from "../error/ErrorPage";
import {base64UrlEncode} from "../../../common/strings";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {UserPermissions} from "../../auth/Permissions";
import {useNavigation} from "../../../common/port/Navigation";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function OnboardingCallback() {
  const {navigateTo} = useNavigation()
  const redirectionUri = currentLocation();
  const {provider} = usePathParams<OAuthProviderPathParameter>();
  const {urls} = useUserAppInfrastructure()
  const {navigateToLogin, userProfile} = useUserAuthentication();
  const {data} = usePostInstallationDetails({
    data: base64UrlEncode(redirectionUri),
    enabled: userProfile.type === AuthenticationType.User
  })

  if (userProfile.type !== AuthenticationType.User) {
    navigateToLogin(provider, redirectionUri)
    return <UnauthorizedPage/>
  }

  if (!data) return <UnauthorizedPage/>

  if (data.kind === "error") {
    return <ErrorPage error={data.error}/>
  }

  if (data.kind === "ok") {
    if (UserPermissions.checkAllowed(userProfile, UserPermissions.EditOrganisation, {owner: data.data.org})) {
      navigateTo(urls.onboardingWelcome(data.data.org))
    } else {
      navigateTo(urls.home())
    }
  }

  return <UnauthorizedPage/>;
}
