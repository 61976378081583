import * as React from "react";
import {Link} from "../../../common/components/navigation/Link";
import {VcsOwner} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type OwnerBreadcrumbProps = {
  owner: VcsOwner
}

export function OwnerBreadcrumb({owner}: OwnerBreadcrumbProps) {
  const {urls} = useUserAppInfrastructure()
  return <Link href={urls.org(owner)}>{owner}</Link>
}
