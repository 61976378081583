import * as React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatStatisticsDates, StatisticsDateAggregate} from "../../api/statistics/model";
import {RechartsTooltipProps} from "../../utils/recharts";
import {Panel} from "../../../common/components/Panel";
import {monopolisTheme} from "../../../common/styles/theme";

export function PipelinesStatusChart({statistics}: PipelinesStatusChartProps) {
  const formattedStats = formatStatisticsDates(statistics)

  return <Panel header="Build Status">
    <ResponsiveContainer width="100%" height={170}>
      {formattedStats.length > 0
          ? <BarChart
              maxBarSize={10}
              data={formattedStats}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3"/>
            <XAxis fontSize="small" dataKey="date"/>
            <YAxis fontSize="small" width={70}/>
            <Tooltip content={<PipelinesStatusChartTooltip/>} cursor={{fill: monopolisTheme.palette.secondary.main}}/>
            <Bar dataKey="successful" stackId="builds" fill={monopolisTheme.palette.success.main} opacity={0.7}/>
            <Bar dataKey="failed" stackId="builds" fill={monopolisTheme.palette.error.main} opacity={0.7}/>
          </BarChart>
          : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography variant="body1" color="text.secondary">No data</Typography>
          </Box>
      }
    </ResponsiveContainer>
  </Panel>
}

export type PipelinesStatusChartProps = {
  statistics: StatisticsDateAggregate[];
}

type DataPayload = {
  successful: string,
  failed: number,
}


function PipelinesStatusChartTooltip(params: RechartsTooltipProps<DataPayload>) {
  if (params.active && params.payload && params.payload.length) {
    const payload = params.payload[0].payload;
    return (<Paper style={{borderColor: monopolisTheme.palette.secondary.main}} variant="outlined" sx={{p: 1}}>
      <Typography variant="body2" sx={{mb: 1}}>
        {params.label}
      </Typography>

      <Typography color={monopolisTheme.palette.success.main} variant="body2" sx={{mb: 1}}>
        Successful : {payload.successful}
      </Typography>

      <Typography color={monopolisTheme.palette.error.main} variant="body2" sx={{mb: 1}}>
        Failed : {payload.failed}
      </Typography>
    </Paper>)
  }

  return null;
}

