import React, {useState} from "react";
import {DashboardWidgetProps} from "./DashboardWidgetView";
import {DashboardWidgetPanel, useDashboardWidgetPanel} from "./DashboardWidgetPanel";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {OrgEventsWidgetConfig} from "../../api/dashboard/widgets/OrgEventsWidget";
import {Box, Divider, IconButton, TextField} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {useOrgPipelinesQuery} from "../../api/pipelines";
import {useOrgGroupedBuildStatisticsQuery} from "../../api/statistics";
import {extendPipelines, pipelineActionsMenu} from "../../utils/pipelines";
import {PipelinesTablePanel} from "../pipelines/PipelinesTablePanel";

export type OrgPipelinesWidgetPanelProps = DashboardWidgetProps<OrgEventsWidgetConfig>;

export function OrgPipelinesWidgetPanel({org, metaFilter, ...props}: OrgPipelinesWidgetPanelProps) {
  const [newMetaFilter, setNewMetaFilter] = useState(metaFilter);
  const panelProps = useDashboardWidgetPanel({...props, title: "Organisation pipelines"});
  const orgPipelinesQuery = useOrgPipelinesQuery({owner: org, metaFilter})
  const orgGroupedBuildStatsQuery = useOrgGroupedBuildStatisticsQuery({owner: org, onQueryError: props.onError})
  const {id, isEditing, onSave} = props

  function handleMetaFilterChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setNewMetaFilter(event.target.value)
  }

  function handleSave() {
    onSave && onSave(id, {org, metaFilter: newMetaFilter})
  }

  function handleCancel() {
    setNewMetaFilter(metaFilter)
    onSave && onSave(id, {org, metaFilter})
  }

  const stats = orgGroupedBuildStatsQuery.data && orgGroupedBuildStatsQuery.data.kind === "ok"
      ? orgGroupedBuildStatsQuery.data.data
      : []

  return <DashboardWidgetPanel {...panelProps}>
    {
        isEditing && <Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
            <TextField fullWidth={true}
                       defaultValue={newMetaFilter}
                       variant="standard"
                       label="Filter"
                       onChange={handleMetaFilterChange}/>

            <IconButton aria-label="save" onClick={handleSave}>
              <CheckRoundedIcon/>
            </IconButton>

            <IconButton aria-label="cancel" onClick={handleCancel}>
              <CloseRoundedIcon/>
            </IconButton>

          </Box>
          <Divider sx={{my: 1}}/>
        </Box>
    }

    <QueryLoader result={orgPipelinesQuery} onError={props.onError} onLoaded={data =>
        <PipelinesTablePanel title="Pipelines"
                             pipelines={extendPipelines(data, stats)}
                             menu={pipelineActionsMenu}
        />
    }/>

  </DashboardWidgetPanel>
}
