import {OnError} from "../../../common/api/TransportLayer";
import * as React from "react";
import {useCachedOrgConfigurationQuery} from "../../api/org";
import {TrialEndingBanner} from "../../../common/components/TrialEndingBanner";
import {VcsOwner} from "../../../common/models";

export function OrgTrialEndingBanner({owner, onError}: OrgTrialEndingBannerProps) {
  const orgConfiguration = useCachedOrgConfigurationQuery({owner: owner, onError: onError})
  return (
      <TrialEndingBanner plan={orgConfiguration?.plan}/>
  )
}

export type OrgTrialEndingBannerProps = {
  owner: VcsOwner
} & OnError

