import {TextWidget} from "./widgets/TextWidget";
import {UserOrgsWidget} from "./widgets/UserOrgsWidget";
import {UserWatchlistWidget} from "./widgets/UserWatchlistWidget";
import {OrgEventsWidget} from "./widgets/OrgEventsWidget";
import {OrgPipelinesWidget} from "./widgets/OrgPipelinesWidget";
import {OrgMonodesWidget} from "./widgets/OrgMonodesWidget";
import {EmbedWidget} from "./widgets/EmbedWidget";
import {OrgMonodesRadiatorWidget} from "./widgets/OrgMonodesRadiatorWidget";

export * from "./widgets/TextWidget"
export * from "./widgets/UserOrgsWidget"
export * from "./widgets/UserWatchlistWidget"
export * from "./widgets/BaseDashboardWidget"

export type DashboardId = string
export type DashboardName = string

export enum DashboardWidgetType {
    Embed = "Embed",
    OrgEvents = "OrgEvents",
    OrgMonodes = "OrgMonodes",
    OrgMonodesRadiator = "OrgMonodesRadiator",
    OrgPipelines = "OrgPipelines",
    Text = "Text",
    UserWatchlist = "UserWatchlist",
    UserOrgs = "UserOrgs",
}

export const DashboardWidgetTypes = Object.values(DashboardWidgetType)

export type DashboardWidget = TextWidget
    | EmbedWidget
    | UserWatchlistWidget
    | UserOrgsWidget
    | OrgEventsWidget
    | OrgMonodesWidget
    | OrgMonodesRadiatorWidget
    | OrgPipelinesWidget

export type DashboardRequest = {
    name: DashboardName,
    widgets: DashboardWidget[]
}

export type Dashboard = {
    id: DashboardId,
    name: DashboardName,
    widgets: DashboardWidget[]
}
