import {MetaQuery, Monode, RolloutTarget} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";
import {metaFilterParam, targetParam} from "../../utils/urlParams";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export class EventUrls {
    static orgEvents(params: { owner: VcsOwner, target?: RolloutTarget, metaFilter?: MetaQuery }) {
        const query = Array<UrlQueryParam>()
        if (params.target) query.push(targetParam(params.target));
        if (params.metaFilter) query.push(metaFilterParam(params.metaFilter));
        return buildUrl(`/events/org/${params.owner}`, query)
    }

    static repositoryEvents(params: { target?: RolloutTarget, path: OrgRepoPath }) {
        const query = Array<UrlQueryParam>()
        if (params.target) query.push(targetParam(params.target))
        return buildUrl(`/events/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`, query)
    }

    static monodeEvents(params: { target?: RolloutTarget, path: OrgRepoPath, monode: Monode }) {
        const query = Array<UrlQueryParam>()
        if (params.target) query.push(targetParam(params.target));
        return buildUrl(`/events/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, query)
    }
}
