import {Monode, RolloutTarget, VcsBranch} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";
import {branchParam, monodeParam, targetParam} from "../../utils/urlParams";
import {CustomerId, OrgRepoPath, VcsOwner} from "../../../common/models";

export class BadgesUrls {
    private readonly baseUri: string;

    constructor(baseUri: string) {
        this.baseUri = baseUri;
    }

    buildFailureRate(params: { customerId: CustomerId, path: OrgRepoPath, branch?: VcsBranch, monode?: Monode }) {
        return this.buildBadge("buildfailurerate", params);
    }

    buildSuccessRate(params: { customerId: CustomerId, path: OrgRepoPath, branch?: VcsBranch, monode?: Monode }) {
        return this.buildBadge("buildsuccessrate", params);
    }

    totalBuilds(params: { customerId: CustomerId, path: OrgRepoPath, branch?: VcsBranch, monode?: Monode }) {
        return this.buildBadge("buildtotal", params);
    }

    averageBuildDuration(params: { customerId: CustomerId, path: OrgRepoPath, branch?: VcsBranch, monode?: Monode }) {
        return this.buildBadge("buildavgduration", params);
    }

    maxBuildDuration(params: { customerId: CustomerId, path: OrgRepoPath, branch?: VcsBranch, monode?: Monode }) {
        return this.buildBadge("buildmaxduration", params);
    }

    changeFailureRate(params: { customerId: CustomerId, org: VcsOwner, path?: OrgRepoPath, target: RolloutTarget, monode?: Monode }) {
        return this.rolloutBadge("changefailurerate", params);
    }

    changeLeadTime(params: { customerId: CustomerId, org: VcsOwner, path?: OrgRepoPath, target: RolloutTarget, monode?: Monode }) {
        return this.rolloutBadge("changeleadtime", params);
    }

    maxChangeLeadTime(params: { customerId: CustomerId, org: VcsOwner, path?: OrgRepoPath, target: RolloutTarget, monode?: Monode }) {
        return this.rolloutBadge("changeleadtimemax", params);
    }

    rolloutFrequency(params: { customerId: CustomerId, org: VcsOwner, path?: OrgRepoPath, target: RolloutTarget, monode?: Monode }) {
        return this.rolloutBadge("rolloutfrequency", params);
    }


    private buildBadge(endpoint: string, params: { customerId: CustomerId; path: OrgRepoPath; branch?: VcsBranch; monode?: Monode }) {
        const query = Array<UrlQueryParam>()
        if (params.monode) query.push(monodeParam(params.monode));
        if (params.branch) query.push(branchParam(params.branch));
        return this.badgeUrl(params, endpoint, query);
    }

    private rolloutBadge(endpoint: string, params: { customerId: CustomerId; org: VcsOwner, path?: OrgRepoPath; branch?: VcsBranch; target: RolloutTarget; monode?: Monode }) {
        const query = Array<UrlQueryParam>()
        if (params.monode) query.push(monodeParam(params.monode));
        query.push(targetParam(params.target));
        return this.badgeUrl(params, endpoint, query);
    }

    private badgeUrl(params: { customerId: CustomerId; org?: VcsOwner, path?: OrgRepoPath }, endpoint: string, query: UrlQueryParam[]) {
        const base = params.path
            ? `${this.baseUri}/${params.customerId}/${params.path.org}/${encodeURIComponent(params.path.repo)}/${endpoint}`
            : `${this.baseUri}/${params.customerId}/${params.org}/${endpoint}`;

        return buildUrl(base, query);
    }

}
