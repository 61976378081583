import {OwnerPathParameter, RepositoryPathParameter} from "./Paths";
import {optionalStringFrom, OrgRepoPath} from "../../common/models";

import {OAuthSlug} from "../../common/models";

export enum VcsRepoFilePathType {
    dir = "dir",
    file = "file"
}

export type BatchSize = number
export type VcsRepoFilePath = string
export type CommitSha = string
export type CommitMessage = string
export type VcsRepository = string
export type VcsRunId = string
export type PipelineName = string
export type VcsEvent = string
export type Journey = string
export type Monode = string
export type RepoMonode = string
export type RolloutTarget = string
export type MetaQuery = string
export type MonodeType = "generic" | "package" | "service"
export type MonodeAutomation = string
export type VersionPattern = string
export type Version = string
export type VcsBranch = string
export type VcsUrl = string
export type VcsRolloutId = string
export type OriginUri = string

export function pathFrom(vcsParameters: OwnerPathParameter & RepositoryPathParameter): OrgRepoPath {
    return {
        org: vcsParameters.owner,
        repo: vcsParameters.repository
    }
}

export function repoMonode(params: { path: OrgRepoPath, monode: Monode }): RepoMonode {
    return `${params.path.repo}?${params.monode}`
}

export function slugFrom(slugOption: OAuthSlug | Array<OAuthSlug | null> | null) {
    return optionalStringFrom(slugOption)
}

