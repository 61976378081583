import * as React from "react";
import {CommitSha, VcsBranch} from "../../models";
import {Link} from "../../../common/components/navigation/Link";
import {VcsIcon} from "../VcsIcon";
import {Box} from "@mui/material";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {OrgRepoPath, VcsProvider} from "../../../common/models";

export type CommitBreadcrumbProps = {
  path: OrgRepoPath
  vcs: VcsProvider
  branch?: VcsBranch
  sha: CommitSha
}

export function CommitBreadcrumb({vcs, path, branch, sha}: CommitBreadcrumbProps) {
  const {vcsUrls, urls} = useUserAppInfrastructure()
  return <Box>
    <Link href={urls.commit({path: path, branch, sha})}>
      {sha.substring(0, 8)}
    </Link>
    <span style={{marginRight: 8, marginLeft: 8}}>
      <Link aria-label={vcs} href={vcsUrls.commit(path, sha)}>
        <VcsIcon type={vcs}/>
      </Link>
    </span>
  </Box>
}
