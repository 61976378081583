import {Monode, RolloutTarget, VcsRepository} from "../../models";
import {OnError} from "../../../common/api/TransportLayer";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {OrgRepoPath, VcsOwner} from "../../../common/models";
import {MaybeOrgConfiguration} from "../../../common/models";

export enum MonodeTargetPageTab {
  rollouts = "rollouts"
}

export type MonodeTargetPageTabProps = {
  owner: VcsOwner
  path: OrgRepoPath
  repository: VcsRepository
  monode: Monode
  target: RolloutTarget
  userProfile: AuthenticatedUserProfile
  orgConfiguration: MaybeOrgConfiguration
} & OnError
