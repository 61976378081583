import {
    BatchSize,
    CommitMessage,
    CommitSha,
    Monode,
    RolloutTarget,
    VcsBranch,
    VcsEvent,
    VcsRolloutId,
    VcsRunId,
    Version
} from "../../models";
import {User} from "../model";
import {TwoFactorCode} from "../user/model";
import {distinct} from "../../../common/arrays";
import {DateTime, OrgRepoPath, VcsProvider} from "../../../common/models";

export enum PipelineStatus {
    Created = "Created",
    Running = "Running",
    Locked = "Locked",
    Passed = "Passed",
    Failed = "Failed",
    Skipped = "Skipped",
    Unknown = "Unknown"
}

export type PipelineStage = {
    name: string;
    status: PipelineStatus
    startedAt: DateTime;
    updatedAt: DateTime;
}

export type UserRollout = {
    request: RolloutRequest,
    twoFactorCode?: TwoFactorCode
}

export type RolloutRequest = {
    path: OrgRepoPath,
    sha: CommitSha,
    branch: VcsBranch,
    monode: Monode,
    target: RolloutTarget,
    crosscheck: boolean,
    version: Version
}

export type RolloutStage = {
    id: VcsRolloutId;
    name: RolloutTarget;
    status: PipelineStatus
    startedAt: DateTime;
    updatedAt: DateTime;
}

export type Commit = {
    sha: CommitSha
    message: CommitMessage
    author?: User
    timestamp: DateTime
}

export type PipelineTrigger = {
    event: VcsEvent;
    user: User;
}

export type PipelineRun = {
    runId: VcsRunId;
    commit: Commit;
    trigger: PipelineTrigger;
    status: PipelineStatus;
    stages: PipelineStage[];
    startedAt: string;
    durationSeconds: number
    rollouts: RolloutStage[];
}

export type PipelineMonodeRollout = {
    target: RolloutTarget
    commit: Commit
    rolloutTime: DateTime
    deployer: User
    user: User
    batchSize: BatchSize
}

export type Pipeline = {
    vcs: VcsProvider
    path: OrgRepoPath
    name: Monode
    branch: VcsBranch
    isWatched: boolean
    runs: PipelineRun[]
    rollouts: PipelineMonodeRollout[]
}

export type PipelineResults = {
    pipeline: Pipeline
    lastRunId?: string;
}

export type DefaultBranch = {
    branch: VcsBranch;
}

export type PipelineId = string

export function pipelineId(pipeline: Pipeline): PipelineId {
    return `${pipeline.path.org}/${pipeline.path.repo}?${pipeline.name}?${pipeline.branch}`
}

export function descriptionOf(path: OrgRepoPath, name?: string): PipelineId {
    return `${path.org}/${path.repo}/${name}`
}

export function pipelineStatus(run: PipelineRun): PipelineStatus {
    const statuses = distinct([
        ...run.stages.map(s => s.status),
        ...run.rollouts
            .filter(s => [PipelineStatus.Skipped, PipelineStatus.Locked, PipelineStatus.Unknown].indexOf(s.status) < 0)
            .map(s => s.status),
        run.status
    ]);
    if (statuses.length === 1) return statuses[0]

    const statusesWithoutPassed = statuses.filter(s => s !== PipelineStatus.Passed);
    return statusesWithoutPassed.includes(PipelineStatus.Failed)
        ? PipelineStatus.Failed
        : statusesWithoutPassed[statusesWithoutPassed.length - 1]
}
