import {PipelineStatus} from "../api/pipelines/model";
import {CustomIconName} from "../../common/components/icons";
import {monopolisTheme} from "../../common/styles/theme";

export type PipelineStatusStyle = {
    color: string
    backgroundColor: string
    backgroundShade: string
    icon: CustomIconName
    spin: boolean
}

const defaultPipelineStatusStyle: PipelineStatusStyle = {
    color: monopolisTheme.palette.background.paper,
    backgroundColor: monopolisTheme.palette.grey[300],
    backgroundShade: monopolisTheme.palette.grey[100],
    icon: "circle-dot",
    spin: false
}

export const PipelineStatusStyles: Record<PipelineStatus, PipelineStatusStyle> = {
    Failed: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.error.main,
        backgroundShade: monopolisTheme.palette.error.light,
        icon: "times",
    },
    Passed: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.success.main,
        backgroundShade: monopolisTheme.palette.success.light,
        icon: "check",
    },
    Created: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.info.main,
        backgroundShade: monopolisTheme.palette.info.light,
        icon: "spinner",
        spin: true
    },
    Running: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.info.main,
        backgroundShade: monopolisTheme.palette.info.light,
        icon: "spinner",
        spin: true
    },
    Locked: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.info.main,
        backgroundShade: monopolisTheme.palette.info.light,
        icon: "pause",
    },
    Skipped: {
        ...defaultPipelineStatusStyle,
    },
    Unknown: {
        ...defaultPipelineStatusStyle,
    }
}

export const RolloutStatusStyles: Record<PipelineStatus, PipelineStatusStyle> = {
    Failed: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.error.main,
        backgroundShade: monopolisTheme.palette.error.light,
        icon: "times",
    },
    Passed: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.success.main,
        backgroundShade: monopolisTheme.palette.success.light,
        icon: "check",
    },
    Running: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.info.main,
        backgroundShade: monopolisTheme.palette.info.light,
        icon: "spinner",
        spin: true
    },
    Created: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.info.main,
        backgroundShade: monopolisTheme.palette.info.light,
        icon: "spinner",
        spin: true
    },
    Locked: {
        ...defaultPipelineStatusStyle,
        backgroundColor: monopolisTheme.palette.info.main,
        backgroundShade: monopolisTheme.palette.info.light,
        icon: "pause",
    },
    Skipped: {
        ...defaultPipelineStatusStyle,
    },
    Unknown: {
        ...defaultPipelineStatusStyle,
    }
}
