import {CommitSha, Monode, RolloutTarget, VcsRepository} from "./index";
import {useParams} from "react-router-dom";
import {EmailRegistrationToken} from "../api/user/model";
import {DashboardId} from "../api/dashboard/model";
import {VcsOwner} from "../../common/models";
import {OAuthProvider, OAuthStatus} from "../../common/models";

export enum PathSegments {
    provider = ":provider",
    vcs = ":vcs",
    owner = ":owner",
    repository = ":repository",
    monode = ":monode",
    sha = ":sha",
    status = ":status",
    target = ":target",
    dashboard = ":dashboard",
}

export enum Paths {
    Home = '/',
    LoginCLI = '/-/login/cli',
    LoginCLICompleted = '/-/login/cli/:status',
    LoginCallback = '/-/login/callback',
    Logout = '/-/logout',
    UserSettings = '/-/settings',
    VerifyEmail = '/-/email/verify/:token',
    OnboardingCallback = '/-/onboarding/callback/:provider',
    Org = '/:owner',
    OrgDashboard = '/:owner/-/dashboard/:dashboard',
    OrgWelcome = '/:owner/-/welcome',
    OrgTarget = '/:owner/-/target/:target',
    OrgConfiguration = '/:owner/-/settings',
    Repository = '/:owner/:repository',
    RepositoryTarget = '/:owner/:repository/-/target/:target',
    RepositorySettings = '/:owner/:repository/-/settings',
    Commit = '/:owner/:repository/-/commit/:sha',
    Monode = '/:owner/:repository/-/x-ray/:monode',
    MonodeTarget = '/:owner/:repository/-/x-ray/:monode/target/:target',
    TestDashboard = '/-/test-dash',
    TestBoundary = '/-/test-boundary',
}

export type OwnerPathParameter = {
    owner: VcsOwner
}

export type RepositoryPathParameter = {
    repository: VcsRepository
}

export type MonodePathParameter = {
    monode: Monode
}

export type CommitPathParameter = {
    sha: CommitSha
}

export type TargetPathParameter = {
    target: RolloutTarget
}

export type OAuthProviderPathParameter = {
    provider: OAuthProvider
}

export type OAuthStatusPathParameter = {
    status: OAuthStatus
}

export type DashboardPathParameter = {
    dashboard: DashboardId
}

export type VerifyEmailTokenPathParameter = {
    token: EmailRegistrationToken
}

export function usePathParams<T extends Record<string, string>>() {
    return useParams() as T;
}
