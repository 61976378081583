import * as React from 'react';
import {Menu, MenuActionDefinition} from "../../../common/components/navigation/Menu";
import {DashboardId} from "../../api/dashboard/model";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {VcsOwner} from "../../../common/models";
import {useNavigation} from "../../../common/port/Navigation";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type OrgBreadcrumbsMenuAction = "editDashboard"

export type OrgDashboardBreadcrumbsMenuProps = {
  owner: VcsOwner,
  dashboardId: DashboardId,
  actions: OrgBreadcrumbsMenuAction[]
  onSuccessfulAction?: (action: OrgBreadcrumbsMenuAction) => void
}

export function OrgDashboardBreadcrumbsMenu({owner, dashboardId, actions}: OrgDashboardBreadcrumbsMenuProps) {
  const {navigateTo} = useNavigation()
  const {urls} = useUserAppInfrastructure()

  const actionDefinitionIfAllowed = (action: OrgBreadcrumbsMenuAction): (MenuActionDefinition | undefined) => {
    if (actions.includes(action)) {
      switch (action) {
        case "editDashboard":
          return {
            id: action,
            text: "Edit",
            icon: <EditRoundedIcon fontSize="small"/>,
            onAction: () => navigateTo(urls.editOrgDashboard(owner, dashboardId))
          }
      }
    }
  }

  return <Menu actions={[
    actionDefinitionIfAllowed("editDashboard")
  ]}/>
}
