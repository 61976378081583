import * as React from 'react';
import Box from "@mui/material/Box";
import {PipelineStatus} from "../../api/pipelines/model";
import {Tooltip} from "@mui/material";
import {PipelineStatusStyle, PipelineStatusStyles} from "../../styles/pipelines";
import {CustomIcon} from "../../../common/components/icons";
import {monopolisTheme} from "../../../common/styles/theme";

export type PipelineStatusIconVariant = "job" | "rollout"

export function PipelineStatusIcon
({
   status,
   variant,
   hasLink = true,
   isFilled = true,
   tooltip = null,
   size = 20,
   linkColor = monopolisTheme.palette.secondary.main
 }: PipelineStatusIconProps) {
  const pipelineStatus = status || PipelineStatus.Unknown;
  const styles = createStyles(status, variant, isFilled, linkColor, size)
  const stageStatus = PipelineStatusStyles[pipelineStatus];
  return (
      <Tooltip arrow
               disableHoverListener={!tooltip}
               title={tooltip || ""}
               placement="top"
               leaveDelay={0}>
        <Box sx={styles.outerContainer}>
          <Box sx={styles.container}>
            <CustomIcon name={stageStatus.icon} spin={stageStatus.spin} style={{height: size * 0.6}}/>
          </Box>

          {hasLink && <Box sx={styles.link}/>}
        </Box>
      </Tooltip>
  )
}

export type PipelineStatusIconProps = {
  status: PipelineStatus;
  tooltip?: React.ReactNode
  hasLink?: boolean;
  isFilled?: boolean;
  linkColor?: string;
  size?: number;
  variant: PipelineStatusIconVariant;
  styles: Record<PipelineStatus, PipelineStatusStyle>;
}


const createStyles = (status: PipelineStatus, variant: PipelineStatusIconVariant, isFilled: boolean, linkColor: string, size: number) => {
  const statusStyle = PipelineStatusStyles[status]
  return ({
        outerContainer: {
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: size,
          width: size,
          maxWidth: size,
          maxHeight: size,
          minWidth: size,
          minHeight: size,
        },
        container: {
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: size,
          width: size,
          maxWidth: size,
          maxHeight: size,
          minWidth: size,
          minHeight: size,
          borderRadius: variant === "job" ? size / 2 : "5px",
          border: isFilled ? 0 : 1,
          borderColor: statusStyle.backgroundColor,
          backgroundColor: isFilled ? statusStyle.backgroundColor : statusStyle.backgroundShade,
          color: isFilled ? statusStyle.color : statusStyle.backgroundColor,
          zIndex: 10,
          ':hover': {
            border: isFilled ? 0 : 2,
          }
        },
        link: {
          position: "absolute",
          height: 4,
          width: size,
          backgroundColor: linkColor,
          zIndex: 1,
          left: 10,
        }
      } as const
  );
}
