import * as React from "react";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ApiPluginConfigView} from "../../api/configurations/model";

export type ApiSelectionProps = {
  showDetails: boolean
  configs: ApiPluginConfigView[]
  selected: ApiPluginConfigView | undefined;
  maxWidth: string;
  onSelect: (config: ApiPluginConfigView | undefined) => void;
}

export function ApiSelection({configs, selected, maxWidth, onSelect, showDetails}: ApiSelectionProps) {
  const [selectedConfig, setSelectedConfig] = React.useState(keyFor(selected));

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const newSelection = configs.find(item => keyFor(item) === value);
    setSelectedConfig(keyFor(newSelection));
    onSelect(newSelection)
  };

  return (
      <Select
          sx={{minWidth: maxWidth, maxWidth: maxWidth}}
          placeholder="api"
          value={selectedConfig}
          onChange={handleChange}
      >{configs.map(config => {
            const key = keyFor(config);
            return <MenuItem key={key} value={key}>
              {config.monode}{showDetails && <span>&nbsp;&nbsp;({config.path.org + "/" + config.path.repo})</span>}
            </MenuItem>;
          }
      )}
      </Select>
  )
}

function keyFor(config: ApiPluginConfigView | undefined): string {
  return config ? (config.path + config.monode + config.branch) : ""
}


