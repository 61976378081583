import {useCallback} from "react";
import {currentLocation} from "../../common/navigation";
import {useAccountAppInfrastructure} from "../AccountAppInfrastructure";
import {AuthenticatedAccountProfile} from "./AuthenticatedAccountProfile";
import {UnauthenticatedProfile} from "../../common/auth/UnauthenticatedProfile";

export const useAccountAuthentication = () => {
  const {authStore} = useAccountAppInfrastructure();
  const accountProfile = authStore.profile();

  const redirectToLogin = useCallback(
      (redirectionUri: string) => {
        authStore.redirectToLogin(redirectionUri)
      },
      [authStore]
  );
  //
  // const navigateToLogin = (redirectionUri?: string) => {
  //   redirectionUri && store.setAuthenticationRedirect(redirectionUri)
  //   navigateTo(AuthenticationResource.login(provider))
  // }

  const loginFromCallback = (token: string, profile: AuthenticatedAccountProfile) => {
    authStore.authenticate(token, profile);
  };

  const enforceLogin = () => {
    redirectToLogin(currentLocation())
  };

  const logout = useCallback(() => {
    authStore.logout();
  }, [authStore]);

  return {
    logout,
    accountProfile,
    enforceLogin,
    loginFromCallback,
  }
}

export type AccountAuthenticationProfile = AuthenticatedAccountProfile | UnauthenticatedProfile
