import * as React from "react";
import {useCallback} from "react";
import {OrgConfigurationPageTabProps} from "./OrgConfigurationPageTab";
import {
  useAllOrgRepositoriesQuery,
  useDisableRepositoryMutation,
  useEnableRepositoryMutation,
  useOrgAdminConfigurationQuery,
} from "../../api/org";
import {useSnackbar} from "notistack";
import {PostMutation} from "../../../common/api/query";
import {RepoRequest} from "../../api/org/model";
import {RepositoryOption, RepositorySearchPanel} from "../../components/org/RepositorySearchPanel";
import {snackOptions} from "../../../common/snackbar";
import {Box, Divider, IconButton, List, Paper, Skeleton, Typography} from "@mui/material";
import {repoLimitFrom} from "../../utils/orgConfigurations";
import {SelectReposHint} from "../../components/hints";
import {RepositoryRow} from "../../components/org/RepositoryRow";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export function OrgConfigurationPageRepositoriesTab({userProfile, owner, onError}: OrgConfigurationPageTabProps) {
  const vcs = userProfile.vcs
  const enableMutation = useEnableRepositoryMutation({org: owner})
  const disableMutation = useDisableRepositoryMutation({org: owner})
  const {data: settings} = useOrgAdminConfigurationQuery({owner, writeCache: true})
  const {data: repositories} = useAllOrgRepositoriesQuery({owner})

  const {enqueueSnackbar} = useSnackbar();

  const handleAction = useCallback(async (mutation: PostMutation<RepoRequest>, option: RepositoryOption, success: string, failure: string) => {
    const response = await mutation.mutateAsync({repo: option.path.repo});
    const isSuccess = response.kind === "ok";
    enqueueSnackbar(isSuccess ? success : failure, snackOptions(isSuccess))
  }, [enqueueSnackbar])

  const onSubscribe = async (option: RepositoryOption) => await handleAction(enableMutation, option,
      `Enabled ${option.path} repository`,
      `Failed to enable ${option.path} repository`
  )

  const onUnsubscribe = async (option: RepositoryOption) => await handleAction(disableMutation, option,
      `Disabled ${option.path} repository`,
      `Failed to disable ${option.path} repository`
  )

  if (!settings || !repositories) {
    return <Box>
      <Skeleton variant="rectangular" width="100%" height={100}/>
    </Box>
  }

  if (settings.kind === "error") {
    onError(settings.error)
    return <></>;
  }

  if (repositories.kind === "error") {
    onError(repositories.error)
    return <></>;
  }

  const limits = repoLimitFrom(settings.data);
  const all = repositories.data
  const selected = repositories.data.filter(repo => settings.data.repos.indexOf(repo.path.repo) >= 0)
  const disabled = limits.isLimitReached

  const allRepos: RepositoryOption[] = all.map(r => (
      {isPrivate: r.isPrivate, path: r.path, vcs, org: owner}
  ))

  const selectedRepos: RepositoryOption[] = selected.map(r => (
      {isPrivate: r.isPrivate, path: r.path, vcs, org: owner}
  ))

  return <Box sx={{mb: 5}}>
    <Paper>
      <Box sx={{pt: 2, px: 2}}>
        <Box sx={{mb: 1}}>
          <Typography variant="body2"
                      color="text.secondary">{limits.selected} selected, {limits.available} available</Typography>
          {limits.selected === 0 && <SelectReposHint/>}
        </Box>

        <RepositorySearchPanel
            all={allRepos}
            selected={selectedRepos}
            disabled={disabled}
            onError={onError}
            onAdd={onSubscribe}
            onRemove={onUnsubscribe}
        />
      </Box>
      <List>
        {selectedRepos.map((option, index) =>
            <Box key={owner + option.path}>
              <RepositoryRow {...option}
                             sx={{ml: 1}}
                             menu={() =>
                                 <IconButton sx={{mr: 1}} aria-label="disable" onClick={() => onUnsubscribe(option)}>
                                   <CloseRoundedIcon/>
                                 </IconButton>
                             }/>
              {index !== selected.length - 1 && <Divider sx={{mt: 1, mb: 1}}/>}
            </Box>
        )}
      </List>
    </Paper>
  </Box>
}
