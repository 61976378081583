import {Box, Checkbox, Divider, ListItemText, MenuItem, Select, SelectChangeEvent, Tabs} from "@mui/material";
import * as React from "react";
import {SyntheticEvent, useCallback, useMemo, useState} from "react";
import {StyledTab, StyledTabContent} from "../../../common/components/navigation/StyledTab";
import {AuditEvent, AuditEventType, AuditEventTypes} from "../../api/events/model";
import {EventsTimeline} from "./timeline/EventsTimeline";
import {EventsList} from "./list/EventsList";
import {RepoMonode} from "../../models";
import {humanise} from "../../../common/strings";
import {filterAuditEventsByType} from "../../utils/events";
import {EventsGraph} from "./graph/EventsGraph";

export type EventsViewProps = {
  monodes: RepoMonode[]
  events: AuditEvent[]
  onEventTypes?: (eventTypes: AuditEventType[]) => void
}

export function EventsView({monodes, events, onEventTypes}: EventsViewProps) {
  const tabGroup = "events"

  enum EventsViewTabId {timeline = "timeline", graph = "graph", list = "list"}

  type EventsViewTab = { id: EventsViewTabId, title: string }

  const tabs: EventsViewTab[] = [
    {id: EventsViewTabId.timeline, title: "Timeline"},
    {id: EventsViewTabId.graph, title: "Graph"},
    {id: EventsViewTabId.list, title: "List"}
  ]

  const [tab, setTab] = useState<EventsViewTabId>(EventsViewTabId.timeline)
  const [eventTypes, setEventTypes] = useState<AuditEventType[]>([]);
  const filteredEvents = useMemo(() => filterAuditEventsByType(events, eventTypes), [events, eventTypes]);

  const handleTabChange = useCallback((event: SyntheticEvent, newValue: EventsViewTabId) => {
    setTab(newValue)
  }, []);

  const handleEventsChange = useCallback((event: SelectChangeEvent<typeof eventTypes>) => {
    const value = event.target.value
    const newEventTypes = typeof value === 'string' ? value.split(',').map(event => event as AuditEventType) : value;
    setEventTypes(newEventTypes);
    onEventTypes && onEventTypes(newEventTypes)
  }, [onEventTypes]);

  return <Box sx={{width: "100%"}}>
    <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
      <Box sx={{display: "flex", alignItems: "center", mx: 2}}>

        <Tabs value={tab} onChange={handleTabChange} aria-label="Events view tabs">
          {tabs.map(tabData => <StyledTab key={tabData.id}
                                          value={tabData.id}
                                          tabId={tabData.id}
                                          label={tabData.title}
                                          tabGroup={tabGroup}/>)}
        </Tabs>

        <Box sx={{flexGrow: 1}}></Box>

        <Select
            style={{height: 32}}
            sx={{width: 200}}
            multiple
            displayEmpty
            size="small"
            value={eventTypes}
            onChange={handleEventsChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>all events</em>;
              }
              return selected.map(humanise).join(', ');
            }}
        >
          {AuditEventTypes.map(eventType => (
              <MenuItem key={eventType} value={eventType}>
                <Checkbox checked={eventTypes.indexOf(eventType) > -1}/>
                <ListItemText primary={humanise(eventType)}/>
              </MenuItem>
          ))}
        </Select>
      </Box>
      <Divider/>
    </Box>

    <StyledTabContent group={tabGroup} id={EventsViewTabId.timeline} selectedId={tab}>
      <EventsTimeline monodes={monodes} events={filteredEvents}/>
    </StyledTabContent>

    <StyledTabContent group={tabGroup} id={EventsViewTabId.graph} selectedId={tab}>
      <EventsGraph events={filteredEvents}/>
    </StyledTabContent>

    <StyledTabContent group={tabGroup} id={EventsViewTabId.list} selectedId={tab}>
      <EventsList events={filteredEvents}/>
    </StyledTabContent>
  </Box>
}


