import {Box, Typography} from "@mui/material";
import * as React from "react";
import {VcsAvatar} from "./VcsAvatar";
import {CalendarIcon, GitBranchIcon, GitCommitIcon} from "@primer/octicons-react";
import {Link} from "../../common/components/navigation/Link";
import {VcsBranch, VcsEvent} from "../models";
import {User} from "../api/model";
import {monopolisTheme} from "../../common/styles/theme";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";
import {DateTime, OrgRepoPath} from "../../common/models";
import {DateFromNow} from "./DateFromNow";

export type VcsCommitProps = {
  path: OrgRepoPath
  branch: VcsBranch
  user: User
  message: string
  sha: string
  event?: VcsEvent
  date?: DateTime
}

export function VcsCommit({path, user, event, message, sha, branch, date}: VcsCommitProps) {
  const {urls, vcsUrls} = useUserAppInfrastructure()
  return (
      <Box sx={{display: "flex", flexDirection: "row", width: "100%", alignItems:"center"}}>
        <Box sx={{
          flex: 1,
          p: 1,
          display: "flex",
          minWidth: "40px",
          maxWidth: "40px",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <VcsAvatar name={user.login} avatar={user.avatar} size={35}/>
        </Box>

        <Box sx={{flexGrow: 1, maxWidth: "calc(100% - 40px)", display: "flex", flexDirection: "column",}}>
          <Typography variant="body2" noWrap maxWidth="100%" sx={{textOverflow: "ellipsis"}}>
            {message}
          </Typography>
          <Typography variant="body2" noWrap maxWidth="100%" sx={{textOverflow: "ellipsis"}}>
            {
                event && <Link href={urls.repository(path, branch)} sx={{mr: 1}}
                               tooltip={path.org + "/" + path.repo} color={monopolisTheme.palette.primary.main}>
                  {event}
                </Link>
            }

            <Link data-test="commit" href={urls.commit({path, branch, sha})}
                  tooltip={sha} color={monopolisTheme.palette.primary.main}>
              <GitCommitIcon/> {sha.substr(0, 8)}
            </Link>

            {
                branch && <Link href={vcsUrls.branch(path, branch)}
                                sx={{ml: 1}}
                                tooltip={branch} color={monopolisTheme.palette.primary.main}>
                  <GitBranchIcon/> {branch}
                </Link>
            }

            {date && <Typography variant="caption" sx={{ml: 1}} color={monopolisTheme.palette.primary.main}>
              <CalendarIcon/> <DateFromNow variant="caption" display="inline"
                                           date={date}/>
            </Typography>}
          </Typography>
        </Box>
      </Box>
  )
}
