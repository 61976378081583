import {ApiKey, VcsOwner, VcsPath} from "../../../common/models";
import {Validation} from "../../../common/validation";

export type MemberRequest = {
    member: VcsOwner
}

export type RepoRequest = {
    repo: VcsPath
}

export enum IntegrationType {
    Discord = "Discord",
    PagerDuty = "PagerDuty",
    Slack = "Slack",
    StatusPage = "StatusPage",
    Teams = "Teams",
}

export type SlackIntegrationSettings = {
    type: IntegrationType.Slack
    integrated: false
} | {
    type: IntegrationType.Slack
    integrated: true
    teamName: string
}

export type DiscordIntegrationSettings = {
    type: IntegrationType.Discord
    integrated: false
} | {
    type: IntegrationType.Discord
    integrated: true
    guildName: string
}

export type PagerDutyIntegrationSettings = {
    type: IntegrationType.PagerDuty
    integrated: boolean
}

export type StatusPageIntegrationSettings = {
    type: IntegrationType.StatusPage
    integrated: boolean
}

export type TeamsIntegrationSettings = {
    type: IntegrationType.Teams
    integrated: boolean
}

export type IntegrationSettings = SlackIntegrationSettings
    | DiscordIntegrationSettings
    | TeamsIntegrationSettings
    | PagerDutyIntegrationSettings
    | StatusPageIntegrationSettings

export type ApiKeyIntegration = {
    apiKey: ApiKey
}

export type ApiKeyName = string
export type ApiKeyExpiry = string
export enum ApiKeyAccessLevel {
    Read= "Read",
    Write = "Write"
}

export const ApiKeyAccessLevels = Object.values(ApiKeyAccessLevel)

export type ApiKeyDetails = {
    name: ApiKeyName,
    accessLevel: ApiKeyAccessLevel,
    expiry: ApiKeyExpiry
}

export type ApiKeyResponse = {
    apiKeyName: ApiKeyName,
    apiKey: ApiKey,
}

export function validateApiKeyName(value: ApiKeyName): Validation<ApiKeyName> {
    return value.trim().length > 0
        ? {result: "success", value: value}
        : {result: "failure", value: value, error: "API key name must not be empty"}
}
