import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {SystemError} from "../../../common/api/TransportLayer";
import {OwnerPathParameter, usePathParams} from "../../models/Paths";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {useTabControls} from "../../../common/tabs";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {OrgConfigurationPageTab, OrgConfigurationPageTabProps} from "./OrgConfigurationPageTab";
import {OrgConfigurationPageRepositoriesTab} from "./OrgConfigurationPageRepositoriesTab";
import {OrgConfigurationPageMembersTab} from "./OrgConfigurationPageMembersTab";
import {OrgConfigurationPageIntegrationsTab} from "./OrgConfigurationPageIntegrationsTab";
import {OrgConfigurationPageNotificationsTab} from "./OrgConfigurationPageNotificationsTab";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {OrgConfigurationPageSecurityTab} from "./OrgConfigurationPageSecurityTab";

export function OrgConfigurationPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {urls} = useUserAppInfrastructure()
  const {userProfile} = useUserAuthentication()
  const pathParams = usePathParams<OwnerPathParameter>();
  const owner = pathParams.owner;
  const tabs = useTabControls({
    defaultTab: OrgConfigurationPageTab.repositories,
    tabs: [
      {title: "Repositories", id: OrgConfigurationPageTab.repositories},
      {title: "Members", id: OrgConfigurationPageTab.members},
      {title: "Security", id: OrgConfigurationPageTab.security},
      {title: "Integrations", id: OrgConfigurationPageTab.integrations},
      {title: "Notifications", id: OrgConfigurationPageTab.notifications},
    ]
  })

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const banner = <OrgTrialEndingBanner owner={owner} onError={setError}/>

  const header = <PageHeader
      title="Settings"
      location={urls.orgConfiguration(owner)}
      breadcrumbs={[
        <OwnerBreadcrumb owner={owner}/>,
      ]}
      tabs={tabs}
  />

  const tabProps: OrgConfigurationPageTabProps = {
    owner: pathParams.owner,
    userProfile,
    onError: setError,
  }

  return <UserAppPage pageId={UserPageId.OrgConfiguration} title={owner} contentHeader={header} banner={banner} error={error}
                      profile={userProfile}>
    <PageTab selectedId={tabs.selected} id={OrgConfigurationPageTab.repositories}>
      <OrgConfigurationPageRepositoriesTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={OrgConfigurationPageTab.members}>
      <OrgConfigurationPageMembersTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={OrgConfigurationPageTab.security}>
      <OrgConfigurationPageSecurityTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={OrgConfigurationPageTab.integrations}>
      <OrgConfigurationPageIntegrationsTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={OrgConfigurationPageTab.notifications}>
      <OrgConfigurationPageNotificationsTab {...tabProps}/>
    </PageTab>
  </UserAppPage>
}
