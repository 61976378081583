import * as React from "react";
import {Link} from "../../../common/components/navigation/Link";
import {formatBreadcrumbs} from "../../../common/breadcrumbs";
import {VcsIcon} from "../VcsIcon";
import {Box} from "@mui/material";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {OrgRepoPath, VcsProvider} from "../../../common/models";

export type RepoBreadcrumbProps = {
  path: OrgRepoPath
  vcs: VcsProvider
}

export function RepoBreadcrumb({vcs, path}: RepoBreadcrumbProps) {
  const {vcsUrls, urls} = useUserAppInfrastructure()
  return <Box>
    <Link href={urls.repository(path)}>
      {formatBreadcrumbs(path.repo)}
    </Link>

    <span style={{marginLeft: 8}}>
      <Link aria-label={vcs} href={vcsUrls.repo(path)}>
        <VcsIcon type={vcs}/>
      </Link>
    </span>
  </Box>
}
