import * as React from "react";
import {ReactNode, useCallback} from "react";
import {OrgConfigurationPageTabProps} from "./OrgConfigurationPageTab";
import {
  useDisableMemberMutation,
  useEnableMemberMutation,
  useOrgAdminConfigurationQuery,
  useOrgMembersQuery,
} from "../../api/org";
import {useSnackbar} from "notistack";
import {SystemResponse} from "../../../common/api/TransportLayer";
import {EmptyResponse, MutationResult} from "../../../common/api/query";
import {MemberRequest} from "../../api/org/model";
import {User} from "../../api/model";
import {snackOptions} from "../../../common/snackbar";
import {Avatar, Box, Divider, IconButton, List, ListItem, Paper, Skeleton, Typography} from "@mui/material";
import {memberLimitFrom} from "../../utils/orgConfigurations";
import {SelectMembersHint} from "../../components/hints";
import {MemberSearchPanel} from "../../components/org/MemberSearchPanel";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {Link} from "../../../common/components/navigation/Link";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {VcsOwner} from "../../../common/models";
import {VcsProvider} from "../../../common/models";

export function OrgConfigurationPageMembersTab({userProfile, owner, onError}: OrgConfigurationPageTabProps) {
  const vcs = userProfile.vcs
  const enableMember = useEnableMemberMutation({org: owner})
  const disableMember = useDisableMemberMutation({org: owner})
  const {data: settings} = useOrgAdminConfigurationQuery({owner, writeCache: true})
  const {data: members} = useOrgMembersQuery({owner})

  const {enqueueSnackbar} = useSnackbar();

  const handleAction = useCallback(async (mutation: MutationResult<SystemResponse<EmptyResponse>, unknown, MemberRequest>, member: User, success: string, failure: string) => {
    const response = await mutation.mutateAsync({member: member.login});
    const isSuccess = response.kind === "ok";
    enqueueSnackbar(isSuccess ? success : failure, snackOptions(isSuccess))
  }, [enqueueSnackbar])

  const onSubscribe = async (member: User) => await handleAction(enableMember, member,
      `Enabled member ${member.login}`,
      `Failed to enable member ${member.login}`
  )

  const onUnsubscribe = async (member: User) => await handleAction(disableMember, member,
      `Disabled member ${member.login}`,
      `Failed to disable member ${member.login}`
  )

  if (!settings || !members) {
    return <Box>
      <Skeleton variant="rectangular" width="100%" height={100}/>
    </Box>
  }

  if (settings.kind === "error") {
    onError(settings.error)
    return <></>;
  }

  if (members.kind === "error") {
    onError(members.error)
    return <></>;
  }

  const limits = memberLimitFrom(settings.data, [])// TODO: get member count for org
  const disabled = limits.isLimitReached
  const all = members.data
  const selected = members.data.filter(member => settings.data.members.indexOf(member.login) >= 0)

  return <Box sx={{mb: 5}}>
    <Paper>
      <Box sx={{pt: 2, px: 2}}>
        <Box sx={{mb: 1}}>
          <Typography variant="body2"
                      color="text.secondary">{limits.selected} selected, {limits.available} available</Typography>
          {limits.selected === 0 && <SelectMembersHint/>}
        </Box>

        <MemberSearchPanel
            all={all}
            selected={selected}
            disabled={disabled}
            onError={onError}
            onAdd={onSubscribe}
            onRemove={onUnsubscribe}
        />
      </Box>
      <List>
        {selected.map((member, index) =>
            <Box key={owner + member.login}>
              <MemberRow vcs={vcs}
                         owner={owner}
                         member={member}
                         menu={() =>
                             <IconButton aria-label="disable" onClick={() => onUnsubscribe(member)}>
                               <CloseRoundedIcon/>
                             </IconButton>
                         }/>
              {index !== selected.length - 1 && <Divider sx={{mt: 1, mb: 1}}/>}
            </Box>
        )}
      </List>
    </Paper>
  </Box>
}

type MemberRowMenuProvider = (params: { vcs: VcsProvider, owner: VcsOwner, member: VcsOwner }) => ReactNode

type MemberRowProps = {
  vcs: VcsProvider
  owner: VcsOwner
  member: User
  menu: MemberRowMenuProvider
}

function MemberRow({vcs, owner, member, menu}: MemberRowProps) {
  const {vcsUrls} = useUserAppInfrastructure()
  const spacingWidth = 30
  const menuSpacingWidth = 10

  return <ListItem>
    <Box sx={{display: "flex", flexDirection: "row", height: 35, width: "100%"}} alignItems="center">
      <Box sx={{flexGrow: 1, width: "100%", marginRight: `${spacingWidth}px`}}>
        <Box alignItems="center" display="flex" flexDirection="row">
          <Link href={vcsUrls.user(member.login)}>
            <Avatar src={member.avatar} sx={{display: "inline-block", width: 30, height: 30, m: 1}}/>
          </Link>
          <Typography>{member.login}</Typography>
        </Box>
      </Box>
      <Box sx={{flex: 1, marginLeft: `${menuSpacingWidth}px`}}>
        {menu({vcs, owner, member: member.login})}
      </Box>
    </Box>
  </ListItem>
}
