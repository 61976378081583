import {DashboardId} from "./model";
import {VcsOwner} from "../../../common/models";

export class DashboardUrls {
    static userDashboard() {
        return `/dashboard/user`;
    }

    static updateUserDashboard() {
        return `/dashboard/user`;
    }

    static dashboards(params: { owner: VcsOwner }) {
        return `/dashboard/org/${params.owner}`
    }

    static dashboard(params: { owner: VcsOwner, dashboardId: DashboardId }) {
        return `/dashboard/org/${params.owner}/${params.dashboardId}`
    }

    static createDashboard(params: { owner: VcsOwner }) {
        return `/dashboard/org/${params.owner}`
    }

    static updateDashboard(params: { owner: VcsOwner, dashboardId: DashboardId }) {
        return `/dashboard/org/${params.owner}/${params.dashboardId}`
    }

    static deleteDashboard(params: { owner: VcsOwner, dashboardId: DashboardId }) {
        return `/dashboard/org/${params.owner}/${params.dashboardId}/delete`
    }
}
