import {useRefetchUserProfileQuery} from "../api/user";
import {useUserAuthentication} from "./useUserAuthentication";
import {OwnerPathParameter, RepositoryPathParameter, usePathParams} from "../models/Paths";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import React, {ReactNode, useEffect} from "react";
import {PermissionTemplate, UserPermissions} from "./Permissions";
import {UnauthorizedPage} from "../pages/error/UnauthorizedPage";


export function RequireAuthentication({children, permissions}: RequireAuthenticationWithProps) {
  const {enforceLogin} = useUserAuthentication();
  const profile = useRefetchUserProfileQuery()
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter>();
  const isAuthenticated = profile.type === AuthenticationType.User

  useEffect(() => {
    if (!isAuthenticated) {
      enforceLogin();
    }
  }, [isAuthenticated, enforceLogin]);

  if (!isAuthenticated) {
    return <UnauthorizedPage/>;
  }

  if (permissions) {
    for (const required of permissions) {
      if (!UserPermissions.checkAllowed(profile, required, pathParams)) return <UnauthorizedPage/>
    }
  }

  return <>{children}</>;
}

interface RequireAuthenticationWithProps {
  children: ReactNode;
  permissions?: PermissionTemplate[]
}
