import * as React from 'react';
import {PipelineStatus} from "../../api/pipelines/model";
import {PipelineStatusIcon} from "./PipelineStatusIcon";
import {Box, Typography} from "@mui/material";
import {PipelineStatusStyles} from "../../styles/pipelines";

export function PipelineStatusPill({status}: PipelineStatusPillProps) {
  const styles = createStyles(status);
  return (
      <Box sx={styles.container}>
        <PipelineStatusIcon variant="job" status={status} hasLink={false} isFilled={true}
                            styles={PipelineStatusStyles}
        />
        <Typography sx={styles.text} noWrap variant="body2">{status}</Typography>
      </Box>
  )
}

export type PipelineStatusPillProps = {
  status: PipelineStatus
}

const createStyles = (status: PipelineStatus) => {
  const statusStyle = PipelineStatusStyles[status]
  const height = 30;
  const width = 120;
  return ({
        container: {
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          border: 1,
          pl: 1,
          borderColor: statusStyle.backgroundColor,
          borderRadius: 2,
          height: height,
          minHeight: height,
          maxHeight: height,
          width: width,
          minWidth: width,
          maxWidth: width,
          backgroundColor: statusStyle.backgroundShade
        },
        text: {
          color: statusStyle.backgroundColor,
          ml: 1,
          mr: 1
        }
      } as const
  );
}


