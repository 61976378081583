import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import {Grid} from "@mui/material";
import {Panel} from "../../../common/components/Panel";
import {pipelineId} from "../../api/pipelines/model";
import {
  ExtendedPipeline,
  extendPipelines,
  PipelineMenuProvider,
  PipelinesTableRowFieldsFilter
} from "../../utils/pipelines";
import {PipelinesTableRow} from "./PipelinesTableRow";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {DragEndEvent} from "@dnd-kit/core/dist/types/events";
import {restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {monopolisConstraints} from "../../../common/styles/constraints";

export type PipelinesTablePanelProps = {
  title: string
  testId?: string
  pipelines: ExtendedPipeline[];
  menu: PipelineMenuProvider;
  fields?: PipelinesTableRowFieldsFilter
  draggable?: boolean;
  onReordered?: (order: string[]) => void
}

export function PipelinesTablePanel({
                                      testId,
                                      pipelines,
                                      menu,
                                      title,
                                      draggable,
                                      onReordered,
                                      fields
                                    }: PipelinesTablePanelProps) {
  const cachedPipelines = useMemo(() => extendPipelines(pipelines, []), [pipelines])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [items, setItems] = useState<{ id: UniqueIdentifier }[]>(cachedPipelines);

  const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
  );

  const sortedPipelines = pipelines
      .map(p => {
        const id = pipelineId(p);
        return {...p, id: id, index: items.findIndex(x => x.id === id)}
      })
      .sort((a, b) => a.index - b.index)

  const handleReorder = useCallback((updated: { id: UniqueIdentifier }[]) => {
    onReordered && onReordered(updated.map(u => u.id as string))
  }, [onReordered])

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over!!.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(p => p.id === active.id);
        const newIndex = items.findIndex(p => p.id === over!!.id);

        const updated = arrayMove(items, oldIndex, newIndex);
        handleReorder(updated);
        return updated;
      });
    }
  }

  if (items.length !== sortedPipelines.length) {
    // this call backs twice - find better way
    setItems(sortedPipelines.map(p => ({id: p.id})))
    handleReorder(sortedPipelines)
  }

  if (cachedPipelines.length === 0) return null;

  return (
      <Grid item xs={12} data-test={testId}>
        <Panel header={title} contentProps={{sx: {p: 0, overflow: "auto"}}}>
          <TableContainer>
            <Table
                sx={{minWidth: monopolisConstraints.panelWidth}}
                aria-labelledby="tableTitle"
                size={'medium'}
            >
              <TableBody>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}
                    onDragEnd={handleDragEnd}
                >
                  <SortableContext
                      items={items}
                      strategy={verticalListSortingStrategy}
                  >
                    {sortedPipelines.map(pipeline =>
                        <PipelinesTableRow key={'pipeline-' + pipeline.id}
                                           fields={fields}
                                           draggable={draggable === true}
                                           pipeline={pipeline}
                                           run={pipeline.runs[0]}
                                           menu={menu}/>
                    )}
                  </SortableContext>
                </DndContext>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              count={cachedPipelines.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Panel>
      </Grid>
  );
}
