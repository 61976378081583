import {Box, Tooltip} from "@mui/material";
import * as React from "react";
import {useLayoutEffect, useRef, useState} from "react";
import {EventsTimelineGraphEvent, maxTimelineEventSpan, scaleTimelineEvents} from "./EventsTimeline";
import {RepoMonode} from "../../../models";
import {EventsTimelineSpanTooltip} from "./EventsTimelineSpanTooltip";
import {eventEntityFor, eventSpanColorFor} from "../../../utils/events";
import {EventsTimelineAxis} from "./EventsTimelineAxis";

type EventsTimelineBarProps = {
  event: EventsTimelineGraphEvent
  index: number
  height: number
}

export type EventsTimelineBarsProps = {
  events: EventsTimelineGraphEvent[]
  monodes: RepoMonode[]
  barHeight: number
  zoom: number
  onWidth?: (width: number) => void
}

export function EventsTimelineBars({events, monodes, barHeight, zoom, onWidth}: EventsTimelineBarsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [width, setWidth] = useState(0)
  const scaledEvents = scaleTimelineEvents(events, zoom)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const maxBarWidth = maxTimelineEventSpan(scaledEvents)?.end || 0
  const ref = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const newWidth = ref?.current?.offsetWidth || 0;
    setWidth(newWidth)
    onWidth && onWidth(newWidth)
  }, [onWidth]);

  return <Box onScroll={e => console.log(e)}
              ref={ref} sx={{
    position: "relative",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    flexGrow: 1,
    height: "100%",
  }}>
    <Box sx={{
      position: "relative",
    }}>

      {
        scaledEvents.map(event =>
            <EventsTimelineBar index={monodes.indexOf(eventEntityFor(event.event))} event={event}
                         height={barHeight}/>
        )
      }

      <EventsTimelineAxis events={scaledEvents} zoom={zoom} index={monodes.length} height={barHeight} width={Math.max(maxBarWidth, width)}/>

    </Box>
  </Box>
}

function EventsTimelineBar({index, height, event}: EventsTimelineBarProps) {
  const startOrEndBorderRadius = "5px"
  const middleBorderRadius = "0px"
  return <Box sx={{
    position: "absolute",
    // width: maxWidth + "px",
    height: height + "px",
    top: (index * height) + "px",
  }}
  >
    <Box sx={{
      position: "absolute",
      // width: "100000px",
      height: height + "px",
      // borderBottom: 1,
      // borderBottomColor: monopolisTheme.palette.secondary.main
    }}>
      {
        event.spans.map(
            (span, index) =>
                <Tooltip title={<EventsTimelineSpanTooltip event={event.event} span={span}/>} followCursor={true}
                         arrow={true} placement={"top"}>
                  <Box sx={{
                    position: "absolute",
                    left: span.start + "px",
                    width: (span.end - span.start) + "px",
                    height: height + "px",
                    paddingY: "4px",
                    transition: "all .5s ease-in-out",
                  }}>
                    <Box sx={{
                      borderTopLeftRadius: index === 0 ? startOrEndBorderRadius : middleBorderRadius,
                      borderBottomLeftRadius: index === 0 ? startOrEndBorderRadius : middleBorderRadius,
                      borderTopRightRadius: index === event.spans.length - 1 ? startOrEndBorderRadius : middleBorderRadius,
                      borderBottomRightRadius: index === event.spans.length - 1 ? startOrEndBorderRadius : middleBorderRadius,
                      width: "100%",
                      height: "100%",
                      opacity: 0.5,
                      backgroundColor: eventSpanColorFor(span.severity)
                    }}/>
                  </Box>
                </Tooltip>
        )
      }
    </Box>
  </Box>
}
