import ReactFlow, {Controls, Edge, Node, ReactFlowProvider, useReactFlow,} from "reactflow";
import {RolledOutMonodeGraphNode, RolledOutMonodeGraphNodeData} from "./RolledOutMonodeGraphNode";
import {repoMonode} from "../../models";
import * as React from "react";
import {useMemo, useState} from "react";
import {Box, CircularProgress, Paper, Typography} from "@mui/material";
import {PipelineStatus} from "../../api/pipelines/model";
import MonodeGraphEdge from "./MonodeGraphEdge";
import {getAllIncomers, getAllOutgoers, layoutGraph} from "../../utils/reactflow";
import {RolloutGraph} from "../../api/graphs/model";
import {OnError, SystemResponse} from "../../../common/api/TransportLayer";
import {monopolisTheme} from "../../../common/styles/theme";
import {QueryResult} from "../../../common/api/query";

import {VcsProvider} from "../../../common/models";

export function RolloutGraphPanel(props: RolloutGraphPanelProps) {
  // @ts-ignore
  return (<ReactFlowProvider>
    <RolloutGraphPan {...props}/>
  </ReactFlowProvider>)
}

// @ts-ignore
function RolloutGraphPan({vcs, graphResult}: RolloutGraphPanelProps) {
  const nodeTypes = useMemo(() => ({monode: RolledOutMonodeGraphNode}), []);
  const edgeTypes = useMemo(() => ({monode: MonodeGraphEdge}), []);
  const {data, isFetching} = graphResult

  const graphNodes = data && data.kind === "ok" && (data.data.nodes.map(node => ({
    id: repoMonode(node),
    type: 'monode',
    data: {
      vcs: vcs,
      rollout: node,
      status: PipelineStatus.Passed
    } as RolledOutMonodeGraphNodeData,
    position: {x: 0, y: 0}
  } as any)))

  const graphEdges = data && data.kind === "ok" && (data.data.edges.map(edge => ({
        type: 'monode',
        id: "edge-" + edge.source + "-" + edge.target,
        source: edge.source,
        target: edge.target,
        animated: true,
        style: {strokeWidth: 3, stroke: monopolisTheme.palette.primary.main}
      }
  )))

  const [nodes, setNodes] = useState<Node<RolledOutMonodeGraphNodeData>[]>([])
  const [edges, setEdges] = useState<Edge[]>([])

  const [previousGraph, setPreviousGraph] = useState<RolloutGraph | undefined>(undefined)
  const {fitView} = useReactFlow();

  if (graphNodes && graphEdges && data && data.kind === "ok" && data.data !== previousGraph) {
    setNodes(graphNodes)
    setEdges(graphEdges)
    setPreviousGraph(data.data)
    setTimeout(async () => {
      fitView();
    }, 0);
  }

  function highlightNode<T extends Node<RolledOutMonodeGraphNodeData> | Edge>(elem: T, highlight: boolean): T {
    elem.style = {
      ...elem.style,
      opacity: highlight ? 1 : 0.25,
      transition: "opacity .20s ease-in-out"
    }
    return elem
  }

  const highlightNodes = (event: React.MouseEvent, node: Node) => {
    if (node && nodes && edges) {
      const incomerIds = getAllIncomers(node, nodes, edges).map(node => node.id)
      const outgoerIds = getAllOutgoers(node, nodes, edges).map(node => node.id)

      setNodes(prevElements => {
        return prevElements.map(elem => {
          return highlightNode(elem,
              elem.id === node.id ||
              incomerIds.includes(elem.id) ||
              outgoerIds.includes(elem.id)
          )
        })
      })

      setEdges(prevElements => {
        return prevElements.map(elem => {
          return highlightNode(elem,
              (outgoerIds.includes(elem.target) && elem.source === node.id) ||
              (incomerIds.includes(elem.source) && elem.target === node.id) ||
              (incomerIds.includes(elem.target) && incomerIds.includes(elem.source)) ||
              (outgoerIds.includes(elem.target) && outgoerIds.includes(elem.source))
          )
        })
      })
    }
  }

  const resetNodeHighlights = () => {
    setNodes(prev => prev.map(elem => highlightNode(elem, true)));
    setEdges(prev => prev.map(elem => highlightNode(elem, true)));
  }

  return (
      <Paper data-test="rollout-graph"
             style={{position: "relative", width: "100%", height: "100%"}}
             sx={{backgroundColor: monopolisTheme.palette.secondary.main, p: 2}}>
        <Typography fontWeight={500} variant="body1">Target Graph</Typography>

        {(isFetching || !data) &&
            <Paper elevation={0} sx={{
              pointerEvents: "none",
              touchAction: "none",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              opacity: 0.82,
              transition: "opacity .10s ease-in-out",
              backgroundColor: monopolisTheme.palette.secondary.main,
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 100
            }}>
              <CircularProgress color="inherit"/>
            </Paper>
        }

        <Box className="reactflow-wrapper" sx={{width: "100%", height: "100%", py: 2}}>
          <ReactFlow
              style={{backgroundColor: monopolisTheme.palette.secondary.main}}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              nodes={layoutGraph(350, 150, nodes, edges)}
              edges={edges}
              maxZoom={0.7}
              snapToGrid
              draggable={false}
              nodesConnectable={false}
              contentEditable={false}
              nodesDraggable={false}
              onNodeMouseEnter={highlightNodes}
              onNodeMouseLeave={resetNodeHighlights}
              fitView
          >
            <Controls showInteractive={false}/>
          </ReactFlow>
        </Box>
      </Paper>
  )
}

export type RolloutGraphPanelProps = {
  vcs: VcsProvider;
  graphResult: QueryResult<SystemResponse<RolloutGraph>>
} & OnError



