import * as React from "react";
import {ReactNode} from "react";
import {Box, BoxProps, Divider, Grid, List, Paper, PaperProps, Stack, Typography} from "@mui/material";

export type ConfigurationStackProps = {
  children: ReactNode
}

export function ConfigurationStack({children}: ConfigurationStackProps) {
  return <Stack spacing={3}>
    {children}
  </Stack>
}

export type ConfigurationPanelProps = {
  children: ReactNode
} & PaperProps

export function ConfigurationPanel({children, ...rest}: ConfigurationPanelProps) {
  return <Paper {...rest}>
    <List>
      {children}
    </List>
  </Paper>
}

export type ConfigurationSectionDividerProps = {
  spacing?: number
}

export function ConfigurationSectionDivider({spacing}: ConfigurationSectionDividerProps) {
  return <Divider flexItem sx={{my: spacing}}/>
}

export type ConfigurationSectionProps = {
  label: ReactNode
  children: ReactNode
} & BoxProps

export function ConfigurationSection({children, label, ...rest}: ConfigurationSectionProps) {
  return <Box sx={{p: 2}} {...rest}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        {children}
      </Grid>
    </Grid>
  </Box>
}


