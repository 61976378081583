import useMediaQuery from '@mui/material/useMediaQuery';
import {ReactNode, useMemo} from "react";

export const monopolisConstraints = {
  headerHeight: 60,
  panelWidth: 900,
  sidebarBreakPoint: 1250,
  sidebarWidth: 300,
  gridSpacing: 3,
  tooltipLongDelay: 500
} as const

export type ResponsiveDisplayProps = {
  standard: () => ReactNode
  compact: () => ReactNode
  breakpoint?: number
}

export function ResponsiveDisplay(
    {standard, compact, breakpoint = monopolisConstraints.panelWidth}: ResponsiveDisplayProps
) {
  const matches = useMediaQuery(`(max-width:${breakpoint + 60}px)`);
  const component = useMemo(() => matches ? compact() : standard(), [matches, compact, standard])
  return <>{component}</>
}
