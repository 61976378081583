import {OnError} from "../../../common/api/TransportLayer";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export enum RepositoryConfigurationPageTab {
  configuration = "configuration",
  notifications = "notifications",
}

export type RepositorySettingsTabProps = {
  owner: VcsOwner
  path: OrgRepoPath
} & OnError
