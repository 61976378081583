import {EmailAddress} from "./index";

export type PlanName = string
export type PlanType = "Free" | "Paid"
export type BillingPeriod = "Annual" | "Monthly"
export type BillingDate = string
export type SeatPrice = number
export type PurchasedSeats = number

export enum PlanFeature {
    PrivateRepos = 'PrivateRepos',
    Crosscheck = 'Crosscheck',
    AutoRollout = 'AutoRollout',
    DoraStats = 'DoraStats'
}

export enum PlanLimit {
    DataRetention = 'DataRetention',
    Environments = 'Environments',
    Monodes = 'Monodes',
    RefreshSeconds = 'RefreshSeconds',
    Repos = 'Repos',
    StatsRange = 'StatsRange'
}

export type PlanPurchase = {
    startAt: string
    plan: CustomerPlan
    billing: PlanBilling
}
export type PlanBilling = {
    seatCost: SeatPrice
    numberOfSeats: PurchasedSeats
    period: BillingPeriod
    billingDate?: BillingDate
    billingEmail?: EmailAddress
}
export type CustomerPlan = {
    name: PlanName
    features: PlanFeature[]
    limits: Record<PlanLimit, number>
    endAt?: string
    trialEndAt?: string
    type: PlanType
}
