import {Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {EventsPanel} from "../../components/events/EventsPanel";
import {useRepositoryEventsQuery} from "../../api/events";
import {RepositoryPageTabProps} from "./RepositoryPageTab";

export function RepositoryPageOverviewTab({
                                            path,
                                            onError,
                                          }: RepositoryPageTabProps) {
  const eventsQuery = useRepositoryEventsQuery({path})

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <QueryLoader result={eventsQuery} onError={onError} onLoaded={data =>
        <EventsPanel data={data}/>
    }/>

  </Grid>
}


