import {Hint} from "./Hint";
import {HintButton} from "./HintButton";
import * as React from "react";
import Link from "@mui/material/Link";

import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";

export function ConfigureWatchlistHint() {
  return <Hint>
    Your Watchlist displays your favourite Monodes and Build Pipelines. Browse your organisations below and
    press <HintButton variant="addToWatchlist"/> to add content.
  </Hint>
}

export function ConfigureOrgHint() {
  return <Hint>
    Organisation administrators can access <HintButton variant="settings"/> to configure repositories and
    users.
  </Hint>
}

export function SelectReposHint() {
  const {config} = useUserAppInfrastructure()
  return <Hint sx={{mb: 2, mt: 1}}>
    No selected repositories. Select repositories to enable {config.appName}.
  </Hint>
}

export function AddDashboardsHint() {
  return <Hint sx={{m: 1, mt: 2}}>
    Dashboards provide convenient way to group information for you and your team. You can create new ones here.
  </Hint>
}

export function EditDashboardsHint() {
  return <Hint sx={{mb: 2, mt: 1}}>
    Use <HintButton variant="edit"/> then <HintButton variant="add"/> to add new widgets to your dashboard.
  </Hint>
}

export function SelectMembersHint() {
  const {config} = useUserAppInfrastructure()
  return <Hint sx={{mb: 2, mt: 1}}>
    No selected members. Select members to allow them to see {config.appName} content.
  </Hint>
}

export function FollowDocumentationToConfigureHint({url, feature}: { url: string, feature: string }) {
  return <Hint sx={{mb: 2, mt: 1}}>
    <Link target="_blank" href={url}>
      Follow documentation to configure {feature} for your repos.
    </Link>
  </Hint>
}

export function DiscoverHint({userProfile}: { userProfile: AuthenticatedUserProfile }) {
  const {config} = useUserAppInfrastructure()
  return <Hint sx={{mb: 2, mt: 1}}>
    Don't see an org you're expecting? <Link
      href={config.signUpBaseUrls[userProfile.vcs]}>Get Monopolis for your organisation here</Link>
  </Hint>
}
