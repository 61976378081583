import {RolloutTarget} from "../../models";
import {OnError} from "../../../common/api/TransportLayer";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {OrgRepoPath, VcsOwner} from "../../../common/models";
import {MaybeOrgConfiguration} from "../../../common/models";

export enum RepositoryTargetPageTab {
  rollouts = "rollouts"
}

export type RepositoryTargetPageTabProps = {
  owner: VcsOwner
  path: OrgRepoPath
  target: RolloutTarget
  userProfile: AuthenticatedUserProfile
  orgConfiguration: MaybeOrgConfiguration
} & OnError
