import * as React from "react";
import {ReactNode, useState} from "react";
import {FormElement, FormStack} from "./Form";
import Box from "@mui/material/Box";
import {CancelButton} from "../navigation/CancelButton";
import Button from "@mui/material/Button";
import {Stack} from "@mui/material";

export type FormEditableFieldProps = {
  editable?: boolean
  editLabel: string
  saveLabel: string
  onEditing?: (editing: boolean) => void
  onSave: () => void
  onCancel?: () => void
  children?: ReactNode
}

export function FormEditableField({
                                    editable,
                                    editLabel,
                                    saveLabel,
                                    onEditing,
                                    onSave,
                                    children
                                  }: FormEditableFieldProps) {
  const [editing, setEditing] = useState(false)
  const isEditable = editable === undefined || editable

  return <FormStack>
    {children}
    {isEditable &&
        <FormElement>
          {
            editing ? <Box>
                  <Stack direction="row" spacing={1}>
                    <CancelButton onClick={() => {
                      setEditing(false)
                      onEditing && onEditing(false)
                    }}/>
                    <Button data-test="save-editable-field"
                            aria-label={saveLabel}
                            variant="contained"
                            onClick={() => {
                              onSave()
                              setEditing(false)
                              onEditing && onEditing(false)
                            }}>
                      {saveLabel}
                    </Button>
                  </Stack>
                </Box>
                : <Button onClick={() => {
                  setEditing(true)
                  onEditing && onEditing(true)
                }} variant="outlined">{editLabel}</Button>
          }

        </FormElement>
    }
  </FormStack>
}

