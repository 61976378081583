import {IntegrationSettings, IntegrationType} from "../../api/org/model";
import React, {ComponentType} from "react";
import {DiscordIntegrationSettingsPanel} from "./DiscordIntegrationSettingsPanel";
import {NotYetImplementedIntegrationSettingsPanel} from "./NotYetImplementedIntegrationSettingsPanel";
import {SlackIntegrationSettingsPanel} from "./SlackIntegrationSettingsPanel";
import {PagerDutyIntegrationSettingsPanel} from "./PagerDutyIntegrationSettingsPanel";
import {StatusPageIntegrationSettingsPanel} from "./StatusPageIntegrationSettingsPanel";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {VcsOwner} from "../../../common/models";

export type IntegrationSettingsPanelProps<T extends IntegrationSettings> = T & {
  owner: VcsOwner
  userProfile: AuthenticatedUserProfile
  originUri: string
}

const componentForType: Record<IntegrationType, ComponentType<IntegrationSettingsPanelProps<any>>> = {
  Discord: DiscordIntegrationSettingsPanel,
  Slack: SlackIntegrationSettingsPanel,
  PagerDuty: PagerDutyIntegrationSettingsPanel,
  StatusPage: StatusPageIntegrationSettingsPanel,

  Teams: NotYetImplementedIntegrationSettingsPanel,
}

export function IntegrationSettingsPanel(props: IntegrationSettingsPanelProps<any>) {
  // @ts-ignore
  const IntegrationSettingsComponent = componentForType[props.type]
  return (
      <IntegrationSettingsComponent {...props}/>
  )
}
