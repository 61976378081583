import {Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {QueryLoader} from "../../../common/components/QueryLoader";
import * as React from "react";
import {MonodePageTabProps} from "./MonodePageTab";
import {DependencyPanel} from "../../components/graphs/DependencyPanel";
import {useMonodeDependencyGraphQuery} from "../../api/graphs";

export function MonodePageDependenciesTab({path, userProfile, monode, onError}: MonodePageTabProps) {
  const dependencyGraphQuery = useMonodeDependencyGraphQuery({path, monode})
  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <QueryLoader height={450} result={dependencyGraphQuery} onError={onError} onLoaded={data =>
        <DependencyPanel vcs={userProfile.vcs}
                         graph={data}/>
    }/>
  </Grid>
}
