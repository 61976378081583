import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime)
dayjs.extend(utc)

const dateFormat = "YYYY-MM-DD"
const timeFormat = "HH:mm:ss"
const fullDateTimeFormat = "D MMMM YYYY HH:mm:ss"
const fullDateFormat = "D MMMM YYYY"
const monthDayDateFormat = "MMM D"

export function formatDuration(durationInSeconds: number) {
    const seconds = Math.trunc(durationInSeconds % 60)
    const minutes = Math.trunc(durationInSeconds / 60 % 60)
    const hours = Math.trunc(durationInSeconds / (60 * 60))

    const hoursStr = (hours < 10) ? "0" + hours : hours;
    const minutesStr = (minutes < 10) ? "0" + minutes : minutes;
    const secondsStr = (seconds < 10) ? "0" + seconds : seconds;

    return hoursStr + ":" + minutesStr + ":" + secondsStr;
}

export function humaniseDuration(durationInSeconds: number) {
    const seconds = Math.trunc(durationInSeconds % 60)
    const minutes = Math.trunc(durationInSeconds / 60 % 60)
    const hours = Math.trunc(durationInSeconds / (60 * 60))

    const hoursStr = (hours === 0) ? "" : hours + "h ";
    const minutesStr = (minutes === 0) ? "" : minutes + "m ";
    const secondsStr = (seconds === 0) ? "" : seconds + "s ";
    const formatted = (hoursStr + minutesStr + secondsStr).trim();

    return formatted !== "" ? formatted : "0s";
}

export function timeZoneOffset(): string {
    const offset = new Date().getTimezoneOffset()
    const offsetAbs = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(offsetAbs / 60)).slice(-2) + ":" + ("00" + (offsetAbs % 60)).slice(-2);
}

export function durationInSecondsFromNowUTC(dateTimeInUTC: string): number {
    return Math.abs(dayjs().utc().diff(dayjs.utc(dateTimeInUTC), "seconds"))
}

export function durationInDaysFromNowUTC(dateTimeInUTC: string): number {
    return Math.abs(dayjs().utc().diff(dayjs.utc(dateTimeInUTC), "days"))
}

export function formatUTCToLocalTime(dateTimeInUTC: string): string {
    return dayjs.utc(dateTimeInUTC).local().format(timeFormat)
}

export function formatUTCToLocalDateTime(dateTimeInUTC: string): string {
    return dayjs.utc(dateTimeInUTC).local().format(fullDateTimeFormat)
}

export function formatUTCToLocalDate(dateTimeInUTC: string): string {
    return dayjs.utc(dateTimeInUTC).local().format(dateFormat)
}

export function formatUTCToHumanisedDurationFromNow(dateTimeInUTC: string): string {
    return dayjs.utc(dateTimeInUTC).fromNow()
}

export function epochMillisAtEndOfDayUTC(date: string): number {
    return dayjs.utc(date).endOf('day').valueOf()
}

export function epochMillisAtUTC(dateTimeInUTC: string): number {
    return dayjs.utc(dateTimeInUTC).valueOf()
}

export function daysFromNowUntil(date?: string): number {
    return Math.abs(dayjs(date, dateFormat).diff(dayjs(), "days"))
}

export function formatDateToMonthAndDay(date: string): string {
    return dayjs(date, dateFormat).format(monthDayDateFormat)
}

export function formatDateToFullDate(date: string): string {
    return dayjs(date, dateFormat).format(fullDateFormat)
}

export function formatDateToDate(date: string): string {
    return dayjs(date, dateFormat).format(dateFormat)
}

export function addDaysToDate(date: string, days: number): string {
    return dayjs(date, dateFormat).add(days, 'days').format(dateFormat)
}

export function nowUTC(): string {
    return dayjs().utc().toISOString()
}

export function addSecondsToUTC(dateTimeInUTC: string, second: number): string {
    return dayjs(dateTimeInUTC).add(second, "seconds").toISOString()
}

export function isBeforeNowUTC(dateTimeInUTC: string): boolean {
    return dayjs.utc(dateTimeInUTC).isBefore(dayjs().utc())
}

export function isDateBeforeNowUTC(date?: string): boolean {
    return dayjs(date, dateFormat).isBefore(dayjs().utc())
}

export function isDateAfterNowUTC(date?: string): boolean {
    return dayjs(date, dateFormat).isAfter(dayjs().utc())
}
