import * as React from 'react';
import {useCallback} from 'react';
import Cookies from 'js-cookie';
import {SystemResponse} from "../../../common/api/TransportLayer";
import {useFetchAccountProfileQuery} from "../../api/account";
import {useAccountAuthentication} from "../../auth/useAccountAuthentication";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {AuthenticatedAccountProfile} from "../../auth/AuthenticatedAccountProfile";

export function LoginCallback() {
  const tokenCookie = Cookies.get('accountSession');
  const {loginFromCallback} = useAccountAuthentication();

  if (!tokenCookie) {
    throw new Error('Token cookie missing');
  }

  const onUserProfileFetched = useCallback((data: SystemResponse<AuthenticatedAccountProfile>) => {
    if (data.kind === "error") {
      throw new Error('Failed to load user profile: ' + data.error.message);
    }

    loginFromCallback(tokenCookie, data.data)
  }, [loginFromCallback, tokenCookie])

  useFetchAccountProfileQuery({token: tokenCookie, onFetch: onUserProfileFetched})

  return <UnauthorizedPage/>;
}
