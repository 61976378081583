import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {SystemError} from "../../../common/api/TransportLayer";
import {OwnerPathParameter, usePathParams} from "../../models/Paths";
import {OrgBreadcrumbsMenu} from "../../components/org/OrgBreadcrumbsMenu";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {useTabControls} from "../../../common/tabs";
import {OrgPageTab, OrgPageTabProps} from "./OrgPageTab";
import {OrgPageOverviewTab} from "./OrgPageOverviewTab";
import {OrgPageDependenciesTab} from "./OrgPageDependenciesTab";
import {OrgPageAPICatalogueTab} from "./OrgPageAPICatalogueTab";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {OrgPageDashboardsTab} from "./OrgPageDashboardsTab";
import {Sidebar} from "../../../common/layout/Sidebar";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {TargetsPanel} from "../../components/targets/TargetsPanel";
import {useOrgTargetsQuery} from "../../api/rollouts";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {PageHeader, PageHeaderMenuProvider, PageTab} from "../../../common/components/navigation/PageHeader";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function OrgPage() {
  const [error, onError] = useState<SystemError | null>(null);
  const {urls} = useUserAppInfrastructure()
  const pathParams = usePathParams<OwnerPathParameter>();

  const targetsQuery = useOrgTargetsQuery({org: pathParams.owner})
  const tabs = useTabControls({
    defaultTab: OrgPageTab.overview,
    tabs: [
      {title: "Overview", id: OrgPageTab.overview},
      {title: "Dependencies", id: OrgPageTab.dependencies},
      {title: "Dashboards", id: OrgPageTab.dashboards},
      {title: "API Catalog", id: OrgPageTab.apiCatalogue},
    ]
  })

  const {userProfile} = useUserAuthentication()
  const title = `${pathParams.owner} Repositories`;

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const breadcrumbsMenu: PageHeaderMenuProvider = () =>
      <OrgBreadcrumbsMenu userProfile={userProfile} actions={["orgConfiguration"]} owner={pathParams.owner}/>;

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={onError}/>
  const header = <PageHeader
      title={pathParams.owner}
      location={urls.org(pathParams.owner)}
      menu={breadcrumbsMenu}
      tabs={tabs}
  />

  const sidebar = <Sidebar>
    <QueryLoader height={150} result={targetsQuery} onError={onError} onLoaded={data =>
        <TargetsPanel vcs={userProfile.vcs}
                      org={pathParams.owner}
                      targets={data}
        />
    }/>
  </Sidebar>


  const tabProps: OrgPageTabProps = {
    owner: pathParams.owner,
    userProfile,
    onError,
  }

  return <UserAppPage pageId={UserPageId.Org} title={title} contentHeader={header} sidebar={sidebar} banner={banner} error={error}
                      profile={userProfile}>
    <PageTab selectedId={tabs.selected} id={OrgPageTab.overview}>
      <OrgPageOverviewTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={OrgPageTab.dependencies}>
      <OrgPageDependenciesTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={OrgPageTab.dashboards}>
      <OrgPageDashboardsTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={OrgPageTab.apiCatalogue}>
      <OrgPageAPICatalogueTab {...tabProps}/>
    </PageTab>
  </UserAppPage>
}
