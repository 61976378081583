import {
    EmptyRequest,
    EmptyResponse,
    GetQuery,
    GetQueryOptions,
    queryOptions
} from "../../../common/api/query";
import {UserRequestId} from "../UserRequestId";
import {DashboardUrls} from "./urls";
import {Dashboard, DashboardId, DashboardRequest} from "./model";
import {useUserApiPost, useUserApiGet} from "../userApi";
import {VcsOwner} from "../../../common/models";

// User dashboard
export const useUserDashboardQuery = (params: GetQueryOptions): GetQuery<Dashboard> =>
    useUserApiGet(UserRequestId.UserDashboard, DashboardUrls.userDashboard(), {}, queryOptions(params))

const userDashboardQueries = [
    UserRequestId.UserDashboard
]

export const useUpdateUserDashboardMutation = () =>
    useUserApiPost<DashboardRequest, EmptyResponse>(UserRequestId.UpdateUserDashboard, DashboardUrls.updateUserDashboard(), userDashboardQueries)


// Org dashboards
export const useDashboardQuery = (params: { owner: VcsOwner, dashboardId: DashboardId } & GetQueryOptions): GetQuery<Dashboard> =>
    useUserApiGet(UserRequestId.Dashboard, DashboardUrls.dashboard(params), {}, queryOptions(params))

export const useDashboardsQuery = (params: { owner: VcsOwner } & GetQueryOptions): GetQuery<Dashboard[]> =>
    useUserApiGet(UserRequestId.Dashboards, DashboardUrls.dashboards(params), {}, queryOptions(params))

const dashboardQueries = [
    UserRequestId.Dashboard,
    UserRequestId.Dashboards
]

export const useCreateDashboardMutation = (params: { owner: VcsOwner }) =>
    useUserApiPost<DashboardRequest, Dashboard>(UserRequestId.CreateDashboard, DashboardUrls.createDashboard(params), dashboardQueries)

export const useUpdateDashboardMutation = (params: { owner: VcsOwner, dashboardId: DashboardId }) =>
    useUserApiPost<DashboardRequest, Dashboard>(UserRequestId.UpdateDashboard, DashboardUrls.updateDashboard(params), dashboardQueries)

export const useDeleteDashboardMutation = (params: { owner: VcsOwner, dashboardId: DashboardId }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.DeleteDashboard, DashboardUrls.deleteDashboard(params), dashboardQueries)
