import {Box, IconButton, Paper, Typography} from "@mui/material";
import {monopolisTheme} from "../styles/theme";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ContentCopyOutlined} from "@mui/icons-material";
import * as React from "react";

export type CopyToClipboardTextProps = {
  text: string
}

export function CopyToClipboardText({text}: CopyToClipboardTextProps) {
  const [copied, setCopied] = React.useState(false);

  return <Paper variant="outlined"
                sx={{my: 2, alignItems: "center", position: "relative", minHeight: 40}} style={{
    borderColor: monopolisTheme.palette.secondary.main,
    backgroundColor: monopolisTheme.palette.primary.light
  }}>
    <Box sx={{p: 2, display: "flex", flexDirection: "row", alignItems: "center"}}>
      <Typography sx={{flexGrow: 1, fontFamily: 'Monospace', fontSize:"large"}} style={{wordWrap: "break-word", wordBreak: "break-all"}}>
        {text}
      </Typography>
      <CopyToClipboard text={text}
                       onCopy={() => {
                         setCopied(true)
                         setTimeout(() => setCopied(false), 1500);
                       }}>
        <IconButton
            sx={{
              flex: 1,
              ml: 2,
              border: 0,
              borderRadius: 1,
              color: monopolisTheme.palette.secondary.main
            }}
            color="primary">
          <ContentCopyOutlined/>
        </IconButton>
      </CopyToClipboard>
    </Box>
    <Paper elevation={0} sx={{
      pointerEvents: "none",
      touchAction: "none",
      display: "flex",
      flexDirection: "column",
      p: 2,
      position: "absolute",
      opacity: copied ? 1 : 0,
      transition: "opacity .10s ease-in-out",
      backgroundColor: monopolisTheme.palette.secondary.main,
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      justifyContent: "center",
      alignItems: "center"
    }}>
      <Typography variant="body1" sx={{display: "flex", alignItems: "center", mt: 1}}>
        <ContentCopyOutlined sx={{mr: 1}}/>Copied to clipboard
      </Typography>
    </Paper>
  </Paper>
}
