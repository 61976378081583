export function toWindows(inputArray: any[], size: number) {
    return inputArray
        .reduce((acc: any[], _: any, index: number, arr: any[]) => {
            if (index + size > arr.length) {
                //we've reached the maximum number of windows, so don't add any more
                return acc;
            }

            //add a new window of [currentItem, maxWindowSizeItem)
            return acc.concat(
                //wrap in extra array, otherwise .concat flattens it
                [arr.slice(index, index + size)]
            );

        }, [])
}

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K) : Record<K, T[]>{
    return list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);
}


export function distinct<T>(list: T[]): T[] {
    return Array.from(new Set(list))
}

export function first<T>(items: T[]): T | undefined {
    return items.length > 0 ? items[0] : undefined;
}

export function last<T>(items: T[]): T | undefined {
    return items.length > 0 ? items[items.length - 1] : undefined;
}
