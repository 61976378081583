import {Paths, PathSegments} from "./Paths";
import {CommitSha, Monode, RolloutTarget, VcsBranch} from "./index";
import {buildUrl, UrlQueryParam} from "../../common/urls";
import {DashboardId} from "../api/dashboard/model";
import {OrgPageTab} from "../pages/org/OrgPageTab";
import {branchParam, dashboardIdParam} from "../utils/urlParams";
import {addSubDomain, tabParam} from "../../common/navigation";
import {OrgRepoPath, VcsOwner, VcsPath} from "../../common/models";
import {teamIdFromCurrentLocation} from "../../common/models/TeamId";

export class UserAppUrls {
    baseUri: string

    constructor(baseUri: string) {
        this.baseUri = baseUri
    }

    baseUrl(): string {
        return addSubDomain(this.baseUri, teamIdFromCurrentLocation());
    }

    rawBaseUrl(): string {
        return this.baseUri
    }

    loginCli(): string {
        return this.baseUrl() +  Paths.LoginCLI
    }

    org(org: VcsOwner): string {
        const base = this.baseUrl() +  Paths.Org
            .replace(PathSegments.owner, org)

        return buildUrl(base)
    }

    orgConfiguration(org: VcsOwner): string {
        const base = this.baseUrl() +   Paths.OrgConfiguration
            .replace(PathSegments.owner, org)

        return buildUrl(base)
    }

    orgDashboard(org: VcsOwner, dashboardId: DashboardId): string {
        const base = this.baseUrl() +   Paths.OrgDashboard
            .replace(PathSegments.owner, org)
            .replace(PathSegments.dashboard, dashboardId)

        return buildUrl(base)
    }

    editOrgDashboard(owner: VcsOwner, dashboardId: DashboardId): string {
        const base = this.baseUrl() +   Paths.Org
            .replace(PathSegments.owner, owner)

        const query = Array<UrlQueryParam>()
        query.push(tabParam(OrgPageTab.dashboards));
        query.push(dashboardIdParam(dashboardId));

        return buildUrl(base, query)
    }

    repository(path: OrgRepoPath, branch: VcsBranch | null = null): string {
        const base = this.baseUrl() +   Paths.Repository
            .replace(PathSegments.owner, path.org)
            .replace(PathSegments.repository, encodeURIComponent(path.repo))

        const query = Array<UrlQueryParam>()
        if (branch) query.push(branchParam(branch));

        return buildUrl(base, query)
    }

    monode(params: { path: OrgRepoPath, branch?: VcsBranch, monode: Monode }) {
        const base = this.baseUrl() +   Paths.Monode
            .replace(PathSegments.owner, params.path.org)
            .replace(PathSegments.repository, encodeURIComponent(params.path.repo))
            .replace(PathSegments.monode, params.monode)

        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));

        return buildUrl(base, query)
    }

    target(params: { org: VcsOwner, repo?: VcsPath, monode?: Monode, target: RolloutTarget }) {
        if (params.repo && params.monode) {
            return buildUrl(this.baseUrl() +   Paths.MonodeTarget
                .replace(PathSegments.owner, params.org)
                .replace(PathSegments.repository, encodeURIComponent(params.repo))
                .replace(PathSegments.monode, params.monode)
                .replace(PathSegments.target, params.target))
        }

        if (params.repo) {
            return buildUrl(this.baseUrl() +   Paths.RepositoryTarget
                .replace(PathSegments.owner, params.org)
                .replace(PathSegments.repository, encodeURIComponent(params.repo))
                .replace(PathSegments.target, params.target))
        }

        return buildUrl(this.baseUrl() +   Paths.OrgTarget
            .replace(PathSegments.owner, params.org)
            .replace(PathSegments.target, params.target))

    }

    repositorySettings(params: { path: OrgRepoPath, branch?: VcsBranch }) {
        const base = this.baseUrl() +   Paths.RepositorySettings
            .replace(PathSegments.owner, params.path.org)
            .replace(PathSegments.repository, encodeURIComponent(params.path.repo))

        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));

        return buildUrl(base, query)
    }

    commit(params: { path: OrgRepoPath, branch?: VcsBranch, sha: CommitSha }) {
        const base = this.baseUrl() +   Paths.Commit
            .replace(PathSegments.owner, params.path.org)
            .replace(PathSegments.repository, encodeURIComponent(params.path.repo))
            .replace(PathSegments.sha, params.sha);

        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));

        return buildUrl(base, query)
    }

    userSettings(): string {
        return this.baseUrl() +   Paths.UserSettings
    }

    home(): string {
        return this.baseUrl() + Paths.Home
    }

    onboardingWelcome(org: VcsOwner): string {
        return this.baseUrl() +   Paths.OrgWelcome
            .replace(PathSegments.owner, org)
    }
}


