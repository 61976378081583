import * as React from "react";
import {useState} from "react";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {RolloutPanel} from "../../components/rollouts/RolloutPanel";
import {showDoraStats} from "../../utils/orgConfigurations";
import {Grid} from "@mui/material";
import {useRolloutGraphQuery} from "../../api/graphs";
import {useRepositoryRolloutStatisticsQuery} from "../../api/statistics";
import {useRepositoryEventsQuery} from "../../api/events";
import {RepositoryTargetPageTabProps} from "./RepositoryTargetPageTab";
import {DateTime} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function RepositoryTargetPageRolloutsTab({
      owner,
      target,
      path,
      userProfile,
      orgConfiguration,
      onError
    }: RepositoryTargetPageTabProps) {
  const {badgesUrls} = useUserAppInfrastructure()
  const [at, setAt] = useState<DateTime | undefined>(undefined);
  const graphResult = useRolloutGraphQuery({owner, path, target, at})
  const eventsResult = useRepositoryEventsQuery({path, target})
  const rolloutStatsResult = useRepositoryRolloutStatisticsQuery({path, target})

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <RolloutPanel customerId={userProfile.customerId}
                  vcs={userProfile.vcs}
                  org={owner}
                  target={target}
                  path={path}
                  urls={badgesUrls}
                  showDoraStats={showDoraStats(orgConfiguration)}
                  statisticsResult={rolloutStatsResult}
                  graphResult={graphResult}
                  eventsResult={eventsResult}
                  onTimestampSelected={setAt}
                  onError={onError}/>
  </Grid>
}
