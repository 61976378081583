import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult
} from "@tanstack/react-query";
import {SystemError, SystemResponse, TransportLayer, TransportLayerParams} from "./TransportLayer";
import {DataStorage} from "../port/DataStorages";

export const defaultRefetchInterval = 20000

export type QueryResult<
    TData = unknown,
    TError = unknown
> = UseQueryResult<TData, TError>

export type MutationResult<
    TData = unknown,
    TError = unknown,
    TVariables = unknown,
    TContext = unknown
> = UseMutationResult<TData, TError, TVariables, TContext>

export type GetQueryOptions = UseQueryOptions<SystemResponse<any>> & {
    onQueryError?: (error: SystemError) => void
}

export const refetchQueryOptions: GetQueryOptions = {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchInterval: defaultRefetchInterval,
    refetchIntervalInBackground: true,
    enabled: true
}

export const noRefetchQueryOptions: GetQueryOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    enabled: false
}

export function queryOptions(options: GetQueryOptions): GetQueryOptions {
    return {...refetchQueryOptions, ...options}
}

export function queryNoRefetchOptions(options: GetQueryOptions): GetQueryOptions {
    return {...noRefetchQueryOptions, ...options}
}

export type EmptyRequest = {}
export type EmptyResponse = {}

export type CacheOptions = {
    cacheKey?: string
    readCache?: boolean
    writeCache?: boolean
    cacheExpiryInSeconds?: number
    cache?: DataStorage<string>
}

export type Resource<REQUEST = null> = string | ((request: REQUEST) => string)
export type Payload<REQUEST = null> = ((request: REQUEST) => any)

export type GetQuery<T> = UseQueryResult<SystemResponse<T>>

export type PostMutation<REQUEST = EmptyRequest, RESPONSE = EmptyResponse> =
    UseMutationResult<SystemResponse<RESPONSE>, unknown, REQUEST>

export type PutMutation<REQUEST = void, RESPONSE = void> =
    UseMutationResult<SystemResponse<RESPONSE>, unknown, REQUEST>

export type DeleteMutation<REQUEST = void, RESPONSE = void> =
    UseMutationResult<SystemResponse<RESPONSE>, unknown, REQUEST>

export const jsonContentTypeHeader = {"Content-Type": "application/json;charset=UTF-8"}

export function useGet<RESPONSE>(
    transport: TransportLayer,
    storage: DataStorage<string>,
    requestId: string,
    resource: Resource,
    transportOptions: TransportLayerParams = {},
    queryOptions: GetQueryOptions = refetchQueryOptions,
    cacheOptions: CacheOptions = {}
): GetQuery<RESPONSE> {
    const resolvedResource = resolve(resource, null);
    const cache = cacheOptions.cache || storage
    const cacheKey = cacheOptions.cacheKey || requestId + "_" + resource
    const cacheExpiry = cacheOptions.cacheExpiryInSeconds || 120
    return useQuery<SystemResponse<RESPONSE>>(
        [requestId, resolvedResource],
        () => {
            const cachedValue = (cacheOptions.readCache && cache.keyState(cacheKey, cacheExpiry) === "active")
                ? cache.getObject<RESPONSE>(cacheKey, cacheExpiry) : undefined

            if (cachedValue) {
                return {kind: "ok", data: cachedValue}
            } else {
                return transport.get<RESPONSE>(requestId, resolvedResource, transportOptions)
                    .then(result => {
                        if (result.kind === "ok" && cacheOptions.writeCache) {
                            cache.setObject(cacheKey, result.data)
                        }

                        if (result.kind === "error" && cacheOptions.writeCache) {
                            queryOptions.onQueryError && queryOptions.onQueryError(result.error)
                            cache.removeItem(cacheKey)
                        }

                        return result
                    })
            }
        },
        queryOptions
    )
}

function resolve<REQUEST>(resource: Resource<REQUEST>, payload: REQUEST) {
    return (typeof resource === "function") ? resource(payload) : resource;
}

function resolvePayload<REQUEST>(payload: Payload<REQUEST>, req: REQUEST){
    return (typeof payload === "function") ? payload(req) : req;
}

export function usePut<REQUEST = EmptyRequest, RESPONSE = EmptyResponse>(
    transport: TransportLayer,
    requestId: string,
    resource: Resource<REQUEST>,
    invalidates: string[] = [],
    transportOptions: TransportLayerParams = {},
    payload: Payload<REQUEST> = (req) => req,
): PutMutation<REQUEST, RESPONSE> {
    const client = useQueryClient()
    return useMutation<SystemResponse<RESPONSE>, unknown, REQUEST>(
        [requestId],
        (req: REQUEST) => {
            const resolvedPayload = resolvePayload(payload, req);
            return transport.put<RESPONSE>(requestId, resolve(resource, req), {
                ...transportOptions,
                payload: resolvedPayload && JSON.stringify(resolvedPayload)
            })
        },
        {
            onSuccess: () => {
                invalidates.forEach(id => client.invalidateQueries([id]))
            }
        }
    );
}

export function usePost<REQUEST = EmptyRequest, RESPONSE = EmptyResponse>(
    transport: TransportLayer,
    requestId: string,
    resource: Resource<REQUEST>,
    invalidates: string[] = [],
    transportOptions: TransportLayerParams = {},
    payload: Payload<REQUEST> = (req) => req,
): PostMutation<REQUEST, RESPONSE> {
    const client = useQueryClient()
    return useMutation<SystemResponse<RESPONSE>, unknown, REQUEST>(
        [requestId],
        (req: REQUEST) => {
            const resolvedPayload = resolvePayload(payload, req);
            return transport.post<RESPONSE>(requestId, resolve(resource, req), {
                ...transportOptions,
                payload: resolvedPayload && JSON.stringify(resolvedPayload)
            })
        },
        {
            onSuccess: () => {
                invalidates.forEach(id => client.invalidateQueries([id]))
            }
        }
    );
}

export function useDelete<REQUEST = void, RESPONSE = void>(
    transport: TransportLayer,
    requestId: string,
    resource: Resource<REQUEST>,
    invalidates: string[] = [],
    transportOptions: TransportLayerParams = {},
): DeleteMutation<REQUEST, RESPONSE> {
    const client = useQueryClient()
    return useMutation<SystemResponse<RESPONSE>, unknown, REQUEST>(
        [requestId],
        (req: REQUEST) => {
            return transport.delete<RESPONSE>(requestId, resolve(resource, req), {
                ...transportOptions
            })
        },
        {
            onSuccess: () => {
                invalidates.forEach(id => client.invalidateQueries([id]))
            }
        }
    );
}
