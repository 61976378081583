import {tabParamName, useQueryParamOrDefault} from "./navigation";


export type TabControl = {
    id: string,
    title: string,
}

export type TabControls = {
    selected: string,
    tabs: TabControl[]
    onChanged?: (tab: string) => void
    setSelected: (id: string, location?: string) => void
}

export function useTabControls({defaultTab, tabs, onChanged}: UseTabControlsProps): TabControls {
    const queryParam = useQueryParamOrDefault(tabParamName, async () => defaultTab)
    return {
        selected: queryParam.value || defaultTab,
        tabs,
        onChanged,
        setSelected: (id: string, location?: string) => queryParam.set(id, location)
    }
}

export type UseTabControlsProps = {
    defaultTab: string
    tabs: TabControl[]
    onChanged?: (tab: string) => void
}
