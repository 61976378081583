export enum ReservedSubDomain {
  Account = "account",
  App = "app"
}

export function isReservedSubDomain(domain: string) : boolean {
  return Object
      .values(ReservedSubDomain)
      .map(domain => domain.toLowerCase())
      .indexOf(domain.toLowerCase()) >= 0
}
