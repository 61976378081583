import {Box} from "@mui/material";
import * as React from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {useOrgNotificationChannelsQuery} from "../../api/notifications";
import {
  NotificationChannel,
  NotificationChannelDetails,
  NotificationEvent,
  NotificationRequest,
  NotificationSubscriptions
} from "../../api/notifications/model";
import {NotificationSubscriptionsPanel} from "./NotificationSubscriptionsPanel";
import {GetQuery, PostMutation} from "../../../common/api/query";
import {ConfigurationSectionDivider, ConfigurationPanel, ConfigurationSection} from "../../../common/components/configuration";
import {VcsOwner} from "../../../common/models";

export type NotificationsPanelProps = {
  owner: VcsOwner;
  subscriptionsQuery: GetQuery<NotificationSubscriptions>
  subscribeMutation: PostMutation<NotificationRequest>;
  unsubscribeMutation: PostMutation<NotificationRequest>;
} & OnError

export function NotificationsPanel({...props}: NotificationsPanelProps) {
  return <NotificationsPanelView {...props}/>
}

function NotificationsPanelView({
                                  owner,
                                  onError,
                                  subscriptionsQuery,
                                  subscribeMutation,
                                  unsubscribeMutation,
                                }: NotificationsPanelViewProps) {
  const {data: channels} = useOrgNotificationChannelsQuery({owner})
  const {data: subscriptions} = subscriptionsQuery
  const {mutateAsync: notificationSubscribe} = subscribeMutation
  const {mutateAsync: notificationUnsubscribe} = unsubscribeMutation

  const actualSubscriptions = (subscriptions && subscriptions.kind === "ok") ? subscriptions.data : {} as Record<NotificationEvent, NotificationChannel[]>
  const actualChannels = (channels && channels.kind === "ok") ? channels.data : []

  const onSubscribe = async (event: NotificationEvent, channel: NotificationChannelDetails) => {
    const data = await notificationSubscribe({event, channel: channel.channel});
    if (data && data.kind === "error") onError(data.error)
  };

  const onUnsubscribe = async (event: NotificationEvent, channel: NotificationChannelDetails) => {
    const data = await notificationUnsubscribe({event, channel: channel.channel});
    if (data && data.kind === "error") onError(data.error)
  };

  function SubscriptionPanel(params: { event: NotificationEvent }) {
    return <Box sx={{mb: 2}}>
      <NotificationSubscriptionsPanel
          notificationEvent={params.event}
          channels={actualChannels}
          subscriptions={actualSubscriptions}
          onError={onError}
          onSubscribe={onSubscribe}
          onUnsubscribe={onUnsubscribe}/>
    </Box>
  }

  return <ConfigurationPanel data-test="notifications">
    <ConfigurationSection label="Build">
      <SubscriptionPanel event={NotificationEvent.Push}/>
      <SubscriptionPanel event={NotificationEvent.BuildStatusChanged}/>
    </ConfigurationSection>

    <ConfigurationSectionDivider/>

    <ConfigurationSection label="Rollout">
      <SubscriptionPanel event={NotificationEvent.RolloutStarted}/>
      <SubscriptionPanel event={NotificationEvent.RolloutFinished}/>
    </ConfigurationSection>

    <ConfigurationSectionDivider/>

    <ConfigurationSection label="Monitor">
      <SubscriptionPanel event={NotificationEvent.IncidentUpdate}/>
      <SubscriptionPanel event={NotificationEvent.MonodeAlert}/>
    </ConfigurationSection>
  </ConfigurationPanel>
}

type NotificationsPanelViewProps = {} & NotificationsPanelProps
