import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {OwnerPathParameter, TargetPathParameter, usePathParams} from "../../models/Paths";
import {OrgBreadcrumbsMenu} from "../../components/org/OrgBreadcrumbsMenu";
import {SystemError} from "../../../common/api/TransportLayer";
import {useCachedOrgConfigurationQuery} from "../../api/org";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {PageHeader, PageHeaderMenuProvider, PageTab} from "../../../common/components/navigation/PageHeader";
import {useTabControls} from "../../../common/tabs";
import {OrgTargetPageTab, OrgTargetPageTabProps} from "./OrgTargetPageTab";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {OrgTargetPageRolloutsTab} from "./OrgTargetPageRolloutsTab";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {OwnerTargetBreadcrumb} from "../../components/breadcrumbs/OwnerTargetBreadcrumb";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function OrgTargetPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const pathParams = usePathParams<OwnerPathParameter & TargetPathParameter>();
  const {urls} = useUserAppInfrastructure()
  const {userProfile} = useUserAuthentication()
  const orgConfiguration = useCachedOrgConfigurationQuery({...pathParams, onError: setError})
  const tabs = useTabControls({
    defaultTab: OrgTargetPageTab.rollouts,
    tabs: [
      {title: "Rollouts", id: OrgTargetPageTab.rollouts},
    ]
  })

  const title = `${pathParams.owner} ${pathParams.target}`;

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const breadcrumbsMenu: PageHeaderMenuProvider = () =>
      <OrgBreadcrumbsMenu userProfile={userProfile} actions={[]} owner={pathParams.owner}/>;

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={setError}/>

  const header = <PageHeader
      title="Target"
      location={urls.target({org: pathParams.owner, target: pathParams.target})}
      breadcrumbs={[
        <OwnerBreadcrumb owner={pathParams.owner}/>,
        <OwnerTargetBreadcrumb owner={pathParams.owner} target={pathParams.target}/>,
      ]}
      menu={breadcrumbsMenu}
      tabs={tabs}
  />

  const tabProps: OrgTargetPageTabProps = {
    owner: pathParams.owner,
    target: pathParams.target,
    userProfile,
    orgConfiguration: orgConfiguration,
    onError: setError,
  }

  return <UserAppPage pageId={UserPageId.OrgTarget} title={title} contentHeader={header} banner={banner} error={error} profile={userProfile}>
    <PageTab selectedId={tabs.selected} id={OrgTargetPageTab.rollouts}>
      <OrgTargetPageRolloutsTab {...tabProps}/>
    </PageTab>
  </UserAppPage>
}
