import {GetQueryOptions, queryOptions} from "../../../common/api/query";
import {MetaQuery, Monode, RolloutTarget} from "../../models";
import {UserRequestId} from "../UserRequestId";
import {EventUrls} from "./urls";
import {AuditEventAggregate} from "./model";
import {useUserApiGet} from "../userApi";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export const useOrgEventsQuery = (params: { owner: VcsOwner, target?: RolloutTarget, metaFilter?: MetaQuery } & GetQueryOptions) =>
    useUserApiGet<AuditEventAggregate>(UserRequestId.OrgEvents, EventUrls.orgEvents(params), {}, queryOptions(params))

export const useRepositoryEventsQuery = (params: { target?: RolloutTarget, path: OrgRepoPath } & GetQueryOptions) =>
    useUserApiGet<AuditEventAggregate>(UserRequestId.RepositoryEvents, EventUrls.repositoryEvents(params), {}, queryOptions(params))

export const useMonodeEventsQuery = (params: { target?: RolloutTarget, path: OrgRepoPath, monode: Monode } & GetQueryOptions) =>
    useUserApiGet<AuditEventAggregate>(UserRequestId.MonodeEvents, EventUrls.monodeEvents(params), {}, queryOptions(params))
