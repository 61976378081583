import {EmptyRequest, EmptyResponse, GetQueryOptions, queryOptions} from "../../../common/api/query";
import {DefaultBranch, Pipeline, PipelineId, PipelineResults, UserRollout} from './model';
import {PipelineUrls} from "./urls";
import {CommitSha, MetaQuery, Monode, PipelineName, VcsBranch, VcsEvent, VcsRunId} from "../../models";
import {UserRequestId} from "../UserRequestId";
import {useUserApiPost, useUserApiGet} from "../userApi";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export const useRepositoryPipelinesQuery = (params: { path: OrgRepoPath, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<Pipeline[]>(UserRequestId.RepositoryPipelines, PipelineUrls.repositoryPipelines(params), {}, queryOptions(params));

export const useOrgPipelinesQuery = (params: { owner: VcsOwner, metaFilter?: MetaQuery } & GetQueryOptions) =>
    useUserApiGet<Pipeline[]>(UserRequestId.OrgPipelines, PipelineUrls.orgPipelines(params), {}, queryOptions(params));

export const useOrgMonodesQuery = (params: { owner: VcsOwner, metaFilter?: MetaQuery } & GetQueryOptions) =>
    useUserApiGet<Pipeline[]>(UserRequestId.OrgMonodes, PipelineUrls.orgMonodes(params), {}, queryOptions(params));

export const useRepositoryMonodesQuery = (params: { path: OrgRepoPath, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<Pipeline[]>(UserRequestId.RepositoryMonodes, PipelineUrls.repositoryMonodes(params), {}, queryOptions(params));

export const useCommitPipelinesQuery = (params: { path: OrgRepoPath, sha: CommitSha, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<Pipeline[]>(UserRequestId.CommitPipelines, PipelineUrls.commitPipelines(params), {}, queryOptions(params));

export const usePipelineHistoryQuery = (params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch, lastRunId?: VcsRunId, eventTypes: VcsEvent[], limit?: number } & GetQueryOptions) =>
    useUserApiGet<PipelineResults>(UserRequestId.PipelineHistory, PipelineUrls.pipelineHistory(params), {}, queryOptions(params))

export const useRepositoryBranchesQuery = (params: { path: OrgRepoPath }) =>
    useUserApiGet<VcsBranch[]>(UserRequestId.RepositoryBranches, PipelineUrls.branches(params))

export const useRepositoryDefaultBranchQuery = (params: { path: OrgRepoPath } & GetQueryOptions) =>
    useUserApiGet<DefaultBranch>(UserRequestId.RepositoryDefaultBranch, PipelineUrls.defaultBranch(params), {}, queryOptions(params))

export const useMonopolisConfigurationQuery = (params: { path: OrgRepoPath, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<string>(UserRequestId.ConfigureRepository, PipelineUrls.configureMonopolis(params), {}, queryOptions(params));

const pipelineQueries = [
    UserRequestId.OrgPipelines,
    UserRequestId.OrgMonodes,
    UserRequestId.RepositoryPipelines,
    UserRequestId.RepositoryMonodes,
    UserRequestId.PipelineHistory,
    UserRequestId.UserWatchlist,
    UserRequestId.CommitPipelines
];

export const useRerunPipelineMutation = (params: { path: OrgRepoPath, runId: VcsRunId }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.RerunPipeline, PipelineUrls.rerun(params), pipelineQueries)

export const useDeletePipelineMutation = (params: { path: OrgRepoPath, runId: VcsRunId, branch: VcsBranch, pipeline: PipelineName }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.DeletePipeline, PipelineUrls.delete(params), pipelineQueries)

export const useCancelPipelineMutation = (params: { path: OrgRepoPath, runId: VcsRunId }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.CancelPipeline, PipelineUrls.cancel(params), pipelineQueries)

export const useRolloutPipelineMutation = (path: OrgRepoPath) =>
    useUserApiPost<UserRollout, EmptyResponse>(UserRequestId.RolloutPipeline, PipelineUrls.rollout(path), pipelineQueries)

const watchlistQueries = [
    ...pipelineQueries, UserRequestId.UserWatchlist
];

export const useUserWatchlistQuery = () =>
    useUserApiGet<Pipeline[]>(UserRequestId.UserWatchlist, PipelineUrls.userWatchlist())

export const useUserOrgsQuery = () =>
    useUserApiGet<VcsOwner[]>(UserRequestId.UserOrgs, PipelineUrls.userOrgs())

export const useUpdateUserWatchlistMutation = () =>
    useUserApiPost<PipelineId[], EmptyResponse>(UserRequestId.UpdateUserWatchlist, PipelineUrls.updateUserWatchlist(), watchlistQueries)

export const useAddToUserWatchlistMutation = (params: { path: OrgRepoPath, monode: Monode, branch: VcsBranch }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.AddToUserWatchlist, PipelineUrls.addToUserWatchlist(params), watchlistQueries)

export const useRemoveFromUserWatchlistMutation = (params: { path: OrgRepoPath, monode: Monode, branch: VcsBranch }) =>
    useUserApiPost<EmptyRequest, EmptyResponse>(UserRequestId.RemoveFromUserWatchlist, PipelineUrls.removeFromUserWatchlist(params), watchlistQueries)
