import {Box, IconButton, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {AuditEvent, AuditEventSpan, AuditEventType} from "../../../api/events/model";
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import FitScreenRoundedIcon from '@mui/icons-material/FitScreenRounded';
import {EventsTimelineHeader} from "./EventsTimelineHeader";
import {monodesFromAuditEvents} from "../../../utils/events";
import {RepoMonode} from "../../../models";
import {EventsTimelineBars} from "./EventsTimelineBars";
import {first, last} from "../../../../common/arrays";

const timelineRowHeight = 30

type PredefinedZoom = {
  value: number
  label: string
}

const predefinedZooms: PredefinedZoom[] = [
  {label: "10 minutes", value: 600000},
  {label: "30 minutes", value: 1800000},
  {label: "1 hour", value: 3600000},
  {label: "4 hours", value: 14400000},
  {label: "12 hours", value: 43200000},
  {label: "1 day", value: 86400000},
  {label: "1 week", value: 532800000},
]

export type EventsTimelineProps = {
  events: AuditEvent[]
  monodes?: RepoMonode[]
}

export function EventsTimeline({monodes, events}: EventsTimelineProps) {
  const [viewWidth, setViewWidth] = useState(-1)
  const [fitZoom, setFitZoom] = useState(-1)
  const [zoom, setZoom] = useState(3600000)
  const timelineEvents = events.flatMap(asTimelineEvent);
  const normalisedTimelineEvents = normaliseTimelineEvents(timelineEvents);
  const maxBarWidth = maxTimelineEventSpan(normalisedTimelineEvents)?.end || 0;
  const actualMonodes = monodes || monodesFromAuditEvents(events)

  useEffect(() => {
    const timer = setInterval(
        () => fitZoom > 0 && setTimeout(() => {
          setZoom(fitZoom)
          clearInterval(timer)
        }, 200),
        100
    );
  }, [fitZoom]);

  const handlePredefinedZoomChange = useCallback((event: SelectChangeEvent) => {
    setZoom(parseInt(event.target.value.toString()) / viewWidth);
  }, [viewWidth]);

  const handleWidthChange = useCallback((width: number) => {
    setViewWidth(width)
    setFitZoom(maxBarWidth / width)
  }, [maxBarWidth]);

  return <Box sx={{display: "flex", flexDirection: "column", mx: 2, my: 1}}>
    <Box sx={{mb: 2, display: "flex", justifyContent: "right", alignItems: "center"}}>
      <IconButton aria-label="disable" onClick={() => setZoom(fitZoom)}><FitScreenRoundedIcon/></IconButton>
      <IconButton aria-label="disable" onClick={() => setZoom(zoom / 1.3)}><ZoomInRoundedIcon/></IconButton>
      <IconButton aria-label="disable" onClick={() => setZoom(zoom * 1.3)}><ZoomOutRoundedIcon/></IconButton>
      <Select
          sx={{width: 200, height: 32}}
          aria-label="Zoom"
          size="small"
          value={(zoom * viewWidth).toString()}
          onChange={handlePredefinedZoomChange}
          renderValue={() => {
            const predefinedZoom = predefinedZooms.find(x => x.value / viewWidth === zoom);
            return predefinedZoom ? predefinedZoom.label : <em>custom zoom</em>;
          }}
      >
        {predefinedZooms.map((predefinedZoom) => (
            <MenuItem key={predefinedZoom.label} value={predefinedZoom.value}
                      selected={predefinedZoom.value / viewWidth === zoom}>
              <ListItemText primary={predefinedZoom.label}/>
            </MenuItem>
        ))}
      </Select>
    </Box>

    <Box sx={{
      display: "flex",
      width: "100%",
      height: (actualMonodes.length * timelineRowHeight + 50) + "px",
      overflow: "hidden",
      backgroundClip: "padding-box"
    }}>
      <Box sx={{
        position: "relative",
        height: "100%",
        maxWidth: "30%",
        overflow: "hidden",
        // borderRight: 1,
        // borderRightColor: monopolisTheme.palette.secondary.main,
      }}>
        {
          actualMonodes.map(monode =>
              <EventsTimelineHeader key={monode} height={timelineRowHeight} title={monode}/>
          )
        }
      </Box>

      <EventsTimelineBars monodes={actualMonodes}
                          zoom={zoom}
                          events={normalisedTimelineEvents}
                          barHeight={timelineRowHeight}
                          onWidth={handleWidthChange}/>
    </Box>
  </Box>
}

export type EventsTimelineGraphSpan = AuditEventSpan & {
  start: number,
  end: number,
}

export type EventsTimelineGraphEvent = {
  event: AuditEvent
  spans: EventsTimelineGraphSpan[]
}

export function asTimelineEvent(evt: AuditEvent): EventsTimelineGraphEvent {
  switch (evt.eventType) {
    case AuditEventType.MonodeAlert:
    case AuditEventType.Incident: {
      let spanData: EventsTimelineGraphSpan[] = []
      let previousSpan: AuditEventSpan | undefined = last(evt.spans);

      for (let i = evt.spans.length - 2; i >= 0; i--) {
        const currentSpan = evt.spans[i]
        const startedAt = currentSpan.startedAt;
        const updatedAt = previousSpan ? previousSpan.startedAt : currentSpan.updatedAt;
        spanData.push({
          ...currentSpan,
          startedAt,
          updatedAt,
          start: new Date(startedAt).getTime(),
          end: new Date(updatedAt).getTime(),
        })

        previousSpan = currentSpan
      }

      return {event: evt, spans: spanData.reverse()}
    }

    case AuditEventType.Push:
    case AuditEventType.Rollout: {
      const firstSpan = first(evt.spans)!!;
      const lastSpan = last(evt.spans)!!;
      const startedAt = firstSpan.startedAt;
      const updatedAt = lastSpan.updatedAt;
      return {
        event: evt,
        spans: [{
          ...lastSpan,
          startedAt,
          updatedAt,
          start: new Date(startedAt).getTime(),
          end: new Date(updatedAt).getTime(),
        }]
      }
    }
  }
}


export function maxTimelineEventSpan(events: EventsTimelineGraphEvent[]): EventsTimelineGraphSpan | undefined {
  return events.flatMap(event => event.spans).sort((a, b) => b.end - a.end)[0]
}

export function normaliseTimelineEvents(events: EventsTimelineGraphEvent[]): EventsTimelineGraphEvent[] {
  const min = Math.min(...(events.flatMap(event => event.spans).map(span => span.start)));
  return events.map(event => ({
    ...event,
    spans: event.spans.map(span => ({...span, start: span.start - min, end: span.end - min}))
  }))
}

export function scaleTimelineEvents(events: EventsTimelineGraphEvent[], zoom: number): EventsTimelineGraphEvent[] {
  return events.map(event => scaleTimelineEvent(event, zoom))
}

function scaleTimelineEvent(event: EventsTimelineGraphEvent, zoom: number): EventsTimelineGraphEvent {
  const scaled = event.spans.map(span => ({...span, start: span.start / zoom, end: span.end / zoom}));
  let added = 0
  const minWidth = 6
  const adjustedToMinWidth = scaled.map(span => {
    const width = span.end - span.start;
    const newSpan = {
      ...span,
      start: span.start + added,
      end: span.end + added + (width < minWidth ? minWidth : 0)
    };

    added = width < minWidth ? added + minWidth : added
    return newSpan
  });

  return {...event, spans: adjustedToMinWidth}
}
