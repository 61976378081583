import {Box, Grid} from "@mui/material";
import * as React from "react";
import {useMemo} from "react";
import {Monode, RolloutTarget} from "../../models";
import {Link} from "../../../common/components/navigation/Link";
import {RolloutMode, Target} from "../../api/rollouts/model";
import {Panel} from "../../../common/components/Panel";
import {distinct, groupBy} from "../../../common/arrays";
import {FollowDocumentationToConfigureHint} from "../hints";
import {TargetTooltip} from "./TargetTooltip";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {VcsOwner, VcsPath, VcsProvider} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type TargetsPanelProps = {
  vcs: VcsProvider
  org: VcsOwner
  repo?: VcsPath
  monode?: Monode
  targets: Target[],
}

export function TargetsPanel({
                               org,
                               repo,
                               monode,
                               targets,
                             }: TargetsPanelProps) {

  const {docsUrls, urls} = useUserAppInfrastructure()
  const targetViews = useMemo(() => targetViewsFrom(targets), [targets])
  const hasTargets = targetViews.length !== 0

  return <Grid item xs={12} data-test="targets">
    <Panel header="Targets" contentProps={{sx: {maxHeight: "200px", overflow: 'auto'}}}>
      <Box sx={{p: 1}}>
        {!hasTargets && <FollowDocumentationToConfigureHint feature="rollout" url={docsUrls.configureRollout()}/>}
        {hasTargets && targetViews.map(target =>
            <Box sx={{p: 1, display: "inline-block"}}>
              <Link tooltip={<TargetTooltip {...target}/>}
                    tooltipEnterDelay={monopolisConstraints.tooltipLongDelay}
                    tooltipNextEnterDelay={monopolisConstraints.tooltipLongDelay}
                    href={urls.target({org, repo: repo, monode, target: target.target})}>
                {target.target}
              </Link>
            </Box>)
        }
      </Box>
    </Panel>
  </Grid>
}

function targetViewsFrom(targets: Target[]): TargetView[] {
  const groupedTargets = groupBy(targets, t => t.target);

  return Object.keys(groupedTargets).map(target => ({
    target: target,
    rollouts: distinct(groupedTargets[target].flatMap(x => [x.rollout])),
    rollbacks: distinct(groupedTargets[target].flatMap(x => [x.rollback]))
  }))
}

export type TargetView = {
  target: RolloutTarget;
  rollouts: RolloutMode[];
  rollbacks: RolloutMode[];
}
