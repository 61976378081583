import React, {ReactNode, useEffect} from 'react';
import {useAccountAuthentication} from "./useAccountAuthentication";
import {AuthenticationType} from "../../common/auth/AuthenticationType";

import {useNavigation} from "../../common/port/Navigation";

export function AuthenticationOrFallback({authenticated, fallback}: RequireAuthenticationWithProps) {
  const {navigateTo} = useNavigation()
  const {accountProfile} = useAccountAuthentication();
  const isAuthenticated = accountProfile.type !== AuthenticationType.Unauthenticated

  const isFallbackUrl = typeof fallback === "string";
  const isAuthUrl = typeof authenticated === "string";
  useEffect(() => {
    if (isAuthenticated) {
      if (isAuthUrl) navigateTo(authenticated)
    } else {
      if (isFallbackUrl) navigateTo(fallback)
    }

  }, [isFallbackUrl, isAuthenticated, isAuthUrl, navigateTo, fallback, authenticated]);

  if (isAuthenticated) {
    return isAuthUrl ? <></> : <>{authenticated}</>;
  } else {
    return isFallbackUrl ? <></> : <>{fallback}</>
  }
}

interface RequireAuthenticationWithProps {
  authenticated: ReactNode
  fallback: ReactNode
}
