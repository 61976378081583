import * as React from "react";
import {RolloutTarget} from "../../models";
import {Link} from "../../../common/components/navigation/Link";
import {OrgRepoPath} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type RepoTargetBreadcrumbProps = {
  path: OrgRepoPath
  target: RolloutTarget
}

export function RepoTargetBreadcrumb({target, path}: RepoTargetBreadcrumbProps) {
  const {urls} = useUserAppInfrastructure()
  return <Link href={urls.target({repo: path.repo, org: path.org, target: target})}>
    {target}
  </Link>
}
