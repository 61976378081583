import React from "react";
import {Page, PageProps} from "../../common/layout/Page";
import {LaunchAppNavigationHeader} from "./LaunchAppNavigationHeader";
import {useLaunchAppInfrastructure} from "../LaunchAppInfrastructure";
import {LaunchAppPageId} from "../pages/LaunchAppPageId";

export function LaunchAppPage({pageId, onError, ...rest}: LaunchAppPageProps) {
  const {analytics} = useLaunchAppInfrastructure()
  const navigationHeader = <LaunchAppNavigationHeader/>;
  return (
      <Page pageId={pageId} analytics={analytics} navigationHeader={navigationHeader} {...rest}/>
  )
}

export type LaunchAppPageProps = {
  pageId: LaunchAppPageId
} & Omit<PageProps, "navigationHeader" | "analytics" | "pageId">


