import {Autocomplete, AutocompleteRenderInputParams, Box, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";
import {VcsOwner} from "../models";
import {OnError} from "../api/TransportLayer";

export type OrgSearchItem = {
  owner: VcsOwner
}

export type OrgSearchPanelProps = {
  all: OrgSearchItem[]
  selected: OrgSearchItem[]
  disabled: boolean
  onAdd: (item: OrgSearchItem) => void;
  onRemove: (item: OrgSearchItem) => void;
} & OnError

export function OrgSearchPanel(
    {
      disabled,
      all,
      selected,
      onAdd,
      onRemove,
    }: OrgSearchPanelProps) {

  const handleChange = useCallback(async (event: any, newValue: OrgSearchItem[]) => {
        const delta = {
          added: newValue.filter(item => selected.map(m => m.owner).indexOf(item.owner) === -1),
          removed: selected.filter(item => newValue.map(m => m.owner).indexOf(item.owner) === -1)
        };
        for (const item of delta.added) await onAdd(item)
        for (const item of delta.removed) await onRemove(item)
      }, [selected, onAdd, onRemove]
  );

  return <Box data-test="org-search">
    <Autocomplete
        multiple
        disabled={disabled}
        disableClearable
        value={selected}
        options={all}
        filterSelectedOptions={true}
        onChange={handleChange}
        getOptionLabel={(option) => option.owner}
        renderTags={() => <></>}
        renderOption={(props, option) => <OrgItem {...props} {...option} />}
        renderInput={(params) => <OrgSearchInput {...params}/>}
    />
  </Box>
}

type ExtendedOrgSearchDetails = {} & OrgSearchItem

type OrgItemProps = {} & React.HTMLAttributes<HTMLLIElement> & ExtendedOrgSearchDetails

function OrgItem({owner, ...props}: OrgItemProps) {
  return <Box component="li" {...props} >
    <Box alignItems="center" display="flex" flexDirection="row">
      <Typography variant="body1">{owner}</Typography>
    </Box>
  </Box>
}

type OrgSearchInputProps = {} & AutocompleteRenderInputParams

function OrgSearchInput({...props}: OrgSearchInputProps) {
  return <TextField {...props} placeholder="Add org..."/>
}
