import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Box, Button} from "@mui/material";
import {UserAppPage} from "../../layout/UserAppPage";
import {SolidCircularProgress} from "../../../common/components/SolidCircularProgress";
import {useOrgAdminConfigurationQuery} from "../../api/org";
import {OwnerPathParameter, usePathParams} from "../../models/Paths";
import {SystemError} from "../../../common/api/TransportLayer";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {UserPageId} from "../UserPageId";
import {OAuthProvider, oauthProviderDetails} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function OnboardingWelcomePage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {userProfile} = useUserAuthentication()
  const {config, urls} = useUserAppInfrastructure()
  const pathParams = usePathParams<OwnerPathParameter>();
  const {data} = useOrgAdminConfigurationQuery({
    ...pathParams,
    writeCache: true,
    refetchInterval: 5000,
    onQueryError: setError
  })

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>
  const newPlanLoaded = data && data.kind === "ok" && data.data.plan.name !== "No Plan"

  return <UserAppPage pageId={UserPageId.OnboardingWelcome} title="Welcome to Monopolis!" error={error}
                      profile={userProfile}>
    <Container component="main">
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
      >
        <Typography variant="h4" sx={{mt: 2, mb: 4}}>
          Welcome to {config.appName}!
        </Typography>
        <Box sx={{
          width: "60%",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          {newPlanLoaded
              ? <>
                <Typography variant="h6" textAlign="center">
                  Congratulations, the <strong>{data.data.plan.name}</strong> plan has now been installed
                  into <strong>{pathParams.owner}</strong>.
                </Typography>

                <Typography variant="h6" sx={{my: 2}} textAlign="center">
                  On the next page you can configure options for your installation, including activating members and
                  repositories.
                </Typography>

                <Button aria-label="letsGo" variant="outlined" color="primary"
                        href={urls.orgConfiguration(pathParams.owner)}>
                  Let's go!
                </Button>
              </>
              : <>
                <SolidCircularProgress/>
                <Typography variant="h6" sx={{mt: 2}}>
                  Please wait here
                  whilst <strong>{oauthProviderDetails(userProfile.vcs as OAuthProvider).name}</strong> bootstraps the
                  Monopolis
                  installation.
                </Typography>
              </>}
        </Box>
      </Box>
    </Container>
  </UserAppPage>
}
