import {Box, Typography, TypographyProps} from "@mui/material";
import * as React from "react";
import {Link} from "../../common/components/navigation/Link";
import {Monode, VcsBranch} from "../models";
import {monopolisTheme} from "../../common/styles/theme";
import {OrgRepoPath} from "../../common/models";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";

export function VcsPipelineName({path, branch, monode, maxWidth, color, monodeProps, pathProps}: VcsPipelineNameProps) {
  const {urls} = useUserAppInfrastructure()
  const actualColor = color || monopolisTheme.palette.primary.main
  return (
      <Box>
        <Typography maxWidth={maxWidth} variant="body1" noWrap sx={{textOverflow: "ellipsis"}} color={actualColor} {...monodeProps}>
          <Link href={urls.monode({path, branch, monode})} color={actualColor}>
            {monode}
          </Link>
        </Typography>
        <Typography noWrap maxWidth={maxWidth} variant="caption" display="block" color={actualColor} {...pathProps} >
          <Link href={urls.repository(path, branch)} color={actualColor}>
            {path.org}/{path.repo}
          </Link>
        </Typography>
      </Box>
  )
}

export type VcsPipelineNameProps = {
  path: OrgRepoPath;
  branch?: VcsBranch;
  monode: Monode;
  monodeProps?: TypographyProps;
  pathProps?: TypographyProps;
  color?: string;
  maxWidth: number | string;
}
