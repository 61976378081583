export enum AccountApiRequestId {
    AccountProfile = 'AccountProfile',
    PurchaseHistory = 'PurchaseHistory',
    AccountConfiguration = 'AccountConfiguration',
    OrgConfigurations = 'OrgConfigurations',
    AvailableOrgs = 'AvailableOrgs',
    EnableOrg = 'EnableOrg',
    DisableOrg = 'DisableOrg',
    AccountUpdateAccessToken = 'AccountUpdateAccessToken',
    AccountUpdateOAuthCredentials = 'AccountUpdateOAuthCredentials',
    AccountUpdateConfiguration = 'AccountUpdateConfiguration',
    AccountUpdateTeamUrl = 'AccountUpdateTeamUrl',
    AccountIssueOneTimeLogin = 'AccountIssueOneTimeLogin',
}
