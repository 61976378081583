import {Box, Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {Panel} from "../../../common/components/Panel";
import {BranchSelection} from "../../components/pipelines/BranchSelection";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {BuildPanel} from "../../components/BuildPanel";
import {PipelineHistoryPanel} from "../../components/pipelines/PipelineHistoryPanel";
import * as React from "react";
import {useEffect, useState} from "react";
import {MonodePageTabProps} from "./MonodePageTab";
import {useBranchOrDefault} from "../../utils/branches";
import {VcsRunId} from "../../models";
import {MonodePathParameter, OwnerPathParameter, RepositoryPathParameter, usePathParams} from "../../models/Paths";
import {usePipelineBuildStatisticsQuery} from "../../api/statistics";
import {usePipelineHistoryQuery} from "../../api/pipelines";
import {queryRefetchIntervalFrom} from "../../utils/orgConfigurations";
import {PipelineMenu} from "../../components/pipelines/PipelineMenu";
import {VcsBreadcrumbsMenu} from "../../components/VcsBreadcrumbsMenu";
import {Pipeline} from "../../api/pipelines/model";
import {PipelineMenuProvider} from "../../utils/pipelines";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function MonodePageBuildsTab({path, userProfile, orgConfiguration, onSidebar, onError}: MonodePageTabProps) {
  const [pipeline, setPipeline] = useState<Pipeline | undefined>()
  const {badgesUrls} = useUserAppInfrastructure()
  const branch = useBranchOrDefault({path, onError});
  const loadComponents = branch !== undefined;
  const [lastRunId, setLastRunId] = useState<VcsRunId | undefined>();
  const [limit, setLimit] = useState<number>(10);
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter & MonodePathParameter>();
  const buildStatsResult = usePipelineBuildStatisticsQuery({...pathParams, path, branch, enabled: loadComponents})

  const pipelineHistoryResult = usePipelineHistoryQuery({
    path,
    monode: pathParams.monode,
    branch,
    lastRunId,
    limit,
    eventTypes,
    refetchInterval: queryRefetchIntervalFrom(orgConfiguration),
    enabled: loadComponents,
    onSuccess: (data) => {
      data.kind === "ok" && setPipeline(data.data.pipeline)
    }
  })

  useEffect(() => onSidebar && onSidebar(<Grid item xs={12}>
    <Panel>
      <Box sx={{display: "flex"}}>
        <Box sx={{flexGrow: 1}}>
          <BranchSelection path={path}
                           selected={branch || ""}
                           maxWidth={"100%"}/>
        </Box>

        <Box sx={{ml: 2}}>
          <VcsBreadcrumbsMenu actions={["addToWatchlist", "removeFromWatchlist"]}
                              path={path}
                              monode={pathParams.monode}
                              branch={branch}
                              isWatched={pipeline && pipeline.isWatched}/>
        </Box>
      </Box>
    </Panel>
  </Grid>), [onSidebar, branch, path, pathParams, pipeline])

  const pipelineMenu: PipelineMenuProvider = (params) =>
      <PipelineMenu {...params} actions={["rerun", "cancel", "rollout"]}/>;

  const handlePage = (runId?: VcsRunId) => setLastRunId(runId);
  const handleLimit = (limit: number) => {
    setLimit(limit)
    setLastRunId(undefined)
  };

  const handleEvents = (events: string[]) => {
    setEventTypes(events)
    setLastRunId(undefined)
  };

  if (!loadComponents) return null

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <QueryLoader height={450} result={buildStatsResult} onError={onError} onLoaded={data =>
        <BuildPanel customerId={userProfile.customerId}
                    vcs={userProfile.vcs}
                    urls={badgesUrls}
                    path={path}
                    monode={pathParams.monode}
                    branch={branch}
                    statistics={data}/>
    }/>

    <PipelineHistoryPanel pipelineHistoryResult={pipelineHistoryResult}
                          menu={pipelineMenu}
                          onError={onError}
                          onPage={handlePage}
                          onLimit={handleLimit}
                          onEvents={handleEvents}/>
  </Grid>
}
