import * as React from "react";
import {ReactNode} from "react";
import {monopolisConstraints} from "../styles/constraints";
import {Grid} from "@mui/material";

export type SidebarProps = {
  children?: ReactNode;
}

export function Sidebar({children}: SidebarProps) {
  return <Grid container xs={12} rowSpacing={monopolisConstraints.gridSpacing}
               sx={{width: "100%"}}>
    {children}
  </Grid>
}

export function SidebarItem({children}: SidebarProps) {
  return <Grid item xs={12}>
    {children}
  </Grid>
}
