import {CommitSha, MetaQuery, Monode, PipelineName, VcsBranch, VcsEvent, VcsRunId} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";
import {branchParam, eventTypes, lastRunId, limit, metaFilterParam, pipelineParam} from "../../utils/urlParams";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export class PipelineUrls {
    static orgPipelines(params: { owner: VcsOwner, metaFilter?: MetaQuery }) {
        const query = Array<UrlQueryParam>()
        if (params.metaFilter) query.push(metaFilterParam(params.metaFilter));
        return buildUrl(`/pipelines/org/${params.owner}`, query);
    }

    static orgMonodes(params: { owner: VcsOwner, metaFilter?: MetaQuery }) {
        const query = Array<UrlQueryParam>()
        if (params.metaFilter) query.push(metaFilterParam(params.metaFilter));
        return buildUrl(`/monodes/org/${params.owner}`, query);
    }

    static repositoryPipelines(params: { path: OrgRepoPath, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/pipelines/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`, query);
    }

    static repositoryMonodes(params: { path: OrgRepoPath, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/monodes/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`, query);
    }

    static configureMonopolis(params: { path: OrgRepoPath, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/config/${params.path.org}/${encodeURIComponent(params.path.repo)}`, query);
    }

    static commitPipelines(params: { path: OrgRepoPath, sha: CommitSha, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/pipelines/commit/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.sha}`, query);
    }

    static defaultBranch(params: { path: OrgRepoPath }) {
        return `/repo/branches/${params.path.org}/${encodeURIComponent(params.path.repo)}/default`;
    }

    static branches(params: { path: OrgRepoPath }) {
        return `/repo/branches/${params.path.org}/${encodeURIComponent(params.path.repo)}`;
    }

    static pipelineHistory(params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch, lastRunId?: VcsRunId, eventTypes: VcsEvent[], limit?: number }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        if (params.lastRunId) query.push(lastRunId(params.lastRunId));
        if (params.limit) query.push(limit(params.limit));
        params.eventTypes.forEach(eventType => query.push(eventTypes(eventType)))
        return buildUrl(`/pipelines/history/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, query)
    }

    static rerun(params: { path: OrgRepoPath, runId: VcsRunId }) {
        return `/pipelines/rerun/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.runId}`;
    }

    static delete(params: { path: OrgRepoPath, runId: VcsRunId, branch: VcsBranch, pipeline: PipelineName }) {
        const query = Array<UrlQueryParam>()
        query.push(branchParam(params.branch));
        query.push(pipelineParam(params.pipeline));
        return buildUrl(`/pipelines/delete/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.runId}`, query)
    }

    static cancel(params: { path: OrgRepoPath, runId: VcsRunId }) {
        return `/pipelines/cancel/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.runId}`;
    }

    static rollout(path: OrgRepoPath) {
        return buildUrl(`/monode/rollout/${path.org}/${encodeURIComponent(path.repo)}`);
    }

    static userWatchlist() {
        return `/pipelines/user`;
    }

    static userOrgs() {
        return `/user/orgs`;
    }

    static updateUserWatchlist() {
        return `/pipelines/user`;
    }

    static addToUserWatchlist(params: { path: OrgRepoPath, monode: Monode, branch: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        query.push(branchParam(params.branch));
        return buildUrl(`/pipelines/user/add/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, query)
    }

    static removeFromUserWatchlist(params: { path: OrgRepoPath, monode: Monode, branch: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        query.push(branchParam(params.branch));
        return buildUrl(`/pipelines/user/remove/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, query)
    }
}
