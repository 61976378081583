import {CacheOptions, GetQueryOptions, queryOptions, refetchQueryOptions} from "../../../common/api/query";
import {
    AccountConfiguration,
    AccountOAuthCredentials,
    AccountVcsAccessTokenRequest,
    MaybeAccountConfiguration, VcsInstance
} from "./model";
import {AccountUrls} from "./urls";
import {useAccountApiDelete, useAccountApiGet, useAccountApiPost, useAccountApiPut} from "../accountApi";
import {AccountApiRequestId} from "../AccountApiRequestId";
import {OnError, SystemResponse} from "../../../common/api/TransportLayer";
import {PlanPurchase, VcsOwner} from "../../../common/models";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {AccountStoredItemKey} from "../../AccountStoredItemKey";
import {AccountAuthenticationProfile, useAccountAuthentication} from "../../auth/useAccountAuthentication";
import {AuthenticatedAccountProfile} from "../../auth/AuthenticatedAccountProfile";
import {useAccountAppInfrastructure} from "../../AccountAppInfrastructure";
import {TeamId} from "../../../common/models/TeamId";

export function useFetchAccountProfileQuery(params: {
    token: string,
    onFetch: (data: SystemResponse<AuthenticatedAccountProfile>) => void
}) {
    const {storage} = useAccountAppInfrastructure()
    return useAccountApiGet<AccountAuthenticationProfile>(AccountApiRequestId.AccountProfile, AccountUrls.account(), {token: () => params.token},
        {...refetchQueryOptions, onSuccess: params.onFetch},
        {
            writeCache: true,
            cacheExpiryInSeconds: 20,
            cache: storage.local,
            cacheKey: AccountStoredItemKey.Profile,
        }
    );
}

export function useRefetchAccountProfileQuery(): AccountAuthenticationProfile {
    const {accountProfile} = useAccountAuthentication()
    const {storage} = useAccountAppInfrastructure()
    const {data} = useAccountApiGet<AccountAuthenticationProfile>(AccountApiRequestId.AccountProfile, AccountUrls.account(), {}, queryOptions({}),
        {
            readCache: true,
            writeCache: true,
            cacheExpiryInSeconds: 20,
            cache: storage.local,
            cacheKey: AccountStoredItemKey.Profile,
        }
    );

    if (!data) return accountProfile

    if (data.kind === "error") {
        return accountProfile
    }

    if (data.kind === "ok") {
        return {...(data.data as AuthenticatedAccountProfile), type: AuthenticationType.Account}
    }

    return accountProfile
}

export const useAccountConfigurationQuery = (params: {} & CacheOptions & GetQueryOptions = {}) =>
    useAccountApiGet<AccountConfiguration>(AccountApiRequestId.AccountConfiguration, AccountUrls.configuration(), {}, queryOptions(params), {});

export const useAccountConfiguredOrgsQuery = (params: {} & CacheOptions & GetQueryOptions = {}) =>
    useAccountApiGet<VcsOwner[]>(AccountApiRequestId.OrgConfigurations, AccountUrls.orgConfigurations(), {}, queryOptions(params), params);

export const useAccountAvailableOrgsQuery = (params: {} & CacheOptions & GetQueryOptions = {}) =>
    useAccountApiGet<VcsOwner[]>(AccountApiRequestId.AvailableOrgs, AccountUrls.availableOrgs(), {}, queryOptions(params), params);

export function useCachedCustomerConfigurationQuery(params: {} & OnError): MaybeAccountConfiguration {
    const {data} = useAccountConfigurationQuery({
        readCache: true,
        writeCache: true,
        onQueryError: params.onError
    })
    if (!data) return undefined

    if (data.kind === "error") {
        return undefined
    }

    if (data.kind === "ok") {
        return data.data
    }
}
const accountConfigurationInvalidations = [AccountApiRequestId.AccountConfiguration]

export const useAccountPlanHistoryQuery = (params: {} & CacheOptions & GetQueryOptions = {}) =>
    useAccountApiGet<PlanPurchase[]>(AccountApiRequestId.PurchaseHistory, AccountUrls.planHistory(), {}, queryOptions(params), params);

export const useAccountUpdateConfigurationMutation = () =>
    useAccountApiPost<VcsInstance>(AccountApiRequestId.AccountUpdateConfiguration, AccountUrls.configuration(), accountConfigurationInvalidations)

export const useAccountUpdateTeamUrl = () =>
    useAccountApiPost<TeamId>(AccountApiRequestId.AccountUpdateTeamUrl, AccountUrls.configureAccountTeamUrl(), accountConfigurationInvalidations)

export const useAccountUpdateVcsAccessTokenMutation = () =>
    useAccountApiPost<AccountVcsAccessTokenRequest>(AccountApiRequestId.AccountUpdateAccessToken, AccountUrls.configureVcsAccessToken(), accountConfigurationInvalidations)

export const useAccountUpdateOAuthCredentialsMutation = () =>
    useAccountApiPost<AccountOAuthCredentials>(AccountApiRequestId.AccountUpdateOAuthCredentials, AccountUrls.configureOAuthCredentials(), accountConfigurationInvalidations)

export type OrgMutationRequest = {
    owner: VcsOwner
}

const orgConfigurationInvalidations = [AccountApiRequestId.OrgConfigurations]

export const useAccountEnableOrgMutation = () =>
    useAccountApiPut<OrgMutationRequest>(AccountApiRequestId.EnableOrg, req => AccountUrls.enableOrg(req), orgConfigurationInvalidations, {}, () => null)

export const useAccountDisableOrgMutation = () =>
    useAccountApiDelete<OrgMutationRequest>(AccountApiRequestId.DisableOrg, req => AccountUrls.disableOrg(req), orgConfigurationInvalidations)



