import * as React from "react";
import {ReactNode} from "react";
import {Box, BoxProps, Divider, Paper, PaperProps, Typography} from "@mui/material";

export function Panel({header, actions, children, contentProps, ...rest}: PanelProps) {
  const contentPadding: BoxProps = rest.hasOwnProperty("noPadding") || rest.noPadding === true ? {sx: {p: 0}} : {}

  return (
      <Paper {...rest} >
        {header &&
            <>
              <Box display={"flex"} flexDirection="row">
                <Typography sx={{flexGrow: 1, py: 2, px: 2}} fontWeight={500} variant="body1">{header}</Typography>
                {actions && <Box sx={{p: 1}}>{actions}</Box>}
              </Box>
              <Divider/>
            </>
        }
        <Box sx={{p: 2, width: "100%"}} {...contentPadding} {...contentProps} >
          {children}
        </Box>
      </Paper>
  )
}

export type PanelProps = {
  header?: ReactNode
  actions?: ReactNode
  noPadding?: boolean
  contentProps?: BoxProps
} & PaperProps
