import {Box, Divider, Typography} from "@mui/material";
import * as React from "react";
import {AuditEvent} from "../../../api/events/model";
import {eventEntityFor} from "../../../utils/events";
import {EventsTimelineGraphSpan} from "./EventsTimeline";
import {formatUTCToLocalDateTime} from "../../../../common/dateTime";

export type EventsTimelineSpanTooltipProps = {
  event: AuditEvent
  span: EventsTimelineGraphSpan
}

export function EventsTimelineSpanTooltip({event, span}: EventsTimelineSpanTooltipProps) {
  return (<>
        <Box sx={{display: "flex"}}>
          <Typography variant="body2"
                      fontWeight="bold"
                      sx={{flexGrow: 1}}
          >
            {event.eventType}
          </Typography>

          <Typography variant="caption">{eventEntityFor(event)}</Typography>
        </Box>


        <Divider sx={{mb: 1}}/>

        {
            "target" in event &&
            <Typography variant="body2">
              Target: {event.target}
            </Typography>
        }

        <Typography variant="body2">
          Started at {formatUTCToLocalDateTime(span.startedAt)}
        </Typography>

        <Typography variant="body2">
          Updated at {formatUTCToLocalDateTime(span.updatedAt)}
        </Typography>
      </>
  )
}
