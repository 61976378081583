import {useRepositoryDefaultBranchQuery} from "../api/pipelines";
import {VcsBranch} from "../models";
import {OnError} from "../../common/api/TransportLayer";
import {useQueryParamOrDefault} from "../../common/navigation";
import {branchParamName} from "./urlParams";
import {OrgRepoPath} from "../../common/models";

export function useBranchOrDefault({path, onError}: UseBranchOrDefaultProps): VcsBranch | undefined {
    const {refetch} = useRepositoryDefaultBranchQuery({path})
    const branch = useQueryParamOrDefault(branchParamName, async () => {
            const {data} = await refetch();
            if (data && data.kind === "error") onError(data.error)
            if (data && data.kind === "ok") {
                return data.data.branch
            }

            return "";
        }
    )

    return branch.value === "" ? undefined : branch.value
}

export type UseBranchOrDefaultProps = {
    path: OrgRepoPath,
} & OnError
