import * as React from "react";
import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {FormStack, FormTextField} from "../../../common/components/form";
import {ConfigurationSection} from "../../../common/components/configuration";

export type AccountDetailsPanelProps = {
  profile: AuthenticatedUserProfile
}

export function AccountDetailsPanel({profile}: AccountDetailsPanelProps) {
  return <ConfigurationSection data-test="monopolis-account" label="Monopolis account">
    <FormStack>
      <FormTextField label="Customer Id"
                     required={true}
                     readOnly={true}
                     value={profile.customerId}
                     placeholder=""
      />
      <FormTextField label="Auth provider"
                     required={true}
                     readOnly={true}
                     value={profile.vcsServer}
                     placeholder=""
      />
    </FormStack>
  </ConfigurationSection>;
}
