import * as React from "react";
import {ReactNode} from "react";
import {OnError, SystemResponse} from "../api/TransportLayer";
import {Box, Skeleton} from "@mui/material";
import {QueryResult} from "../api/query";

export function QueryLoader<T>({result, onLoaded, enabled = true, height, onError}: QueryLoaderProps<T>) {
  if (enabled !== undefined && !enabled) {
    return <></>
  }

  const {data} = result
  const styles = createStyles()
  if (!data) {
    return <Box sx={styles.skeleton}>
      <Skeleton variant="rectangular" width="100%" height={height || 100}/>
    </Box>
  }

  if (data.kind === "error") {
    onError(data.error)
    return <></>;
  }

  return (<>{onLoaded(data.data)}</>);
}

export type QueryLoaderProps<T> = {
  result: QueryResult<SystemResponse<T>>;
  height?: number;
  enabled?: boolean;
  onLoaded: (data: T) => ReactNode;
} & OnError

const createStyles = () => {
  return ({
    skeleton: {
      py: 2,
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center"
    }
  } as const)
}
