import {UserAuthenticationProfile, useUserAuthentication} from "./useUserAuthentication";
import {useFetchUserProfileQuery} from "../api/user";
import {noRefetchQueryOptions} from "../../common/api/query";
import * as React from "react";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {unauthenticatedProfile} from "../../common/auth/UnauthenticatedProfile";
import {AuthenticatedUserProfile} from "./AuthenticatedUserProfile";
import {AuthenticationType} from "../../common/auth/AuthenticationType";

export type FetchUserProfileProps = {
  onFetch: (profile: UserAuthenticationProfile) => ReactNode
}

export function FetchUserProfile({onFetch}: FetchUserProfileProps) {
  const {getCookie, logout, loginUserFromCallback} = useUserAuthentication()

  const {refetch: fetchProfile} = useFetchUserProfileQuery({...noRefetchQueryOptions, token: getCookie});
  const [newUserProfile, setNewUserProfile] = useState<UserAuthenticationProfile>(unauthenticatedProfile)
  const [cookie, setCookie] = useState<string | undefined>(undefined)
  const [initialised, setInitialised] = useState(false)

  const fetch = useCallback((currentCookie: string | undefined) => {
    if (!currentCookie) {
      setCookie(currentCookie)
      logout(() => {
        setNewUserProfile(unauthenticatedProfile)
      })
    } else {
      fetchProfile().then(result => {
        if (result.data && result.data?.kind === "ok") {
          const data = result.data.data as AuthenticatedUserProfile;
          const profile: AuthenticatedUserProfile = {...data, type: AuthenticationType.User};
          setNewUserProfile(profile)
          loginUserFromCallback(currentCookie, profile)
        } else logout(() => {
          setNewUserProfile(unauthenticatedProfile)
        })
      })
    }
  }, [fetchProfile, logout, loginUserFromCallback])

  useEffect(() => {
    if (!initialised) {
      fetch(getCookie())
      setInitialised(true)
    }

    const intervalId = setInterval(() => {
      const currentCookie = getCookie()
      if (cookie !== currentCookie) {
        fetch(currentCookie);
        setCookie(currentCookie)
      }
    }, 500);

    return () => clearInterval(intervalId);

  }, [initialised, setInitialised, fetch, getCookie, cookie, setCookie, logout, fetchProfile, loginUserFromCallback]);

  return <>{onFetch(newUserProfile)}</>
}
