import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography
} from "@mui/material";
import {ContentCopyOutlined, LinkRounded} from "@mui/icons-material";
import {EmbeddedImageFormat, EmbeddedImageFormats, embedImage} from "../../utils/embed";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {monopolisTheme} from "../../../common/styles/theme";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";


export type BadgeDialogProps = {
  title: string
  description: string
  uri: string
}

export function BadgeDialog({title, uri, description}: BadgeDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [badgeFormat, setBadgeFormat] = React.useState<EmbeddedImageFormat>("Markdown");
  const {analytics} = useUserAppInfrastructure()
  const handleOpen = () => {
    setOpen(true)
    analytics.dialogview("Badge - " + title)
  }
  const handleClose = () => setOpen(false)
  const handleChangeFormat = (event: SelectChangeEvent) => setBadgeFormat(event.target.value as EmbeddedImageFormat)

  const imageEmbedText = embedImage(badgeFormat, title, uri)

  return (
      <>
        <IconButton aria-label={`${title} Badge`}
                    size="small"
                    sx={{border: 0, borderRadius: 0, borderColor: monopolisTheme.palette.primary.main}}
                    color="primary" onClick={handleOpen}>
          <LinkRounded fontSize="small"/>
        </IconButton>
        <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={handleClose}>
          <DialogTitle>
            {title}
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <DialogContentText component={'span'} color="primary" sx={{mb: 3}}>
              <Typography variant="body1">
                {description}
              </Typography>
            </DialogContentText>

            <DialogContentText component={'span'} color="primary" sx={{mb: 3}}>
              <Paper variant="outlined"
                     sx={{display: "flex", my: 2, p: 2, justifyContent: "center", alignItems: "center", minHeight: 60}}
                     style={{
                       borderColor: monopolisTheme.palette.secondary.main,
                       backgroundColor: monopolisTheme.palette.primary.light
                     }}>
                {!loaded && <Skeleton variant="rectangular" width="100%" height={20}/>}
                <img src={uri} alt={title} onLoad={() => setLoaded(true)}/>
              </Paper>
            </DialogContentText>

            <DialogContentText component={'span'} color="primary">
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{flexGrow: 1}}>Copy the text below to embed this badge in your page</Typography>

                <Select
                    sx={{flexGrow: 1, ml: 1}}
                    placeholder="branch"
                    size="small"
                    value={badgeFormat}
                    onChange={handleChangeFormat}
                >
                  {
                    EmbeddedImageFormats.map(format =>
                        <MenuItem key={format} value={format}>{format}</MenuItem>
                    )
                  }
                </Select>
              </Box>

              <Paper variant="outlined" sx={{my: 2, position: "relative", minHeight: 120}} style={{
                borderColor: monopolisTheme.palette.secondary.main,
                backgroundColor: monopolisTheme.palette.primary.light
              }}>
                <Box sx={{p: 2}}>
                  <Typography variant="body2" sx={{fontFamily: 'Monospace', wordWrap: "break-word", mr: "40px"}}>
                    {imageEmbedText}
                  </Typography>
                  <CopyToClipboard text={imageEmbedText}
                                   onCopy={() => {
                                     setCopied(true)
                                     setTimeout(() => setCopied(false), 1500);
                                   }}>
                    <IconButton
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          border: 0,
                          borderRadius: 1,
                          color: monopolisTheme.palette.secondary.main
                        }}
                        color="primary">
                      <ContentCopyOutlined/>
                    </IconButton>
                  </CopyToClipboard>
                </Box>

                <Paper elevation={0} sx={{
                  pointerEvents: "none",
                  touchAction: "none",
                  display: "flex",
                  flexDirection: "column",
                  p: 2,
                  position: "absolute",
                  opacity: copied ? 1 : 0,
                  transition: "opacity .10s ease-in-out",
                  backgroundColor: monopolisTheme.palette.secondary.main,
                  width: "100%",
                  height: "100%",
                  left: 0,
                  top: 0,
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <ContentCopyOutlined/>
                  <Typography variant="body1" sx={{mt: 1}}>Copied to clipboard</Typography>
                </Paper>

              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button aria-label="Close" variant="outlined" color="primary" autoFocus onClick={handleClose} sx={{mr: 2, mb: 2}}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}
