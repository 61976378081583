import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {LoginCallback} from "./pages/login/LoginCallback";
import {Logout} from "./pages/logout/Logout";
import {LoginPage} from "./pages/login/LoginPage";
import {Dashboard as TestDash} from "./components/examples/Dashboard";
import {MonodePage} from "./pages/monode/MonodePage";
import {Paths} from "./models/Paths";
import {UserDashboardPage} from "./pages/userDashboard/UserDashboardPage";
import {RepositoryPage} from "./pages/repository/RepositoryPage";
import {CommitPage} from "./pages/commit/CommitPage";
import {RepositoryConfigurationPage} from "./pages/repositoryConfiguration/RepositoryConfigurationPage";
import {TestBoundary} from "./components/examples/TestBoundary";
import {OrgPage} from "./pages/org/OrgPage";
import {RepositoryTargetPage} from "./pages/repositoryTarget/RepositoryTargetPage";
import {OrgConfigurationPage} from "./pages/orgConfiguration/OrgConfigurationPage";
import {UserConfigurationPage} from "./pages/userConfiguration/UserConfigurationPage";
import {OrgTargetPage} from "./pages/orgTarget/OrgTargetPage";
import {MonodeTargetPage} from "./pages/monodeTarget/MonodeTargetPage";
import {OnboardingCallback} from "./pages/onboarding/OnboardingCallback";
import {OnboardingWelcomePage} from "./pages/onboarding/OnboardingWelcomePage";
import {VerifyEmailCallback} from "./pages/emailVerify/VerifyEmailCallback";
import {OrgDashboardPage} from "./pages/orgDashboard/OrgDashboardPage";
import {AuthenticationOrFallback} from "./auth/AuthenticationOrFallback";
import {RequireAuthentication} from "./auth/RequireAuthentication";
import {UserPermissions} from "./auth/Permissions";
import {PageNotFound} from "./pages/error/PageNotFound";

//Note: Order of items is important here as we do not prefix owner/repo paths
export const UserAppRouter: React.FC = () => {
  return (
      <Routes>
        <Route path={Paths.LoginCallback} element={<LoginCallback/>}/>
        <Route path={Paths.Logout} element={<Logout/>}/>
        <Route path={Paths.OnboardingCallback} element={<OnboardingCallback/>}/>

        <Route path={Paths.OrgWelcome} element={
          <RequireAuthentication permissions={[UserPermissions.EditOrganisation]}>
            <OnboardingWelcomePage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.Home} element={
          <AuthenticationOrFallback fallback={<LoginPage/>}>
            <UserDashboardPage/>
          </AuthenticationOrFallback>
        }/>

        <Route path={Paths.VerifyEmail} element={
          <RequireAuthentication>
            <VerifyEmailCallback/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.UserSettings} element={
          <RequireAuthentication>
            <UserConfigurationPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.RepositorySettings} element={
          <RequireAuthentication>
            <RepositoryConfigurationPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.Repository} element={
          <RequireAuthentication>
            <RepositoryPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.RepositoryTarget} element={
          <RequireAuthentication>
            <RepositoryTargetPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.TestDashboard} element={<TestDash/>}/>
        <Route path={Paths.TestBoundary} element={<TestBoundary/>}/>

        <Route path={Paths.Commit} element={
          <RequireAuthentication>
            <CommitPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.Monode} element={
          <RequireAuthentication>
            <MonodePage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.MonodeTarget} element={
          <RequireAuthentication>
            <MonodeTargetPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.OrgConfiguration} element={
          <RequireAuthentication permissions={[UserPermissions.EditOrganisation]}>
            <OrgConfigurationPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.OrgTarget} element={
          <RequireAuthentication>
            <OrgTargetPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.OrgDashboard} element={
          <RequireAuthentication>
            <OrgDashboardPage/>
          </RequireAuthentication>
        }/>

        <Route path={Paths.Org} element={
          <RequireAuthentication>
            <OrgPage/>
          </RequireAuthentication>
        }/>

        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
  );
};
