import {GetQueryOptions, queryOptions} from "../../../common/api/query";
import {UserRequestId} from "../UserRequestId";
import {DocumentationContent, DocumentationListView, LinkConfig, PluginsConfigView} from "./model";
import {PluginsUrls} from "./urls";
import {Monode, RolloutTarget, VcsBranch, VcsRepoFilePath} from "../../models";
import {useApiUrl, useUserApiGet} from "../userApi";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export const useMonodeApiSpecContentUrl = (params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch }) =>
    useApiUrl(PluginsUrls.monodeApiContent(params))

export const useMonodeDocumentationContentQuery = (params: { path: OrgRepoPath, monode: Monode, file: VcsRepoFilePath, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<DocumentationContent>(UserRequestId.MonodeDocumentationContent, PluginsUrls.monodeDocumentationContent(params), {}, queryOptions(params))

export const useMonodeDocumentationListQuery = (params: { path: OrgRepoPath, monode: Monode, file: VcsRepoFilePath, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<DocumentationListView>(UserRequestId.MonodeDocumentationList, PluginsUrls.monodeDocumentationList(params), {}, queryOptions(params))

export const useOrgMonodesPluginsConfigsQuery = (params: { owner: VcsOwner } & GetQueryOptions) =>
    useUserApiGet<PluginsConfigView[]>(UserRequestId.OrgMonodesPluginsConfigs, PluginsUrls.orgMonodesPluginsConfigs(params), {}, queryOptions(params))

export const useRepositoryMonodesPluginsConfigsQuery = (params: { path: OrgRepoPath } & GetQueryOptions) =>
    useUserApiGet<PluginsConfigView[]>(UserRequestId.RepoMonodesPluginsConfigs, PluginsUrls.repositoryMonodesPluginsConfigs(params), {}, queryOptions(params))

export const useMonodePluginsConfigQuery = (params: { path: OrgRepoPath, monode: Monode } & GetQueryOptions) =>
    useUserApiGet<PluginsConfigView>(UserRequestId.MonodePluginsConfig, PluginsUrls.monodePluginsConfig(params), {}, queryOptions(params))

export const useRepositoryLinksQuery = (params: { path: OrgRepoPath } & GetQueryOptions) =>
    useUserApiGet<LinkConfig[]>(UserRequestId.RepoLinks, PluginsUrls.repositoryLinks(params), {}, queryOptions(params))

export const useMonodeLinksQuery = (params: { path: OrgRepoPath, monode: Monode } & GetQueryOptions) =>
    useUserApiGet<LinkConfig[]>(UserRequestId.MonodeLinks, PluginsUrls.monodeLinks(params), {}, queryOptions(params))

export const useMonodeTargetLinksQuery = (params: { path: OrgRepoPath, monode: Monode, target: RolloutTarget } & GetQueryOptions) =>
    useUserApiGet<LinkConfig[]>(UserRequestId.MonodeTargetLinks, PluginsUrls.monodeTargetLinks(params), {}, queryOptions(params))
