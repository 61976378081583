import * as React from 'react';
import {SystemError} from "../../../common/api/TransportLayer";
import {AccountAppPage} from "../../layout/AccountAppPage";
import {useAccountAuthentication} from "../../auth/useAccountAuthentication";
import {AccountPageId} from "../AccountPageId";

export type ErrorPageProps = {
  error: SystemError;
}

export function ErrorPage({error}: ErrorPageProps) {
  const {accountProfile} = useAccountAuthentication()
  return <AccountAppPage pageId={AccountPageId.Error} title={`Error: ${error.message}`} error={error} profile={accountProfile}/>;
}
