import * as React from "react";
import {Bar, BarChart} from "recharts";
import {formatStatisticsDates, StatisticsDateAggregate} from "../../api/statistics/model";
import {monopolisTheme} from "../../../common/styles/theme";

export type SmallPipelinesStatusChartProps = {
  statistics: StatisticsDateAggregate[];
}

export function SmallPipelinesStatusChart({statistics}: SmallPipelinesStatusChartProps) {
  const formattedStats = formatStatisticsDates(statistics)

  return <BarChart
      width={100}
      height={50}
      data={formattedStats}
  >
    <Bar dataKey="successful" stackId="builds" fill={monopolisTheme.palette.success.main} opacity={0.7}/>
    <Bar dataKey="failed" stackId="builds" fill={monopolisTheme.palette.error.main} opacity={0.7}/>
  </BarChart>
}
