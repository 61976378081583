import * as React from "react";
import {useCallback, useState} from "react";
import {AccountConfiguration} from "../../api/account/model";
import {FormEditableField, FormStack, FormTextField} from "../../../common/components/form";
import {ConfigurationSection} from "../../../common/components/configuration";
import {useAccountUpdateTeamUrl} from "../../api/account";
import {OnError} from "../../../common/api/TransportLayer";
import {useSnackbar} from "notistack";
import {snackOptions} from "../../../common/snackbar";

export type TeamIdentityPanelProps = {
  config: AccountConfiguration
} & OnError

export function TeamURLPanel({config}: TeamIdentityPanelProps) {
  const {mutateAsync: configure} = useAccountUpdateTeamUrl()
  const {enqueueSnackbar} = useSnackbar();

  const [editing, setEditing] = useState(false)

  const [editedTeamUrl, setEditedTeamUrl] = useState(config.teamId)

  const updateEditing = useCallback(
      (editing: boolean) => setEditing(editing), [setEditing]
  );

  const disabled = false

  const update = useCallback(async () => {
    const data = await configure(editedTeamUrl);
    if (data && data.kind === "error") enqueueSnackbar(`Failed to configure Team URL`, snackOptions(false))
    if (data && data.kind === "ok") enqueueSnackbar(`Configured Team URL`, snackOptions(true))
  }, [configure, enqueueSnackbar, editedTeamUrl])

  return <ConfigurationSection data-test="team-url" label="Team URL">
    <FormStack>
      <FormEditableField
          editable={!disabled}
          editLabel="Change Team URL"
          saveLabel="Save Team URL"
          onSave={update}
          onEditing={updateEditing}>

        <FormTextField label="The URL where your Team will log into Monopolis"
                       required={true}
                       readOnly={disabled || !editing}
                       value={editing ? editedTeamUrl : config.teamUrl}
                       placeholder="Team URL"
                       onChange={e => setEditedTeamUrl(e.target.value)}
        />
      </FormEditableField>
    </FormStack>
  </ConfigurationSection>
}


