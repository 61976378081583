import * as React from "react";
import {useMemo} from "react";
import {AuditEventAggregate} from "../../api/events/model";
import {Panel} from "../../../common/components/Panel";
import {allAuditEventsFrom, monodesFromAuditEvents, totalEvents} from "../../utils/events";
import {Grid} from "@mui/material";
import {EventsView} from "./EventsView";

export type EventsPanelProps = {
  data: AuditEventAggregate
}

export function EventsPanel({data}: EventsPanelProps) {
  const hasNoEvents = useMemo(() => totalEvents(data) === 0, [data])
  const events = useMemo(() => allAuditEventsFrom(data), [data]);
  const monodes = useMemo(() => monodesFromAuditEvents(events), [events]);

  if (hasNoEvents) return null

  return (<Grid item xs={12}>
    <Panel data-test="events" header="Events" contentProps={{sx: {px: 0, py: 1}}}>
      <EventsView events={events} monodes={monodes}/>
    </Panel>
  </Grid>)
}



