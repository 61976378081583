import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {RepositorySettingsTabProps} from "./RepositoryConfigurationPageTab";
import {useBranchOrDefault} from "../../utils/branches";
import {Grid} from "@mui/material";
import ConfigureMonopolisPanel from "../../components/configurations/ConfigureMonopolisPanel";
import {useMonopolisConfigurationQuery} from "../../api/pipelines";

export function RepositoryConfigurationPageConfigureMonopolisTab({path, onError}: RepositorySettingsTabProps) {
  const branch = useBranchOrDefault({path, onError});
  const loadComponents = branch !== undefined;

  const result = useMonopolisConfigurationQuery({path, branch, enabled: loadComponents})

  return <QueryLoader enabled={loadComponents} result={result} onError={onError} onLoaded={data =>
      <Grid item xs={12}>
        <ConfigureMonopolisPanel content={data} path={path} branch={branch}/>
      </Grid>
  }/>
}
