import {Navigation} from "../../../common/port/Navigation";
import {addSubDomain} from "../../../common/navigation";

import {teamIdFromCurrentLocation} from "../../../common/models/TeamId";

export class UserAppNavigation extends Navigation {
    private readonly _baseUrl: string

    constructor(baseUrl: string) {
        super()
        this._baseUrl = baseUrl
    }

    baseUrl(): string {
        return addSubDomain(this._baseUrl, teamIdFromCurrentLocation())
    }
}
