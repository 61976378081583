import {
  Box,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import {DashboardWidgetProps} from "./DashboardWidgetView";
import {DashboardWidgetPanel, useDashboardWidgetPanel} from "./DashboardWidgetPanel";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {DashboardWidgetType, TextWidgetConfig, TextWidgetVariant, TextWidgetVariants} from "../../api/dashboard/model";

export type TextWidgetPanelProps = DashboardWidgetProps<TextWidgetConfig>;

export function textWidget(config: { id: string } & TextWidgetConfig): TextWidgetPanelProps {
  return {
    ...config,
    type: DashboardWidgetType.Text,
    isResizeable: false,
    isEditable: true,
    isDeletable: true,
    onError: () => {
    },
  }
}

export function TextWidgetPanel({text, variant, ...props}: TextWidgetPanelProps) {
  const [newText, setNewText] = useState(text);
  const [newVariant, setNewVariant] = useState<TextWidgetVariant>(variant);
  const panelProps = useDashboardWidgetPanel({...props, title: "Text"});
  const {id, isEditing, onSave} = props

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setNewText(event.target.value)
  }

  function handleVariantChange(event: SelectChangeEvent) {
    setNewVariant(event.target.value as TextWidgetVariant);
  }

  function handleSave() {
    onSave && onSave(id, {text: newText, variant: newVariant})
  }

  function handleCancel() {
    setNewText(text)
    setNewVariant(variant)
    onSave && onSave(id, {text, variant})
  }

  return <DashboardWidgetPanel {...panelProps}>
    {
        isEditing && <Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
            <TextField fullWidth={true}
                       defaultValue={newText}
                       variant="standard"
                       InputProps={{disableUnderline: true}}
                       onChange={handleTextChange}/>

            <IconButton aria-label="save" onClick={handleSave}>
              <CheckRoundedIcon/>
            </IconButton>

            <IconButton aria-label="cancel" onClick={handleCancel}>
              <CloseRoundedIcon/>
            </IconButton>

            <Select
                aria-label="variant"
                size="small"
                value={newVariant}
                onChange={handleVariantChange}
            >
              {TextWidgetVariants.map(v => (
                  <MenuItem key={v} value={v} selected={v === newVariant}>
                    <ListItemText primary={v}/>
                  </MenuItem>
              ))}
            </Select>

          </Box>
          <Divider sx={{my: 1}}/>
        </Box>
    }

    <Box sx={{width: "100%"}}>
      <Typography variant={newVariant}
                  sx={{width: "100%", display: "inline", wordBreak: "break-word"}}>{newText}</Typography>
    </Box>
  </DashboardWidgetPanel>
}
