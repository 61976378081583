import {addSecondsToUTC, isBeforeNowUTC, nowUTC} from "../dateTime";

export type DataStorageKeyState = "missing" | "expired" | "active"
const defaultExpiry: number = 1000000

export interface DataStorages {
    local: DataStorage<string>
    session: DataStorage<string>
}

export class DataStorage<KEY_TYPE extends string> {
    delegate: Storage

    constructor(_delegate: Storage) {
        this.delegate = _delegate;
    }

    setItem(key: KEY_TYPE, value: string) {
        this.delegate.setItem(key, JSON.stringify({
            value: value,
            createdOn: nowUTC()
        }));
    }

    getItem(key: KEY_TYPE, expiryInSeconds: number = defaultExpiry) {
        const item = this.delegate.getItem(key);
        const parsedItem: DataStorageItem | undefined = item ? JSON.parse(item) : undefined;
        if (parsedItem) {
            if (isBeforeNowUTC(addSecondsToUTC(parsedItem.createdOn, expiryInSeconds))) {
                this.removeItem(key)
                return undefined
            }
            return parsedItem.value
        }
        return undefined
    }

    setObject<T>(key: KEY_TYPE, data: T) {
        this.setItem(key, JSON.stringify(data));
    }

    getObject<T>(key: KEY_TYPE, expiryInSeconds: number = defaultExpiry): (T | undefined) {
        const item = this.getItem(key, expiryInSeconds);
        return item ? JSON.parse(item) : undefined;
    }

    keyState(key: KEY_TYPE, expiryInSeconds: number = defaultExpiry): DataStorageKeyState {
        const item = this.delegate.getItem(key);
        if (!item) return "missing"
        const parsedItem: DataStorageItem = JSON.parse(item);
        return isBeforeNowUTC(addSecondsToUTC(parsedItem.createdOn, expiryInSeconds)) ? "expired" : "active"
    }

    removeItem(key: KEY_TYPE) {
        this.delegate.removeItem(key);
    }

    clear() {
        this.delegate.clear();
    }
}

type DataStorageItem = {
    value: string
    createdOn: string
}
