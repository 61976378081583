import {
    GetQuery,
    GetQueryOptions,
    jsonContentTypeHeader,
    PostMutation,
    queryOptions
} from "../../../common/api/query";
import {UserRequestId} from "../UserRequestId";
import {NotificationUrls} from "./urls";
import {NotificationChannelDetails, NotificationRequest, NotificationSubscriptions,} from "./model";
import {useUserApiPost, useUserApiGet} from "../userApi";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export const useOrgNotificationChannelsQuery = (params: { owner: VcsOwner } & GetQueryOptions): GetQuery<NotificationChannelDetails[]> =>
    useUserApiGet(UserRequestId.OrgNotificationChannels,
        NotificationUrls.orgNotificationChannels(params), {}, queryOptions(params))

export const useOrgNotificationSubscriptionsQuery = (params: { owner: VcsOwner } & GetQueryOptions): GetQuery<NotificationSubscriptions> =>
    useUserApiGet(UserRequestId.OrgNotificationSubscriptions,
        NotificationUrls.orgNotificationSubscriptions(params), {}, queryOptions(params))

const orgNotificationQueries = [
    UserRequestId.OrgNotificationSubscriptions,
];

export const useOrgNotificationSubscribeMutation = (params: { owner: VcsOwner }): PostMutation<NotificationRequest> =>
    useUserApiPost(UserRequestId.OrgNotificationSubscribe,
        NotificationUrls.orgNotificationSubscribe(params), orgNotificationQueries, {headers: jsonContentTypeHeader});

export const useOrgNotificationUnsubscribeMutation = (params: { owner: VcsOwner }): PostMutation<NotificationRequest> =>
    useUserApiPost(UserRequestId.OrgNotificationUnsubscribe,
        NotificationUrls.orgNotificationUnsubscribe(params), orgNotificationQueries, {headers: jsonContentTypeHeader});

export const useRepoNotificationSubscriptionsQuery = (params: { path: OrgRepoPath } & GetQueryOptions): GetQuery<NotificationSubscriptions> =>
    useUserApiGet(UserRequestId.RepoNotificationSubscriptions,
        NotificationUrls.repoNotificationSubscriptions(params), {}, queryOptions(params))

const repoNotificationQueries = [
    UserRequestId.RepoNotificationSubscriptions
]

export const useRepoNotificationSubscribeMutation = (params: { path: OrgRepoPath }): PostMutation<NotificationRequest> =>
    useUserApiPost(UserRequestId.RepoNotificationSubscribe,
        NotificationUrls.repoNotificationSubscribe(params), repoNotificationQueries, {headers: jsonContentTypeHeader});

export const useRepoNotificationUnsubscribeMutation = (params: { path: OrgRepoPath }): PostMutation<NotificationRequest> =>
    useUserApiPost(UserRequestId.RepoNotificationUnsubscribe,
        NotificationUrls.repoNotificationUnsubscribe(params), repoNotificationQueries, {headers: jsonContentTypeHeader});
