import * as React from "react";
import {ReactNode} from "react";
import {Grid, Stack, Typography} from "@mui/material";

export * from "./FormTextField"

export type FormElementProps = {
  fullWidth?: boolean
  children: ReactNode
}
export function FormElement({children, fullWidth}: FormElementProps) {
  return <Grid item container columns={16}>
    <Grid item xs={16} md={fullWidth ? 16 : 10} lg={fullWidth ? 16 : 8}>
      {children}
    </Grid>
  </Grid>
}

export type FormFieldProps = {
  label: ReactNode
  fullWidth?: boolean
  children: ReactNode
}

export function FormField({children, fullWidth, label}: FormFieldProps) {
  return <FormElement fullWidth={fullWidth}>
      <Typography pb={0.5}>{label}</Typography>
      {children}
  </FormElement>
}

export type FormStackProps = {
  children: ReactNode
}

export function FormStack({children}: FormStackProps) {
  return <Stack spacing={3}>
    {children}
  </Stack>
}
