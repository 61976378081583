import * as React from "react";
import {CustomIcon} from "../../common/components/icons";

import {VcsProvider} from "../../common/models";

export function VcsIcon({type}: VcsIconProps) {
  return (
      <CustomIcon style={{width:"1em"}} name={type}/>
  );
}

export type VcsIconProps = {
  type: VcsProvider
};
