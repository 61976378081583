import {Box, Tooltip, Typography} from "@mui/material";
import * as React from "react";

export type EventsTimelineHeaderProps = {
  title: string,
  height: number,
}

export function EventsTimelineHeader({title, height}: EventsTimelineHeaderProps) {
  return <Tooltip title={title} arrow={true} followCursor={true} placement={"top"}>
    <Box sx={{
      display: "flex",
      alignItems: "center",
      height: height + "px",
      width: "100%",
    }}>
      <Typography variant="body2" noWrap sx={{paddingX: 1, textOverflow: "ellipsis"}}>{title}</Typography>
    </Box>
  </Tooltip>
}
