import {VcsProvider, VcsProviders} from "./VcsProvider";

export type OAuthProvider = `${VcsProvider}` // enum to union type
export type OAuthStatus = "successful" | "failed"
export type OAuthSlug = string
export type OAuthProviderDetails = {
    provider: OAuthProvider;
    name: string;
}

export function oauthProviderDetails(provider: OAuthProvider): OAuthProviderDetails {
    switch (provider) {
        case "github":
            return {
                provider: provider,
                name: "GitHub"
            }

        case "gitlab":
            return {
                provider: provider,
                name: "GitLab"
            }

        case "sandbox":
            return {
                provider: provider,
                name: "Sandbox"
            }
    }
}

export const ConfigurableOAuthProviders = VcsProviders
    .filter(provider => provider !== VcsProvider.Sandbox)
    .map(provider => provider as OAuthProvider)
