import {Box, Typography} from "@mui/material";
import React, {useCallback} from "react";
import {DiscordIntegrationSettings} from "../../api/org/model";
import Button from "@mui/material/Button";
import {snackOptions} from "../../../common/snackbar";
import {useSnackbar} from "notistack";
import {useOrgIntegrationAddUrl, useOrgIntegrationRemoveMutation} from "../../api/org";
import {IntegrationSettingsPanelProps} from "./IntegrationSettingsPanel";
import {ConfigurationSection} from "../../../common/components/configuration";
import {useNavigation} from "../../../common/port/Navigation";

export type DiscordIntegrationSettingsPanelProps = IntegrationSettingsPanelProps<DiscordIntegrationSettings>;

export function DiscordIntegrationSettingsPanel(
    {
      owner,
      type,
      originUri,
      ...settings
    }: DiscordIntegrationSettingsPanelProps) {
  const {navigateTo} = useNavigation()
  const {enqueueSnackbar} = useSnackbar();

  const addUrl = useOrgIntegrationAddUrl({owner, type, originUri})
  const removeMutation = useOrgIntegrationRemoveMutation({owner, type})

  const add = async () => {
    await navigateTo(addUrl)
  }

  const remove = useCallback(async () => {
    const data = await removeMutation.mutateAsync({});
    if (data && data.kind === "error") enqueueSnackbar(`Failed to remove Discord integration from ${owner}`, snackOptions(false))
    if (data && data.kind === "ok") enqueueSnackbar(`Removed Discord integration from ${owner}`, snackOptions(true))
  }, [removeMutation, enqueueSnackbar, owner])

  return <ConfigurationSection data-test="discord-integration" label="Discord">
    {settings.integrated
        ? <Box>
          <Typography pb={1}>Connected to {settings.guildName}</Typography>
          <Button onClick={remove} variant="outlined">Disconnect</Button>
        </Box>
        : <Button onClick={add} variant="outlined">Connect to Discord</Button>}
  </ConfigurationSection>
}
