import {Monode, RolloutTarget, VcsBranch, VcsRepoFilePath} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";


import {useApiUrl} from "../userApi";
import {branchParam, filePathParam} from "../../utils/urlParams";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export class PluginsUrls {

    static repositoryLinks(params: { path: OrgRepoPath }) {
        return buildUrl(`/configs/links/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`, [])
    }

    static monodeLinks(params: { path: OrgRepoPath, monode: Monode }) {
        return buildUrl(`/configs/links/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, [])
    }

    static monodeTargetLinks(params: { path: OrgRepoPath, monode: Monode, target: RolloutTarget }) {
        return buildUrl(`/configs/links/target/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}/${params.target}`, [])
    }

    static monodePluginsConfig(params: { path: OrgRepoPath, monode: Monode }) {
        return buildUrl(`/configs/plugins/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`)
    }

    static orgMonodesPluginsConfigs(params: { owner: VcsOwner }) {
        return buildUrl(`/configs/plugins/org/monodes/${params.owner}`)
    }

    static repositoryMonodesPluginsConfigs(params: { path: OrgRepoPath }) {
        return buildUrl(`/configs/plugins/repo/monodes/${params.path.org}/${encodeURIComponent(params.path.repo)}`)
    }

    static monodeApiContent(params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/configs/plugins/api/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}/content`, query)
    }

    static monodeDocumentationContent(params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch, file: VcsRepoFilePath }) {
        const query = Array<UrlQueryParam>()
        query.push(filePathParam(params.file));
        if (params.branch) query.push(branchParam(params.branch));

        return buildUrl(`/configs/plugins/documentation/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}/content`, query)
    }

    static monodeDocumentationList(params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch, file: VcsRepoFilePath }) {
        const query = Array<UrlQueryParam>()
        query.push(filePathParam(params.file));
        if (params.branch) query.push(branchParam(params.branch));

        return buildUrl(`/configs/plugins/documentation/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, query)
    }

    static documentationContentUrl = (params: { path: OrgRepoPath, monode: Monode, file: VcsRepoFilePath, branch?: VcsBranch }) =>
        useApiUrl(PluginsUrls.monodeDocumentationContent(params))

}
