import {VcsBranch} from "../../models";
import {VcsPath} from "../../../common/models";

export enum AuditEventType {
    Push = "Push",
    Rollout = "Rollout",
    Incident = "Incident",
    MonodeAlert = "MonodeAlert",
}

export enum AuditEventSpanStatus {
    Open = "Open",
    Closed = "Closed",
}

export enum AuditEventSpanSeverity {
    Success = "Success",
    Failure = "Failure",
    Warning = "Warning",
    Info = "Info",
}

export const AuditEventTypes = Object.values(AuditEventType)

export type AuditEventId = string

export type AuditEventSpan = {
    status: AuditEventSpanStatus,
    severity: AuditEventSpanSeverity,
    startedAt: string
    updatedAt: string
}

export type BaseAuditEvent = {
    id: AuditEventId,
    spans: AuditEventSpan[],
}

export type RolloutEvent = BaseAuditEvent & {
    eventType: AuditEventType.Rollout
    repo: string
    monode: string
    target: string
}

export type IncidentEvent = BaseAuditEvent & {
    eventType: AuditEventType.Incident
    repo: string
    monode: string
    target: string
}

export type MonodeAlertEvent = BaseAuditEvent & {
    eventType: AuditEventType.MonodeAlert
    repo: string
    monode: string
    target: string
}

export type PushEvent = BaseAuditEvent & {
    eventType: AuditEventType.Push
    repo: VcsPath
    branch: VcsBranch
}

export type AuditEvent = PushEvent | RolloutEvent | IncidentEvent | MonodeAlertEvent

export type AuditEventDateAggregate = {
    date: string;
    events: AuditEvent[];
}

export type AuditEventAggregate = {
    dates: AuditEventDateAggregate[]
}
