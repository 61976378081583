import {UserStoredItemKey} from "../UserStoredItemKey";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import {reloadWindow} from "../../common/navigation";
import {AuthenticationResource} from "../utils/resourcesUrl";
import {Paths} from "../models/Paths";
import {AuthenticatedUserProfile} from "./AuthenticatedUserProfile";
import {unauthenticatedProfile} from "../../common/auth/UnauthenticatedProfile";
import {Analytics} from "../../common/port/Analytics";
import {UserAuthenticationProfile} from "./useUserAuthentication";
import {DataStorages} from "../../common/port/DataStorages";

import {OAuthProvider, OAuthSlug, VcsProvider} from "../../common/models";

export type UserAuthenticationRedirect = (uri: string) => void

export class UserAuthenticationStore {
    private readonly storage: DataStorages
    private readonly analytics: Analytics
    private readonly apiUrls: AuthenticationResource

    constructor(storage: DataStorages, analytics: Analytics, apiUrls: AuthenticationResource) {
        this.storage = storage
        this.analytics = analytics
        this.apiUrls = apiUrls
    }

    itemId(key: string) {
        return key
    }

    getOAuthProvider(): OAuthProvider | undefined {
        const item = this.storage.local.getItem(this.itemId(UserStoredItemKey.OAuthProvider));
        return item ? item as OAuthProvider : VcsProvider.GitHub//TODO: This default will go as we merge APIs into one
    }

    setOAuthProvider(provider: OAuthProvider) {
        this.storage.local.setItem(this.itemId(UserStoredItemKey.OAuthProvider), provider)
    }

    setAuthenticationRedirect(redirectionUrl: string) {
        this.storage.session.setItem(UserStoredItemKey.UserAuthRedirect, redirectionUrl)
    }

    setOAuthCliProvider(provider: OAuthProvider) {
        this.storage.session.setItem(UserStoredItemKey.OAuthCliProvider, provider)
    }

    setOAuthSlug(slug: OAuthSlug) {
        this.storage.session.setItem(UserStoredItemKey.OAuthSlug, slug)
    }

    getOAuthSlug(): OAuthSlug | undefined {
        const item = this.storage.session.getItem(UserStoredItemKey.OAuthSlug);
        return item ? item as OAuthSlug : undefined
    }

    logout(onRedirect: UserAuthenticationRedirect = reloadWindow) {


        const userProfile = this.getProfile()
        if (userProfile.type === AuthenticationType.User) {
            this.analytics.event("User", "LoggedOut", `User logged out from account on ${userProfile.vcs}`)
        }
        this.storage.local.removeItem(this.itemId(UserStoredItemKey.UserProfile))
        this.storage.local.removeItem(this.itemId(UserStoredItemKey.UserToken))
        this.storage.local.removeItem(this.itemId(UserStoredItemKey.OAuthProvider))
        onRedirect && onRedirect(this.apiUrls.logout())
    }

    redirectToLogin(redirectionUri: string) {
        this.storage.session.setItem(UserStoredItemKey.UserAuthRedirect, redirectionUri)
        reloadWindow(Paths.Home);
    }

    authenticateUser(token: string, userProfile: AuthenticatedUserProfile, onRedirect?: UserAuthenticationRedirect) {
        this.analytics.event("User", "LoggedIn", `User logged in with account on ${userProfile.vcs}`)
        const redirectPath = this.storage.session.getItem(UserStoredItemKey.UserAuthRedirect) || Paths.Home;
        this.storage.local.setItem(this.itemId(UserStoredItemKey.UserToken), token);
        this.storage.session.removeItem(UserStoredItemKey.UserAuthRedirect)
        onRedirect && onRedirect(redirectPath)
    }

    initiateLoginCLI(slug: OAuthSlug) {
        this.storage.session.removeItem(UserStoredItemKey.OAuthCliProvider)
        this.setOAuthSlug(slug)
    }

    authenticateCLI() {
        this.storage.session.removeItem(UserStoredItemKey.OAuthSlug)
        this.storage.session.removeItem(UserStoredItemKey.OAuthCliProvider)
    }

    getToken() {
        return this.storage.local.getItem(this.itemId(UserStoredItemKey.UserToken));
    }

    getProfile(): UserAuthenticationProfile {
        const item = this.storage.local.getObject<AuthenticatedUserProfile>(this.itemId(UserStoredItemKey.UserProfile));
        return item ? {...item, type: AuthenticationType.User} : unauthenticatedProfile;
    }
}
