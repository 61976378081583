import {TextField} from '@mui/material';
import React, {useState} from 'react'
import {Panel} from "../../../common/components/Panel";

import {OrgRepoPath} from "../../../common/models";

export type ConfigureMonopolisPanelProps = {
  content: string;
  path: OrgRepoPath;
  branch?: string;
}

export default function ConfigureMonopolisPanel({content}: ConfigureMonopolisPanelProps) {
  const [input, setInput] = useState(content);

  return <Panel data-test="configure-monopolis" header="Monopolis Configuration">
    <TextField required
               variant="outlined"
               multiline={true}
               value={input}
               inputProps={{
                 readOnly: true,
               }}
               onChange={e => setInput(e.target.value)}
               fullWidth
               rows="15"
               name='input'
               id="input"
    />
  </Panel>
}
