import * as React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import {monopolisTheme} from "./common/styles/theme";
import {isReservedSubDomain, ReservedSubDomain} from "./ReservedSubDomain";
import {UserApp} from "./userApp/UserApp";
import {AccountApp} from "./accountApp/AccountApp";
import {UserAuthenticationStore} from "./userApp/auth/UserAuthenticationStore";
import {UserAppTransportLayer} from "./userApp/api/userApi";
import {AccountAppTransportLayer} from "./accountApp/api/accountApi";
import {AccountAuthenticationStore} from "./accountApp/auth/AccountAuthenticationStore";
import {GoogleAnalytics} from "./common/adapter/analytics/GoogleAnalytics";
import {EnvironmentConfig} from "./Environment";
import {BrowserDataStorages} from "./common/adapter/datastorage/BrowserDataStorages";
import {UserAppNavigation} from "./userApp/adapter/navigation/UserAppNavigation";
import {AccountAppNavigation} from "./accountApp/adapter/navigation/AccountAppNavigation";
import {AccountAppUrls} from "./accountApp/models/AccountAppUrls";
import {UserAppUrls} from "./userApp/models/UserAppUrls";
import {AuthenticationResource} from "./userApp/utils/resourcesUrl";
import {LaunchApp} from "./launchApp/LaunchApp";
import {LaunchAppUrls} from "./launchApp/models/LaunchAppUrls";
import {teamIdFromCurrentLocation} from "./common/models/TeamId";
import {VcsUrlsSelector} from "./userApp/models/VcsUrlsSelector";
import {DocsUrls} from "./userApp/api/docs/urls";
import {BadgesUrls} from "./userApp/api/badges/urls";

const teamId = teamIdFromCurrentLocation()
const config = new EnvironmentConfig()
const analytics = new GoogleAnalytics(config.gaMeasurementId)
const storage = new BrowserDataStorages()
const launchUrls = new LaunchAppUrls(config.launchAppBaseUrl)
const userUrls = new UserAppUrls(config.userAppBaseUrl)
const userApiUrls = new AuthenticationResource(config, userUrls)
const userDocsUrls = new DocsUrls(config.docsBaseUrl)
const userBadgesUrls = new BadgesUrls(config.badgeBaseUrl)
const userAuthStore = new UserAuthenticationStore(storage, analytics, userApiUrls)
const vcsUrls = new VcsUrlsSelector(userAuthStore)
const userTransport = new UserAppTransportLayer(teamId, analytics, userAuthStore, config)
const userNavigation = new UserAppNavigation(config.userAppBaseUrl)
const accountUrls = new AccountAppUrls(config.accountAppBaseUrl)
const accountAuthStore = new AccountAuthenticationStore(storage, analytics, config.accountApiBaseUrl)
const accountTransport = new AccountAppTransportLayer(analytics, accountAuthStore, config.accountApiBaseUrl)
const accountNavigation = new AccountAppNavigation(config.accountAppBaseUrl)

window.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error): boolean => {
  if (error) {
    analytics.event("Error", error.name + ": " + error.message, error.stack ? error.stack : "")
  } else {
    analytics.event("Error", event + "", source + " @ " + lineno + "" + colno)
  }

  return false;
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ThemeProvider theme={monopolisTheme}>
      <CssBaseline/>

      {(teamId === ReservedSubDomain.Account) && <AccountApp infra={{
        authStore: accountAuthStore,
        transport: accountTransport,
        navigation: accountNavigation,
        urls: accountUrls,
        analytics,
        storage,
        config
      }}/>}

      {(teamId === ReservedSubDomain.App) && <LaunchApp infra={{
        authStore: userAuthStore,
        transport: userTransport,
        navigation: userNavigation,
        urls: userUrls,
        apiUrls: userApiUrls,
        docsUrls: userDocsUrls,
        badgesUrls: userBadgesUrls,
        launchUrls,
        vcsUrls,
        analytics,
        storage,
        config
      }}/>}

      {!isReservedSubDomain(teamId) && <UserApp infra={{
        authStore: userAuthStore,
        transport: userTransport,
        navigation: userNavigation,
        urls: userUrls,
        apiUrls: userApiUrls,
        docsUrls: userDocsUrls,
        badgesUrls: userBadgesUrls,
        vcsUrls,
        analytics,
        storage,
        config
      }}/>}

    </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
