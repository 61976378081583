import * as React from 'react';
import {useCallback, useState} from 'react';
import {monopolisConstraints} from "../../common/styles/constraints";
import {MonopolisFavicon} from "../../common/components/icons";
import {Box, Grid, InputAdornment, Link, OutlinedInput, Typography} from "@mui/material";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import {hostname} from "../../common/navigation";
import {FetchUserProfile} from "../../userApp/auth/FetchUserProfile";
import {FormStack} from "../../common/components/form";
import {isValidTeamId, sandboxTeamId, TeamId} from "../../common/models/TeamId";
import {LoginButtons} from "../../userApp/components/user/LoginButtons";
import {useLaunchAppInfrastructure} from "../LaunchAppInfrastructure";

export type LoginTarget = "CLI" | "App"

export type LoginPanelProps = {
  target: LoginTarget,
  onLogin: (teamId: TeamId) => void
  sandboxDisabled: boolean
}

export function LoginPanel({target, onLogin}: LoginPanelProps) {
  const {config} = useLaunchAppInfrastructure()
  const [loggingIn, setLoggingIn] = useState(false)
  const [teamId, setTeamId] = useState("")

  const handleLogin = useCallback((teamId: TeamId) => {
    if (teamId.trim() !== "") {
      setLoggingIn(true)
      onLogin(teamId)
    }
  }, [onLogin, setLoggingIn])

  return <FetchUserProfile onFetch={userProfile =>
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
      >
        <Box sx={{m: 1}}>
          <MonopolisFavicon height={monopolisConstraints.headerHeight}/>
        </Box>

        <Typography variant="h4" align={"center"}>
          Welcome to {config.appName}!
        </Typography>

        <Typography variant="body1" sx={{mt: 10, mb: 3}}>Enter team id to login to your {target} account</Typography>
        <FormStack>
          <OutlinedInput
              autoComplete="teamId"
              value={teamId}
              disabled={loggingIn}
              fullWidth
              required
              autoFocus
              inputProps={{
                "data-test": "team-id",
                style: {textAlign: 'end'}
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault()
                  handleLogin(teamId)
                } else if (!isValidTeamId(event.key)) {
                  event.preventDefault()
                }
              }}

              onChange={(event) => {
                const value = event.target.value;
                if (value !== "" && !isValidTeamId(value)) return
                setTeamId(value)
              }}

              endAdornment={
                <InputAdornment position="end">.{hostname(config.userAppBaseUrl)}</InputAdornment>
              }
          />

          <LoginButtons loginDisabled={teamId.trim() === "" || loggingIn}
                        onLogin={() => handleLogin(teamId)}
                        onSandbox={() => handleLogin(sandboxTeamId)}/>


          <Typography variant="body1" align={"center"}>
            {
              userProfile.type === AuthenticationType.User
                  ? <Typography variant="body2" color="primary">logged in
                    as <b>{userProfile.login}</b> on {userProfile.vcs}</Typography>
                  : <></>
            }
          </Typography>


          <Grid container>
            <Grid item xs>
              <Link href={config.accountAppBaseUrl} variant="body2">
                Looking for billing?
              </Link>
            </Grid>
            <Grid item>
              <Link href={config.websiteBaseUrl} variant="body2">
                {"Don't have an account?"}
              </Link>
            </Grid>
          </Grid>
        </FormStack>
      </Box>

  }/>

}


