import ReactFlow, {Controls, Edge, Node, ReactFlowProvider, useEdgesState, useNodesState} from "reactflow";
import {MonodeGraphNode, MonodeGraphNodeData} from "./MonodeGraphNode";
import {repoMonode} from "../../models";
import * as React from "react";
import {useMemo} from "react";
import {Box, Paper, Typography} from "@mui/material";
import {PipelineStatus} from "../../api/pipelines/model";
import {getAllIncomers, getAllOutgoers, layoutGraph} from "../../utils/reactflow";
import MonodeGraphEdge from "./MonodeGraphEdge";
import {DependencyGraph} from "../../api/graphs/model";
import {monopolisTheme} from "../../../common/styles/theme";

import {VcsProvider} from "../../../common/models";

export function DependencyGraphPanel(props: DependencyGraphPanelProps) {
  return (<ReactFlowProvider>
    <DependencyGraphPanelView {...props}/>
  </ReactFlowProvider>)

}

function DependencyGraphPanelView({vcs, graph}: DependencyGraphPanelProps) {
  const nodeTypes = useMemo(() => ({monode: MonodeGraphNode}), []);
  const edgeTypes = useMemo(() => ({monode: MonodeGraphEdge}), []);
  const envGraphNodes = graph.nodes.map(node => ({
    id: repoMonode(node),
    type: 'monode',
    data: {
      path: node.path,
      vcs: vcs,
      monode: node.monode,
      type: node.spec.type || "service",
      status: PipelineStatus.Passed
    },
    position: {x: 0, y: 0}
  } as any));

  const envGraphEdges = graph.edges.map(edge => ({
    type: 'monode',
    id: "edge-" + edge.source + "-" + edge.target,
    source: edge.source,
    target: edge.target,
    animated: true,
    style: {strokeWidth: 3, stroke: monopolisTheme.palette.primary.main}
  }));


  const [nodes, setNodes, onNodesChange] = useNodesState(layoutGraph<MonodeGraphNodeData>(350, 60, envGraphNodes, envGraphEdges));
  const [edges, setEdges, onEdgesChange] = useEdgesState(envGraphEdges);

  function highlightNode<T extends Node<MonodeGraphNodeData> | Edge>(elem: T, highlight: boolean): T {
    elem.style = {
      ...elem.style,
      opacity: highlight ? 1 : 0.25,
      transition: "opacity .20s ease-in-out"
    }
    return elem
  }

  const highlightNodes = (event: React.MouseEvent, node: Node) => {
    if (node && nodes && edges) {
      const incomerIds = getAllIncomers(node, nodes, edges).map(node => node.id)
      const outgoerIds = getAllOutgoers(node, nodes, edges).map(node => node.id)

      setNodes(prev => {
        return prev.map(elem => {
          return highlightNode(elem,
              elem.id === node.id ||
              incomerIds.includes(elem.id) ||
              outgoerIds.includes(elem.id)
          )
        })
      })

      setEdges(prev => {
        return prev.map(elem => {
          return highlightNode(elem,
              (outgoerIds.includes(elem.target) && elem.source === node.id) ||
              (incomerIds.includes(elem.source) && elem.target === node.id) ||
              (incomerIds.includes(elem.target) && incomerIds.includes(elem.source)) ||
              (outgoerIds.includes(elem.target) && outgoerIds.includes(elem.source))
          )
        })
      })
    }
  }

  const resetNodeHighlights = () => {
    setNodes(prev => prev.map(elem => highlightNode(elem, true)));
    setEdges(prev => prev.map(elem => highlightNode(elem, true)));
  }

  if (envGraphNodes.length === 0) return <></>

  return (
      <Paper data-test="dependency-graph" style={{width: "100%", height: "100%"}}
             sx={{backgroundColor: monopolisTheme.palette.secondary.main, p: 2}}>
        <Typography fontWeight={500} variant="body1">Dependencies</Typography>
        <Box className="reactflow-wrapper" sx={{width: "100%", height: "100%", py: 2}}>
          <ReactFlow
              style={{backgroundColor: monopolisTheme.palette.secondary.main}}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              nodes={nodes}
              edges={edges}
              maxZoom={0.7}
              minZoom={0.1}
              draggable={true}
              nodesConnectable={false}
              contentEditable={false}
              nodesDraggable={true}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodeMouseEnter={highlightNodes}
              onNodeMouseLeave={resetNodeHighlights}
              fitView
          >
            <Controls showInteractive={false}/>
          </ReactFlow>
        </Box>
      </Paper>
  )
}

export type DependencyGraphPanelProps = {
  vcs: VcsProvider;
  graph: DependencyGraph;
}
