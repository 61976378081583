import React, {createContext, ReactNode, useContext} from 'react';
import {AuthenticationType} from "../common/auth/AuthenticationType";
import {AccountAuthenticationStore} from "./auth/AccountAuthenticationStore";
import {AccountAppTransportLayer} from "./api/accountApi";
import {CommonAppInfrastructure, CommonAppInfrastructureProvider} from "../common/CommonAppInfrastructure";
import {AccountAppUrls} from "./models/AccountAppUrls";

export interface AccountAppInfrastructure extends CommonAppInfrastructure {
  urls: AccountAppUrls
  transport: AccountAppTransportLayer
  authStore: AccountAuthenticationStore,
}

export type AccountAppInfrastructureProviderProps = {
  infra: AccountAppInfrastructure
  children: ReactNode
}

export const AccountAppInfrastructureContext = createContext<null | AccountAppInfrastructure>(null);

export const AccountAppInfrastructureProvider = ({children, infra}: AccountAppInfrastructureProviderProps) => {
  return <CommonAppInfrastructureProvider infra={infra}>
    <AccountAppInfrastructureContext.Provider value={infra}>
      {children}
    </AccountAppInfrastructureContext.Provider>
  </CommonAppInfrastructureProvider>
}

export const useAccountAppInfrastructure = (): AccountAppInfrastructure => {
  const context = useContext(AccountAppInfrastructureContext);
  if (context == null) {
    throw new Error('AccountAppInfrastructureContext requires to be wrapped in an AccountAppInfrastructureProvider');
  }

  const userProfile = context.authStore.profile();
  if (userProfile.type === AuthenticationType.Account) {
    context.analytics.additionalData({authenticated: true, customerId: userProfile.customerId})
  } else {
    context.analytics.additionalData({authenticated: false})
  }

  return context;
}
