import * as React from 'react';
import SwaggerUI, {SwaggerUIProps} from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"
import {ApiPluginConfigView} from "../../api/configurations/model";
import {useMonodeApiSpecContentUrl} from "../../api/configurations";
import Cookies from "js-cookie";

export type OpenApiSpecPanelProps = {
  config: ApiPluginConfigView
}

export function OpenApiSpecPanel(props: OpenApiSpecPanelProps) {
  const view = props.config;
  const internalSpecUrl = useMonodeApiSpecContentUrl(view);
  const commonPlugins = [HideInfoUrlPartsPlugin, /*DisableTryItOutPlugin*/]
  const serveFromPath = view.config.path !== undefined;

  const sourceSpecificProps = serveFromPath ? {
    url: internalSpecUrl,
    requestInterceptor: addBearerTokenInterceptor
  } : {
    url: view.config.url
  }

  const swaggerUiProps : SwaggerUIProps = {
    tryItOutEnabled: false,
    showExtensions: true,
    docExpansion: "list",
    plugins: commonPlugins,
    ...sourceSpecificProps
  };

  return <SwaggerUI {...swaggerUiProps}/>
}


// const DisableTryItOutPlugin = function () {
//   return {
//     statePlugins: {
//       spec: {
//         wrapSelectors: {
//           allowTryItOutFor: () => () => false
//         }
//       }
//     }
//   }
// }


const HideInfoUrlPartsPlugin = function () {
  return {
    wrapComponents: {
      InfoUrl: () => () => null
    }
  }
}

function addBearerTokenInterceptor(req: any): any {
  req.headers["Authorization"] = `Bearer ${Cookies.get("appSession")}`
  return req
}
