export const EmbeddedImageFormats = ["Markdown", "HTML"]
export type EmbeddedImageFormat = typeof EmbeddedImageFormats[number]

export function embedImage(format: EmbeddedImageFormat, title: string, url: string): string {
    return embeddedImageFormatters[format](title, url)
}

type EmbeddedImageUrlFormatter = (title: string, url: string) => string

const embeddedImageFormatters: Record<EmbeddedImageFormat, EmbeddedImageUrlFormatter> = {
    Markdown: (title, uri) => `![${title}](${uri})`,
    HTML: (title, uri) => `<img alt="${title}" src="${uri}">`
}
