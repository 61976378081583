import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {Title} from './Title';
import {monopolisTheme} from "../../../common/styles/theme";

function createData(date: string, successful?: number, failed?: number) {
  return {date, successful, failed};
}

const data = [
  createData('Jan 1', 10, 4),
  createData('Jan 2', 13, 4),
  createData('Jan 3', 20, 4),
  createData('Jan 4', 8, 4),
  createData('Jan 5', 2, 4),
  createData('Jan 6', 33, 4),
  createData('Jan 7', 24, 4),
  createData('Jan 8', 2, 4),
  createData('Jan 9', 33, 4),
  createData('Jan 10', 11, 4),
  createData('Jan 11', 12, 4),
  createData('Jan 12', 19, 4),
  createData('Jan 13', 10, 4),
  createData('Jan 14', 7, 4),
  createData('Jan 15', 10, 4),
  createData('Jan 16', 10, 4),
  createData('Jan 17', 10, 4),
  createData('Jan 18', 10, 4),
  createData('Jan 19', 10, 4),
  createData('Jan 20', 10, 4),
  createData('Jan 21', 10, 4),
  createData('Jan 22', 10, 4),
  createData('Jan 23', 10, 4),
  createData('Jan 24', 10, 4),
  createData('Jan 25', 10, 4),
  createData('Jan 26', 10, 4),
  createData('Jan 27', 10, 4),
  createData('Jan 28', 10, 4),
  createData('Jan 29', 10, 4),
  createData('Jan 30', 10, 4),
  createData('Jan 31', 10, 4),
];


export function Chart() {
  const theme = useTheme();

  return (
      <React.Fragment>
        <Title>Today</Title>
        <ResponsiveContainer>
          <BarChart
              maxBarSize={10}
              width={200}
              height={800}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3"/>
            <XAxis dataKey="date"/>
            <YAxis/>
            <Tooltip cursor={{fill: monopolisTheme.palette.primary.main}}/>
            <Legend/>
            <Bar dataKey="successful" stackId="builds" fill={monopolisTheme.palette.success.main}/>
            <Bar dataKey="failed" stackId="builds" fill={monopolisTheme.palette.error.main}/>
          </BarChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
              width={200}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3"/>
            <XAxis dataKey="date"/>
            <YAxis/>
            <Tooltip/>
            <Area type="monotone" dataKey="successful" stackId="1" stroke={monopolisTheme.palette.success.main}
                  fill={monopolisTheme.palette.success.light}/>
            <Area type="monotone" dataKey="failed" stackId="1" stroke={monopolisTheme.palette.error.main}
                  fill={monopolisTheme.palette.error.light}/>
          </AreaChart>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <LineChart
              data={data}
              margin={{
                top: 16,
                right: 16,
                bottom: 0,
                left: 24,
              }}
          >
            <XAxis
                dataKey="date"
                stroke={theme.palette.text.secondary}
            />
            <YAxis
                stroke={theme.palette.text.secondary}
            >
              <Label
                  angle={270}
                  position="left"
                  fontSize={1}
                  style={{
                    textAnchor: 'middle',
                    fill: theme.palette.text.primary,
                    ...theme.typography.body1,
                  }}
              >
                Sales ($)
              </Label>
            </YAxis>
            <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="successful"
                stroke={theme.palette.primary.main}
                dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </React.Fragment>
  );
}
