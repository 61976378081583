import {GetQueryOptions, queryOptions} from "../../../common/api/query";
import {StatisticsUrls} from "./urls";
import {Monode, RolloutTarget, VcsBranch} from "../../models";
import {UserRequestId} from "../UserRequestId";
import {StatisticsAggregate, StatisticsGroupAggregate} from "./model";
import {useUserApiGet} from "../userApi";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export const useOrgGroupedBuildStatisticsQuery = (params: { owner: VcsOwner } & GetQueryOptions) =>
    useUserApiGet<StatisticsGroupAggregate[]>(UserRequestId.OrgGroupedBuildStatistics, StatisticsUrls.orgGroupedBuildStats(params), {}, queryOptions(params));

export const useRepositoryGroupedBuildStatisticsQuery = (params: { path: OrgRepoPath, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<StatisticsGroupAggregate[]>(UserRequestId.RepositoryGroupedBuildStatistics, StatisticsUrls.repositoryGroupedBuildStats(params), {}, queryOptions(params));

export const useRepositoryBuildStatisticsQuery = (params: { path: OrgRepoPath, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<StatisticsAggregate>(UserRequestId.RepositoryBuildStatistics, StatisticsUrls.repositoryBuildStats(params), {}, queryOptions(params));

export const usePipelineBuildStatisticsQuery = (params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch } & GetQueryOptions) =>
    useUserApiGet<StatisticsAggregate>(UserRequestId.PipelineBuildStatistics, StatisticsUrls.pipelineBuildStats(params), {}, queryOptions(params));

export const useOrgRolloutsStatisticsQuery = (params: { owner: VcsOwner, target: RolloutTarget } & GetQueryOptions) =>
    useUserApiGet<StatisticsAggregate>(UserRequestId.OrgRolloutStatistics, StatisticsUrls.orgRolloutStats(params), {}, queryOptions(params));

export const useRepositoryRolloutStatisticsQuery = (params: { path: OrgRepoPath, target: RolloutTarget } & GetQueryOptions) =>
    useUserApiGet<StatisticsAggregate>(UserRequestId.RepositoryRolloutStatistics, StatisticsUrls.repositoryRolloutStats(params), {}, queryOptions(params));

export const useMonodeRolloutStatisticsQuery = (params: { path: OrgRepoPath, monode: Monode, target: RolloutTarget } & GetQueryOptions) =>
    useUserApiGet<StatisticsAggregate>(UserRequestId.MonodeRolloutStatistics, StatisticsUrls.monodeRolloutStats(params), {}, queryOptions(params));
