import {Monode} from "../../models";
import {OnError} from "../../../common/api/TransportLayer";
import {ReactNode} from "react";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {OrgRepoPath, VcsOwner} from "../../../common/models";
import {MaybeOrgConfiguration} from "../../../common/models";

export enum MonodePageTab {
  overview = "overview",
  builds = "builds",
  dependencies = "dependencies",
}

export type MonodePageTabProps = {
  owner: VcsOwner
  path: OrgRepoPath
  monode: Monode
  userProfile: AuthenticatedUserProfile
  orgConfiguration: MaybeOrgConfiguration
  onSidebar?: (children?: ReactNode) => void
} & OnError
