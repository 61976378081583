import React, {CSSProperties, forwardRef, ReactNode, Ref} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {BaseDashboardWidgetProps} from "./DashboardWidgetView";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {monopolisTheme} from "../../../common/styles/theme";
import {Box, IconButton, Typography} from "@mui/material";

export type DashboardWidgetPanelConfig = BaseDashboardWidgetProps & {
  isChangingLayout?: boolean
  isDragging?: boolean
  isEditable?: boolean
  isDeletable?: boolean
  isEditing?: boolean
  isResizeable: boolean
  title: string
  draggingProps?: any
  style?: CSSProperties
  children?: ReactNode
  onDelete?: (id: string) => void
  onEdit?: (id: string) => void
  onResize?: (id: string, width: number) => void
}

export const DashboardWidgetPanel = forwardRef((
    {
      id,
      height,
      width,
      title,
      children,
      style,
      isResizeable,
      isChangingLayout,
      isDeletable,
      isEditable,
      isEditing,
      isDragging,
      draggingProps,
      onEdit,
      onDelete,
      onResize,
      ...props
    }: DashboardWidgetPanelConfig, ref: Ref<any>) => {


  const inlineStyle = {
    zIndex: isDragging ? 1000 : 0,
    borderRadius: "0.5em",
    height: height,
    gridColumnStart: 'span ' + width,
    display: 'inline-block',
    ...style
  };

  if (!isChangingLayout)
    return <div ref={ref} style={inlineStyle}>{children}</div>

  const header = <Box sx={{
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  }}>
    <DragIndicatorIcon {...draggingProps} sx={{cursor: "grab"}}/>

    <Typography sx={{ml: 1}}>{title}</Typography>

    <Box sx={{flexGrow: 1}}/>

    {
        isEditable === true && <IconButton disabled={isEditing} onClick={() => onEdit && onEdit(id)}>
          <EditRoundedIcon/>
        </IconButton>
    }

    {
        isResizeable && <IconButton onClick={() => onResize && onResize(id, (width || 0) + 1)}>
          <AddRoundedIcon/>
        </IconButton>
    }

    {
        isResizeable && <IconButton onClick={() => onResize && onResize(id, (width || 0) - 1)}>
          <RemoveRoundedIcon/>
        </IconButton>
    }

    {
        isDeletable === true && <IconButton disabled={isEditing} onClick={() => onDelete && onDelete(id)}>
          <CloseRoundedIcon/>
        </IconButton>
    }
  </Box>

  const outline = isDragging
      ? "2px dashed " + monopolisTheme.palette.grey.A400
      : "1px dashed " + monopolisTheme.palette.grey.A400


  return <div ref={ref} style={inlineStyle}>
    <Box sx={{border: outline, borderRadius: "10px", backgroundColor: monopolisTheme.palette.background.default}}>
      <Box sx={{
        p: 1,
        display: "flex",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        borderBottom: outline
      }}>
        <Typography sx={{flexGrow: 1}} fontWeight={500} variant="body1">{header}</Typography>
      </Box>

      <Box sx={{p: 1}}>
        {children}
      </Box>

    </Box>
  </div>
})

export function useDashboardWidgetPanel(props: DashboardWidgetPanelConfig) {
  const {id} = props

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});
  const actualId = id + (isDragging ? "-overlay" : "")

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return {
    ref: setNodeRef,
    ...props,
    key: actualId,
    isDragging,
    id: actualId,
    style,
    draggingProps: {...attributes, ...listeners}
  } as const
}
