import {VcsRepository} from "../../models";
import {OnError} from "../../../common/api/TransportLayer";
import {ReactNode} from "react";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {OrgRepoPath, VcsOwner} from "../../../common/models";
import {MaybeOrgConfiguration} from "../../../common/models";

export enum RepositoryPageTab {
  overview = "overview",
  builds = "builds",
  dependencies = "dependencies",
  apiCatalogue = "api-catalogue",
}

export type RepositoryPageTabProps = {
  org: VcsOwner
  repository: VcsRepository
  path: OrgRepoPath
  userProfile: AuthenticatedUserProfile
  orgConfiguration: MaybeOrgConfiguration
  onSidebar?: (children?: ReactNode) => void;
} & OnError
