import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {OrgPageTabProps} from "./OrgPageTab";
import {ApiCataloguePanel} from "../../components/configurations/ApiCataloguePanel";
import {apiConfigViews} from "../../api/configurations/model";
import {useOrgMonodesPluginsConfigsQuery} from "../../api/configurations";

export function OrgPageAPICatalogueTab({owner, onError}: OrgPageTabProps) {
  const apiCatalogueQuery = useOrgMonodesPluginsConfigsQuery({owner})

  return <QueryLoader height={450} result={apiCatalogueQuery} onError={onError} onLoaded={data =>
      <ApiCataloguePanel selectionDetails={true} configs={apiConfigViews(data)}/>
  }/>
}
