export class DocsUrls {
    private readonly baseUri: string;

    constructor(baseUri: string) {
        this.baseUri = baseUri;
    }

    configureAPIs() {
        return this.monopolisYmlUrl("api");
    }

    configureDependencies() {
        return this.monopolisYmlUrl("needs");
    }

    configureRollout() {
        return this.monopolisYmlUrl("rollout");
    }

    private docsUrl(endpoint: string) {
        return `${this.baseUri}/${endpoint}`;
    }

    private monopolisYmlUrl(section: string) {
        return this.docsUrl(`reference/monopolis-yml/#${section}`);
    }
}
