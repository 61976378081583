import * as React from 'react';
import {ClickAwayListener, Divider, Fade, IconButton, List, Paper, Popper, Typography} from "@mui/material";
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import {monopolisTheme} from "../../../common/styles/theme";
import {DocumentationPluginConfig} from "../../api/configurations/model";
import {DocumentationMenuItem} from "./DocumentationMenuItem";

export type DocumentationSetItem = DocumentationPluginConfig

export function DocumentationSetMenu({disabled, selected, items, onItem}: DocumentationSetMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelected = (item: DocumentationSetItem) => () => {
    handleClose()
    onItem(item)
  };

  return <>
    <IconButton aria-label="Table of contents" disabled={disabled} color="primary" onClick={handleClick}>
      <AutoStoriesOutlinedIcon sx={{flex: 1, alignItems: "center"}} fontSize="small"/>
    </IconButton>

    <Popper transition={true} placement="bottom-start" open={open} anchorEl={anchorEl}
            style={{paddingTop: 5}}>
      {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper variant="outlined" sx={{
                borderWidth: 4,
                backgroundColor: monopolisTheme.palette.secondary.light,
                maxHeight: 300,
                maxWidth: 400,
                overflow: 'auto'
              }}>

                <Typography sx={{py: 2, px: 2}} fontWeight={500} variant="body1">Documentation Sets</Typography>
                <Divider/>

                <List>{items.map(item =>
                    <DocumentationMenuItem
                        key={item.path}
                        selected={selected?.name === item.name}
                        onSelect={handleItemSelected(item)}>
                      {item.name}
                    </DocumentationMenuItem>
                )}</List>
              </Paper>
            </Fade>
          </ClickAwayListener>)}
    </Popper>
  </>
}

export type DocumentationSetMenuProps = {
  items: DocumentationSetItem[]
  selected?: DocumentationSetItem
  onItem: (item: DocumentationSetItem) => void
  disabled?: boolean
}
