import * as React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "./LaunchApp.css";
import "font-awesome-animation/css/font-awesome-animation.css";
import 'reactflow/dist/style.css';
import {StyledSnackbarProvider} from "../common/components/StyledSnackbarProvider";
import {LaunchAppRouter} from "./LaunchAppRouter";
import ErrorBoundary from "./layout/ErrorBoundary";
import {LaunchAppInfrastructure, LaunchAppInfrastructureProvider} from "./LaunchAppInfrastructure";

export type LaunchAppProps = {
  infra: LaunchAppInfrastructure
}

export function LaunchApp({infra}: LaunchAppProps): React.ReactElement {
  return (
      <StyledSnackbarProvider>
        <QueryClientProvider client={new QueryClient()}>
          <LaunchAppInfrastructureProvider infra={infra}>
            <ErrorBoundary>
              <BrowserRouter>
                <LaunchAppRouter/>
              </BrowserRouter>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false}/>
          </LaunchAppInfrastructureProvider>
        </QueryClientProvider>
      </StyledSnackbarProvider>
  );
}
