import * as React from 'react';
import {useCallback, useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {SystemError} from "../../../common/api/TransportLayer";
import {PageHeader} from "../../../common/components/navigation/PageHeader";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {DashboardGrid} from "../../components/dashboard/DashboardGrid";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {useUpdateUserDashboardMutation, useUserDashboardQuery} from "../../api/dashboard";
import {snackOptions} from "../../../common/snackbar";
import {asDashboardWidgetProps, DashboardWidgetProps} from "../../components/dashboard/DashboardWidgetView";
import {DashboardName, DashboardWidget} from "../../api/dashboard/model";
import {useSnackbar} from "notistack";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {UserPageId} from "../UserPageId";

export function UserDashboardPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {userProfile} = useUserAuthentication()
  const dashboard = useUserDashboardQuery({})
  const updateDashboard = useUpdateUserDashboardMutation()
  const {enqueueSnackbar} = useSnackbar();

  const handleSaveDashboard = useCallback(async (name: DashboardName, widgets: DashboardWidgetProps<any>[]) => {
    const response = await updateDashboard.mutateAsync({name: name, widgets: widgets as DashboardWidget[]});
    const success = response.kind === "ok";
    const message = success ? "Updated dashboard" : "Failed to update dashboard"
    enqueueSnackbar(message, snackOptions(success))
  }, [enqueueSnackbar, updateDashboard])


  if (userProfile && userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const header = <PageHeader
      title="Dashboard"
  />

  return <UserAppPage pageId={UserPageId.UserDashboard}
                      title="Dashboard" contentHeader={header} error={error} profile={userProfile}>
    <QueryLoader result={dashboard} onError={setError} onLoaded={data =>
        <DashboardGrid
            name="User Dashboard"
            showName={false}
            columns={16}
            onError={setError}
            onSave={handleSaveDashboard}
            widgets={data.widgets.map(asDashboardWidgetProps)}/>
    }/>
  </UserAppPage>
}
