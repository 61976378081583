import {GetQueryOptions, queryOptions} from "../../../common/api/query";
import {Monode, RolloutTarget} from "../../models";
import {UserRequestId} from "../UserRequestId";
import {RolloutUrls} from "./urls";
import {Target} from "./model";
import {MonodeRollout} from "../graphs/model";
import {useUserApiGet} from "../userApi";
import {OrgRepoPath, VcsOwner} from "../../../common/models";
import {groupBy} from "../../../common/arrays";

export const useOrgTargetsQuery = (params: { org: VcsOwner, path?: OrgRepoPath, monode?: Monode } & GetQueryOptions) =>
    useUserApiGet<Target[]>(UserRequestId.OrgTargets, RolloutUrls.orgTargets(params), {}, queryOptions(params))

export const useRepositoryRolloutsQuery = (params: { path: OrgRepoPath }) =>
    useUserApiGet<MonodeRollout[]>(UserRequestId.RepositoryRollouts, RolloutUrls.repositoryRollouts(params))

export const useMonodeRolloutsQuery = (params: { path: OrgRepoPath, monode: Monode }) =>
    useUserApiGet<MonodeRollout[]>(UserRequestId.MonodeRollouts, RolloutUrls.monodeRollouts(params))

export type MonodeRolloutsByTarget = {
    targets: RolloutTarget[],
    rollouts: (MonodeRollout | undefined)[][]
}

export const monodeRolloutsByTarget = function (allRollouts: MonodeRollout[], allTargets: Target[]): MonodeRolloutsByTarget {
    const targets = allTargets.filter(target => allRollouts.find(rollout => rollout.target === target.target) !== undefined).map(v => v.target)
    const rollouts = Object.entries(groupBy(allRollouts, rollout => rollout.path.org + "/" + rollout.path.repo + "/" + rollout.monode))
        .sort(([firstKey], [secondKey]) => firstKey > secondKey ? -1 : 1)
        .map(([_, rollout]) => targets.map(target => rollout.find(item => item.target === target)))

    return {targets, rollouts}
};
