import {useCachedCustomerConfigurationQuery} from "../api/account";
import {OnError} from "../../common/api/TransportLayer";
import React from "react";
import {TrialEndingBanner} from "../../common/components/TrialEndingBanner";

export function AccountTrialEndingBanner({onError}: AccountTrialEndingBannerProps) {
  const config = useCachedCustomerConfigurationQuery({onError})
  return (
      <TrialEndingBanner plan={config?.plan}/>
  )
}

export type AccountTrialEndingBannerProps = OnError

