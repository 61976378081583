import * as React from 'react';
import "swagger-ui-react/swagger-ui.css"
import {ApiPluginConfigView} from "../../api/configurations/model";
import {Box, Divider} from "@mui/material";
import {OpenApiSpecPanel} from "./OpenApiSpecPanel";
import {ApiSelection} from "./ApiSelection";
import {FollowDocumentationToConfigureHint} from "../hints";
import {Panel} from "../../../common/components/Panel";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type ApiCataloguePanelProps = {
  selectionDetails: boolean
  configs: ApiPluginConfigView[]
}

export function ApiCataloguePanel({configs, selectionDetails}: ApiCataloguePanelProps) {
  const {docsUrls} = useUserAppInfrastructure()
  const [selectedConfig, setSelectedConfig] = React.useState<ApiPluginConfigView | undefined>(configs[0]);

  if (configs.length === 0) return <FollowDocumentationToConfigureHint feature="APIs" url={docsUrls.configureAPIs()}/>

  return <Panel data-test="api-catalogue" noPadding>
    <Box p={2}>
      <ApiSelection showDetails={selectionDetails} configs={configs} maxWidth={"100%"} onSelect={setSelectedConfig}
                    selected={selectedConfig}/>
    </Box>
    <Divider/>
    <Box p={2}>
      {selectedConfig && <OpenApiSpecPanel config={selectedConfig}/>}
    </Box>
  </Panel>
}
