import {Grid} from "@mui/material";
import * as React from "react";
import {StatisticsAggregate} from "../api/statistics/model";
import {PipelinesStatusChart} from "./pipelines/PipelinesStatusChart";
import {PipelinesStatisticsData} from "./pipelines/PipelinesStatisticsData";
import {humaniseDuration} from "../../common/dateTime";
import {PipelinesPerformanceChart} from "./pipelines/PipelinesPerformanceChart";
import {Monode, VcsBranch} from "../models";
import {BadgesUrls} from "../api/badges/urls";
import {BadgeDialog} from "./rollouts/BadgeDialog";
import {formatBreadcrumbs} from "../../common/breadcrumbs";
import {monopolisConstraints} from "../../common/styles/constraints";
import {formatPercentage} from "../../common/format";
import {CustomerId, OrgRepoPath, VcsProvider} from "../../common/models";

export type BuildPanelProps = {
  customerId: CustomerId
  vcs: VcsProvider
  path: OrgRepoPath
  branch?: VcsBranch
  monode?: Monode
  statistics: StatisticsAggregate
  urls:BadgesUrls
}

export function BuildPanel({statistics, customerId, urls, path, branch, monode}: BuildPanelProps) {
  const badgeLinkParams = {customerId, path, branch, monode}
  const buildable = formatBreadcrumbs(path.org, path.repo) + (monode ? " / " + monode : "");
  const statisticsDays = statistics.dates.length;

  return <Grid item container xs={12}>
    <Grid container spacing={monopolisConstraints.gridSpacing} sx={{mb: monopolisConstraints.gridSpacing}}>
      <Grid item xs={12} md={3} lg={3}>
        <PipelinesStatisticsData title="Builds"
                                 link={<BadgeDialog title="Builds"
                                                    description={`Total number of builds of ${buildable} over the period of last ${statisticsDays} days.`}
                                                    uri={urls.totalBuilds(badgeLinkParams)}/>}
                                 graph={statistics.dates.map(date => ({value: date.successful + date.failed}))}
                                 value={(statistics.successful + statistics.failed).toString()}/>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <PipelinesStatisticsData title="Success Rate"
                                 link={<BadgeDialog title="Build Success Rate"
                                                    description={`Success rate of the build of ${buildable} over the period of last ${statisticsDays} days.`}
                                                    uri={urls.buildSuccessRate(badgeLinkParams)}/>}
                                 graph={statistics.dates.map(date => ({value: date.successful}))}
                                 value={formatPercentage(statistics.successRate)}/>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <PipelinesStatisticsData title="Avg Duration"
                                 link={<BadgeDialog title={`Avg Build Duration`}
                                                    description={`Average duration of build of ${buildable} over the period of last ${statisticsDays} days.`}
                                                    uri={urls.averageBuildDuration(badgeLinkParams)}/>}
                                 graph={statistics.dates.map(date => ({value: date.duration.avg}))}
                                 value={humaniseDuration(statistics.duration.avg)}/>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <PipelinesStatisticsData title="Max Duration"
                                 link={<BadgeDialog title="Max Build Duration"
                                                    description={`Maximum duration of build of ${buildable} over the period of last ${statisticsDays} days.`}
                                                    uri={urls.maxBuildDuration(badgeLinkParams)}/>}
                                 graph={statistics.dates.map(date => ({value: date.duration.max}))}
                                 value={humaniseDuration(statistics.duration.max)}/>
      </Grid>
    </Grid>

    <Grid container spacing={monopolisConstraints.gridSpacing}>
      <Grid item xs={12} md={6} lg={6}>
        <PipelinesStatusChart statistics={statistics.dates}/>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <PipelinesPerformanceChart statistics={statistics.dates}/>
      </Grid>
    </Grid>

  </Grid>
}
