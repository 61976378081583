import {Avatar, Box, Button, ClickAwayListener, Divider, Fade, IconButton, List, Paper, Popper} from "@mui/material";
import * as React from "react";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import {MenuItem} from "../../common/components/navigation/MenuItem";
import {MenuDirection, menuItemsStyles} from "../../common/layout/MenuItems";
import {UserLoggedInAs} from "../components/user/UserLoggedInAs";
import {Paths} from "../models/Paths";

import {UserAuthenticationProfile} from "../auth/useUserAuthentication";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";

export function UserBarMenu({profile}: UserMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return profile.type === AuthenticationType.User ?
      <>
        <IconButton aria-label="User Profile" onClick={handleClick} data-test="user-profile-menu">
          <Avatar alt={`${profile.login} Avatar`} sx={{width: 30, height: 30}} src={profile.avatar}/>
        </IconButton>

        <Popper transition={true} placement="bottom-start" open={open} anchorEl={anchorEl}
                style={{paddingTop: 5, minWidth: 150, maxWidth: 250, zIndex: 1001}}>
          {({TransitionProps}) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper variant="outlined">
                    <Box sx={{p: 2}}>
                      <UserLoggedInAs profile={profile}/>
                    </Box>
                    <Divider/>
                    <UserMenuItems profile={profile} direction="vertical"/>
                    <Divider/>
                    <Box sx={{p: 2}}>
                      <Button href={Paths.Logout} fullWidth={true} variant="outlined" data-test="logout">Logout</Button>
                    </Box>
                  </Paper>
                </Fade>
              </ClickAwayListener>)}
        </Popper>
      </> : <></>
}


export function UserDrawerMenu({profile}: UserMenuProps) {
  return <>
    <Box sx={{px: 1, pt: 1}}><UserLoggedInAs profile={profile}/></Box>
    {
      profile.type !== AuthenticationType.Unauthenticated
          ? <Button href={Paths.Logout} variant="outlined" sx={{m: 1}}>
            Logout
          </Button>
          : <Button href={Paths.Home} variant="outlined" sx={{m: 1}}>
            Login
          </Button>
    }

    {
      profile.type !== AuthenticationType.Unauthenticated
          ? <Divider/>
          : <></>
    }
    <MainMenuItems profile={profile} direction="vertical"/>
    <UserMenuItems profile={profile} direction="vertical"/>
  </>
}

export function MainMenuItems({direction}: UserMenuItemsProps) {
  const styles = menuItemsStyles()
  return (<List style={styles[direction]}></List>)
}

export function UserMenuItems({direction, profile}: UserMenuItemsProps) {
  const {urls} = useUserAppInfrastructure()
  const styles = menuItemsStyles()
  return (
      profile.type === AuthenticationType.User ?
          <List style={styles[direction]}>
            <MenuItem text="Dashboard" href={urls.home()}/>
            <MenuItem text="Settings" href={urls.userSettings()}/>
          </List>
          : <></>
  )
}

export type UserMenuProps = {
  profile: UserAuthenticationProfile
}

export type UserMenuItemsProps = {
  direction: MenuDirection;
  profile: UserAuthenticationProfile
}

