import React from "react";
import {NavigationHeader} from "../../common/layout/NavigationHeader";
import {UserBarMenu, UserDrawerMenu} from "./UserAppMenus";

import {UserAuthenticationProfile} from "../auth/useUserAuthentication";

export function UserAppNavigationHeader({profile}: UserAppNavigationHeaderProps) {
  return (
      <NavigationHeader barMenu={<UserBarMenu profile={profile}/>}
                        drawerMenu={<UserDrawerMenu profile={profile}/>}/>
  )
}

export type UserAppNavigationHeaderProps = {
  profile: UserAuthenticationProfile
}
