import * as React from "react";
import {TextField, TextFieldProps} from "@mui/material";
import {FormField} from "./Form";
import Typography from "@mui/material/Typography";

export type FormTextFieldProps = {
  label: string
  readOnly: boolean
  fullWidth?: boolean
  value?: string
} & Omit<TextFieldProps, "disabled" | "variant" | "fullWidth" | "value">

export function FormTextField({label, fullWidth, readOnly, value, ...fieldProps}: FormTextFieldProps) {
  return <FormField label={label} fullWidth={fullWidth}>
    {readOnly
        ? <Typography color="secondary">{value || "none"}</Typography>
        : <TextField variant="outlined"
                     fullWidth={true}
                     value={value}
                     {...fieldProps}/>
    }
  </FormField>
}
