import * as React from 'react';
import {SystemError} from "../../../common/api/TransportLayer";
import {UserAppPage} from "../../layout/UserAppPage";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {UserPageId} from "../UserPageId";

export type ErrorPageProps = {
  error: SystemError;
}

export function ErrorPage({error}: ErrorPageProps) {
  const {userProfile} = useUserAuthentication()
  return <UserAppPage pageId={UserPageId.Error} title={`Error: ${error.message}`} error={error}
                      profile={userProfile}></UserAppPage>;
}
