import {AdditionalAnalyticsData, Analytics, EventCategory} from "../../port/Analytics";
import ReactGA from "react-ga4";

export class GoogleAnalytics implements Analytics {
    constructor(measurementId: string) {
        ReactGA.initialize(measurementId)
    }

    additionalData(data: AdditionalAnalyticsData): void {
        ReactGA.set(data)
    }

    pageview(title: string): void {
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search, title});
    }

    dialogview(title: string): void {
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search, title});
    }

    event(category: EventCategory, action: string, label: string): void {
        ReactGA.event({category, action, label});
    }

    timing(category: EventCategory, action: string, label: string, value: number): void {
        ReactGA.event("timing_complete", {category, action, label, value})
    }
}
