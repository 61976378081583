import {
  CacheOptions,
  EmptyRequest,
  GetQueryOptions,
  queryNoRefetchOptions,
  queryOptions
} from "../../../common/api/query";
import {UserRequestId} from "../UserRequestId";
import {
  ApiKeyDetails,
  ApiKeyName,
  ApiKeyResponse,
  IntegrationSettings,
  IntegrationType,
  MemberRequest,
  RepoRequest
} from "./model";

import {OrgUrls} from "./urls";
import {OriginUri} from "../../models";
import {OwnerPathParameter, usePathParams} from "../../models/Paths";
import {OnError} from "../../../common/api/TransportLayer";
import {Repository, User} from "../model";
import {useApiUrl, useUserApiDelete, useUserApiGet, useUserApiPost} from "../userApi";
import {MaybeOrgConfiguration, OrgConfiguration, VcsOwner} from "../../../common/models";

export const useOrgConfigurationQuery = (params: { owner: VcsOwner } & CacheOptions & GetQueryOptions) =>
    useUserApiGet<OrgConfiguration>(UserRequestId.OrgConfiguration, OrgUrls.orgConfiguration(params), {}, queryOptions(params), params);

export const useOrgAdminConfigurationQuery = (params: { owner: VcsOwner } & CacheOptions & GetQueryOptions) =>
    useUserApiGet<OrgConfiguration>(UserRequestId.OrgConfiguration, OrgUrls.orgAdminConfiguration(params), {}, queryOptions(params), params);

export type UseOrgConfigurationProps = {
  owner: VcsOwner
} & OnError

export function useCachedOrgConfigurationQuery(params: UseOrgConfigurationProps): MaybeOrgConfiguration {
  const vcsParams = usePathParams<OwnerPathParameter>();
  const {data} = useOrgConfigurationQuery({
    ...vcsParams,
    readCache: true,
    writeCache: true,
    onQueryError: params.onError
  })
  if (!data) return undefined

  if (data.kind === "error") {
    return undefined
  }

  if (data.kind === "ok") {
    return data.data
  }
}

export const useOrgMembersQuery = (params: { owner: VcsOwner } & GetQueryOptions) =>
    useUserApiGet<User[]>(UserRequestId.OrgMembers, OrgUrls.orgMembers(params), {}, queryOptions(params))

export const useAllOrgRepositoriesQuery = (params: { owner: VcsOwner } & GetQueryOptions) =>
    useUserApiGet<Repository[]>(UserRequestId.AllOrgRepositories, OrgUrls.allOrgRepositories(params), {}, queryOptions(params))

export const useConfiguredOrgRepositoriesQuery = (params: { owner: VcsOwner } & GetQueryOptions) =>
    useUserApiGet<Repository[]>(UserRequestId.ConfiguredOrgRepositories, OrgUrls.configuredOrgRepositories(params), {}, queryOptions(params))

export const useOrgAllIntegrationsSettingsQuery = (params: { owner: VcsOwner } & GetQueryOptions) =>
    useUserApiGet<IntegrationSettings[]>(UserRequestId.OrgAllIntegrationsSettings, OrgUrls.orgAllIntegrationsConfigurations(params), {}, queryOptions(params))

export const useOrgApiKeysQuery = (params: { org: VcsOwner } & GetQueryOptions) =>
    useUserApiGet<ApiKeyDetails[]>(UserRequestId.OrgApiKeys, OrgUrls.orgApiKeys(params), {}, queryOptions(params))

export const useOrgIntegrationSettingsWithSecretsQuery = <T extends IntegrationSettings, >(params: {
  owner: VcsOwner,
  type: IntegrationType
} & GetQueryOptions) =>
    useUserApiGet<T>(UserRequestId.OrgIntegrationSettingsWithSecrets, OrgUrls.orgIntegrationConfigurationssWithSecrets(params), {}, queryNoRefetchOptions(params))

export const useOrgIntegrationAddUrl = (params: { owner: VcsOwner, type: IntegrationType, originUri: OriginUri }) =>
    useApiUrl(OrgUrls.orgIntegrationAdd(params))

const configurationQueries = [
  UserRequestId.OrgConfiguration,
  UserRequestId.OrgMembers,
  UserRequestId.AllOrgRepositories,
  UserRequestId.ConfiguredOrgRepositories,
  UserRequestId.OrgIntegrationSettings,
  UserRequestId.OrgAllIntegrationsSettings,
  UserRequestId.OrgNotificationChannels,
  UserRequestId.OrgApiKeys,
];

export const useOrgIntegrationAddMutation = <T, >(params: { owner: VcsOwner, type: IntegrationType }) =>
    useUserApiPost<T>(UserRequestId.OrgSettingsAddIntegration, OrgUrls.orgIntegrationAdd(params), configurationQueries, {})

export const useOrgIntegrationRemoveMutation = (params: { owner: VcsOwner, type: IntegrationType }) =>
    useUserApiPost<EmptyRequest>(UserRequestId.OrgIntegrationRemove, OrgUrls.orgIntegrationRemove(params), configurationQueries)

export const useEnableRepositoryMutation = (params: { org: VcsOwner }) =>
    useUserApiPost<RepoRequest>(UserRequestId.EnableRepository, OrgUrls.enableRepository(params), configurationQueries)

export const useDisableRepositoryMutation = (params: { org: VcsOwner }) =>
    useUserApiPost<RepoRequest>(UserRequestId.DisableRepository, OrgUrls.disableRepository(params), configurationQueries)

export const useGenerateOrgApiKeyMutation = (params: { org: VcsOwner }) =>
    useUserApiPost<ApiKeyDetails, ApiKeyResponse>(UserRequestId.GenerateOrgApiKey, OrgUrls.orgApiKeys(params), configurationQueries)

export const useDeleteOrgApiKeyMutation = (params: { org: VcsOwner}) =>
    useUserApiDelete<ApiKeyName>(UserRequestId.DeleteOrgApiKey, name => OrgUrls.orgApiKey({org: params.org, name}), configurationQueries)

export const useEnableMemberMutation = (params: { org: VcsOwner }) =>
    useUserApiPost<MemberRequest>(UserRequestId.EnableMember, OrgUrls.enableMember(params), configurationQueries)

export const useDisableMemberMutation = (params: { org: VcsOwner }) =>
    useUserApiPost<MemberRequest>(UserRequestId.DisableMember, OrgUrls.disableMember(params), configurationQueries)
