import * as React from "react";
import {Monode} from "../../models";
import {Link} from "../../../common/components/navigation/Link";
import {OrgRepoPath, VcsProvider} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type MonodeBreadcrumbProps = {
  path: OrgRepoPath
  monode: Monode
  vcs: VcsProvider
}

export function MonodeBreadcrumb({monode, path}: MonodeBreadcrumbProps) {
  const {urls} = useUserAppInfrastructure()
  return <Link href={urls.monode({
    path: path,
    monode: monode
  })}>{monode}</Link>
}
