import * as React from "react";
import {AccountConfigurationPageTabProps} from "./AccountConfigurationPageTab";
import {Box, Chip, Skeleton, Typography} from "@mui/material";
import {useAccountConfigurationQuery, useAccountPlanHistoryQuery} from "../../api/account";
import {PlanLimit} from "../../api/account/model";
import {daysFromNowUntil, formatDateToFullDate, isDateAfterNowUTC, isDateBeforeNowUTC} from "../../../common/dateTime";

import {ConfigurationSectionDivider, ConfigurationPanel, ConfigurationSection} from "../../../common/components/configuration";

export function AccountConfigurationPageBillingTab({onError}: AccountConfigurationPageTabProps) {
  const {data: configuration} = useAccountConfigurationQuery()
  const {data: planHistory} = useAccountPlanHistoryQuery()

  if (!configuration || !planHistory) {
    return <Box>
      <Skeleton variant="rectangular" width="100%" height={100}/>
    </Box>
  }

  if (configuration.kind === "error") {
    onError(configuration.error)
    return <></>
  }

  if (planHistory.kind === "error") {
    onError(planHistory.error)
    return <></>
  }

  return <ConfigurationPanel>
    <ConfigurationSection data-test="billing-plan" label="Plan">
      <Typography variant="subtitle1">{configuration.data.plan.name}</Typography>

      <Typography variant="subtitle1" sx={{mt: 2}}>Features</Typography>
      <Typography variant="body2" color="text.secondary">
        {configuration.data.plan.features.join(", ")}
      </Typography>

      <Typography variant="subtitle1" sx={{mt: 2}}>Limits</Typography>
      <Typography variant="body2" color="text.secondary">
        {Object.keys(configuration.data.plan.limits)
            .map(limit => limit as PlanLimit)
            .map(limit => <div key={limit}>{limit}: {configuration.data.plan.limits[limit]}</div>)}
      </Typography>
    </ConfigurationSection>

    <ConfigurationSectionDivider/>

    <ConfigurationSection label="Purchase History">
      {planHistory.data
          .map(purchase => {
            const current = isDateBeforeNowUTC(purchase.startAt) && isDateAfterNowUTC(purchase.plan.endAt)

            function onTrial(): string {
              return isDateAfterNowUTC(purchase.plan.trialEndAt)
                  ? " (Trial ends in " + daysFromNowUntil(purchase.plan.trialEndAt) + " day)"
                  : ""
            }

            return <Box key={purchase.startAt} sx={{border: current ? 1 : 0, pb: 2}}>
              <Typography variant="subtitle1">{purchase.plan.name}
                <Chip label={purchase.plan.type} size="small" variant="outlined" sx={{ml: 1, mr: 1}}/>
              </Typography>


              <Typography variant="body2" color="text.secondary">
                <span>{formatDateToFullDate(purchase.startAt)}</span>
                <span> - </span>
                <span>{purchase.plan.endAt ? formatDateToFullDate(purchase.plan.endAt) : "?"}</span>
                <span>{onTrial()}</span>
              </Typography>

              <Typography variant="body2" color="text.secondary">
                <span>{purchase.billing.billingDate ? formatDateToFullDate(purchase.billing.billingDate) : "?"}</span>
                <span> | </span>
                <span>Billing: {purchase.billing.period}</span>
                <span> | </span>
                <span>${purchase.billing.seatCost / 100 * purchase.billing.numberOfSeats}</span>
                <span> | </span>
                <span>({purchase.billing.numberOfSeats} x ${purchase.billing.seatCost / 100})</span>
              </Typography>
            </Box>
          })}
    </ConfigurationSection>
  </ConfigurationPanel>

}
