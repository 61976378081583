

export type UrlQueryParam = {
    name: string;
    value?: string;
}

export function buildUrl(base: string, query: UrlQueryParam[] = []): string {
    return base + (query.length === 0 ? "" : "?" + query
        .filter(entry => entry.value !== undefined)
        .map(entry => entry.name + "=" + encodeURIComponent(entry.value!!))
        .join("&"))
}


