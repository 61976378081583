import {OnError} from "../../../common/api/TransportLayer";

import {AuthenticatedUserProfile} from "../../auth/AuthenticatedUserProfile";
import {VcsOwner} from "../../../common/models";

export enum OrgPageTab {
  overview = "overview",
  dependencies = "dependencies",
  dashboards = "dashboards",
  apiCatalogue = "api-catalogue",
}

export type OrgPageTabProps = {
  owner: VcsOwner
  userProfile: AuthenticatedUserProfile
} & OnError
