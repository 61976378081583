export function base64UrlEncode(value: string) {
    return Buffer.from(value).toString("base64").replaceAll("+", "-").replaceAll("/", "_")
}

export function humanise(value: string) {
    const camelMatch = /([A-Z])/g;
    const underscoreMatch = /_/g;
    const camelCaseToSpaces = value.replace(camelMatch, " $1");
    const underscoresToSpaces = camelCaseToSpaces.replace(underscoreMatch, " ");
    return underscoresToSpaces.charAt(0).toUpperCase() +
        underscoresToSpaces.slice(1).toLowerCase();
}


export function capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}
