import {Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import * as React from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {OrgPageTabProps} from "./OrgPageTab";
import {DependencyPanel} from "../../components/graphs/DependencyPanel";
import {useOrgDependencyGraphQuery} from "../../api/graphs";

export function OrgPageDependenciesTab({owner, userProfile, onError}: OrgPageTabProps) {
  const dependencyGraphQuery = useOrgDependencyGraphQuery({owner})

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <QueryLoader height={450} result={dependencyGraphQuery} onError={onError} onLoaded={data =>
        <DependencyPanel vcs={userProfile.vcs}
                         graph={data}/>
    }/>
  </Grid>
}
