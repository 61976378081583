import * as React from 'react';
import {PipelineStage, RolloutStage} from "../../api/pipelines/model";
import {Box} from "@mui/material";
import {PipelineStatusIcon} from "./PipelineStatusIcon";
import {PipelineStatusStyles, RolloutStatusStyles} from "../../styles/pipelines";
import {PipelineStageTooltip} from "./PipelineStageTooltip";

export type PipelineStageIconsProps = {
  stages: PipelineStage[];
  rollouts: RolloutStage[];
}

export function PipelineStageIcons({stages, rollouts}: PipelineStageIconsProps) {
  return (<Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", maxWidth: "100%"}}>
    {stages.map((stage, index) => (
            <Box key={index} sx={{pr: 1, py:0.25}}>
              <PipelineStatusIcon variant="job"
                                  status={stage.status}
                                  isFilled={false}
                                  tooltip={<PipelineStageTooltip variant="pipeline" stage={stage}/>}
                                  hasLink={index !== stages.length - 1 || rollouts.length > 0}
                                  styles={PipelineStatusStyles}
              />
            </Box>
        )
    )}
    {rollouts.map((rollout, index) => (
            <Box key={index} sx={{pr: 1, py:0.25}}>
              <PipelineStatusIcon variant="rollout"
                                  status={rollout.status}
                                  isFilled={false}
                                  tooltip={<PipelineStageTooltip variant="rollout" stage={rollout}/>}
                                  hasLink={index !== rollouts.length - 1}
                                  styles={RolloutStatusStyles}
              />
            </Box>
        )
    )}
  </Box>)
}
