import * as React from "react";
import {ReactNode} from "react";
import {Box, Typography} from "@mui/material";
import {Line, LineChart, ResponsiveContainer} from "recharts";
import {Panel} from "../../../common/components/Panel";
import {monopolisTheme} from "../../../common/styles/theme";

export function PipelinesStatisticsData({title, value, graph, link}: PipelinesStatisticsDataProps) {
  return <Panel header={title} sx={{position: "relative"}}>
    {
        link && <Box
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}>
          {link}
        </Box>
    }

    <ResponsiveContainer width="100%" height={55}>
      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <Typography variant="h5" color="text.secondary">
          {value}
        </Typography>

        <ResponsiveContainer width="100%" height={20}>
          <LineChart data={graph || [{value: 0}, {value: 0}]}>
            <Line type="monotone" dataKey="value" stroke={monopolisTheme.palette.primary.main} strokeWidth={2}
                  dot={<></>}/>
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </ResponsiveContainer>
  </Panel>
}

export type PipelinesStatisticsDataPoint = {
  value: number
}

export type PipelinesStatisticsDataProps = {
  title: string;
  value: string;
  graph: PipelinesStatisticsDataPoint[];
  link: ReactNode;
}
