import * as React from "react";
import {Monode, RolloutTarget} from "../../models";
import {Link} from "../../../common/components/navigation/Link";

import {OrgRepoPath} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type MonodeTargetBreadcrumbProps = {
  path: OrgRepoPath
  target: RolloutTarget
  monode: Monode
}

export function MonodeTargetBreadcrumb({target, path, monode}: MonodeTargetBreadcrumbProps) {
  const {urls} = useUserAppInfrastructure()
  return <Link href={urls.target({repo: path.repo, monode: monode, org: path.org, target: target})}>
    {target}
  </Link>
}
