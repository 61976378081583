import * as React from 'react';
import {useState} from 'react';
import {UserAppPage} from "../../layout/UserAppPage";
import {OwnerPathParameter, RepositoryPathParameter, usePathParams} from "../../models/Paths";
import {pathFrom} from "../../models";
import {SystemError} from "../../../common/api/TransportLayer";
import {useUserAuthentication} from "../../auth/useUserAuthentication";
import {OrgTrialEndingBanner} from "../../components/configurations/OrgTrialEndingBanner";
import {PageHeader, PageTab} from "../../../common/components/navigation/PageHeader";
import {useTabControls} from "../../../common/tabs";
import {RepositoryConfigurationPageTab, RepositorySettingsTabProps} from "./RepositoryConfigurationPageTab";
import {RepositoryConfigurationPageConfigureMonopolisTab} from "./RepositoryConfigurationPageConfigureMonopolisTab";
import {RepositoryConfigurationPageNotificationsTab} from "./RepositoryConfigurationPageNotificationsTab";
import {UnauthorizedPage} from "../error/UnauthorizedPage";
import {OwnerBreadcrumb} from "../../components/breadcrumbs/OwnerBreadcrumb";
import {RepoBreadcrumb} from "../../components/breadcrumbs/RepoBreadcrumb";
import {AuthenticationType} from "../../../common/auth/AuthenticationType";
import {UserPageId} from "../UserPageId";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";


export function RepositoryConfigurationPage() {
  const [error, setError] = useState<SystemError | null>(null);
  const {urls} = useUserAppInfrastructure()
  const tabs = useTabControls({
    defaultTab: RepositoryConfigurationPageTab.configuration,
    tabs: [
      {title: "Configuration", id: RepositoryConfigurationPageTab.configuration},
      {title: "Notifications", id: RepositoryConfigurationPageTab.notifications}
    ]
  })
  const {userProfile} = useUserAuthentication()
  const pathParams = usePathParams<OwnerPathParameter & RepositoryPathParameter>();
  const owner = pathParams.owner;
  const path = pathFrom(pathParams);

  if (userProfile.type !== AuthenticationType.User) return <UnauthorizedPage/>

  const header = <PageHeader
      title="Settings"
      location={urls.repositorySettings({path})}
      breadcrumbs={[
        <OwnerBreadcrumb owner={path.org}/>,
        <RepoBreadcrumb vcs={userProfile.vcs} path={path}/>
      ]}
      tabs={tabs}
  />

  const banner = <OrgTrialEndingBanner owner={pathParams.owner} onError={setError}/>
  const title = "Settings " + owner + "/" + pathParams.repository;

  const tabProps: RepositorySettingsTabProps = {
    path,
    owner,
    onError: setError
  }

  return <UserAppPage pageId={UserPageId.RepoConfiguration} title={title} contentHeader={header} banner={banner} error={error}
                      profile={userProfile}>
    <PageTab selectedId={tabs.selected} id={RepositoryConfigurationPageTab.configuration}>
      <RepositoryConfigurationPageConfigureMonopolisTab {...tabProps}/>
    </PageTab>

    <PageTab selectedId={tabs.selected} id={RepositoryConfigurationPageTab.notifications}>
      <RepositoryConfigurationPageNotificationsTab{...tabProps}/>
    </PageTab>
  </UserAppPage>
}
