import * as React from "react";
import {useMemo} from "react";
import {Box, Divider, List, Typography} from "@mui/material";
import {AuditEvent} from "../../../api/events/model";
import {eventMessageFor} from "../../../utils/events";
import {EventsListRow} from "./EventsListRow";
import {groupBy, last} from "../../../../common/arrays";
import {epochMillisAtUTC, formatDateToMonthAndDay, formatUTCToLocalDate} from "../../../../common/dateTime";

export type EventsListProps = {
  events: AuditEvent[];
}

export function EventsList({events}: EventsListProps) {
  const reversedEvents = useMemo(() => events
          .sort((a, b) => epochMillisAtUTC(last(a.spans)!!.updatedAt) - epochMillisAtUTC(last(b.spans)!!.updatedAt))
          .reverse(),
      [events]
  )
  const groupedByDate = useMemo(() => groupBy(reversedEvents, event => formatUTCToLocalDate(last(event.spans)!!.updatedAt)), [reversedEvents])
  const sortedDates = useMemo(() => Object.keys(groupedByDate).sort().reverse(), [groupedByDate])

  return <>
    {events.length > 0
        ? <List sx={{width: "100%", maxHeight: "300px", overflowY: "auto", m: 0, p: 0}}>
          {
            sortedDates.flatMap(date => [
                  <Box key={date}>
                    <Typography sx={{m: 2, mt:4}} variant="body1" fontWeight={500}>{formatDateToMonthAndDay(date)}</Typography>
                    <Divider/>
                  </Box>,

                  ...groupedByDate[date].map((event, index) => {
                        const span = last(event.spans)!!;
                        const title = eventMessageFor(event, span);
                        const timelineEvent = {
                          title,
                          dateTime: span.updatedAt,
                          type: event.eventType,
                          status: span.status,
                          severity: span.severity
                        };
                        return <Box key={title + span.updatedAt}>
                          <EventsListRow event={timelineEvent}
                                         menu={() => null}
                                         selected={false}
                                         onClick={() => {
                                                 }}
                          />
                          {index !== events.length - 1 && <Divider/>}
                        </Box>
                      }
                  )
                ]
            )
          }
        </List>
        : <Box sx={{p: 2}}><Typography variant="body1" color="text.secondary">No events</Typography></Box>
    }
  </>
}


