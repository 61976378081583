import * as React from "react";
import {useEffect} from "react";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {RepositoryPageTabProps} from "./RepositoryPageTab";
import {Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import {Panel} from "../../../common/components/Panel";
import {BranchSelection} from "../../components/pipelines/BranchSelection";
import {BuildPanel} from "../../components/BuildPanel";
import {useBranchOrDefault} from "../../utils/branches";
import {useRepositoryMonodesQuery, useRepositoryPipelinesQuery} from "../../api/pipelines";
import {queryRefetchIntervalFrom} from "../../utils/orgConfigurations";
import {useRepositoryBuildStatisticsQuery, useRepositoryGroupedBuildStatisticsQuery} from "../../api/statistics";
import {PipelinesTablePanel} from "../../components/pipelines/PipelinesTablePanel";
import {extendPipelines, monodeActionsMenu, pipelineActionsMenu} from "../../utils/pipelines";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function RepositoryPageBuildsTab({
                                          path,
                                          userProfile,
                                          orgConfiguration,
                                          onSidebar,
                                          onError,
                                        }: RepositoryPageTabProps) {
  const {badgesUrls} = useUserAppInfrastructure()
  const branch = useBranchOrDefault({path, onError});
  const loadComponents = branch !== undefined;
  const repositoryMonodesResult = useRepositoryMonodesQuery({
    path,
    branch,
    refetchInterval: queryRefetchIntervalFrom(orgConfiguration),
    enabled: loadComponents
  })

  const repositoryPipelinesResult = useRepositoryPipelinesQuery({
    path,
    branch,
    refetchInterval: queryRefetchIntervalFrom(orgConfiguration),
    enabled: loadComponents
  })

  const orgGroupedBuildStatsQuery = useRepositoryGroupedBuildStatisticsQuery({
    path,
    branch,
    onQueryError: onError,
    enabled: loadComponents
  })

  useEffect(() => onSidebar && onSidebar(<Grid item xs={12}>
    <Panel>
      <BranchSelection path={path}
                       selected={branch || ""}
                       maxWidth={"100%"}/>
    </Panel>
  </Grid>), [onSidebar, branch, path])

  const stats = orgGroupedBuildStatsQuery.data && orgGroupedBuildStatsQuery.data.kind === "ok"
      ? orgGroupedBuildStatsQuery.data.data
      : []


  const buildStatisticsQuery = useRepositoryBuildStatisticsQuery({
    path,
    branch,
    enabled: loadComponents
  })


  if (!loadComponents) return null

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    <QueryLoader height={450} result={buildStatisticsQuery} onError={onError} onLoaded={data =>
        <BuildPanel customerId={userProfile.customerId}
                    vcs={userProfile.vcs}
                    urls={badgesUrls}
                    path={path}
                    branch={branch}
                    statistics={data}/>
    }/>

    <QueryLoader result={repositoryMonodesResult} onError={onError} onLoaded={data =>
        <PipelinesTablePanel testId="monodes" title="Monodes" pipelines={extendPipelines(data, stats)} menu={monodeActionsMenu}/>
    }/>

    <QueryLoader result={repositoryPipelinesResult} onError={onError} onLoaded={data =>
        <PipelinesTablePanel testId="pipelines" title="Pipelines" pipelines={extendPipelines(data, stats)} menu={pipelineActionsMenu}/>
    }/>

  </Grid>
}
