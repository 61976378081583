import {useCallback} from "react";
import {currentLocation, reloadWindow} from "../../common/navigation";
import {useUserAppInfrastructure} from "../UserAppInfrastructure";
import {AuthenticatedUserProfile} from "./AuthenticatedUserProfile";
import {UnauthenticatedProfile} from "../../common/auth/UnauthenticatedProfile";
import {OAuthSlug} from "../../common/models";
import {useNavigation} from "../../common/port/Navigation";
import {UserAuthenticationRedirect} from "./UserAuthenticationStore";
import Cookies from "js-cookie";
import {SystemResponse} from "../../common/api/TransportLayer";
import {useFetchUserProfileQuery} from "../api/user";
import {TeamId} from "../../common/models/TeamId";

export const useUserAuthentication = () => {
  const {navigateTo} = useNavigation()
  const {authStore, apiUrls} = useUserAppInfrastructure();
  const userProfile = authStore.getProfile();

  const redirectToLogin = useCallback(
      (redirectionUri: string) => {
        authStore.redirectToLogin(redirectionUri)
      },
      [authStore]
  );

  const navigateToLogin = (teamId: TeamId, redirectionUri?: string) => {
    redirectionUri && authStore.setAuthenticationRedirect(redirectionUri)
    navigateTo(apiUrls.login(teamId))
  }

  const initiateLoginCLI = (slug: OAuthSlug) => {
    authStore.initiateLoginCLI(slug)
  }

  const navigateToLoginCLI = (teamId: TeamId) => {
    navigateTo(apiUrls.loginCLI(teamId, authStore.getOAuthSlug()))
  }

  const completeLoginCLI = useCallback(() => {
    authStore.authenticateCLI()
  }, [authStore])

  const loginUserFromCallback = (token: string, profile: AuthenticatedUserProfile, onRedirect?: UserAuthenticationRedirect) => {
    authStore.authenticateUser(token, profile, onRedirect);
  };

  const enforceLogin = () => {
    redirectToLogin(currentLocation())
  };

  const logout = useCallback((onRedirect: UserAuthenticationRedirect = reloadWindow) => {
    authStore.logout(onRedirect);
  }, [authStore]);

  const getCookie = () => Cookies.get('appSession');

  return {
    logout,
    getCookie,
    userProfile,
    enforceLogin,
    navigateToLogin,
    loginUserFromCallback,
    initiateLoginCLI,
    navigateToLoginCLI,
    completeLoginCLI,
  };
};

export type LoginUserFromCookieProps = {
  onRedirect?: UserAuthenticationRedirect,
  onError?: (message: string) => void,
  enabled?: boolean
}

export function useLoginUserFromCookie({onRedirect, onError, enabled}: LoginUserFromCookieProps) {
  const tokenCookie = Cookies.get('appSession');
  const {loginUserFromCallback} = useUserAuthentication();

  if (!tokenCookie && onError) onError("Not authenticated")

  const onUserProfileFetched = useCallback((data: SystemResponse<AuthenticatedUserProfile>) => {
    if (data.kind === "error" && onError) onError(data.error.message)
    if (data.kind === "ok" && tokenCookie) loginUserFromCallback(tokenCookie, data.data, onRedirect)
  }, [loginUserFromCallback, tokenCookie, onRedirect, onError])

  useFetchUserProfileQuery({
    token: () => tokenCookie,
    onSuccess: onUserProfileFetched,
    enabled: enabled === undefined || enabled
  })
}


export type UserAuthenticationProfile = AuthenticatedUserProfile | UnauthenticatedProfile
