import {ListItemButton, Typography} from "@mui/material";
import * as React from "react";
import {monopolisTheme} from "../../../common/styles/theme";

export type DocumentationMenuItemProps = {
  children: React.ReactNode
  selected: boolean
  onSelect: () => void
}

export function DocumentationMenuItem({children, selected, onSelect}: DocumentationMenuItemProps) {
  return (
      <ListItemButton selected={selected} onClick={onSelect} sx={{
        p: 1, pl: 2, m: 0,
        "&.Mui-selected": {
          backgroundColor: monopolisTheme.palette.secondary.main,
          color: monopolisTheme.palette.primary.dark,
          ":hover": {
            backgroundColor: monopolisTheme.palette.secondary.main,
            color: monopolisTheme.palette.primary.dark
          }
        },
        ":hover": {
          backgroundColor: monopolisTheme.palette.secondary.dark,
          color: monopolisTheme.palette.primary.contrastText
        }
      }}>
        <Typography noWrap sx={{textOverflow: "ellipsis", display: "flex", alignItems: "center"}}>
          {children}
        </Typography>
      </ListItemButton>
  )
}
