import {CircularProgress, circularProgressClasses, CircularProgressProps} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {monopolisTheme} from "../styles/theme";

export function SolidCircularProgress(props: CircularProgressProps) {
  return (
      <Box sx={{position: 'relative'}}>
        <CircularProgress
            variant="determinate"
            sx={{
              color: monopolisTheme.palette.grey[300],
            }}
            size={40}
            thickness={4}
            {...props}
            value={100}
        />
        <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: monopolisTheme.palette.primary.dark,
              animationDuration: '1500ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
            size={40}
            thickness={4}
            {...props}
        />
      </Box>
  );
}
