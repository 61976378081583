import * as React from 'react';
import {useEffect, useState} from 'react';
import {useEmailRegistrationCompleteMutation} from "../../api/user";
import {ErrorPage} from "../error/ErrorPage";
import {usePathParams, VerifyEmailTokenPathParameter} from "../../models/Paths";
import {SystemResponse} from "../../../common/api/TransportLayer";
import {EmptyResponse} from "../../../common/api/query";
import {UnknownErrorPage} from "../error/UnknownErrorPage";
import {useNavigation} from "../../../common/port/Navigation";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export function VerifyEmailCallback() {
  const [verificationResult, setVerificationResult] = useState<SystemResponse<EmptyResponse> | undefined>()
  const {urls} = useUserAppInfrastructure()
  const {token} = usePathParams<VerifyEmailTokenPathParameter>()
  const {navigateTo} = useNavigation()

  const completeEmail = useEmailRegistrationCompleteMutation()
  
  useEffect(() => {
    if (!verificationResult) {
      completeEmail.mutateAsync({token}).then(setVerificationResult)
    }
  }, [token, completeEmail, navigateTo, verificationResult, setVerificationResult]);

  if (verificationResult) {
    verificationResult.kind === "ok" ? navigateTo(urls.userSettings()) :
        <ErrorPage error={verificationResult.error}/>
  }

  return <UnknownErrorPage/>;
}
