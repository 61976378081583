import {Paths} from "./Paths";

export class AccountAppUrls {
    baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    settings(): string {
        return this.baseUrl + Paths.Settings
    }

    login(): string {
        return this.baseUrl + Paths.Login
    }


    home(): string {
        return this.baseUrl + Paths.Home
    }
}
