import {OrgRepoPath, VcsOwner} from "../../common/models";

export enum Toggle {
    On = 'On',
    Off = 'Off',
    Disabled = 'Disabled',
}

export type Repository = {
    path: OrgRepoPath
    isPrivate: boolean
}

export type User = {
    login: VcsOwner;
    avatar: string;
}

