import {Navigation} from "../../../common/port/Navigation";

export class AccountAppNavigation extends Navigation {
    private readonly _baseUrl: string

    constructor(baseUrl: string) {
        super()
        this._baseUrl = baseUrl
    }

    baseUrl(): string {
        return this._baseUrl
    }
}
