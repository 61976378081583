import {Alert, AlertTitle, BoxProps} from "@mui/material";
import * as React from "react";
import {SystemError} from "../api/TransportLayer";
import Box from "@mui/material/Box";
import {SolidCircularProgress} from "./SolidCircularProgress";

export function ErrorPanel({error, ...rest}: ErrorPanelProps) {
  switch (error.code) {
    case "FORBIDDEN":
    case "INVALID_TOKEN": {
      return <Box {...rest} data-test="app-error">
        <Box sx={{
          mt: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <SolidCircularProgress/>
        </Box>
      </Box>
    }

    default:
      return <Box {...rest} data-test="app-error">
        <Alert severity="error">
          <AlertTitle>{error.message}</AlertTitle>
        </Alert>
      </Box>
  }
}

export type ErrorPanelProps = {
  error: SystemError;
} & BoxProps
