import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography
} from "@mui/material";
import {CancelButton} from "../../../common/components/navigation/CancelButton";

import {ApiKey, VcsOwner} from "../../../common/models";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {ApiKeyAccessLevel, ApiKeyAccessLevels, ApiKeyName} from "../../api/org/model";
import {useGenerateOrgApiKeyMutation} from "../../api/org";
import {FormStack, FormTextField} from "../../../common/components/form";
import {FormSelectField} from "../../../common/components/form/FormSelectField";
import {FormDateField} from "../../../common/components/form/FormDateField";

export type RegisteredApiKey = {
  name: ApiKeyName,
  key: ApiKey,
}

export type RegisterApiKeyDialogProps = {
  org: VcsOwner
  onSubmit: () => void
  onCancelled: () => void
  onSuccessful: (registered: RegisteredApiKey) => void
} & OnError

export function RegisterApiKeyDialog(
    {
      org,
      onCancelled,
      onSubmit,
      onSuccessful,
      onError
    }: RegisterApiKeyDialogProps) {
  const {config, analytics} = useUserAppInfrastructure()

  useEffect(() => {
    analytics.dialogview("Register Api Key")
  }, [analytics]);

  const [apiKeyName, setApiKeyName] = useState<ApiKeyName>("");
  const [accessLevel, setAccessLevel] = useState<ApiKeyAccessLevel>(ApiKeyAccessLevel.Read);
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);

  const generateOrgApiKeyMutation = useGenerateOrgApiKeyMutation({org})
  const handleAction = useCallback(async () => {
    onSubmit && onSubmit()
    const details = {
      name: apiKeyName,
      accessLevel: accessLevel,
      expiry: expiryDate || "2400-01-01"
    };
    const response = await generateOrgApiKeyMutation.mutateAsync(details);
    const isSuccess = response.kind === "ok";

    if (isSuccess) {
      onSuccessful({name: response.data.apiKeyName, key: response.data.apiKey})
      analytics.event("User", "Register Api Key", `Register api key successful`)
      setApiKeyName("")
    } else {
      analytics.event("User", "Register Api Key", `Register api key failure`)
      onError && onError(response.error)
      setApiKeyName("")
    }
  }, [analytics, accessLevel, apiKeyName, expiryDate, onError, onSubmit, onSuccessful, generateOrgApiKeyMutation])

  return (
      <Dialog maxWidth="xs" fullWidth={true} open={true} onClose={onCancelled}>
        <DialogTitle>
          Register API Key
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText component={'span'} color="primary">
            <Typography variant="body1">
              Enter details of new API key for {config.appName} api.
            </Typography>
          </DialogContentText>

          <DialogContentText color="primary" sx={{mt: 3, mb: 3}}>
            <FormStack>
              <FormTextField label="Key Name"
                             placeholder="Key Name"
                             fullWidth={true}
                             required={true}
                             readOnly={false}
                             value={apiKeyName}
                             inputProps={{style: {textTransform: "uppercase"}}}
                             onChange={e => setApiKeyName(e.target.value)}
              />
              <FormSelectField label="Access Level"
                               placeholder="Access Level"
                               readOnly={false}
                               fullWidth={true}
                               options={ApiKeyAccessLevels}
                               value={accessLevel}
                               onChange={e => setAccessLevel(e.target.value as ApiKeyAccessLevel)}
              />

              <FormDateField label="Expiry"
                             fullWidth={true}
                             readOnly={false}
                             onChange={e => setExpiryDate(e.toString())}
                             value={expiryDate}
              />

            </FormStack>
          </DialogContentText>
        </DialogContent>

        <Divider/>

        <DialogActions>
          <CancelButton onClick={onCancelled}/>
          {
            <Button aria-label="Register" variant="contained" autoFocus
                    onClick={handleAction}>
              Register
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}
