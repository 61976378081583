import {BaseDashboardWidget} from "./BaseDashboardWidget";
import {DashboardWidgetType} from "../model";

export enum TextWidgetVariant {
    h1 = "h1",
    h2 = "h2",
    h3 = "h3",
    h4 = "h4",
    h5 = "h5",
    h6 = "h6",
}

export const TextWidgetVariants = Object.values(TextWidgetVariant)

export type TextWidgetConfig = {
    text: string
    variant: TextWidgetVariant
}

export type TextWidget = BaseDashboardWidget<TextWidgetConfig> & {
    type: DashboardWidgetType.Text
}
