import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import {CancelButton} from "../../../common/components/navigation/CancelButton";
import {useDeleteDashboardMutation} from "../../api/dashboard";
import {Dashboard, DashboardId, DashboardName} from "../../api/dashboard/model";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";
import {VcsOwner} from "../../../common/models";

export type DeleteDashboardDialogProps = {
  owner: VcsOwner
  dashboardId: DashboardId
  dashboardName: DashboardName
  disabled?: boolean
  onSuccessful: (dashboard: Dashboard) => void
} & OnError

export function DeleteDashboardDialog(
    {
      owner,
      dashboardId,
      dashboardName,
      disabled,
      onSuccessful,
      onError
    }: DeleteDashboardDialogProps) {
  const {analytics} = useUserAppInfrastructure()
  const [open, setOpen] = useState(false);

  useEffect(() => {
    analytics.dialogview("Delete Dashboard")
  }, [analytics]);

  const deleteDashboard = useDeleteDashboardMutation({owner, dashboardId})

  const handleOpen = useCallback(async () => {
        setOpen(true)
      }, [setOpen]
  )

  const handleOnClose = useCallback(async () => {
        setOpen(false)
      }, [setOpen]
  )

  const handleOnDelete = useCallback(async () => {
    setOpen(false)

    const response = await deleteDashboard.mutateAsync({});
    const isSuccess = response.kind === "ok";

    if (isSuccess) {
      onSuccessful({id: dashboardId, name: dashboardName, widgets: []})
      analytics.event("User", "Delete Dashboard", `Delete dashboard successful`)
    } else {
      analytics.event("User", "Delete Dashboard", `Delete dashboard failure`)
      onError && onError(response.error)
    }
  }, [analytics, onError, onSuccessful, deleteDashboard, dashboardId, dashboardName])

  return (
      <Box>
        <IconButton data-test="delete-dashboard-btn"
                    onClick={handleOpen} aria-label={"Delete dashboard"} disabled={disabled}>
          <CloseRoundedIcon/>
        </IconButton>
        <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={handleOnClose}>
          <DialogTitle>
            Delete Dashboard
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <DialogContentText component={'span'} color="primary">
              <Typography variant="body1">
                Are you sure you want to delete dashboard "{dashboardName}"?
              </Typography>
            </DialogContentText>
          </DialogContent>

          <Divider/>

          <DialogActions>
            <CancelButton onClick={handleOnClose}/>
            {
              <Button data-test="delete-dashboard-dialog-btn" aria-label="Delete"
                      variant="contained"
                      autoFocus
                      onClick={handleOnDelete}>
                Delete
              </Button>
            }
          </DialogActions>
        </Dialog>
      </Box>);
}
