import * as React from 'react';
import {ReactNode, useState} from 'react';
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer
} from "@mui/material";
import {PipelineResults, PipelineRun} from "../../api/pipelines/model";
import {Paging} from "../../../common/components/navigation/Paging";
import {VcsRunId} from "../../models";
import {OnError, SystemResponse} from "../../../common/api/TransportLayer";
import {QueryLoader} from "../../../common/components/QueryLoader";
import {Panel} from "../../../common/components/Panel";
import {PipelinesTableRow} from "./PipelinesTableRow";
import {ExtendedPipeline, extendPipeline, PipelinesTableRowField} from "../../utils/pipelines";
import {QueryResult} from "../../../common/api/query";

export type PipelineRunMenuProvider = (params: { pipeline: ExtendedPipeline, run: PipelineRun }) => ReactNode

export type PipelineHistoryPanelProps = {
  pipelineHistoryResult: QueryResult<SystemResponse<PipelineResults>>
  menu: PipelineRunMenuProvider
  onPage: (runId?: VcsRunId) => void
  onLimit: (limit: number) => void
  onEvents: (events: string[]) => void
} & OnError

const events = ["push", "schedule", "manual"];
const limits = [10, 15, 25];

export function PipelineHistoryPanel({
                                       pipelineHistoryResult,
                                       menu,
                                       onPage,
                                       onLimit,
                                       onError,
                                       onEvents,
                                     }: PipelineHistoryPanelProps) {
  const [pages, setPages] = useState<string[]>([])
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const [limit, setLimit] = useState<number>(10)
  const {data} = pipelineHistoryResult
  const nextPage = data && data.kind === "ok" ? data.data.lastRunId : undefined;

  const handlePreviousPage = () => setPages((prevState) => {
    prevState.pop();
    onPage(prevState[prevState.length - 1]);
    return prevState
  });

  const handleNextPage = () => setPages((prevState) => {
    if (nextPage) {
      prevState.push(nextPage)
      onPage(nextPage);
    }
    return prevState
  });

  const handleLimitChange = (event: SelectChangeEvent) => {
    const value = parseInt(event.target.value);
    setPages([])
    setLimit(value)
    onLimit(value)
  };

  const handleEventsChange = (event: SelectChangeEvent<typeof eventTypes>) => {
    const value = event.target.value
    const newEvents = typeof value === 'string' ? value.split(',') : value;
    setEventTypes(newEvents);
    onEvents(newEvents)
  };

  return <Grid item xs={12} data-test="pipeline-history">
    <Panel header="History" noPadding>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        alignItems: "center",
        p: 1,
        gap: 0.5
      }}>
        <Select
            sx={{width: 200}}
            multiple
            displayEmpty
            size="small"
            value={eventTypes}
            onChange={handleEventsChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>All events</em>;
              }

              return selected.join(', ');
            }}
        >
          {events.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={eventTypes.indexOf(name) > -1}/>
                <ListItemText primary={name}/>
              </MenuItem>
          ))}
        </Select>
        <Divider orientation="vertical" sx={{height: 40, mx: 2}}/>
        <Select
            aria-label="Page Size"
            size="small"
            value={limit.toString()}
            onChange={handleLimitChange}
        >
          {limits.map((value) => (
              <MenuItem key={`limit_${value}`} value={value}>{value}</MenuItem>
          ))}
        </Select>
        <Paging next={nextPage !== undefined}
                previous={pages.length > 0}
                onPrevious={handlePreviousPage}
                onNext={handleNextPage}/>
      </Box>
      <Divider/>
      <QueryLoader height={150} result={pipelineHistoryResult} onError={onError}
                   onLoaded={pipelineHistory => {

                     if (pipelineHistory.pipeline.runs.length === 0) return null;

                     return <Box>
                       <TableContainer>
                         <Table
                             sx={{minWidth: 750}}
                             aria-labelledby="tableTitle"
                             size={'medium'}
                         >
                           <TableBody>
                             {pipelineHistory.pipeline.runs.map((run) => {
                                   const extendedPipeline = extendPipeline(pipelineHistory.pipeline, run);
                                   return <PipelinesTableRow
                                       key={extendedPipeline.id + run.runId}
                                       pipeline={extendedPipeline}
                                       run={run}
                                       menu={menu}
                                       fields={{
                                         [PipelinesTableRowField.name]: false,
                                         [PipelinesTableRowField.buildStatusChart]: false
                                       }}

                                   />;
                                 }
                             )}
                           </TableBody>
                         </Table>
                       </TableContainer>
                     </Box>
                   }}/>

      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "right", alignItems: "center", p: 1, gap: 0.5}}>
        <Paging next={nextPage !== undefined}
                previous={pages.length > 0}
                onPrevious={handlePreviousPage}
                onNext={handleNextPage}/>
      </Box>
    </Panel>
  </Grid>
}
