import {TransportLayer, TransportLayerParams} from "../../common/api/TransportLayer";
import {
    CacheOptions,
    DeleteMutation,
    GetQuery,
    GetQueryOptions,
    Payload,
    PostMutation, PutMutation,
    refetchQueryOptions,
    Resource,
    useDelete,
    useGet,
    usePost, usePut
} from "../../common/api/query";
import {AccountApiRequestId} from "./AccountApiRequestId";
import {AccountAuthenticationStore} from "../auth/AccountAuthenticationStore";
import {useAccountAppInfrastructure} from "../AccountAppInfrastructure";
import {Analytics} from "../../common/port/Analytics";

export class AccountAppTransportLayer extends TransportLayer {
    private readonly baseUri: string;
    private authStore: AccountAuthenticationStore;

    constructor(analytics: Analytics, authStore: AccountAuthenticationStore, baseUri: string, headers?: Record<string, string>) {
        super(analytics, headers)
        this.authStore = authStore;
        this.baseUri = baseUri;
    }

    token(): Promise<string | undefined> {
        return new Promise((resolve) => resolve(this.authStore.token()))
    }

    logout(): void {
        this.authStore.logout()
    }

    baseUrl() {
        return this.baseUri
    }
}

export function useAccountApiGet<RESPONSE>(
    requestId: AccountApiRequestId,
    resource: Resource,
    transportOptions: TransportLayerParams = {},
    queryOptions: GetQueryOptions = refetchQueryOptions,
    cacheOptions: CacheOptions = {}
): GetQuery<RESPONSE> {
    const {transport, storage} = useAccountAppInfrastructure()
    return useGet(transport, storage.session, requestId, resource, transportOptions, queryOptions, cacheOptions)
}

export function useAccountApiPost<REQUEST = void, RESPONSE = void>(
    requestId: AccountApiRequestId,
    resource: Resource<REQUEST>,
    invalidates: AccountApiRequestId[] = [],
    transportOptions: TransportLayerParams = {},
    payload: Payload<REQUEST> = (req) => req,
): PostMutation<REQUEST, RESPONSE> {
    const {transport} = useAccountAppInfrastructure()
    return usePost(transport, requestId, resource, invalidates, transportOptions, payload)
}

export function useAccountApiPut<REQUEST = void, RESPONSE = void>(
    requestId: AccountApiRequestId,
    resource: Resource<REQUEST>,
    invalidates: AccountApiRequestId[] = [],
    transportOptions: TransportLayerParams = {},
    payload: Payload<REQUEST> = (req) => req,
): PutMutation<REQUEST, RESPONSE> {
    const {transport} = useAccountAppInfrastructure()
    return usePut(transport, requestId, resource, invalidates, transportOptions, payload)
}

export function useAccountApiDelete<REQUEST = void, RESPONSE = void>(
    requestId: AccountApiRequestId,
    resource: Resource<REQUEST>,
    invalidates: AccountApiRequestId[] = [],
    transportOptions: TransportLayerParams = {},
): DeleteMutation<REQUEST, RESPONSE> {
    const {transport} = useAccountAppInfrastructure()
    return useDelete(transport, requestId, resource, invalidates, transportOptions)
}
