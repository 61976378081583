import {VcsOwner} from "../../../common/models";

export class AccountUrls {

    static account() {
        return `/account`;
    }

    static configuration() {
        return "/account/config";
    }

    static orgConfigurations() {
        return "/account/config/orgs";
    }

    static availableOrgs() {
        return "/account/config/orgs/all";
    }

    static planHistory() {
        return "/account/history";
    }

    static enableOrg(params: { owner: VcsOwner }) {
        return `/account/config/orgs/${encodeURIComponent(params.owner)}`;
    }

    static disableOrg(params: { owner: VcsOwner }) {
        return `/account/config/orgs/${encodeURIComponent(params.owner)}`;
    }

    static configureVcsAccessToken() {
        return "/account/config/accessToken";
    }

    static configureAccountTeamUrl() {
        return "/account/config/teamId";
    }

    static configureOAuthCredentials() {
        return "/account/config/oauth";
    }
}
