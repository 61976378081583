import React, {ReactNode, useEffect} from 'react';
import {useAccountAuthentication} from "./useAccountAuthentication";
import {AuthenticationType} from "../../common/auth/AuthenticationType";
import {useRefetchAccountProfileQuery} from "../api/account";
import {UnauthorizedPage} from "../pages/error/UnauthorizedPage";

export function RequireAuthentication({children}: RequireAuthenticationWithProps) {
  const {enforceLogin} = useAccountAuthentication();
  const profile = useRefetchAccountProfileQuery()
  const isAuthenticated = profile.type === AuthenticationType.Account

  useEffect(() => {
    if (!isAuthenticated) {
      enforceLogin();
    }
  }, [isAuthenticated, enforceLogin]);

  if (!isAuthenticated) {
    return <UnauthorizedPage/>;
  }

  return <>{children}</>;
}

interface RequireAuthenticationWithProps {
  children: ReactNode;
}
