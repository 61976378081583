import {Toggle} from "../../api/model";
import * as React from "react";
import {useCallback} from "react";
import {Button, Typography} from "@mui/material";
import {snackOptions} from "../../../common/snackbar";
import {RegisterTwoFactorDialog, RegisterTwoFactorDialogProps} from "./RegisterTwoFactorDialog";
import {useSnackbar} from "notistack";

export type TwoFactorSetupProps = {
  toggle: Toggle
}

export function TwoFactorSetup({toggle}: TwoFactorSetupProps) {
  const [registerTwoFactorDialogProps, setRegisterTwoFactorDialogProps] = React.useState<RegisterTwoFactorDialogProps | undefined>(undefined);
  const {enqueueSnackbar} = useSnackbar();

  const handleOpenRegisterTwoFactorDialog = useCallback(async () => {
    setRegisterTwoFactorDialogProps({
      onSuccessful: () => enqueueSnackbar(`Registering TwoFactor Device`, snackOptions(true)),
      onError: () => enqueueSnackbar(`Failed to register TwoFactor Device`, snackOptions(false)),
      onSubmit: () => setRegisterTwoFactorDialogProps(undefined),
      onCancelled: () => setRegisterTwoFactorDialogProps(undefined)
    })
  }, [setRegisterTwoFactorDialogProps, enqueueSnackbar])
  return (<>
    {registerTwoFactorDialogProps && <RegisterTwoFactorDialog {...registerTwoFactorDialogProps}/>}
    <span>
      <Typography variant="body1" noWrap sx={{textOverflow: "ellipsis"}}>{descriptionFor(toggle)}</Typography>
      <Button aria-label="registerTwoFactorDeviceOpenButton" variant="outlined" color="primary"
              onClick={handleOpenRegisterTwoFactorDialog}>
        Register New Device
      </Button>
    </span>
  </>);
}


function descriptionFor(toggle: Toggle) {
  switch (toggle) {
    case Toggle.On:
      return "A TwoFactor Device is currently registered.";
    case Toggle.Off:
      return "No TwoFactor Device is currently registered.";
    case Toggle.Disabled:
      return "TwoFactor Device is unsupported.";
  }
}
