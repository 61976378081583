import {Box, Button, Chip, Divider, IconButton, List, ListItem, Skeleton, Typography} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";
import {OnError} from "../../../common/api/TransportLayer";
import {useSnackbar} from "notistack";
import {snackOptions} from "../../../common/snackbar";
import {useRemoveUserEmailMutation, useUserEmailsQuery} from "../../api/user";
import {RegisterEmailDialog, RegisterEmailDialogProps} from "./RegisterEmailDialog";
import {ErrorPanel} from "../../../common/components/ErrorPanel";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {ConfigurationSection} from "../../../common/components/configuration";
import {EmailAddress} from "../../../common/models";

export type EmailsPanelProps = OnError

export function EmailsPanel() {
  const {data: emailsResult} = useUserEmailsQuery()

  const [registerEmailDialogProps, setRegisterEmailDialogProps] = React.useState<RegisterEmailDialogProps | undefined>(undefined);
  const {enqueueSnackbar} = useSnackbar();

  const handleOpenRegisterEmailDialog = useCallback(async () => {
    setRegisterEmailDialogProps({
      onSuccessful: () => enqueueSnackbar(`Verification email sent`, snackOptions(true)),
      onError: () => enqueueSnackbar(`Failed to initiate email registration`, snackOptions(false)),
      onSubmit: () => setRegisterEmailDialogProps(undefined),
      onCancelled: () => setRegisterEmailDialogProps(undefined)
    })
  }, [setRegisterEmailDialogProps, enqueueSnackbar])


  if (!emailsResult) {
    return <Box>
      <Skeleton variant="rectangular" width="100%" height={100}/>
    </Box>
  }

  if (emailsResult.kind === "error") {
    return <ErrorPanel error={emailsResult.error}/>
  }

  return <ConfigurationSection data-test="emails" label="Emails">
    {registerEmailDialogProps && <RegisterEmailDialog {...registerEmailDialogProps}/>}
    <List>
      <EmailListItems emails={emailsResult.data.registered} pending={false}/>
      <EmailListItems emails={emailsResult.data.pending} pending={true}/>
      <Box sx={{display: "flex", p: 2, justifyContent: "right", alignItems: "right", width: "100%"}}>
        <Button aria-label="Register email button" variant="outlined" color="primary"
                onClick={handleOpenRegisterEmailDialog}>
          Register Email
        </Button>
      </Box>
    </List>
  </ConfigurationSection>;
}

type EmailListItemsProps = {
  emails: EmailAddress[]
  pending: boolean
}

function EmailListItems({emails, pending}: EmailListItemsProps) {
  const menuSpacingWidth = 10
  const removeEmail = useRemoveUserEmailMutation()
  const {enqueueSnackbar} = useSnackbar()
  const handleRemoveEmail = useCallback(async (email: EmailAddress) => {
    const response = await removeEmail.mutateAsync({email})
    const isSuccess = response.kind === "ok";
    if (isSuccess) {
      enqueueSnackbar(`Email ${email} removed successfully`, snackOptions(true))
    } else {
      enqueueSnackbar(`Failed to remove email ${email}`, snackOptions(false))
    }
  }, [removeEmail, enqueueSnackbar])

  return <>
    {
      emails.map((email) => <Box key={"pending:" + pending + email}>
        <ListItem>
          <Box sx={{display: "flex", flexDirection: "row", height: 35, width: "100%"}} alignItems="center">
            <Box sx={{flexGrow: 1, width: "100%"}}>
              <Typography noWrap>{email}</Typography>
            </Box>
            <Box sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              marginLeft: `${menuSpacingWidth}px`,
              justifyContent: "center",
              alignItems: "center"
            }}>
              {pending && <Chip sx={{mx: 1}} label="pending verification"/>}
              <IconButton aria-label="disable" onClick={() => handleRemoveEmail(email)}>
                <CloseRoundedIcon/>
              </IconButton>
            </Box>
          </Box>
        </ListItem>
        <Divider sx={{mt: 1, mb: 1}}/>
      </Box>)

    }
  </>
}
