import {Grid} from "@mui/material";
import {monopolisConstraints} from "../../../common/styles/constraints";
import * as React from "react";
import {OrgPageTabProps} from "./OrgPageTab";
import {OrgDashboardPanel} from "../../components/dashboard/OrgDashboardPanel";
import {OrgDashboardListPanel} from "../../components/dashboard/OrgDashboardListPanel";
import {getQuery} from "../../../common/navigation";
import {dashboardIdParamName} from "../../utils/urlParams";
import {optionalStringFrom} from "../../../common/models";

export function OrgPageDashboardsTab({owner, onError}: OrgPageTabProps) {
  const dashboardId = optionalStringFrom(getQuery(dashboardIdParamName))

  return <Grid container spacing={monopolisConstraints.gridSpacing}>
    {
        !dashboardId && <Grid item xs={12}>
          <OrgDashboardListPanel onError={onError} owner={owner}/>
        </Grid>
    }

    {
        dashboardId && <Grid item xs={12}>
          <OrgDashboardPanel
              isEditing={true}
              dashboardId={dashboardId}
              owner={owner}
              onError={onError}
          />
        </Grid>
    }
  </Grid>
}
