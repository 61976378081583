import React, {ReactNode} from 'react';
import {useUserAuthentication} from "./useUserAuthentication";
import {AuthenticationType} from "../../common/auth/AuthenticationType";

export function AuthenticationOrFallback({children, fallback}: RequireAuthenticationWithProps) {
  const {userProfile} = useUserAuthentication();
  const isAuthenticated = userProfile.type !== AuthenticationType.Unauthenticated

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <>{fallback}</>
  }
}

interface RequireAuthenticationWithProps {
  children: ReactNode;
  fallback: ReactNode;
}
