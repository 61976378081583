import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {FollowDocumentationToConfigureHint} from "../hints";
import {useMonodeDocumentationContentQuery, useMonodeDocumentationListQuery} from "../../api/configurations";
import {OnError} from "../../../common/api/TransportLayer";
import {Monode, VcsBranch, VcsRepoFilePathType} from "../../models";
import {Box, Breadcrumbs, Grid, Skeleton} from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  breadcrumbsFrom,
  contentDescriptionFrom,
  DocumentationContentDescription,
  DocumentationPluginConfig,
  fileNameFrom,
  humaniseIfMarkdown,
  isMarkdown,
  makeRelativeTo,
  mergePaths,
  nearestDirectoryFrom
} from "../../api/configurations/model";
import {Panel} from "../../../common/components/Panel";
import {Link} from "../../../common/components/navigation/Link";
import {getFragment, setFragment, useQueryParamOrDefault} from "../../../common/navigation";
import {DocumentationListPanel} from "./DocumentationListPanel";
import {parseMarkdownTOC} from "../../utils/markdown";
import {monopolisTheme} from "../../../common/styles/theme";
import {DocumentationTOCItem, DocumentationTOCMenu} from "./DocumentationTOCMenu";
import {PluginsUrls} from "../../api/configurations/urls";
import {DocumentationContentPanel} from "./DocumentationContentPanel";
import {DocumentationSetItem, DocumentationSetMenu} from "./DocumentationSetMenu";

import {OrgRepoPath} from "../../../common/models";
import {useNavigation} from "../../../common/port/Navigation";
import {useUserAppInfrastructure} from "../../UserAppInfrastructure";

export type DocumentationPanelProps = {
  path: OrgRepoPath
  monode: Monode
  branch: VcsBranch
  documentation: DocumentationPluginConfig[]
} & OnError


export function DocumentationPanel({onError, documentation, ...props}: DocumentationPanelProps) {
  const {docsUrls} = useUserAppInfrastructure()
  const [tableOfContents, setTableOfContents] = useState<DocumentationTOCItem[] | undefined>(undefined)
  const {navigateTo} = useNavigation()

  const docsSetParam = useQueryParamOrDefault("docs", documentation[0].name)
  const dirParam = useQueryParamOrDefault("dir", "")
  const fileParam = useQueryParamOrDefault("file", "")
  const fragment = getFragment()

  const dirDesc: DocumentationContentDescription = {path: dirParam.value, type: VcsRepoFilePathType.dir}
  const fileDesc = contentDescriptionFrom(dirDesc, fileParam.value)
  const docsSet = documentation.find(doc => doc.name === docsSetParam.value);
  const tocDesc = tableOfContents?.find(tocItem => tocItem.id === fragment)

  const docsSetRoot = docsSet?.path || "";
  const breadcrumbs = breadcrumbsFrom(docsSetRoot, fileDesc.path.trim() !== "" ? fileDesc : dirDesc)
  const dirPath = mergePaths(docsSetRoot, dirParam.value);
  const filePath = mergePaths(docsSetRoot, dirParam.value, fileParam.value);
  const loadContent = fileDesc.type === VcsRepoFilePathType.file && isMarkdown(fileDesc.path);

  const {data: list} = useMonodeDocumentationListQuery({
    ...props,
    file: dirPath,
    onQueryError: onError
  });

  const {data: content} = useMonodeDocumentationContentQuery({
    ...props,
    file: filePath,
    enabled: loadContent,
    onQueryError: onError
  });

  const scrollToFragment = (fragment: string) => {
    const element = document.getElementById(fragment)
    element && element.scrollIntoView({behavior: "smooth"})
  }

  const handleFragment = useCallback((fragment: string) => {
    navigateTo(setFragment(fragment), {replace: true})
    scrollToFragment(fragment)
  }, [navigateTo])

  useEffect(() => {
    const timer = setInterval(
        () => content && content.kind === "ok" && setTimeout(() => {
          scrollToFragment(getFragment())
          clearInterval(timer)
        }, 200),
        100
    );
  }, [handleFragment, content]);


  const handleContentChange = useCallback((selected: DocumentationContentDescription) => {
    setTableOfContents(undefined)
    const root = nearestDirectoryFrom({...selected, path: makeRelativeTo(docsSetRoot, selected.path)});

    root.path === ""
        ? dirParam.delete()
        : dirParam.set(root.path)

    selected.type === VcsRepoFilePathType.file
        ? fileParam.set(fileNameFrom(selected))
        : fileParam.delete()

    navigateTo(setFragment(""), {replace: true})
  }, [dirParam, docsSetRoot, fileParam, navigateTo])

  const handleDocumentationSetChange = (selected: DocumentationSetItem) => {
    setTableOfContents(undefined)
    docsSetParam.set(selected.name)
    fileParam.delete()
    dirParam.delete()
    navigateTo(setFragment(""), {replace: true})
  }

  const handleContentUrlTransform = (path: string) => {
    return PluginsUrls.documentationContentUrl({
      ...props,
      file: mergePaths(docsSetRoot, contentDescriptionFrom(dirDesc, path).path)
    });
  }

  if (fileDesc.type === VcsRepoFilePathType.dir && list && list.kind === "ok" && list.data.index) {
    fileParam.set(fileNameFrom({path: list.data.index, type: VcsRepoFilePathType.file}))
  }

  if (content && content.kind === "ok" && !tableOfContents) {
    setTableOfContents(parseMarkdownTOC(content.data))
  }

  if (docsSetRoot.trim() === "")
    return <FollowDocumentationToConfigureHint feature="documentation" url={docsUrls.configureAPIs()}/>

  const docListing = list && list.kind === "ok" ? list?.data?.items : []

  const header = <Box sx={{display: "flex", flexDirection: "row", width: "100%"}}>
    <Box sx={{display: "flex", flexGrow: 1, alignItems: "center"}}>
      <Breadcrumbs>
        <DocumentationSetMenu items={documentation} selected={docsSet} onItem={handleDocumentationSetChange}
                              disabled={false}/>

        <Link href="#"
              sx={{display: "flex", alignItems: "center"}}
              onClick={() => handleContentChange({path: "", type: VcsRepoFilePathType.dir})}>
          {docsSet?.name || <HomeOutlinedIcon sx={{flex: 1, alignItems: "center"}} fontSize="small"/>}
        </Link>

        {breadcrumbs.map(breadcrumb => <Link key={breadcrumb.path}
                                             href="#"
                                             onClick={() => handleContentChange(breadcrumb)}>{humaniseIfMarkdown(breadcrumb.name)}
        </Link>)}
      </Breadcrumbs>
    </Box>
    <Box sx={{display: "flex", alignItems: "center"}}>
      <DocumentationTOCMenu
          selected={tocDesc}
          disabled={!tableOfContents || tableOfContents.length === 0}
          items={tableOfContents || []}
          onItem={(item) => handleFragment(item.id)}
      />
    </Box>
  </Box>

  return <Grid item xs={12} id="docs-panel">
    <Panel noPadding header={header}>
      <Grid container>
        <Grid item xs={12} md={3} borderRight={1} borderColor={monopolisTheme.palette.divider}>
          {
            docListing ? <DocumentationListPanel path={props.path}
                                                 onContent={content => handleContentChange(content)}
                                                 selected={mergePaths(docsSetRoot, fileDesc.path)}
                                                 content={docListing}/>
                : <Skeleton height={50} sx={{m: 2}}/>
          }
        </Grid>
        <Grid container item xs={12} md={9} spacing={1}>
          <Grid item xs={12}>
            <Box padding={2}>
              <DocumentationContentPanel path={props.path}
                                         monode={props.monode}
                                         file={filePath}
                                         branch={props.branch}
                                         onFragment={handleFragment}
                                         onError={onError}
                                         transformContentUrl={handleContentUrlTransform}/>

            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  </Grid>
}
