import {defaultRefetchInterval} from "../../common/api/query";
import {PlanFeature, PlanLimit, PlanPurchase} from "../../common/models";
import {MaybeOrgConfiguration, OrgConfiguration} from "../../common/models";

export interface SelectionLimit {
    max: number
    selected: number
    available: number
    isLimitReached: boolean
}

function selectionLimitFor(max: number, selected: number) {
    const available = max - selected;
    const isLimitReached = available <= 0
    return {max, selected, available, isLimitReached}
}

export function repoLimitFrom(orgConfiguration: OrgConfiguration): SelectionLimit {
    return selectionLimitFor(orgConfiguration.plan.limits[PlanLimit.Repos] || 3, orgConfiguration.repos.length);
}

export function memberLimitFrom(orgConfiguration: OrgConfiguration, purchases: PlanPurchase[]): SelectionLimit {
    // TODO: get member count for org
    return selectionLimitFor(purchases.find(p => !p.plan.endAt)?.billing?.numberOfSeats || 5, orgConfiguration.members.length);
}

export function queryRefetchIntervalFrom(orgConfiguration: MaybeOrgConfiguration) {
    const limit = orgConfiguration?.plan?.limits[PlanLimit.RefreshSeconds];
    return (limit ? limit * 1000 : defaultRefetchInterval)
}

export function showDoraStats(orgConfiguration: MaybeOrgConfiguration) {
    return isFeatureOn(orgConfiguration, PlanFeature.DoraStats)
}

function isFeatureOn(orgConfiguration: MaybeOrgConfiguration, feature: PlanFeature) {
    return orgConfiguration !== undefined && orgConfiguration.plan.features.find(f => f === feature) != null;
}

