import {Monode, RolloutTarget, VcsBranch} from "../../models";
import {buildUrl, UrlQueryParam} from "../../../common/urls";


import {branchParam} from "../../utils/urlParams";
import {OrgRepoPath, VcsOwner} from "../../../common/models";

export class StatisticsUrls {

    static repositoryBuildStats(params: { path: OrgRepoPath, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/stats/build/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`, query)
    }

    static pipelineBuildStats(params: { path: OrgRepoPath, monode: Monode, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/stats/build/pipeline/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}`, query)
    }

    static orgGroupedBuildStats(params: { owner: VcsOwner }) {
        return `/stats/grouped/build/org/${params.owner}`;
    }

    static repositoryGroupedBuildStats(params: { path: OrgRepoPath, branch?: VcsBranch }) {
        const query = Array<UrlQueryParam>()
        if (params.branch) query.push(branchParam(params.branch));
        return buildUrl(`/stats/grouped/build/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}`, query)
    }

    static orgRolloutStats(params: { owner: VcsOwner, target: RolloutTarget }) {
        return `/stats/rollout/org/${params.owner}/${params.target}`;
    }

    static repositoryRolloutStats(params: { path: OrgRepoPath, target: RolloutTarget }) {
        return `/stats/rollout/repo/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.target}`;
    }

    static monodeRolloutStats(params: { path: OrgRepoPath, monode: Monode, target: RolloutTarget }) {
        return `/stats/rollout/monode/${params.path.org}/${encodeURIComponent(params.path.repo)}/${params.monode}/${params.target}`;
    }

}
