import {IntegrationSettingsPanelProps} from "./IntegrationSettingsPanel";
import React from "react";
import {Box, Typography} from "@mui/material";
import {ConfigurationSection} from "../../../common/components/configuration";

export type NotYetImplementedIntegrationSettingsPanelProps = IntegrationSettingsPanelProps<any>;

export function NotYetImplementedIntegrationSettingsPanel({type}: NotYetImplementedIntegrationSettingsPanelProps) {
  return <ConfigurationSection data-test="not-yet-implemented-integration" label={type}>
    <Box>
      <Typography pb={1} color="text.secondary">{type} integration coming soon...</Typography>
    </Box>
  </ConfigurationSection>
}
