export type NotificationChannel = string
export type NotificationChannelType = "Slack" | "Discord"

export enum NotificationEvent {
    RolloutStarted = "RolloutStarted",
    RolloutFinished = "RolloutFinished",
    BuildStatusChanged = "BuildStatusChanged",
    IncidentUpdate = "IncidentUpdate",
    MonodeAlert = "MonodeAlert",
    Push = "Push"
}

export type NotificationRequest = {
    event: NotificationEvent,
    channel: NotificationChannel
}

export type NotificationChannelDetails = {
    name: string
    channel: NotificationChannel
    type: NotificationChannelType
}

export type NotificationSubscriptions = Record<NotificationEvent, NotificationChannel[]>
