import * as React from "react";
import Container from "@mui/material/Container";
import {CopyrightAndStatus} from "./CopyrightAndStatus";
import {monopolisTheme} from "../styles/theme";

export function SimpleFooter() {
  return <Container
      component="footer"
      sx={{
        minWidth: "100%",
        borderTop: `1px solid ${monopolisTheme.palette.secondary.main}`,
        backgroundColor: monopolisTheme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
      }}
  >
    <Container maxWidth="md">
      <CopyrightAndStatus sx={{mt: 4, mb: 4}}/>
    </Container>
  </Container>;
}
