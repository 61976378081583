import {Pipeline, pipelineId, PipelineRun, pipelineStatus, PipelineStatus} from "../api/pipelines/model";
import {StatisticsAggregate, StatisticsGroupAggregate} from "../api/statistics/model";
import * as React from "react";
import {ReactNode} from "react";
import {PipelineMenu} from "../components/pipelines/PipelineMenu";
import {VcsOwner, VcsPath} from "../../common/models";
import {VcsProvider} from "../../common/models";

export enum PipelinesTableRowField {
  name = "Name",
  status = "Status",
  commit = "Commit",
  buildStatusChart = "Build Status Chart",
}

const defaultFields: PipelinesTableRowField[] = [
  PipelinesTableRowField.name,
  PipelinesTableRowField.status,
  PipelinesTableRowField.commit,
  PipelinesTableRowField.buildStatusChart,
]

export type PipelinesTableRowFieldsFilter = Partial<Record<PipelinesTableRowField, boolean>>

export type PipelineMenuProvider = (params: { pipeline: ExtendedPipeline, run: PipelineRun }) => ReactNode

export function hasRowField(field: PipelinesTableRowField, filter?: PipelinesTableRowFieldsFilter): boolean {
  const value = filter && filter[field];
  return value !== undefined ? value : defaultFields.indexOf(field) >= 0
}

export type ExtendedPipeline = Pipeline & {
  id: string
  status: PipelineStatus
  statistics?: StatisticsAggregate
  vcs: VcsProvider
  org: VcsOwner
  repo: VcsPath
}

export function extendPipeline(pipeline: Pipeline, run: PipelineRun, statistics?: StatisticsAggregate) {
  const {org, repo} = pipeline.path
  return ({
    ...pipeline,
    id: pipelineId(pipeline),
    status: pipelineStatus(run),
    statistics,
    org,
    repo
  });
}

export function extendPipelines(pipelines: Pipeline[], statistics: StatisticsGroupAggregate[] = []): ExtendedPipeline[] {
  const statsMap = new Map(statistics.map(i => [i.group, i.statistics]));
  return pipelines.map(pipeline => {
    const pipelineStats = statsMap.get(`${pipeline.vcs}/${pipeline.path}?${pipeline.branch}?${pipeline.name}`);
    return extendPipeline(pipeline, pipeline.runs[0], pipelineStats);
  })
}

export const monodeActionsMenu: PipelineMenuProvider = (params) =>
    <PipelineMenu {...params}
                  actions={["rerun", "cancel", "rollout", "addToWatchlist", "removeFromWatchlist"]}/>;

export const pipelineActionsMenu: PipelineMenuProvider = (params) =>
    <PipelineMenu {...params} actions={["rerun", "cancel", "addToWatchlist", "removeFromWatchlist", "remove"]}/>;

